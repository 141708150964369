import dayjs from "dayjs";

import { BusinessType } from "../enums";

export const formatDate = (date: string) => {
  const parsedDate = new Date(date);
  return new Intl.DateTimeFormat("ro-RO", {
    dateStyle: "medium",
    timeStyle: "short",
  }).format(parsedDate);
};

export const betweenDates = (startDate: Date, endDate: Date) => {
  if (
    (dayjs().isSame(dayjs(startDate), "day") ||
      dayjs().isAfter(dayjs(startDate), "day")) &&
    (dayjs().isSame(dayjs(endDate), "day") ||
      dayjs().isBefore(dayjs(endDate), "day"))
  ) {
    return true;
  }

  return false;
};

export const afterDate = (date: Date) => {
  return dayjs().isAfter(dayjs(date), "day");
};

export const afterDateOrSame = (date: Date) => {
  return (
    dayjs().isAfter(dayjs(date), "day") || dayjs().isSame(dayjs(date), "day")
  );
};

export const beforeDate = (date: Date) => {
  return dayjs().isBefore(dayjs(date), "day");
};

export const beforeDateOrSame = (date: Date) => {
  return (
    dayjs().isBefore(dayjs(date), "day") || dayjs().isSame(dayjs(date), "day")
  );
};

export const sameDate = (date: Date) => {
  return dayjs().isSame(dayjs(date), "day");
};

export enum UserSearchOptions {
  NUME = "Nume",
  EMAIL = "E-mail",
  CNP = "CNP",
  CUI = "CUI",
}

export enum PfPaymentsSearchOptions {
  NUME = "Nume",
  CNP = "CNP",
}

export enum PjPaymentsSearchOptions {
  DENUMIRE = "Denumire",
  CUI = "CUI",
}

export function downloadBase64File(
  base64Data: string,
  contentType: string,
  fileName: string,
) {
  const linkSource = `data:${contentType};base64,${base64Data}`;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export type Option = {
  label: string;
  value: BusinessType;
};

export const businessTypeOptions: Option[] = Object.keys(BusinessType).map(
  (key) => ({
    label: key,
    value: BusinessType[key as keyof typeof BusinessType],
  }),
);

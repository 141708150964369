import { faAdd, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row, Spinner, Stack } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IContractTemplate } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export default function ListContractTemplates() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const [templates, setTemplates] = useState<IContractTemplate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [newTemplate, setNewTemplate] = useState<{ [key: string]: any }>({
    name: "",
    file: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const client = useAPIClient();
  const [tplToGenerate, setTplToGenerate] = useState("");
  const [key, setKey] = useState("");
  const [keyValue, setKeyValue] = useState("");
  const [keyValuePairs, setKeyValuePairs] = useState<{ [key: string]: any }>(
    {},
  );
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [tplToDelete, setTplToDelete] = useState({} as IContractTemplate);

  const fetchContractTemplates = async () => {
    try {
      const res = await client.getContractTemplates();
      setTemplates(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchContractTemplates();
  }, []);

  const validate = () => {
    let valid = true;
    let _errors: { [key: string]: any } = {};

    if (!newTemplate.name.trim()) {
      _errors.name = t("templates.errors_name");
      valid = false;
    }

    if (!newTemplate.file) {
      _errors.file = t("templates.errors_load");
      valid = false;
    }

    if (newTemplate.file) {
      let fileName = newTemplate.file.name;
      let fileExtension = fileName.split(".").pop();

      if (fileExtension !== "docx") {
        _errors.file = t("templates.errors_docx");
        valid = false;
      }
    }

    setErrors(_errors);

    return valid;
  };

  const handleAddTemplate = async () => {
    if (!validate()) return;

    setIsSubmitting(true);
    try {
      await client.createContractTemplate(newTemplate);
      toast.success(t("templates.success_add"));
      setNewTemplate({
        ...newTemplate,
        name: "",
        file: "",
      });
      fetchContractTemplates();
      setOpen(false);
    } catch (e) {
      // console.log(e);
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDeleteTpl = async () => {
    try {
      await client.deleteContractTemplate(tplToDelete.id);
      toast.success(t("templates.success_delete"));
      fetchContractTemplates();
      setShowConfirmDeletion(false);
    } catch (e) {
      // console.log(e);
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5 text-uppercase text-center">
              {t("templates.title")}
            </h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                    <Button
                      variant="outline-primary"
                      className="m-1"
                      // style={{ float: "right" }}
                      onClick={() => setOpen(true)}
                    >
                      <FontAwesomeIcon icon={faAdd} className="me-2" />
                      {t("templates.add")}
                    </Button>
                  </Col>
                  <Col lg={12}>
                    {templates && templates.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h5 className="mb-0">{t("templates.none")}</h5>
                      </div>
                    ) : (
                      <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                          {isLoading ? (
                            <LoadingView />
                          ) : (
                            <Table
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="border-0 text-center">#</th>
                                  <th className="border-0 text-center">{t("date")}</th>
                                  <th className="border-0 text-center">{t("templates.name")}</th>
                                  <th className="border-0 text-center">
                                    {t("templates.file")}
                                  </th>
                                  <th className="border-0 text-center"></th>
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {orderBy(
                                  templates,
                                  ["createdAt"],
                                  ["desc"],
                                ).map((template, i) => (
                                  <tr key={i}>
                                    <td>
                                      <Card.Link
                                        href="#"
                                        className="text-primary fw-bold"
                                      >
                                        {i + 1}
                                      </Card.Link>
                                    </td>
                                    <td>
                                      {dayjs(template.createdAt).format(
                                        "DD-MM-YYYY",
                                      )}
                                    </td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>
                                      {template.name}
                                    </td>
                                    <td>
                                      <Card.Link
                                        onClick={() =>
                                          client.getDocumentTemplateFile(
                                            template.id,
                                          )
                                        }
                                        target="_blank"
                                      >
                                        {t("download_file")}{" "}
                                        <FontAwesomeIcon
                                          icon={faExternalLinkAlt}
                                          className="ms-1"
                                        />
                                      </Card.Link>
                                    </td>
                                    <td className="text-center">
                                      <Button
                                        variant="danger"
                                        size="sm"
                                        onClick={() => {
                                          setShowConfirmDeletion(true);
                                          setTplToDelete(template);
                                        }}
                                      >
                                        {t("delete")}
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </Card.Body>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal de adaugare sablon */}
      <Modal show={open} size="lg" onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("templates.add_contract_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="name">
                  <Form.Label>{t("templates.name")}</Form.Label>
                  <Form.Control
                    id="name"
                    className={errors.name && "error-control"}
                    type="text"
                    value={newTemplate.name && newTemplate.name}
                    onChange={({ target: { value } }) =>
                      setNewTemplate({
                        ...newTemplate,
                        name: value,
                      })
                    }
                    required
                  />
                </Form.Group>
                {errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="file">
                  <Form.Label>{t("templates.docx")}</Form.Label>
                  <Form.Control
                    type="file"
                    id="file"
                    accept=".docx"
                    // className="form-control "
                    className={errors.file && "error-control"}
                    required
                    onChange={({ target: { files } }: any) =>
                      setNewTemplate({ ...newTemplate, file: files[0] })
                    }
                  />
                  {errors.file && (
                    <span className="error-message">{errors.file}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpen(false)}>
          {t("cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddTemplate}
            disabled={isSubmitting}
          >
            {t("save")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal confirmare stergere */}
      <Modal
        show={showConfirmDeletion}
        onHide={() => setShowConfirmDeletion(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("delete_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("templates.delete_warn")} "{tplToDelete && tplToDelete.name}"?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowConfirmDeletion(false)}
          >
            {t("cancel")}
          </Button>
          <Button variant="primary" onClick={handleDeleteTpl}>
          {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import React, { useState } from "react";
import { Form, Button, Modal, ModalProps, Badge, Stack } from "react-bootstrap";
import { useAPIClient } from "../../../helpers/api";
import { toast } from "react-hot-toast";
import { ICategory, ISurvey, ISurveyAnswer } from "../../../cjt";
import { MdClose } from "react-icons/md";
import "../../../assets/css/tags.css";
import { Trash3 } from "react-bootstrap-icons";
import handleException from "../../../helpers/exceptions";
import { useTranslation } from "react-i18next";

interface IModalAddQuestionProps extends ModalProps {}

function ModalAddQuestion({
  showModalAddQuestion,
  setShowModalAddQuestion,
  setShowModalAddSurvey,
  surveys,
  fetchSurveys,
  handleClose,
}: IModalAddQuestionProps) {
  const [selectedSurvey, setSelectedSurvey] = useState("");
  const client = useAPIClient();
  const { t } = useTranslation();
  const [questionContent, setQuestionContent] = useState("");
  const [answerContent, setAnswerContent] = useState("");

  const [keywords, setKeywords] = useState<string[]>([]);
  const [newKeyword, setNewKeyword] = useState<string>("");

  const addKeyword = () => {
    if (newKeyword.trim() !== "") {
      if (keywords.includes(newKeyword)) {
        toast.error(t("survey.error_question_exists"))
        return;
      }

      setKeywords([...keywords, newKeyword]);
      setNewKeyword("");
    } else {
      toast.error(t("survey.insert_keyword"));
    }
  };

  const removeKeyword = (index: number) => {
    const updatedKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(updatedKeywords);
  };

  const addQuestion = async () => {
    if (selectedSurvey === "") {
      toast.error(t("survey.error_select_survey"));
      return;
    }
    if (questionContent === "") {
      toast.error(t("survey.error_insert_question"));
      return;
    }
    if (keywords.length === 0) {
      toast.error(t("survey.error_insert_answer"));
      return;
    }

    try {
      const question = await client.addSurveyQuestion(
        selectedSurvey,
        questionContent
      );

      for (let i = 0; i < keywords.length; i++) {
        const answer = await client.addSurveyAnswer(
            question.id,
            keywords[i]
        )  
      }

      setShowModalAddSurvey(false);
      setShowModalAddQuestion(false);
      fetchSurveys();
      toast.success(t("survey.success_question_register"));
      setSelectedSurvey("");
      setQuestionContent("");
      setAnswerContent("");
      setKeywords([]);
    } catch (error: any) {
      // console.log(err);
      toast.error(t(error.response.data.message));
    }
  };
  return (
    <Modal show={showModalAddQuestion} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("survey.new_question")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="category">
          <Form.Label>{t("survey.select_a_survey")}:</Form.Label>
          <Form.Control
            as="select"
            value={selectedSurvey}
            onChange={(e) => setSelectedSurvey(e.target.value)}
          >
            <option value="">{t("survey.select_a_survey_option")}</option>
            {surveys &&
              surveys.map((survey: ISurvey) => (
                <option key={survey.id} value={survey.id}>
                  {survey.name}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="question" className="mt-3">
          <Form.Label>{t("survey.question")}:</Form.Label>
          <Form.Control
            type="text"
            // placeholder="Intrebare"
            value={questionContent}
            onChange={(e) => setQuestionContent(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="keyword" className="mt-3">
          <Form.Label>{t("survey.add_asn_to_question")}:</Form.Label>
          <div className="d-flex ">
            <Form.Control
              type="text"
              // placeholder="Enter a new keyword"
              value={newKeyword}
              style={{ width: "80%", marginRight: "10px" }}
              onChange={(e) => setNewKeyword(e.target.value)}
            />
            <Button variant="primary" onClick={addKeyword}>
            {t("calendar.add")}
            </Button>
          </div>
        </Form.Group>

        <Stack direction="horizontal" className="mt-3">
          {keywords.map((keyword, index) => (
            <div className="single-tag" key={index} style={{color:"black",border:"0"}}>
                <Form.Check
                    type="radio"
                    label={keyword}
                    disabled
                />
                <i style={{color:"black"}} onClick={() => removeKeyword(index)}>
                    <MdClose />
                </i>
            </div>
          ))}
        </Stack>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        {t("grant_apply.close")} 
        </Button>
        <Button variant="primary" onClick={addQuestion}>
        {t("calendar.add")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddQuestion;

import { Card, Col } from "react-bootstrap";
import { Icon } from "./Icon";

type Props = {
  iconName: string;
  buttonText: string;
  route: string;
  disabled: boolean;
};

function ModuleButton({ iconName, buttonText, route }: Props) {
  return (
    <>
      <Col className="p-2 col-md-2 d-flex">
        <Card>
          <Card.Body className="text-center">
            <Card.Title>
              <Icon iconName={iconName} size={40} />
            </Card.Title>
            <Card.Text>
              <a href={route} className="btn stretched-link">
                {buttonText}
              </a>
            </Card.Text>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}

export default ModuleButton;

import { useState } from "react";
import { Button, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IAuditor, IUser } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

type HearingRequestModalProps = {
  show: boolean;
  onHide: () => void;
  desiredPerson: IAuditor;
  inviteePerson: IUser;
  hearingReason: string;
  context: string;
  id: string;
};

const HearingRequestModal = ({
  show,
  onHide,
  desiredPerson,
  inviteePerson,
  hearingReason,
  context,
  id,
}: HearingRequestModalProps) => {
  const client = useAPIClient();

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reason, setReason] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const handleClickOnDate = (event: any) => {
    event.target.showPicker();
  };
  const isDifferenceBiggerThanDay = (
    dateStringBegin: Date,
    dateStringEnd: Date,
  ): boolean => {
    const difference = Math.abs(
      dateStringBegin.getTime() - dateStringEnd.getTime(),
    );
    const millisecondsInDay = 24 * 60 * 60 * 1000;

    return difference > millisecondsInDay;
  };
  const handleSchedule = async () => {
    try {
      setIsSubmitting(true);
      const hBeginDate = new Date(startDate);
      const hEndDate = new Date(endDate);
      if (isDifferenceBiggerThanDay(hBeginDate, hEndDate))
        throw new Error(
          "Diferenta intre data de inceput si data de sfarsit nu poate fi mai mare de o zi!",
        );

      await client.approveHearingRequest(id, reason, hBeginDate, hEndDate);

      //await client.deleteHearingRequest(id);

      toast.success("Audiența a fost programată cu succes!");
      onHide();
      navigate("/admin/hearing-requests");
    } catch (error: any) {
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
      // console.log(error);
      // if (Array.isArray(error)) {
      //   error.forEach((err) => toast.error(err.message));
      // } else if (error instanceof Error) {
      //   toast.error(error.message);
      // } else {
      //   toast.error(t(JSON.parse(error.request.response).message));
      // }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStartDateChanged = (value: string) => {
    setStartDate(value);
  };

  const handleEndDateChanged = (value: string) => {
    setEndDate(value);
  };
  const handleChangeReason = (reason: string) => {
    setReason(reason);
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Programare audiență online</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OverlayTrigger
          overlay={
            <Tooltip>
              Datele introduse mai jos sunt conform fusului orar al
              Romaniei(GMT+2/3)
            </Tooltip>
          }
        >
          <p>Setați data de început pentru audență</p>
        </OverlayTrigger>
        <input
          type="datetime-local"
          id="datetime"
          name="startDate"
          value={startDate}
          onChange={({ target: { value } }) => handleStartDateChanged(value)}
          className="form-control mb-4"
          onClick={handleClickOnDate}
        />

        <OverlayTrigger
          overlay={
            <Tooltip>
              Datele introduse mai jos sunt conform fusului orar al
              Romaniei(GMT+2/3)
            </Tooltip>
          }
        >
          <p>Setați data de sfârșit pentru audență</p>
        </OverlayTrigger>
        <input
          type="datetime-local"
          id="datetime"
          name="endDate"
          value={endDate}
          onChange={({ target: { value } }) => handleEndDateChanged(value)}
          className="form-control"
          onClick={handleClickOnDate}
        />
        <p className="mt-2">
          Introduceti motivul acceptarii sau detalii suplimentare despre
          audienta:
        </p>
        <input
          type="text"
          id="reasonInput"
          name="reason"
          value={reason}
          onChange={({ target: { value } }) => handleChangeReason(value)}
          className="form-control"
        />
      </Modal.Body>
      <Modal.Footer>
        {!isSubmitting ? (
          <>
            <Button variant="secondary" onClick={onHide}>
              Renunță
            </Button>
            <Button variant="primary" onClick={() => handleSchedule()}>
              Programare
            </Button>
          </>
        ) : (
          <LoadingView />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default HearingRequestModal;

/// REJECT MODAL

export const HearingRequestRejectModal = ({
  show,
  onHide,
  id,
}: Partial<HearingRequestModalProps>) => {
  const client = useAPIClient();

  const navigate = useNavigate();
  const [reason, setReason] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const handleClickOnDate = (event: any) => {
    event.target.showPicker();
  };
  const isDifferenceBiggerThanDay = (
    dateStringBegin: string,
    dateStringEnd: string,
  ): boolean => {
    const date1 = new Date(dateStringBegin);
    const date2 = new Date(dateStringEnd);
    const difference = Math.abs(date1.getTime() - date2.getTime());
    const millisecondsInDay = 24 * 60 * 60 * 1000;

    return difference > millisecondsInDay;
  };

  const handleChangeReason = (reason: string) => {
    setReason(reason);
  };
  const handleReject = async () => {
    if (id === null) {
      return;
    }

    try {
      //await client.deleteHearingRequest(requestId); -> we reject hearing requests instead of deleting them
      await client.rejectHearingRequest(id!, reason);
      navigate("/admin/hearing-requests");
      toast.success("Cererea a fost refuzată!");
    } catch (error) {
      // toast.error("Cererea nu a putut fi refuzată!");
      // console.log(error);
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Programare audiență online</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Introduceti motivul respingerii cererii de audienta: </p>
        <textarea
          id="reasonInput"
          name="reason"
          value={reason}
          onChange={({ target: { value } }) => handleChangeReason(value)}
          className="form-control mb-4"
        />
      </Modal.Body>
      <Modal.Footer>
        {!isSubmitting ? (
          <>
            <Button variant="secondary" onClick={onHide}>
              Renunță
            </Button>
            <Button variant="primary" onClick={() => handleReject()}>
              Confirma
            </Button>
          </>
        ) : (
          <LoadingView />
        )}
      </Modal.Footer>
    </Modal>
  );
};

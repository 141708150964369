import axios from "axios";
import FileSaver from "file-saver";
import { t } from "i18next";
import { Omit } from "react-bootstrap/esm/helpers";
import toast from "react-hot-toast";

import {
  IAuditor,
  IBusiness,
  ICalendar,
  IForumTopic,
  IHearing,
  IHearingRequest,
  IProfile,
  IProiect,
  IProiectCategory,
  IProjectTask,
  IQuestion,
  IRecording,
  ITax,
  ITemplate,
  ITemplateBody,
  IUser,
  IWhiteboard,
  IWhiteboardUser,
} from "../cjt";
import { BASE_URL } from "../config/env";
import { ActivateOptions, FormUsage } from "../enums";
import handleException from "./exceptions";
import {
  PfPaymentsSearchOptions,
  PjPaymentsSearchOptions,
  UserSearchOptions,
} from "./utilities";

function clean(obj: any) {
  for (const propName in obj) {
    if (obj[propName] === "" || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
  return obj;
}

export function useAPIClient() {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
  };
  return {
    signIn: async (
      email: string,
      password: string,
      captchaResponse: string | null,
    ) => {
      const { data } = await axios.post(
        BASE_URL + "/auth/signin",
        {
          email,
          password,
          captchaResponse,
        },
        config,
      );

      return data;
    },

    signUp: async (
      email: string,
      password: string,
      captchaResponse: string | null,
    ) => {
      const { data } = await axios.post(
        BASE_URL + "/auth/signup",
        {
          email,
          password,
          captchaResponse,
        },
        config,
      );

      return data;
    },

    getTemplates: async (): Promise<ITemplate[]> => {
      /// from Postgre
      const { data } = await axios.get(BASE_URL + "/form-templates", config);

      return data;
    },

    getActiveTemplates: async (): Promise<ITemplate[]> => {
      const { data } = await axios.get(
        BASE_URL + "/form-templates/active",
        config,
      );

      return data;
    },

    getFormsCategories: async () => {
      const { data } = await axios.get(BASE_URL + "/categories", config);

      return data;
    },

    getTemplateById: async (id: string): Promise<ITemplateBody> => {
      /// from mongoDb
      const { data } = await axios.get(
        BASE_URL + "/form-templates/template-body/" + id,
        config,
      );
      return data;
    },

    toggleTemplate: async (
      id: string,
      option: ActivateOptions,
    ): Promise<ITemplateBody> => {
      const { data } = await axios.patch(
        BASE_URL + "/form-templates/status",
        { id, option },
        config,
      );
      return data;
    },

    assignCategoryToTemplate: async (
      id: string,
      label: string,
    ): Promise<ITemplateBody> => {
      const { data } = await axios.patch(
        BASE_URL + "/form-templates/category",
        { template_id: id, category_label: label },
        config,
      );
      return data;
    },

    deleteTemplate: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + "/form-templates/" + id,
        config,
      );
      return data;
    },

    sendSubmission: async (
      templateName: string,
      submissionTemplate: string,
      submissionBody: string,
      userLocation: string | null,
      calendar?: ICalendar | undefined,
    ) => {
      const { data } = await axios.post(
        BASE_URL + "/submissions",
        {
          templateName,
          submissionTemplate,
          submissionBody,
          userLocation,
          calendar,
        },
        config,
      );
      return data;
    },

    createTemplate: async (name: string, usage?: FormUsage) => {
      const { data } = await axios.post(
        BASE_URL + "/form-templates",
        { name, templateBody: "{}", needsLocation: false, usage: usage },
        config,
      );

      return data;
    },

    editTemplate: async (
      name: string,
      templateBody: string,
      needsLocation: boolean,
      id: string,
    ) => {
      const { data } = await axios.put(
        BASE_URL + "/form-templates/" + id,
        { name, needsLocation, templateBody },
        config,
      );

      return data;
    },

    updateFormName: async (formId: string, formName: string) => {
      const { data } = await axios.patch(
        BASE_URL + `/forms/${formId}`,
        {
          name: formName,
        },
        config,
      );
      return data;
    },

    deleteForm: async (form_id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/forms/${form_id}`,
        config,
      );

      return data;
    },

    toggleForm: async (form_id: string) => {
      const { data } = await axios.patch(
        BASE_URL + `/forms/toggle/${form_id}`,
        {},
        config,
      );

      return data;
    },

    sendCompletedForm: async (form_data: any) => {
      const { data } = await axios.post(
        BASE_URL + "/requests/send",
        form_data,
        config,
      );

      return data;
    },

    getUser: async (email: string): Promise<IUser> => {
      const { data } = await axios.get(
        BASE_URL + "/admin/user/" + email,
        config,
      );
      return data;
    },

    getUsers: async (type: UserSearchOptions, q?: string): Promise<IUser[]> => {
      const { data } = await axios.get(
        BASE_URL + `/admin/users?type=${type}${q ? `&search=${q}` : ""}`,
        config,
      );

      return data;
    },

    getUserFeed: async (): Promise<IUser> => {
      const { data } = await axios.get(
        BASE_URL + "/user/personal-feed/",
        config,
      );
      localStorage.setItem("userProfile", JSON.stringify(data.user));
      return data.user;
    },

    editEmail: async (id: string, newEmail: string): Promise<IUser> => {
      const { data } = await axios.patch(
        BASE_URL + "/auth/edit-email/" + id,
        { newEmail },
        config,
      );

      return data.user;
    },

    activateUser: async (email: string) => {
      await axios.put(BASE_URL + "/admin/activate/" + email, {}, config);
    },

    verifyUser: async (email: string) => {
      await axios.put(BASE_URL + "/admin/verify/" + email, {}, config);
    },

    deactivateUser: async (email: string) => {
      await axios.put(BASE_URL + "/admin/deactivate/" + email, {}, config);
    },

    promoteUser: async (email: string) => {
      await axios.put(BASE_URL + "/admin/promote/" + email, {}, config);
    },

    addBusinessProfile: async (business: IBusiness) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const businessSend: Omit<IBusiness, "id"> = business;
      await axios.post(
        BASE_URL + "/business-request",
        { ...businessSend, id: undefined },
        config,
      );
    },

    deleteBusinessProfile: async (businessId: string) => {
      await axios.delete(
        BASE_URL + "/auth/delete-business/" + businessId,
        config,
      );
    },

    updateBusinessProfile: async (data: IBusiness) => {
      await axios.put(
        BASE_URL + "/auth/edit-business/" + data.id,
        { ...data, id: undefined },
        config,
      );
    },

    getCategories: async () => {
      const { data } = await axios.get(BASE_URL + "/chatbot/category", config);

      return data;
    },

    getQuestions: async (categoryId: string): Promise<IQuestion[]> => {
      const { data } = await axios.get(
        BASE_URL + "/chatbot/questions/" + categoryId,
        config,
      );

      return data;
    },
    getQuestionByid: async (questionId: string): Promise<IQuestion> => {
      const { data } = await axios.get(
        BASE_URL + "/chatbot/question/" + questionId,
        config,
      );

      return data;
    },

    addCategory: async (name: string) => {
      const { data } = await axios.post(
        BASE_URL + "/chatbot/category",
        { name },
        config,
      );
      return data;
    },

    addQuestion: async (
      categoryId: string,
      content: string,
      answer: string,
      keywords: string[],
    ) => {
      const { data } = await axios.post(
        BASE_URL + "/chatbot/question",
        { categoryId, content, answer, keywords },
        config,
      );
      return data;
    },

    editQuestion: async (
      questionId: string,
      question_content: string,
      answer_content: string,
      keywords: string[],
    ) => {
      const { data } = await axios.put(
        BASE_URL + "/chatbot/question/" + questionId,
        { question_content, keywords, answer_content },
        config,
      );
      return data;
    },

    deleteCategory: async (categoryId: string) => {
      await axios.delete(BASE_URL + "/chatbot/category/" + categoryId, config);
    },

    deleteQuestion: async (questionId: string) => {
      await axios.delete(BASE_URL + "/chatbot/question/" + questionId, config);
    },

    getSurveyByid: async (id: string) => {
      const { data } = await axios.get(BASE_URL + "/survey/" + id, config);

      return data;
    },

    getSurveys: async () => {
      const { data } = await axios.get(BASE_URL + "/survey", config);

      return data;
    },

    getActiveSurveys: async () => {
      const { data } = await axios.get(BASE_URL + "/survey/active", config);

      return data;
    },

    deleteSurvey: async (surveyId: string) => {
      await axios.delete(BASE_URL + "/survey/" + surveyId, config);
    },

    addSurvey: async (name: string) => {
      const { data } = await axios.post(BASE_URL + "/survey", { name }, config);
      return data;
    },

    editSurvey: async (id: string, name: string, active: boolean) => {
      const { data } = await axios.patch(
        BASE_URL + "/survey/" + id,
        { name, active },
        config,
      );
      return data;
    },

    deleteSurveyQuestion: async (questionId: string) => {
      await axios.delete(BASE_URL + "/survey/question/" + questionId, config);
    },

    deleteSurveyAnswer: async (answerId: string) => {
      await axios.delete(BASE_URL + "/survey/answer/" + answerId, config);
    },

    getSurveyQuestionByid: async (questionId: string) => {
      const { data } = await axios.get(
        BASE_URL + "/survey/question/" + questionId,
        config,
      );

      return data;
    },

    addSurveyQuestion: async (id: string, question: string) => {
      const { data } = await axios.post(
        BASE_URL + `/survey/${id}/add-question`,
        { question },
        config,
      );
      return data;
    },

    addSurveyAnswer: async (id: string, answer: string) => {
      const { data } = await axios.post(
        BASE_URL + `/survey/question/${id}/add-answer`,
        { answer },
        config,
      );
      return data;
    },

    editSurveyQuestion: async (id: string, question: string) => {
      const { data } = await axios.patch(
        BASE_URL + "/survey/question/" + id,
        { question },
        config,
      );
      return data;
    },

    editSurveyAnswer: async (id: string, answer: string) => {
      const { data } = await axios.patch(
        BASE_URL + "/survey/answer/" + id,
        { answer },
        config,
      );
      return data;
    },

    registerSurveyAnswearByAnswerId: async (id: string) => {
      const { data } = await axios.post(
        BASE_URL + `/survey/answer/${id}/register`,
        {},
        config,
      );
      return data;
    },

    getSearchResults: async (q: string) => {
      const { data } = await axios.get(BASE_URL + `/search?q=${q}`, config);

      return data;
    },

    getSearchDownloadById: async (id: string) => {
      const response = await axios.get(BASE_URL + `/search/download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    sendChatMessage: async (message: string) => {
      const { data } = await axios.get(
        BASE_URL + "/chatbot/question/ask/?input=" + message,
        config,
      );
      return data;
    },

    getWhiteboards: async (): Promise<IWhiteboard[]> => {
      const { data } = await axios.get(BASE_URL + "/whiteboard", config);
      return data;
    },

    addUserToWhiteboard: async (whiteboardId: string, userId: string) => {
      await axios.post(
        BASE_URL + `/whiteboard/${whiteboardId}/add-user/${userId}`,
        {},
        config,
      );
    },

    removeWhiteboardUser: async (whiteboardId: string, userId: string) => {
      await axios.delete(
        BASE_URL + `/whiteboard/${whiteboardId}/remove-user/${userId}`,
        config,
      );
    },

    changeWhiteboardPermission: async (
      whiteboardId: string,
      userId: string,
      permission: string[],
    ) => {
      await axios.patch(
        BASE_URL + `/whiteboard/${whiteboardId}/change-permission/${userId}`,
        { permission: permission },
        config,
      );
    },

    deleteWhiteboardFile: async (whiteboardId: string, fileId: string) => {
      await axios.delete(
        BASE_URL + `/whiteboard/${whiteboardId}/delete-file/${fileId}`,
        config,
      );
    },

    inviteWhiteboardUsers: async (whiteboardId: string, emails: string[]) => {
      const data = {
        emails: emails.join(","),
      };
      await axios.post(
        BASE_URL + `/whiteboard/${whiteboardId}/invite-users`,
        data,
        config,
      );
    },

    getWhiteboardFile: async (whiteboardId: string, fileId: string) => {
      const response = await axios.get(
        BASE_URL + `/whiteboard/${whiteboardId}/get-file/${fileId}`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );
      let fileName = response.headers["content-disposition"];
      fileName = fileName.slice(fileName.indexOf("filename=") + 10, -1);
      FileSaver.saveAs(response.data, fileName);
    },
    getWhiteboard: async (id: string, token?: string): Promise<IWhiteboard> => {
      const { data } = await axios.get(
        BASE_URL + `/whiteboard/${id}/${token ? token : ""}`,
        config,
      );

      return data;
    },

    deleteWhiteboard: async (id: string) => {
      await axios.delete(BASE_URL + `/whiteboard/${id}`, config);
    },

    renameWhiteboard: async (id: string, name: string) => {
      await axios.patch(
        BASE_URL + `/whiteboard/${id}/rename`,
        { name: name },
        config,
      );
    },

    createWhiteboard: async (name: string) => {
      const { data } = await axios.post(
        BASE_URL + "/whiteboard",
        { name },
        config,
      );

      return data;
    },

    getAllUserWhiteboards: async (): Promise<IWhiteboard[]> => {
      const { data } = await axios.get(
        BASE_URL + "/whiteboard/get-all-user-whiteboards",
        config,
      );
      return data;
    },

    getWhiteboardUsers: async (
      whiteboardId: string,
    ): Promise<IWhiteboardUser[]> => {
      const { data } = await axios.get(
        BASE_URL + `/whiteboard/${whiteboardId}/users`,
        config,
      );
      return data;
    },

    changeWhiteboardUploadPermission: async (
      whiteboardId: string,
      userId: string,
      allowUpload: boolean,
    ) => {
      const data = {
        allowUpload: allowUpload,
      };
      return await axios.patch(
        BASE_URL +
          `/whiteboard/${whiteboardId}/change-upload-permission/${userId}`,
        data,
        config,
      );
    },
    updateWhiteboard: async (id: string, content: string) => {
      const { data } = await axios.put(
        BASE_URL + `/whiteboard/${id}`,
        { content },
        config,
      );

      return data;
    },

    uploadWhiteboardFile: async (
      whiteboardId: string,
      uploadParams: { [key: string]: any },
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.post(
        BASE_URL + "/whiteboard/" + whiteboardId + "/upload-file",
        uploadParams,
        config,
      );
      return data;
    },

    synchronizeWhiteboardFilesWithDrive: async (whiteboardId: string) => {
      await axios.get(
        BASE_URL + "/whiteboard/" + whiteboardId + "/synchronize-with-drive",
        config,
      );
    },

    getFileListByContract: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + `/whiteboard/${id}/uploaded-files`,
        config,
      );

      return data;
    },

    createHearing: async (
      auditorId: string,
      hearingReason: string,
      inviteesIds: string[],
      startDate: string,
      endDate: string,
      context: string = "personal",
    ) => {
      const data = {
        auditorId,
        hearingReason,
        inviteesIds: inviteesIds.join(","),
        startDate,
        endDate,
        context,
      };

      await axios.post(BASE_URL + "/hearing", data, config);
    },

    getHearings: async (): Promise<IHearing[]> => {
      const { data } = await axios.get(BASE_URL + "/hearing", config);

      return data;
    },

    getHearingById: async (id: string): Promise<IHearing> => {
      const { data } = await axios.get(BASE_URL + `/hearing/${id}`, config);

      return data;
    },

    deleteHearing: async (id: string) => {
      await axios.delete(BASE_URL + `/hearing/${id}`, config);
    },

    listHearingRecordings: async (id: string): Promise<IRecording[]> => {
      const { data } = await axios.get(
        BASE_URL + `/webex/meeting/${id}/recordings`,
        config,
      );

      return data.items;
    },

    getLabels: async () => {
      const { data } = await axios.get(BASE_URL + "/forms/labels", config);

      return data;
    },

    createLabel: async (name: string) => {
      const { data } = await axios.post(
        BASE_URL + "/categories",
        { label: name },
        config,
      );

      return data;
    },

    deleteLabel: async (labelId: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/categories/${labelId}`,
        config,
      );

      return data;
    },

    updatePairFormLabel: async (formID: string, formLabelId: string) => {
      const { data } = await axios.put(
        BASE_URL + `/forms/pair/${formID}/with/${formLabelId}`,
        {
          formId: formID,
          formLabelId: formLabelId,
        },
        config,
      );

      return data;
    },

    updateUnpairFormLabel: async (formID: string, formLabelId: string) => {
      const { data } = await axios.put(
        BASE_URL + `/forms/unpair/${formID}/with/${formLabelId}`,
        {
          formId: formID,
          formLabelId: formLabelId,
        },
        config,
      );

      return data;
    },

    getUserSmartFormRequests: async () => {
      const { data } = await axios.get(
        BASE_URL + "/submissions/my-submissions",
        config,
      );

      return data;
    },

    getHearingRequestsForUser: async () => {
      const { data } = await axios.get(
        BASE_URL + "/hearing-request/my-requests",
        config,
      );

      return data;
    },

    getHearingsForUser: async () => {
      const { data } = await axios.get(BASE_URL + "/hearing/own", config);

      return data;
    },

    getUserSmartFormRequestsById: async (id: string) => {
      const { data } = await axios.get(BASE_URL + `/submissions/${id}`, config);

      return data;
    },

    getOnlineRequestsById: async (id: string) => {
      const { data } = await axios.get(BASE_URL + `/submissions/${id}`, config);

      return data;
    },

    getOnlineRequests: async () => {
      const { data } = await axios.get(BASE_URL + "/submissions", config);

      return data;
    },

    updateOnlineRequestStatus: async (
      submissionId: string,
      status: string,
      reason: string,
    ) => {
      const { data } = await axios.put(
        BASE_URL + `/submissions/status`,
        { submissionId, status, reason },
        config,
      );

      return data;
    },

    addOnlineRequestRegNumber: async (submissionId: string) => {
      await axios.patch(
        BASE_URL + `/submissions/register`,
        { submissionId },
        config,
      );
    },

    addOnlineRequestRegNumberManual: async (
      submissionId: string,
      submissionRegNumber: string,
      submissionRegDate: string,
    ) => {
      await axios.patch(
        BASE_URL + `/submissions/register`,
        {
          submissionId,
          submissionRegNumber: submissionRegNumber,
          submissionRegDate: submissionRegDate,
        },
        config,
      );
    },
    uploadSubmissionResponse: async (
      submissionId: string,
      submissionResponse: string,
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      await axios.post(
        BASE_URL + `/submissions/response/${submissionId}`,
        { file: submissionResponse },
        config,
      );
    },

    deleteOnlineRequest: async (submissionId: string) => {
      await axios.delete(BASE_URL + `/submissions/${submissionId}`, config);
    },

    createUserProfileRequest: async (profile: IProfile | Partial<IProfile>) => {
      config["headers"]["Content-Type"] = "multipart/form-data";

      await axios.post(BASE_URL + "/profile-request", profile, config);
    },

    getProfileRequestsByUserId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/profile-request/user/" + id,
        config,
      );

      return data;
    },

    getProfileRequests: async () => {
      const { data } = await axios.get(BASE_URL + "/profile-request", config);

      return data;
    },

    getMyProfileRequest: async () => {
      const { data } = await axios.get(
        BASE_URL + "/profile-request/my-requests",
        config,
      );

      return data;
    },

    getBusinessRequests: async () => {
      const { data } = await axios.get(BASE_URL + "/business-request", config);

      return data;
    },

    getOwnBusinessRequests: async () => {
      const { data } = await axios.get(
        BASE_URL + "/business-request/my-requests",
        config,
      );

      return data;
    },

    getBusinessRequestById: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + `/business-request/${id}`,
        config,
      );

      return data;
    },

    getProfileRequestById: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + `/profile-request/${id}`,
        config,
      );

      return data;
    },

    getProfileRequestDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/profile-request/${id}/document`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];
      console.log(contentDispositionHeader);
      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getSignedPdf: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/contract-folder/${id}/signed-pdf`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getUserSignedPdf: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/contract-folder/${id}/user-signed-pdf`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getBusinessRequestDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/business-request/${id}/document`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];
      console.log(contentDispositionHeader);
      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },
    getBusinessDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/admin/business/${id}/document`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      FileSaver.saveAs(
        response.data,
        "document." +
          (response.headers["content-type"] as string).split("/")[1],
      );
    },

    approveProfileRequest: async (id: string) => {
      const { data } = await axios.post(
        BASE_URL + `/profile-request/approve/${id}`,
        { id: id },
        config,
      );

      return data;
    },

    approveBusinessRequest: async (id: string) => {
      const { data } = await axios.post(
        BASE_URL + `/business-request/approve/${id}`,
        { id: id },
        config,
      );

      return data;
    },

    rejectBusinessRequest: async (id: string, reason: string) => {
      const { data } = await axios.post(
        BASE_URL + `/business-request/reject/${id}`,
        { reason },
        config,
      );

      return data;
    },

    rejectProfileRequest: async (id: string, reason: string) => {
      const { data } = await axios.post(
        BASE_URL + `/profile-request/reject/${id}`,
        { reason },
        config,
      );

      return data;
    },

    deleteProfileRequest: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/profile-request/${id}`,
        config,
      );

      return data;
    },

    changePassword: async (password: string) => {
      await axios.post(
        BASE_URL + `/auth/change-password`,
        { password },
        config,
      );
    },

    confirmEmail: async (token: string) => {
      await axios.post(
        BASE_URL + `/email-confirmation/confirm`,
        { token },
        config,
      );
    },

    completeProfile: async (profile: IProfile) => {
      await axios.post(
        BASE_URL + `/auth/create-profile`,
        clean(profile),
        config,
      );
    },

    getAuditors: async (): Promise<IAuditor[]> => {
      const { data } = await axios.get(BASE_URL + `/auditor/`, config);

      return data;
    },

    setAuditor: async (
      userId: string,
      displayName: string,
      realName: string,
    ) => {
      await axios.post(
        BASE_URL + `/auditor/`,
        { userId, displayName, realName },
        config,
      );
    },

    deleteAuditor: async (id: string) => {
      await axios.delete(BASE_URL + `/auditor/${id}`, config);
    },

    createAuditor: async (
      userId: string,
      displayName: string,
      realName: string,
    ) => {
      const { data } = await axios.post(
        BASE_URL + "/auditor",
        { userId: userId, displayName: displayName, realName: realName },
        config,
      );

      return data;
    },

    invalidateBusiness: async (businessId: string) => {
      const { data } = await axios.patch(
        BASE_URL + "/auth/invalidate-business/" + businessId,
        {},
        config,
      );

      return data;
    },

    getHearingRequests: async (): Promise<IHearingRequest[]> => {
      const { data } = await axios.get(BASE_URL + `/hearing-request/`, config);

      return data;
    },

    getHearingRequestById: async (id: string): Promise<IHearingRequest> => {
      const { data } = await axios.get(
        BASE_URL + `/hearing-request/${id}`,
        config,
      );

      return data;
    },

    getHearingAuditors: async (): Promise<IAuditor[]> => {
      const { data } = await axios.get(BASE_URL + `/auditor/`, config);

      return data;
    },

    createHearingRequest: async (
      auditorId: string,
      hearingReason: string,
      context: string,
      user?: any,
    ) => {
      await axios.post(
        BASE_URL + `/hearing-request/`,
        {
          auditorId,
          hearingReason,
          context,
          business: user,
        },
        config,
      );
    },

    deleteHearingRequest: async (id: string) => {
      await axios.delete(BASE_URL + `/hearing-request/${id}`, config);
    },

    approveHearingRequest: async (
      id: string,
      reason: string,
      startDate: Date,
      endDate: Date,
    ) => {
      await axios.post(
        BASE_URL + `/hearing-request/${id}/approve`,
        {
          reason,
          startDate,
          endDate,
        },
        config,
      );
    },

    rejectHearingRequest: async (id: string, reason: string) => {
      await axios.post(
        BASE_URL + `/hearing-request/${id}/reject`,
        {
          reason,
        },
        config,
      );
    },

    fetchLogs: async (page: number, limit: number) => {
      const { data } = await axios.get(
        BASE_URL + `/logs?limit=${limit}&page=${page}`,
        config,
      );
      return data;
    },

    processOcr: async (formData: FormData) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.post(BASE_URL + "/ocr", formData, config);
      return data;
    },

    assignAttributesAdmin: async (email: string, data: any) => {
      await axios.put(
        BASE_URL + "/admin/assign-attributes/" + email,
        { attributes: data },
        config,
      );
    },

    // PAYMENTS

    getAccountPlans: async () => {
      const { data } = await axios.get(
        BASE_URL + `/payments/account-plans`,
        config,
      );

      return data;
    },

    checkFiscalCodeExists: async (code: string) => {
      const { data } = await axios.get(
        BASE_URL + `/payments/fiscal-code-exists/${code}`,
        config,
      );
      return data;
    },
    getContractTemplates: async () => {
      const { data } = await axios.get(BASE_URL + "/document_template", config);
      return data;
    },

    getReceivedPaymentDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/payments/received-payment/${id}/document`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );
      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];
      console.log(contentDispositionHeader);
      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );
      console.log(response);
      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getAllReceivedPayments: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + `/payments/${id}/all-received-payments`,
        config,
      );
      return data;
    },

    getKeysFromTpl: async (id: string): Promise<string[]> => {
      const { data } = await axios.get(
        BASE_URL + "/document_template/get-documment-keys/" + id,
        config,
      );
      return data;
    },

    getPaymentsDue: async (pastPayments: boolean, code: string) => {
      const { data } = await axios.get(
        BASE_URL + `/payments/due?pastPayments=${pastPayments}&code=${code}`,
        config,
      );

      return data;
    },

    createContractTemplate: async (newTemplate: { [key: string]: any }) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.post(
        BASE_URL + "/document_template",
        newTemplate,
        config,
      );

      return data;
    },

    generateContract: async (templateId: string, params: any) => {
      const body = {
        id: templateId,
        params: JSON.stringify(params),
      };
      const { data } = await axios.post(
        BASE_URL + "/document_template/final",
        body,
        config,
      );

      return data;
    },

    setAllowUpload: async (id: string, value: boolean) => {
      await axios.get(
        BASE_URL + `/contract-folder/${id}/allow-upload/${value}`,
        config,
      );
    },

    deleteContractTemplate: async (id: string) => {
      await axios.delete(BASE_URL + `/document_template/${id}`, config);
    },
    getChatContentById: async (
      id: string,
    ): Promise<{
      chatContent: { user: string; text: string; timestamp: number }[];
    }> => {
      const { data } = await axios.get(BASE_URL + "/chat/" + id, config);

      return data;
    },
    sendChatBoxMessage: async (
      id: string,
      text: string,
      user: string,
      path: string,
    ) => {
      const { data } = await axios.put(
        BASE_URL + "/chat/" + id,
        { name: user, text_reply: text, relativeRoute: path },
        config,
      );

      return data;
    },

    getBusinesses: async (searchQuery: string): Promise<IBusiness[]> => {
      const { data } = await axios.get(
        BASE_URL + `/admin/businesses?cui=${searchQuery}`,
        config,
      );

      return data;
    },

    addContract: async (name: string, businessId: string) => {
      const { data } = await axios.post(
        BASE_URL + `/contract-folder`,
        { name, businessId },
        config,
      );

      return data;
    },

    addProject: async (
      name: string,
      startDate: string,
      endDate: string,
      businessIds: string[],
    ) => {
      const { data } = await axios.post(
        BASE_URL + `/projects`,
        { name, startDate, endDate, businessIds },
        config,
      );

      return data;
    },

    addParticipantsToProject: async (id: string, businessIds: string[]) => {
      const { data } = await axios.patch(
        BASE_URL + `/projects/${id}/add-businesses`,
        { businessIds },
        config,
      );

      return data;
    },

    removeParticipantFromProject: async (id: string, businessId: string) => {
      const { data } = await axios.patch(
        BASE_URL + `/projects/${id}/remove-business`,
        { businessId },
        config,
      );

      return data;
    },

    getAllProjects: async (showOnlyOwn: boolean) => {
      const { data } = await axios.get(
        BASE_URL + `/projects/all/${showOnlyOwn}`,
        config,
      );

      return data;
    },

    getAllUserProjects: async () => {
      const { data } = await axios.get(
        BASE_URL + `/projects/my-projects`,
        config,
      );

      return data;
    },

    getProjectById: async (id: string) => {
      const { data } = await axios.get(BASE_URL + `/projects/${id}`, config);

      return data;
    },
    uploadProjectFile: async (
      id: string,
      file: any,
      fileDescription: string,
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";

      const { data } = await axios.post(
        BASE_URL + `/projects/file/${id}`,
        { file: file, description: fileDescription },
        config,
      );

      return data;
    },

    uploadPhaseFile: async (id: string, file: any, fileDescription: string) => {
      config["headers"]["Content-Type"] = "multipart/form-data";

      const { data } = await axios.post(
        BASE_URL + `/projects/phase/file/${id}`,
        { file: file, description: fileDescription },
        config,
      );

      return data;
    },

    editProjectFolder: async (
      id: string,
      name?: string,
      budget?: string,
      description?: string,
    ) => {
      const { data } = await axios.patch(
        BASE_URL + `/projects/${id}`,
        { name, budget, description },
        config,
      );

      return data;
    },

    editProjectPhase: async (id: string, name?: string, budget?: string) => {
      const { data } = await axios.patch(
        BASE_URL + `/projects/phase/${id}`,
        { name, budget },
        config,
      );

      return data;
    },

    uploadTaskFile: async (id: string, file: any, fileDescription: string) => {
      config["headers"]["Content-Type"] = "multipart/form-data";

      const { data } = await axios.post(
        BASE_URL + `/projects/task/file/${id}`,
        { file: file, description: fileDescription },
        config,
      );

      return data;
    },

    getFile: async (id: string) => {
      const response = await axios.get(BASE_URL + `/projects/file/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];
      console.log(contentDispositionHeader);
      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    // @Get('/response/:id')
    getSubmissionResponse: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/submissions/response/${id}`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );
      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];
      console.log(contentDispositionHeader);
      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getContractList: async (showOnlyOwn: boolean) => {
      const { data } = await axios.get(
        BASE_URL + `/contract-folder/all/${showOnlyOwn}`,
        config,
      );

      return data;
    },

    getContractListForBusiness: async (businessId: string) => {
      const { data } = await axios.get(
        BASE_URL + `/contract-folder/all/business/${businessId}`,
        config,
      );

      return data;
    },

    getContractById: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + `/contract-folder/${id}`,
        config,
      );

      return data;
    },
    getLegalPersonByCui: async (cui: string) => {
      const { data } = await axios.get(
        BASE_URL + `/admin/users?type=CUI&search=${cui}`,
        config,
      );

      return data;
    },
    deleteChatReply: async (id: string, index: number) => {
      const { data } = await axios.patch(
        BASE_URL + "/chat/" + id + "/" + index,
        {},
        config,
      );

      return data;
    },

    uploadUserPdf: async (uploadParams: { [key: string]: any }) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.patch(
        BASE_URL +
          "/contract-folder/" +
          uploadParams["contractFolderId"] +
          "/" +
          "upload-user-signed-pdf",
        uploadParams,
        config,
      );
      return data;
    },

    uploadUserAnnexPdf: async (
      contractId: string,
      uploadParams: { [key: string]: any },
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.patch(
        BASE_URL +
          "/contract-folder/" +
          contractId +
          "/" +
          "upload-user-annex-pdf",
        uploadParams,
        config,
      );
      return data;
    },

    uploadAnnexPdf: async (
      contractId: string,
      uploadParams: { [key: string]: any },
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.patch(
        BASE_URL + "/contract-folder/" + contractId + "/upload-annex-pdf",
        uploadParams,
        config,
      );
      return data;
    },

    updateContract: async (updateParams: { [key: string]: any }) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.patch(
        BASE_URL + `/contract-folder/`,
        updateParams,
        config,
      );
      return data;
    },

    addProjectPhase: async (updateParams: { [key: string]: any }) => {
      const { data } = await axios.post(
        BASE_URL + `/projects/phase`,
        updateParams,
        config,
      );
      return data;
    },

    addProjectTask: async (
      name: string,
      startDate: string,
      endDate: string,
      projectPhaseId: string,
    ): Promise<IProjectTask> => {
      const { data } = await axios.post(
        BASE_URL + `/projects/task/`,
        { name, startDate, endDate, projectPhaseId },
        config,
      );
      return data;
    },

    editProjectTask: async (
      id: string,
      name?: string,
      dependencies?: string[],
      progress?: number,
      startDate?: Date,
      endDate?: Date,
      businessIds?: string[],
      budget?: number,
    ) => {
      const { data } = await axios.patch(
        BASE_URL + `/projects/task/${id}`,
        {
          name,
          startDate,
          endDate,
          progress,
          dependencies,
          businessIds,
          budget,
        },
        config,
      );
      return data;
    },

    deleteProjectTask: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/projects/task/${id}`,
        config,
      );
      return data;
    },

    deleteProjectPhase: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/projects/phase/${id}`,
        config,
      );
      return data;
    },

    getFinalAnnexPdf: async (annexId: string) => {
      const response = await axios.get(
        BASE_URL + `/contract-folder/${annexId}/final-annex-pdf`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getAnnexPdf: async (contractId: string) => {
      const response = await axios.get(
        BASE_URL + `/contract-folder/${contractId}/annex-pdf`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getUserAnnexPdf: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/contract-folder/${id}/user-annex-pdf`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );
      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    confirmAnnex: async (id: string) => {
      await axios.get(
        BASE_URL + `/contract-folder/${id}/confirm-annex`,
        config,
      );
    },

    deleteAnnex: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/contract-folder/annex/${id}`,
        config,
      );
      return data;
    },

    getAnnexListByContract: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + `/contract-folder/${id}/annexes`,
        config,
      );

      return data;
    },

    createAnnex: async (createParams: { [key: string]: any }) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.post(
        BASE_URL + `/contract-folder/create-annex`,
        createParams,
        config,
      );
      return data;
    },

    resendActivationLinkEmail: async (
      email: string,
      captchaResponse: string | null,
    ) => {
      await axios.post(
        BASE_URL + "/auth/resend-confirmation-link",
        { email, captchaResponse },
        config,
      );
    },

    getGhiseulRoLink: async (payload: { [key: string]: any }) => {
      try {
        const { data } = await axios.post(
          BASE_URL + "/ghiseul/create-redirect-url",
          payload,
          config,
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    getPaymentConfirmation: async (payload: any) => {
      try {
        const { data } = await axios.post(
          BASE_URL + "/ghiseul/payment-confirmation",
          payload,
          config,
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    getPaymentsList: async () => {
      try {
        const { data } = await axios.get(
          BASE_URL + "/ghiseul/my-payments",
          config,
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    getAllPaymentsList: async (
      personType: string,
      type?: PfPaymentsSearchOptions | PjPaymentsSearchOptions,
      q?: string,
      selectedTax?: string,
      date?: string,
    ) => {
      try {
        const { data } = await axios.get(
          BASE_URL +
            `/ghiseul/all-payments?personType=${personType}&type=${type}${
              q ? `&search=${q}` : ""
            }${selectedTax ? `&selectedTax=${selectedTax}` : ""}${
              date ? `&crDate=${date}` : ""
            }`,
          config,
        );
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    getTaxesList: async () => {
      try {
        const { data } = await axios.get(BASE_URL + "/taxes/all", config);
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    addNewTax: async (taxToAdd: ITax) => {
      try {
        await axios.post(BASE_URL + "/taxes", taxToAdd, config);
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    editTax: async (taxId: string, taxToEdit: ITax) => {
      try {
        console.log(taxToEdit);
        const { data } = await axios.put(
          BASE_URL + "/taxes/" + taxId,
          taxToEdit,
          config,
        );
        console.log(data);
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    deleteTax: async (id: string) => {
      try {
        const { data } = await axios.delete(BASE_URL + `/taxes/${id}`, config);
        console.log(data);
        return data;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },

    createCalendar: async (calendar: ICalendar) => {
      const { data } = await axios.post(
        BASE_URL + "/guvernare-deschisa/calendar",
        calendar,
        config,
      );

      return data;
    },

    updateCalendar: async (id: string, calendar: ICalendar) => {
      const { data } = await axios.put(
        BASE_URL + "/guvernare-deschisa/calendar-edit/" + id,
        calendar,
        config,
      );
      return data;
    },

    deleteCalendar: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + "/guvernare-deschisa/calendar/" + id,
        config,
      );
      return data;
    },

    getAllCalendar: async () => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/calendar",
        config,
      );
      return data;
    },

    getAllPreviousCalendar: async () => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/calendar-prev",
        config,
      );
      return data;
    },
    getActiveCalendar: async () => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/calendar-active",
        config,
      );
      return data;
    },

    ArchiveCalendar: async (id: string) => {
      const { data } = await axios.put(
        BASE_URL + "/guvernare-deschisa/calendar-archive/" + id,
        {},
        config,
      );
      return data;
    },

    getSubmittedFormByCalendarId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/form/" + id,
        config,
      );
      return data;
    },

    getAllSubmittedFormByCalendarId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/all-form/" + id,
        config,
      );
      return data;
    },

    getAllSubmittedFormByUser: async () => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/all-form-user",
        config,
      );
      return data;
    },

    createProiectCategory: async (category: IProiectCategory) => {
      const { data } = await axios.post(
        BASE_URL + "/guvernare-deschisa/create-category",
        category,
        config,
      );
      return data;
    },

    getProiectCategories: async () => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/get-categories",
        config,
      );
      return data;
    },

    createProiectByFormSubmissionId: async (id: string, proiect: IProiect) => {
      const { data } = await axios.post(
        BASE_URL + "/guvernare-deschisa/proiect/" + id,
        proiect,
        config,
      );
      return data;
    },

    voteProiect: async (id: string) => {
      const { data } = await axios.post(
        BASE_URL + "/guvernare-deschisa/vote/" + id,
        {},
        config,
      );
      return data;
    },

    commentProiect: async (id: string, comment: string) => {
      const { data } = await axios.post(
        BASE_URL + "/guvernare-deschisa/comment/" + id,
        { comment },
        config,
      );
      return data;
    },

    getCommentsByProiectId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/proiect/comments/" + id,
        config,
      );
      return data;
    },

    getVotesByProiectId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/votes/" + id,
        config,
      );
      return data;
    },

    getVotesByCalendarId: async (id: string, page: number, limit: number) => {
      const { data } = await axios.get(
        BASE_URL +
          `/guvernare-deschisa/calendar-votes/${id}?limit=${limit}&page=${page}`,
        config,
      );
      return data;
    },

    getAllSubmittedProiecteByCalendarId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/all-form/" + id,
        config,
      );
      return data;
    },

    getProiectById: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/proiect/" + id,
        config,
      );
      return data;
    },

    updateProiect: async (formData: IProiect, id: string) => {
      config["headers"]["Content-Type"] = "multipart/form-data";

      const { data } = await axios.put(
        BASE_URL + "/guvernare-deschisa/proiect/" + id,
        formData,
        config,
      );
      return data;
    },

    updateProiectCategory: async (id: string, updateObject: object) => {
      const { data } = await axios.patch(
        BASE_URL + `/guvernare-deschisa/update-category/${id}`,
        updateObject,
        config,
      );
      return data;
    },

    deleteProiectCategory: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/guvernare-deschisa/delete-category/${id}`,
        config,
      );
      return data;
    },

    getProiectFormSubmissionId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/proiect/calendar/" + id,
        config,
      );
      return data;
    },

    getProiecteApprovedByCalendarId: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/proiect/calendar/aprobate/" + id,
        config,
      );
      return data;
    },

    getProiecteFromActiveCalendar: async () => {
      const { data } = await axios.get(
        BASE_URL + "/guvernare-deschisa/proiect/calendar-activ/aprobate",
        config,
      );
      return data;
    },

    getForumTopic: async () => {
      const { data } = await axios.get(BASE_URL + "/forum-topic", config);
      return data;
    },

    getForumTopicById: async (id: string) => {
      const { data } = await axios.get(BASE_URL + "/forum-topic/" + id, config);
      return data;
    },

    createForumTopic: async (topic: IForumTopic) => {
      const { data } = await axios.post(
        BASE_URL + "/forum-topic",
        topic,
        config,
      );
      return data;
    },

    updateForumTopic: async (id: string, updatedTopic: IForumTopic) => {
      const { data } = await axios.patch(
        BASE_URL + "/forum-topic/" + id,
        updatedTopic,
        config,
      );
      return data;
    },

    updateForumTopicCategory: async (id: string, category: string) => {
      const { data } = await axios.patch(
        BASE_URL + `/forum-topic/${id}/category`,
        { category_label: category },
        config,
      );
      return data;
    },

    deleteForumTopic: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/forum-topic/${id}`,
        config,
      );
      return data;
    },

    deleteTopicFile: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/forum-topic/file/${id}`,
        config,
      );
      return data;
    },

    deleteTopicPublicFile: async (topicId: string, fileId: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/forum-topic/${topicId}/publicFile/${fileId}`,
        config,
      );
      return data;
    },

    deleteTopicPrivateFile: async (topicId: string, fileId: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/forum-topic/${topicId}/privateFile/${fileId}`,
        config,
      );
      return data;
    },

    createForumCategory: async (category: object) => {
      const { data } = await axios.post(
        BASE_URL + "/forum-topic/category",
        category,
        config,
      );
      return data;
    },

    updateForumCategory: async (id: string, label: string) => {
      const { data } = await axios.patch(
        BASE_URL + `/forum-topic/category/${id}`,
        { label: label },
        config,
      );
      return data;
    },

    deleteForumCategory: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/forum-topic/category/${id}`,
        config,
      );
      return data;
    },

    uploadForumTopicFile: async (
      id: string,
      description: string,
      file: any,
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.post(
        BASE_URL + "/forum-topic/publicFile/" + id,
        { description: description, file: file },
        config,
      );
      return data;
    },

    uploadForumUserResponseTopicFile: async (
      id: string,
      description: string,
      file: any,
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.post(
        BASE_URL + "/forum-topic/file/" + id,
        { description: description, file: file },
        config,
      );
      return data;
    },

    getForumCategories: async () => {
      const { data } = await axios.get(
        BASE_URL + "/forum-topic/category",
        config,
      );
      return data;
    },

    getForumTopicFile: async (id: string) => {
      const response = await axios.get(BASE_URL + `/forum-topic/file/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
      });
      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];
      console.log(contentDispositionHeader);
      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getAllGrants: async () => {
      const { data } = await axios.get(BASE_URL + "/grant", config);
      return data;
    },

    getGrant: async (id: string) => {
      const { data } = await axios.get(BASE_URL + `/grant/${id}`, config);
      return data;
    },

    getPublishedGrants: async (businessId?: string) => {
      let url = BASE_URL + "/grant/available";

      if (businessId) {
        url += `?businessId=${businessId}`;
      }

      const { data } = await axios.get(url, config);
      return data;
    },

    getSubmittedGrants: async (businessId?: string) => {
      let url = BASE_URL + "/grant/submitted";

      if (businessId) {
        url += `?businessId=${businessId}`;
      }

      const { data } = await axios.get(url, config);
      return data;
    },

    getSubmittedGrant: async (id: string) => {
      const { data } = await axios.get(
        BASE_URL + `/grant/submitted/${id}`,
        config,
      );

      return data;
    },

    getGrantCategories: async () => {
      const { data } = await axios.get(BASE_URL + "/grant/categories", config);
      return data;
    },

    addGrantCategory: async (category: string) => {
      const { data } = await axios.post(
        BASE_URL + "/grant/categories",
        { name: category },
        config,
      );
      return data;
    },

    updateGrantCategory: async (id: string, category: string) => {
      const { data } = await axios.patch(
        BASE_URL + `/grant/categories/${id}`,
        { name: category },
        config,
      );
      return data;
    },

    deleteGrantCategory: async (id: string) => {
      const { data } = await axios.delete(
        BASE_URL + `/grant/categories/${id}`,
        config,
      );
      return data;
    },

    addGrantScoringCriteria: async (id: string, scoring: any) => {
      await axios.post(
        `${BASE_URL}/grant/${id}/scoring-criteria`,
        scoring,
        config,
      );
    },

    getGrantScoringCriteria: async (id: string) => {
      const { data } = await axios.get(
        `${BASE_URL}/grant/${id}/scoring-criteria`,
        config,
      );
      return data;
    },

    deleteGrantScoringCriteria: async (id: string) => {
      await axios.delete(`${BASE_URL}/grant/scoring-criteria/${id}`, config);
    },

    createGrantProject: async (grantProject: any) => {
      const formData = new FormData();
      const filesIndexedByKeys = new Map();
      Object.keys(grantProject).forEach((key) => {
        if (key.startsWith("fileDescription")) {
          const index = parseInt(key.slice(15));
          if (filesIndexedByKeys.has(index)) {
            filesIndexedByKeys.get(index)["description"] = grantProject[key];
          } else {
            filesIndexedByKeys.set(index, { description: grantProject[key] });
          }
        } else if (key.startsWith("file")) {
          const index = parseInt(key.slice(4));
          if (filesIndexedByKeys.has(index)) {
            filesIndexedByKeys.get(index)["document"] = grantProject[key][0];
          } else {
            filesIndexedByKeys.set(index, {
              document: grantProject[key][0],
            });
          }
        } else {
          formData.append(key, grantProject[key]);
        }
      });
      const { data } = await axios.post(BASE_URL + "/grant", formData, config);
      const projectId = data.id;

      config["headers"]["Content-Type"] = "multipart/form-data";
      for (const document of Array.from(filesIndexedByKeys.values())) {
        await axios.post(
          BASE_URL + `/grant/${projectId}/document`,
          document,
          config,
        );
      }
    },

    updateGrantProject: async (id: string, grantProject: any) => {
      await axios.patch(BASE_URL + `/grant/${id}`, grantProject, config);
    },

    publishGrantProject: async (id: string) => {
      await axios.get(BASE_URL + `/grant/${id}/publish`, config);
    },

    unpublishGrantProject: async (id: string) => {
      await axios.get(BASE_URL + `/grant/${id}/unpublish`, config);
    },

    submitGrantProject: async (
      grantId: string,
      businessId: string,
      completedForm: string,
      files: File[],
    ) => {
      const formData = new FormData();
      formData.append("business", businessId);
      formData.append("completedForm", completedForm);

      files.forEach((file) => {
        formData.append("requiredDocuments", file, file.name);
      });

      console.log(formData);

      config["headers"]["Content-Type"] = "multipart/form-data";
      const { data } = await axios.post(
        `${BASE_URL}/grant/${grantId}/submit`,
        formData,
        config,
      );

      return data;
    },

    addAdditionalSubmissionDocument: async (id: string, document: File) => {
      const formData = new FormData();

      formData.append("document", document);
      config["headers"]["Content-Type"] = "multipart/form-data";

      await axios.post(
        `${BASE_URL}/grant/submitted/${id}/additional-document`,
        formData,
        config,
      );
    },

    uploadSubmissionContestation: async (
      id: string,
      contestation: string,
      contestationFile: File,
    ) => {
      const formData = new FormData();
      formData.append("document", contestationFile);
      formData.append("contestation", contestation);

      config["headers"]["Content-Type"] = "multipart/form-data";

      await axios.post(
        `${BASE_URL}/grant/submitted/${id}/contestation`,
        formData,
        config,
      );
    },

    linkContractToSubmittedGrant: async (id: string, contractId: string) => {
      await axios.post(
        `${BASE_URL}/grant/submitted/${id}/link-contract`,
        { contract: contractId },
        config,
      );
    },

    addRequiredDocument: async (
      id: string,
      document: File,
      description: string,
    ) => {
      const formData = new FormData();

      formData.append("document", document);
      formData.append("description", description);
      config["headers"]["Content-Type"] = "multipart/form-data";

      await axios.post(`${BASE_URL}/grant/${id}/document`, formData, config);
    },

    downloadRequiredDocument: async (id: string, documentId: string) => {
      const response = await axios.get(
        BASE_URL + `/grant/${id}/document/${documentId}`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      FileSaver.saveAs(
        response.data,
        "document." + response.headers["content-type"],
      );
    },

    downloadPartialResultsDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/grant/${id}/download-partial-results`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      FileSaver.saveAs(
        response.data,
        "document." + response.headers["content-type"],
      );
    },

    downloadContenstationResultsDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/grant/${id}/download-contestation-results`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      FileSaver.saveAs(
        response.data,
        "document." + response.headers["content-type"],
      );
    },

    downloadFinalResultsDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/grant/${id}/download-final-results`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      FileSaver.saveAs(
        response.data,
        "document." + response.headers["content-type"],
      );
    },

    downloadSubmittedGrantDocument: async (id: string, documentId: string) => {
      const response = await axios.get(
        BASE_URL + `/grant/submitted/${id}/document/${documentId}`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      FileSaver.saveAs(
        response.data,
        "document." + response.headers["content-type"],
      );
    },

    downloadContestationDocument: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/grant/submitted/${id}/contestation/document`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      FileSaver.saveAs(
        response.data,
        "contestatie." + response.headers["content-type"],
      );
    },

    removeRequiredDocument: async (id: string, documentId: string) => {
      await axios.delete(
        BASE_URL + `/grant/${id}/document/${documentId}`,
        config,
      );
    },

    replaceRequiredDocument: async (
      id: string,
      oldDocumentId: string,
      file: File,
    ) => {
      const formData = new FormData();
      formData.append("document", file, file.name);

      config["headers"]["Content-Type"] = "multipart/form-data";

      await axios.post(
        `${BASE_URL}/grant/submitted/${id}/replace-required-document/${oldDocumentId}`,
        formData,
        config,
      );
    },

    uploadAditionalGrantSubmissionDocument: async (
      submissionId: string,
      file: File,
      description: string,
    ) => {
      config["headers"]["Content-Type"] = "multipart/form-data";

      console.log(description);
      const formData = new FormData();

      formData.append("document", file, file.name);
      formData.append("description", description);

      await axios.post(
        `${BASE_URL}/grant/submitted/${submissionId}/additional-document`,
        formData,
        config,
      );
    },

    deleteAditionalGrantSubmissionDocument: async (
      submissionId: string,
      docId: string,
    ) => {
      await axios.delete(
        `${BASE_URL}/grant/submitted/${submissionId}/additional-document/${docId}`,
        config,
      );
    },

    getGrantSubmissionContestationDocument: async (submissionId: string) => {
      const response = await axios.get(
        `${BASE_URL}/grant/submitted/${submissionId}/contestation-file`,
        config,
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    submitEvaluation: async (submissionId: string, evaluation: any) => {
      await axios.post(
        `${BASE_URL}/grant/submitted/${submissionId}/evaluate`,
        evaluation,
        config,
      );
    },

    approveGrantSubmission: async (submissionId: string) => {
      await axios.post(
        `${BASE_URL}/grant/submitted/${submissionId}/approve`,
        {},
        config,
      );
    },

    rejectGrantSubmission: async (submissionId: string, reason: string) => {
      await axios.post(
        `${BASE_URL}/grant/submitted/${submissionId}/reject`,
        { reason },
        config,
      );
    },

    uploadGrantSubmissionPartialResults: async (
      submissionId: string,
      doc: File,
    ) => {
      const formData = new FormData();
      formData.append("document", doc, doc.name);

      config["headers"]["Content-Type"] = "multipart/form-data";
      await axios.post(
        `${BASE_URL}/grant/${submissionId}/upload-partial-results`,
        formData,
        config,
      );
    },

    deleteGrantSubmissionPartialResults: async (submissionId: string) => {
      await axios.delete(
        `${BASE_URL}/grant/${submissionId}/delete-partial-results`,
        config,
      );
    },

    uploadGrantSubmissionContestationResults: async (
      submissionId: string,
      doc: File,
    ) => {
      const formData = new FormData();
      formData.append("document", doc, doc.name);

      config["headers"]["Content-Type"] = "multipart/form-data";
      await axios.post(
        `${BASE_URL}/grant/${submissionId}/upload-contestation-results`,
        formData,
        config,
      );
    },

    deleteGrantSubmissionContestationResults: async (submissionId: string) => {
      await axios.delete(
        `${BASE_URL}/grant/${submissionId}/delete-contestation-results`,
        config,
      );
    },

    uploadGrantSubmissionFinalResults: async (
      submissionId: string,
      doc: File,
    ) => {
      const formData = new FormData();
      formData.append("document", doc, doc.name);

      config["headers"]["Content-Type"] = "multipart/form-data";
      await axios.post(
        `${BASE_URL}/grant/${submissionId}/upload-final-results`,
        formData,
        config,
      );
    },

    deleteGrantSubmissionFinalResults: async (submissionId: string) => {
      await axios.delete(
        `${BASE_URL}/grant/${submissionId}/delete-final-results`,
        config,
      );
    },

    getDocumentTemplateFile: async (id: string) => {
      const response = await axios.get(
        BASE_URL + `/document_template/${id}/file`,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        },
      );

      const contentDispositionHeader =
        response.headers["content-disposition"] ||
        response.headers["Content-Disposition"];

      const matches = contentDispositionHeader.match(
        /filename\*?=['"]?(?:UTF-8['"]'')?([^'";]+)['"]?;?/,
      );

      if (matches && matches.length > 1) {
        const filename = matches[1];

        FileSaver.saveAs(response.data, filename);
      } else {
        // If the header is not in the expected format, use a default filename
        FileSaver.saveAs(
          response.data,
          "default-filename" + response.headers["content-type"],
        );
      }
    },

    getContractRegNumber: async (contractId: string) => {
      await axios.patch(
        BASE_URL + `/contract-folder/register`,
        { contractId },
        config,
      );
    },

    getTranslations: async () => {
      const roTranslation = (await axios.get(BASE_URL + "/translations/ro"))
        .data;
      const enTranslation = (await axios.get(BASE_URL + "/translations/en"))
        .data;

      return { ro: roTranslation, en: enTranslation };
    },
  };
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { IForumCategory, IForumTopic } from "../../../cjt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import { useAuthContext } from "../../../context/AuthContext";
import "../forum.css";
import Select from "react-select";

import ForumCategoryScreen from "./ForumCategoryScreen";
import { AccountAttribute } from "../../../enums";

function Forum() {
  const { requiredAttribute } = useAuthContext();
  const client = useAPIClient();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [forumTopics, setForumTopics] = useState<IForumTopic[]>([]);

  const [loading, setLoading] = useState(false);
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [topicIdAction, setTopicIdAction] = useState<string>('');
  const [showEditModal, setShowEditModal] = useState(false);
  const [editedTopic, setEditedTopic] = useState<IForumTopic | null>(null);
  const [showManageForumCategoryModal, setShowManageForumCategoryModal] = useState(false);
  const { register, handleSubmit, getValues, reset } = useForm();
  const [projectFile, setProjectFile] = useState<any>();
  const [forumCategoriesOptions, setForumCategoriesOptions] = useState<Option[]>();
  const [selectedForumCategory, setSelectedForumCategory] = useState<Option | null>(null);
  const [forumCategories, setForumCategories] = useState<IForumCategory[] | null>();
  const [showConfirmFileDeletion, setShowConfirmFileDeletion] = useState<boolean>(false);
  const [fileIdDelete, setFileIdDelete] = useState<string>();
  

  type Option = {
    label: string;
    value: string;
  };

  const fetchForumTopics = async () => {
    try {
      const topics = await client.getForumTopic();
      setForumTopics(topics.reverse());
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t("topic.error_return"));
    }
  };

  const fetchForumCategories = async () => {
    try {
      setLoading(true);
      const categories = await client.getForumCategories();
      setForumCategories(categories)
    } catch (error: any) {
      toast.error(t("topic.error") + error.message as string);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchForumTopics();
    fetchForumCategories();  
  }, []);

  useEffect(() => {
    const forumCategoryOptions = forumCategories?.map(
      (key) => ({
        label: key.label,
        value: key.id
      })
    );
    setForumCategoriesOptions(forumCategoryOptions as Option[])
  }, [forumCategories]);


  const handleDeleteTopic = async (event: any, id: string) => {
    event.preventDefault();
    setShowConfirmDeletion(false);
    try {
      await client.deleteForumTopic(id);
      //setForumTopics((prevTopics) => prevTopics.filter((topic) => topic.id !== id));
      fetchForumTopics();
      toast.success(t("topic.success_delete"));
    } catch (error) {
      toast.error(t("topic.error_delete"));
    }
  };

  const handleEditTopic = async () => {
    setLoading(true);
    setShowEditModal(false);
    const formData = getValues() as IForumTopic;
    //formData.category = {id: selectedForumCategory?.value, label: selectedForumCategory?.label} as IForumCategory
    formData.category_label = selectedForumCategory?.label as string
    try {
      const updatedTopic = await client.updateForumTopic(editedTopic!.id, formData);
      // setForumTopics(prevTopics =>
      //   prevTopics.map(topic => (topic.id === updatedTopic.id ? updatedTopic : topic))
      // );
      await onSubmitFile()
      setEditedTopic(null);
      reset();
      fetchForumTopics();
      setLoading(false);
      toast.success(t("topic.success_update"));
    } catch (error) {
      setLoading(false);
      toast.error(t("topic.error_update"));
    }
  };

  const onSubmitFile = async () => {
    if(projectFile && editedTopic){
      try {
        setLoading(true);
        const response = await client.uploadForumTopicFile(editedTopic.id, " ", projectFile[0]);
        toast.success(t("topic.success_upload"));
      } catch (error) {
        setLoading(false);
        toast.error(t("topic.error_upload"));
      }
    }
  };

  const handleDeleteFile = async () => {
    try {
      setLoading(true);
      await client.deleteTopicPublicFile(editedTopic!.id, fileIdDelete!);
  
      setEditedTopic(prevState => {
        if (prevState) {
          const updatedFileData = prevState.fileData!.filter(i => {
            const fileData = JSON.parse(i) as {id: string, description: string, name: string};
            return fileData.id !== fileIdDelete;
          });
  
          return {
            ...prevState,
            fileData: updatedFileData,
          };
        }
        return prevState;
      });
  
      fetchForumTopics();
      toast.success(t("topic.success_delete_file"));
      setShowConfirmFileDeletion(false);
    } catch (error: any) {
      toast.error(t("topic.error") + error.message as string);
    } finally {
      setLoading(false);
    }
  }
  
  

  const handleCloseEditModal = () => {
    reset();
    setShowEditModal(false);
    setEditedTopic(null);
  };

  const handleClose = (event: any) => {
    event.preventDefault();
    setShowConfirmDeletion(false);
    setShowManageForumCategoryModal(false)
    setShowConfirmFileDeletion(false)
  };

  const handleDeleteModal = (event: any, topicId: string) => {
    event.preventDefault();
    setShowConfirmDeletion(true);
    setTopicIdAction(topicId);
  }

  const handleDeleteFileModal = (fileId: string) => {
    setShowConfirmFileDeletion(true);
    setFileIdDelete(fileId)
  }

  const handleEditModal = (event: any, topic: IForumTopic) => {
    setSelectedForumCategory({label: topic?.category?.label, value: topic?.category?.id} as Option)
    reset();
    event.preventDefault();
    event.stopPropagation();
    setShowEditModal(true);
    setEditedTopic(topic);
  }

  return (
    <>
      <Container>
        <Card>
          <Card.Body>
            <h3 className="text-center mb-4">{t("forum_title")}</h3>
            {requiredAttribute(AccountAttribute.OpenGovernmentForumAdministrator) && (
            <div className="d-flex w-100 justify-content-between"> 
              <a href="/admin/creare-topic/">
                <button className="btn btn-success" style={{marginBottom: '20px'}}>{t("forum_new_topic")}</button>
              </a>
              <a href="/admin/forum/administrare-categorii/">
                <button className="btn btn-success" style={{marginBottom: '20px'}}>{t("forum_new_category")}</button>
                </a>
            </div>)}
            <div className="list-group">
              {forumTopics && forumTopics.map((topic) => (
                  <div
                    key={topic.id}
                    className="list-group-item list-group-item-action flex-column align-items-start"
                    style={{ border: "1px solid" }}
                  >
                    <div className="d-flex w-100 justify-content-between">
                      <Link style={{width: "80%"}} to={`/bugetare-participativa/topic/${topic.id}`}><h5 className="mb-1">{topic.title}</h5></Link>
                      {requiredAttribute(AccountAttribute.OpenGovernmentForumAdministrator) && (
                      <div>
                        <a href="#edit" className="me-2" onClick={(event) => {handleEditModal(event, topic)}}>
                          <FontAwesomeIcon
                            color="blue"
                            size="1x"
                            icon={faEdit}
                          />
                        </a>
                        <a href="#delete" onClick={(event) => handleDeleteModal(event, topic.id)}>
                          <FontAwesomeIcon
                            color="red"
                            size="1x"
                            icon={faCircleXmark}
                          />
                        </a>
                      </div>)}
                      {/* Modal confirmare ștergere topic */}
                      <Modal backdrop="static" show={showConfirmDeletion}>
                        <Modal.Header>
                          <Modal.Title>{t("delete_title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>{t("topic_delete_topic")}</p>
                          <p><i>{t("topic_delete_warning")}</i></p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={(event) => handleClose(event)}>
                            {t("cancel")}
                          </Button>
                          <Button variant="primary" onClick={(event) => handleDeleteTopic(event, topicIdAction)}>
                            {t("delete")}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {/* Modal confirmare ștergere fisier */}
                      <Modal backdrop="static" show={showConfirmFileDeletion}>
                        <Modal.Header>
                          <Modal.Title>{t("delete_title")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>{t("topic_delete_file")}</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={(event) => handleClose(event)}>
                            {t("cancel")}
                          </Button>
                          <Button variant="primary" onClick={async (event) => await handleDeleteFile()}>
                            {t("delete")}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                      {/* Edit Topic Modal */}
                      <Modal show={showEditModal} backdrop="static" keyboard={false} onClick={(event:any) => event.stopPropagation()}>
                        <Modal.Header>
                          <Modal.Title>{t("topic_edit")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <Form>
                            <Form.Group controlId="editTopicTitle">
                              <Form.Label>{t("topic_title")}</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder={t("topic_title_insert")}
                                defaultValue={editedTopic?.title || ''}
                                {...register("title", { required: true })} 
                              />
                            </Form.Group>
                            <Form.Group controlId="editTopicDescription">
                              <Form.Label>{t("topic_description")}</Form.Label>
                              <Form.Control
                                as="textarea"
                                rows={4}
                                placeholder={t("topic_description_insert")}
                                defaultValue={editedTopic?.description || ''}
                                {...register("description", { required: true })} 
                              />
                            </Form.Group>
                            <Form.Group className="mb-3">
                            <Form.Control
                                type="file"
                                id="projectFile"
                                required
                                onChange={async ({ target: { files } }: any) =>
                                  setProjectFile(files)
                                }
                              />
                            </Form.Group>
                            <Form.Group className="d-flex align-items-center">
                              <Select
                                className="w-100"
                                options={forumCategoriesOptions}
                                value={selectedForumCategory ? selectedForumCategory : {label: editedTopic?.category?.label, value: editedTopic?.category?.id} as Option}
                                onChange={(selectedOption) => setSelectedForumCategory(selectedOption)}
                                required={true}
                              />
                            </Form.Group>
                          </Form>
                          <p>{t("topic_attached_files")}</p>
                          {editedTopic?.fileData?.map((file, index) => {
                            const fileObject: {
                              id: string;
                              description: string;
                              name: string;
                            } = JSON.parse(file);
                            return (
                              <Row>
                                <Col>
                                  <a onClick={() => handleDeleteFileModal(fileObject.id)}>
                                    <FontAwesomeIcon
                                      color="red"
                                      size="1x"
                                      icon={faCircleXmark}
                                    /> 
                                  </a>
                                  <a
                                    onClick={() => {
                                      client.getFile(fileObject.id);
                                    }}
                                  >
                                    {index + 1}
                                    {". "} {fileObject.name ?? fileObject.id}
                                  </a>   
                                </Col>                        
                              </Row>
                            );
                          })}
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant="secondary" onClick={() => handleCloseEditModal()}>
                            {t("cancel")}
                          </Button>
                          <Button variant="primary" onClick={() => handleEditTopic()} disabled={loading}>
                            {t("save")}
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                    <Link style={{display:"block", width: "100%"}} to={`/bugetare-participativa/topic/${topic.id}`}>
                      <small>{t("topic_created_by")} <b>Admin</b></small>
                      <br />
                      <small className="overflow-hidden">{topic.description.substring(0, 300)}...</small>
                      <div className="mt-1">
                        {t("topic_categories")}:
                        <span className="badge m-1 p-2" style={{backgroundColor:'#05A677'}}>{topic.category?.label}</span>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default Forum;

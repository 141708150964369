import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Table } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { ICalendar, IProiect } from "../../../cjt";
import dayjs from "dayjs";
import { Accordion } from '@themesberg/react-bootstrap';
import { DosarStatus } from "../../../enums";

function ProiecteArhivate() {
  const { id } = useParams();
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [calendarActive, setCalendarActive] = useState<ICalendar>();
  const [proiecteActive, setProiecteActive] = useState<IProiect[]>();
  const [proiecteFinantate, setProiecteFinantate] = useState<IProiect[]>();

  const [loading, setLoading] = useState(false);

  const getProiecteApproved = async () => {
    try {
      const proiecte = await client.getProiecteApprovedByCalendarId(id!);
      setProiecteActive(proiecte);
    } catch (error: any) {
      toast.error(error.message as string);
    }
  }

  useEffect(() => {
    getProiecteApproved();
  }, []);

  useEffect(() => {
    if(proiecteActive){
      const fundedProiecte = proiecteActive.filter(proiect => proiect.status === DosarStatus.Funded);
      setProiecteFinantate(fundedProiecte)
    }
  }, [proiecteActive]);

  useEffect(() => {

  }, [proiecteActive]);

  return (
    <>
      <Container>
        <Card>
          <Card.Body>
            <h3 className="text-center">{t("calendar.vote_ended_results")}</h3>
            {proiecteActive && proiecteActive.length > 0 && dayjs(proiecteActive[0].calendar.anunt_proiecte_castigatoare).isBefore(new Date()) && (
              <>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="1">
                    <Accordion.Button className="w-100 d-flex justify-content-between">
                      <span className="h6 mb-0 fw-bold">
                      {t("calendar.funding_results")}
                      </span>
                    </Accordion.Button>
                    <Accordion.Body>
                      <Card.Body className="py-2 px-0">
                      <Table
                        responsive
                        className="table-centered table-nowrap rounded mb-0"
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="border-0">#</th>
                            <th className="border-0">{t("user")}</th>
                            <th className="border-0">{t("state")}</th>
                            <th className="border-0">{t("funding_budget")}</th>
                            <th className="border-0 text-center">{t("calendar.thead_options")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {proiecteFinantate && proiecteFinantate.map((proiect, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{proiect.user ? proiect.user.profile.nume : ''}</td>
                              <td>
                                <b>{t("calendar.funded")}</b>
                              </td>
                              <td>{proiect.buget} lei</td>
                              <td className="text-center">
                                <Button variant="primary" size="sm" href={`/bugetare-participativa/detalii-proiect/${proiect.id}`} >
                                {t("calendar.proiect_details")}
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                      </Card.Body>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <h4 className="text-center mt-3 mb-3">{t("calendar.proiecte_complete_list")}: </h4>
              </>
            )}
            <div className="row d-flex justify-content-evenly">
              {proiecteActive && proiecteActive.length > 0 ? (
                  proiecteActive.map((proiect) => (
                    <div className="custom-width card mt-3 px-0" key={proiect.id}>
                      <img
                        className="card-img-top"
                        src={proiect.imageId}
                        alt="Card image cap"
                      ></img>
                      <div className="card-body">
                        <div className="star">
                          <span>{proiect.vote.length} {proiect.vote.length === 1 ? 'vot' :  <>{t("calendar.votes")}</>}</span>
                        </div>
                        <h5 className="card-title">{proiect.title}</h5>
                        <p className="card-text" dangerouslySetInnerHTML={{ __html: proiect.description.substring(0, 120)}}></p>
                        <a href={`/bugetare-participativa/detalii-proiect/${proiect.id}`} className="btn btn-primary">{t("calendar.proiecte_details")}</a>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>{t("calendar.no_active_proiecte")}</p>
                )}
            </div>
          </Card.Body>
        </Card>  
      </Container>
    </>
  );
}

export default ProiecteArhivate;

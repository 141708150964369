import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "../../assets/css/spinner.css";
import { useAPIClient } from "../../helpers/api";
import LoadingView from "../../layout/LoadingView";
import { ISearchFile } from "../../cjt";

const Search = () => {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); 
  const [searchResults, setSearchResults] = useState<ISearchFile[]>([]);

  const handleSearch = useCallback(async () => {
    setIsLoading(true);
    try {
      const results = await client.getSearchResults(searchQuery); 
      setSearchResults(results);
    } catch (error) {
      toast.error("A apărut o eroare în prelucrarea datelor.");
    } finally {
      setIsLoading(false);
    }
  }, [client, searchQuery]);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  return (
    <>
    <Container className="px-0">
      <Row>
        <Col xs={12} className="p-3">
          <Card>
            <Card.Body>
              <h2 className="fw-bold mb-4 text-uppercase text-center">
                {t("sidebar_cautare")}
              </h2>
              <Tab.Container defaultActiveKey="cautare-avansata">
                <Row>
                  <Col lg={12}>
                    <Nav className="nav-tabs">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="cautare-avansata"
                          className="mb-sm-3 mb-md-0"
                        >
                          {t("sidebar_cautare")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={12}>
                    <Tab.Content>
                      <Tab.Pane eventKey="cautare-avansata" className="py-4">
                        <Card border="light" className="shadow-sm mb-4">
                          <Card.Body className="pb-0">
                            {isLoading ? (
                              <LoadingView />
                            ) : (
                              <Form>
                                <Row>
                                  <Col md={12} className="mb-3 mt-2">
                                    <Form.Group className="mb-3">
                                        <Form.Label>Căutare avansată fișiere</Form.Label>
                                        <InputGroup>
                                        <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Denumire fișier, modul, etc."
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                            onKeyPress={handleKeyPress}
                                        />
                                        </InputGroup>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <Table
                                  responsive
                                  className="table-centered table-nowrap rounded mb-0"
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="border-0">#</th>
                                      <th className="border-0">Denumire fișier</th>
                                      <th className="border-0">Modul</th>
                                      <th className="border-0">Status</th>
                                      <th className="border-0 text-center">Opțiuni</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {searchResults.length === 0 ? (
                                    <tr>
                                      <td colSpan={5} className="text-center">
                                        Nu s-au găsit informații în baza de date.
                                      </td>
                                    </tr>
                                ) : (
                                    <>
                                    {searchResults.map((result, index) => (
                                      <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            {result.originalName}
                                        </td>
                                        <td
                                          style={{ whiteSpace: "pre-wrap" }}
                                        >
                                          {result.module}
                                        </td>
                                        <td>{result.ocrStatus}</td>
                                        <td className="text-center">
                                          <Button
                                            variant="primary"
                                            size="sm"
                                            onClick={() => client.getSearchDownloadById(result.id)}
                                          >
                                            Descarcă fișierul
                                          </Button>
                                        </td>
                                      </tr>))}
                                      </>
                                )}
                                  </tbody>
                                </Table>
                              </Form>
                            )}
                          </Card.Body>
                        </Card>
                      </Tab.Pane>

                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  </>
  );
};

export default Search;

import { useCallback, useEffect, useState } from "react";
import { Robot } from "react-bootstrap-icons";
import Chatbot from "react-chatbot-kit";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { useAPIClient } from "../helpers/api";
import handleException from "../helpers/exceptions";
import LoadingView from "../layout/LoadingView";
import ActionProvider from "../modules/Chatbot/ActionProvider";
import "../modules/Chatbot/Chatbot.css";
import MessageParser from "../modules/Chatbot/MessageParser";
import config from "../modules/Chatbot/config";
import FinishProfile from "../modules/CompleteProfile/FinishProfile";
import Footer from "./Footer";

function Layout() {
  const [showChatbot, toggleChatbot] = useState(false);
  const client = useAPIClient();
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getFeed = useCallback(async () => {
    try {
      setLoading(true);
      const data = await client.getUserFeed();
      setProfile(data.profile);
    } catch (error: any) {
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getFeed();
  }, [getFeed]);

  return (
    <>
      <Sidebar />
      <main className="content">
        <div
          className="d-flex  mt-3"
          style={{ justifyContent: "center", width: "100%" }}
        >
          <img
            className="ui centered big image w-100-mobile"
            src="/poca2.png"
            style={{ width: "40%", maxWidth: "450px" }}
          ></img>
        </div>
        <Navbar />
        <div className="alert alert-warning text-center mt-4">
          <span>{t("beta.disclaimer")}</span>
        </div>
        {loading ? (
          <LoadingView />
        ) : profile ? (
          <Outlet />
        ) : (
          <FinishProfile onCompleted={getFeed} />
        )}
      </main>
      <div className="app-chatbot-container">
        {showChatbot && (
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        )}
      </div>
      <button
        className="app-chatbot-button"
        onClick={() => toggleChatbot((prev) => !prev)}
      >
        <Robot className="app-chatbot-button-icon" color="white" size={25} />
      </button>
      <Footer></Footer>
    </>
  );
}
export default Layout;

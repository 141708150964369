import React, { useEffect } from "react";
import { Accordion, Stack, Badge } from "react-bootstrap";
import { ISurvey, ISurveyQuestion } from "../../../cjt";
import { Trash3Fill } from "react-bootstrap-icons";
import { MdEdit } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@themesberg/react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPIClient } from "../../../helpers/api";
import { toast } from "react-hot-toast";

type QuestionListProps = {
  questions: ISurveyQuestion[];
  setShowConfirmDeletion: (show: boolean) => void;
  setShowEdit: (show: boolean) => void;
  setQuestionToDelete: (question: ISurveyQuestion) => void;
  setQuestionToEdit: (question: ISurveyQuestion) => void;
};

const QuestionList = ({
  questions,
  setShowConfirmDeletion,
  setShowEdit,
  setQuestionToDelete,
  setQuestionToEdit,
}: QuestionListProps) => {
  useEffect(() => {}, [questions]);
  return (
    <Accordion>
      {questions.map((question: ISurveyQuestion, index: number) => (
        <React.Fragment key={question.id}>
          <Accordion.Item
            eventKey={question.id.toString()}
            style={{
              marginTop: "5px",
              borderBottom: "1px solid #dee2e6",
              borderBottomLeftRadius: "0.5rem",
              borderBottomRightRadius: "0.5rem",
              borderBottomWidth: index !== questions.length - 1 ? "1px" : "0",
            }}
          >
            <Accordion.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <span>{question.question}</span>
                <span>
                  <span
                    role="button"
                    className="btn btn-danger btn-sm"
                    onClick={(event) => {
                      event.stopPropagation();
                      setQuestionToDelete(question);
                      setShowConfirmDeletion(true);
                      }}
                    >
                    <Trash3Fill />
                  </span>
                  <Button
                    className="mr-2"
                    style={{marginLeft: '10px'}}
                    variant="primary"
                    size="sm"
                    onClick={(event) => {
                      event.stopPropagation();
                      setQuestionToEdit(question);
                      setShowEdit(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
            {question.answers && question.answers.length !== 0 && (
              <>
                <ul className="mb-3">
                  {question.answers.map((answer) => (
                    <li key={answer.id}>
                      <b>{answer.answer}</b>
                    </li>
                  ))}
                </ul>
              </>
            )}

            </Accordion.Body>
          </Accordion.Item>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

type SurveyListProps = {
  surveys: ISurvey[];
  setShowConfirmDeletion: (show: boolean) => void;
  setShowEdit: (show: boolean) => void;
  setSurveyToUpdate: (survey: ISurvey) => void;
  setSurveyToDelete: (survey: ISurvey) => void;
  setQuestionToDelete: (question: ISurveyQuestion) => void;
  setQuestionToEdit: (question: ISurveyQuestion) => void;
  handleUpdateSurvey: any;
  fetchSurveys: any;
};

const SurveyList = ({
  surveys,
  setShowConfirmDeletion,
  setShowEdit,
  setSurveyToUpdate,
  setSurveyToDelete,
  setQuestionToDelete,
  setQuestionToEdit,
  handleUpdateSurvey,
  fetchSurveys,
}: SurveyListProps) => {
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const client = useAPIClient();
  const handleRedirectTakeSurvey = (id: string) => {
    navigate("/admin/sondaje-rezultate/"+id)
  };

  

  const handleUpdate = async (survey: ISurvey) => {
    console.log('=====----survey')
    setSurveyToUpdate(survey); 
    //handleUpdateSurvey();
    if(survey){
      try {
        await client.editSurvey(survey.id, survey.name, !survey.active);
        fetchSurveys();
        if(survey.active){
          toast.success(t("survey.deactivated_success"));
        }else{
          toast.success(t("survey.activated_success"));
        }
        
      } catch (error: any) {
          toast.error(t("survey.error_cannot_update_survey"));
      }
    }
  }
  

  return (
    <Accordion>
      {surveys.map((survey: ISurvey) => (
        <React.Fragment key={survey.id}>
          <Accordion.Item eventKey={survey.id.toString()}>
            <Accordion.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <span>{survey.name}</span>
                <div>
                    {survey.active ? (
                        <span className="badge bg-success me-2">{t("active")}</span> 
                        ) : (
                        <span className="badge bg-danger me-2">{t("inactive")}</span> 
                    )}
                    <span
                        role="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                            setSurveyToDelete(survey);
                            setShowConfirmDeletion(true);
                        }}
                    >
                        <Trash3Fill />
                    </span>
                    <span
                        role="button"
                        className="btn btn-primary btn-sm mx-2"
                        onClick={() => {
                          handleRedirectTakeSurvey(survey.id);
                        }}
                    >
                        {t("survey.see_results")} 
                    </span>
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {survey.questions && survey.questions.length !== 0 ? (
                <>
                  {survey.active ? (
                      <button
                          type="button"
                          className="btn btn-warning mr-1"
                          style={{ marginRight: "5px" }}
                          onClick={() => handleUpdate(survey)}
                      >
                          {t("deactivate")}
                      </button>
                  ) : (
                      <button
                          type="button"
                          className="btn btn-success mr-1"
                          style={{ marginRight: "5px" }}
                          onClick={() => handleUpdate(survey)}
                      >
                          {t("activate")}
                      </button>
                  )}
                    <QuestionList
                    questions={survey.questions}
                    setQuestionToEdit={setQuestionToEdit}
                    setShowConfirmDeletion={setShowConfirmDeletion}
                    setQuestionToDelete={setQuestionToDelete}
                    setShowEdit={setShowEdit}
                    />
                </>
              ) : (
                <p>{t("survey.no_questions")} </p>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

export default SurveyList;

import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAPIClient } from "../../helpers/api";
import FormEditor from "../../components/Forms/FormEditor";
import LoadingView from "../../layout/LoadingView";
import { ITemplateBody } from "../../cjt";
import "react-form-builder2/dist/app.css";
import "../../assets/css/smartforms.css";
import handleException from "../../helpers/exceptions";
import { useTranslation } from "react-i18next";

function EditSmartForm() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [form, setForm] = useState<ITemplateBody | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchForm = async (form_id: string) => {
    setIsLoading(true);
    try {
      const res = await client.getTemplateById(form_id);
      setForm(res);
    } catch (err: any) {
      // toast.error(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
    setIsLoading(false);
  };

  const handleFormChange = () => {
    if (form) {
      fetchForm(form.template.id);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const form_id = queryParams.get("id");
    fetchForm(form_id!);
  }, []);

  useEffect(() => {
    const updateButtonText = () => {
      const button = document.querySelector(
        'button[data-target="#group-premium"]'
      );
      if (button) {
        button.textContent = "Other";
      }
    };

    const timeoutId = setTimeout(updateButtonText, 100);

    return () => clearTimeout(timeoutId);
  }, [form]);

  if (isLoading || form === null) {
    return <LoadingView />;
  }

  return <FormEditor form={form} onChange={handleFormChange} />;
}
export default EditSmartForm;

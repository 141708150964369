import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Table } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { ICalendar, IProiect } from "../../../cjt";
import dayjs from "dayjs";
import { Accordion } from '@themesberg/react-bootstrap';
import { DosarStatus } from "../../../enums";
import LoadingView from "../../../layout/LoadingView";

function VotareProiecte() {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [calendarActive, setCalendarActive] = useState<ICalendar>();
  const [proiecteActive, setProiecteActive] = useState<IProiect[]>();
  const [proiecteFinantate, setProiecteFinantate] = useState<IProiect[]>();
  const [proiecteAprobate, setProiecteAprobate] = useState<IProiect[]>();
  const [loading, setLoading] = useState(true);

  const getCalendarActive = async () => {
    setLoading(true);
    try {
      const requests = await client.getActiveCalendar();
      setCalendarActive(requests);

      const proiecte = await client.getProiecteFromActiveCalendar();
      setProiecteActive(proiecte);
      

    } catch (error: any) {
      toast.error("Perioadă de votare inactivă sau a apărut o eroare în afișarea proiectelor aprobate.")
    } 
  }

  useEffect(() => {
    getCalendarActive();
  }, []);

  useEffect(() => {
    if(proiecteActive){
      const fundedProiecte = proiecteActive.filter(proiect => proiect.status === DosarStatus.Funded);
      setProiecteFinantate(fundedProiecte)
      const approvedProiecte = proiecteActive.filter(proiect => proiect.status === DosarStatus.Approved);
      setProiecteAprobate(approvedProiecte)
    }
  }, [proiecteActive]);

  if(loading){
    <LoadingView />
  }

  return (
    <>
      <Container>
        <Card>
          <Card.Body>
            <h3 className="text-center">{t("calendar.proiet_voting")}</h3>
            {calendarActive ? (
              dayjs(calendarActive.vot_proiecte_end).add(1, 'day').isAfter(new Date()) && dayjs(calendarActive.vot_proiecte_start).isBefore(new Date()) ? (
                <>
                    <h5 className="text-center">{calendarActive.denumire}</h5>
                    <div className="row d-flex justify-content-evenly">
                      {proiecteActive && proiecteActive.length > 0 ? (
                          proiecteActive.map((proiect) => (
                            <div className="custom-width card mt-3 px-0" key={proiect.id}>
                              <img
                                className="card-img-top"
                                src={proiect.imageId}
                                alt="Card image cap"
                              ></img>
                              <div className="card-body">
                                <div className="star">
                                  <span>{proiect.vote.length} {proiect.vote.length === 1 ? 'vot' :  <>{t("calendar.votes")}</>}</span>
                                </div>
                                <h5 className="card-title">{proiect.title}</h5>
                                <div className="card-text" dangerouslySetInnerHTML={{ __html: proiect.description.substring(0, 60) }}></div>
                                <a href={`/bugetare-participativa/detalii-proiect/${proiect.id}`} className="btn btn-primary">{t("calendar.proiect_details")}</a>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p>{t("calendar.no_active_proiecte")}</p>
                        )}
                    </div>
                </>
              ) : (
                <>
                  <h5 className="text-center">{calendarActive.denumire}</h5>
                  <p>{t("calendar.inactive_voting_period")} <a href="/bugetare-participativa/proces">{t("calendar.procesul_bugetare")}</a> {t("calendar.inactive_voting_period2")}.</p>
                  {dayjs(calendarActive.anunt_proiecte_castigatoare).isBefore(new Date()) && (
                  <>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="1">
                        <Accordion.Button className="w-100 d-flex justify-content-between">
                          <span className="h6 mb-0 fw-bold">
                          {t("calendar.funding_results")}
                          </span>
                        </Accordion.Button>
                        <Accordion.Body>
                          <Card.Body className="py-2 px-0">
                          <Table
                                      responsive
                                      className="table-centered table-nowrap rounded mb-0"
                                    >
                                      <thead className="thead-light">
                                        <tr>
                                          <th className="border-0">#</th>
                                          <th className="border-0">{t("user")}</th>
                                          <th className="border-0">{t("state")}</th>
                                          <th className="border-0">{t("funding_budget")}</th>
                                          <th className="border-0 text-center">{t("calendar.thead_options")}</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {proiecteFinantate && proiecteFinantate.map((proiect, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{proiect.user ? proiect.user.profile.nume : ''}</td>
                                            <td>
                                              <b>{t("calendar.funded")}</b>
                                            </td>
                                            <td>{proiect.buget} lei</td>
                                            <td className="text-center">
                                              <Button variant="primary" size="sm" href={`/bugetare-participativa/detalii-proiect/${proiect.id}`} >
                                              {t("calendar.proiect_details")}
                                              </Button>
                                            </td>
                                          </tr>
                                        ))}
                                      </tbody>

                                    </Table>
                          </Card.Body>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                    <h4 className="text-center mt-3 mb-3">{t("calendar.proiecte_complete_list")}: </h4>
                  </>
                  )}
                    <div className="row d-flex justify-content-evenly">
                    {dayjs(calendarActive.afisare_proiecte_start).isAfter(new Date()) ? (
                        <p>{t("calendar.Inactive_proiect_display_period")} <b>{dayjs(calendarActive.afisare_proiecte_start).format("DD-MM-YYYY")}</b>.</p>
                      ) : (
                        proiecteActive && proiecteActive.length > 0 ? (
                          proiecteActive.map((proiect) => (
                            <div className="custom-width card mt-3 px-0" key={proiect.id}>
                              <img
                                className="card-img-top"
                                src={proiect.imageId}
                                alt="Card image cap"
                              ></img>
                              <div className="card-body">
                                <div className="star">
                                  <span>{proiect.vote.length === 1 ? 'vot' :  <>{t("calendar.votes")}</>}</span>
                                </div>
                                <h5 className="card-title">{proiect.title}</h5>
                                <div dangerouslySetInnerHTML={{ __html: proiect.description.substring(0, 120) }}></div>
                                <a href={`/bugetare-participativa/detalii-proiect/${proiect.id}`} className="btn btn-primary">{t("calendar.proiecte_details")}</a>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p></p>
                        )
                      )}

                    </div>
                </>
              )
            ) : (
              <>
                <p>{t("calendar.no_defined_calendar")} <a href="/bugetare-participativa/proces">{t("calendar.here")}</a> {t("calendar.voting_info")}.</p>
              </>
            )}
          </Card.Body>
        </Card>  
      </Container>
    </>
  );
}

export default VotareProiecte;

import ReCAPTCHA from "react-google-recaptcha";
import { captcha_site_key } from "../../config/env";
import React from "react";

export default function CaptchaForm({
  onCaptchaSolved,
  captchaRef,
}: {
  onCaptchaSolved: any;
  captchaRef: any;
}) {
  const handleCaptchaChange = (value: string | null) => {
    onCaptchaSolved(value);
  };

  const resetCaptcha = () => {
    const recaptchaValue = captchaRef.current;
    recaptchaValue?.reset();
  };

  return (
    <div>
      <ReCAPTCHA
        ref={captchaRef}
        sitekey={captcha_site_key}
        onChange={handleCaptchaChange}
      />
    </div>
  );
}

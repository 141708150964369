import { Spinner } from "@themesberg/react-bootstrap";
import { FormEvent, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IAuditor, IBusiness, IUser } from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import SelectUser from "../Payments/SelectUser";

export interface OptionType {
  id: string;
  name: string;
  tip: string;
  email: string;
}
interface UserOptionType extends OptionType {
  instance: IUser | IBusiness;
}

function RequestHearing() {
  const client = useAPIClient();
  const navigate = useNavigate();

  const [desiredPerson, setDesiredPerson] = useState("");
  const [hearingReason, setHearingReason] = useState("");
  const { currentProfileId } = useAuthContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();
  const [auditors, setAuditors] = useState<IAuditor[]>([]);

  const [user, setUser] = useState<UserOptionType | null>(null);

  const handleOptionChange = (value: string) => {
    setDesiredPerson(value);
  };

  const handleTextareaChange = (value: string) => {
    setHearingReason(value);
  };

  const checkIfFormIsValid = () => {
    if (desiredPerson === "") {
      toast.error(t("hearings_select_auditor"));
      return false;
    }

    if (hearingReason === "") {
      toast.error("Completați obiectul audienței");
      return false;
    }

    return true;
  };

  const fetchAuditors = async () => {
    try {
      const res = await client.getAuditors();
      setAuditors(res);
    } catch (error) {
      // console.log(error);
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchAuditors();
  }, []);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!checkIfFormIsValid()) return;

    try {
      setIsSubmitting(true);
      await client.createHearingRequest(
        desiredPerson,
        hearingReason,
        user!.tip,
        user!.tip == "pj" ? user!.instance : undefined,
      );
      navigate("/account/online-requests", {
        state: "persoana-fizica-cereri-audienta",
      });
      toast.success(t("hearings_success"));
    } catch (error) {
      // console.log(error);
      // toast.error("Solicitarea nu a putut fi trimisă!");
      const errorMessages = handleException(error, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-6 mt-5">
          <h2 className="fw-bold mb-5 text-uppercase text-center">
            {t("online_hearings")}
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="dropdown">{t("hearings_select_user")}</label>
              <SelectUser setpUser={setUser} />
            </div>
            <div className="form-group mt-2">
              <label htmlFor="dropdown">{t("hearings_select_auditor")}</label>
              <select
                id="desiredPerson"
                className="form-control"
                value={desiredPerson}
                onChange={({ target: { value } }) => handleOptionChange(value)}
              >
                <option value="">{t("hearings_option")}</option>
                {auditors &&
                  auditors.map((auditor, index) => (
                    <option key={index} value={auditor.id}>
                      {auditor.displayName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group mt-2">
              <label htmlFor="textarea">{t("hearings_reason")}</label>
              <textarea
                id="textarea"
                className="form-control"
                value={hearingReason}
                onChange={({ target: { value } }) =>
                  handleTextareaChange(value)
                }
              ></textarea>
            </div>

            <hr></hr>
            <i>
              {t("disclaimer")}https://www.cjtimis.ro/informatii-publice/protectia-datelor-cu-caracter-personal/
            </i>
            <hr></hr>

            <button
              type="submit"
              className="btn btn-primary"
              disabled={isSubmitting}
            >
              {t("send_request")}
              {isSubmitting && (
                <Spinner animation="border" variant="secondary" size="sm" />
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RequestHearing;

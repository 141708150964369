import { useState, useEffect } from "react";
import orderBy from "lodash/orderBy";
import { useAPIClient } from "../../helpers/api";
import { Col, Row, Card, Container, Spinner } from "react-bootstrap";
import { Table, Button, Modal, Form } from "@themesberg/react-bootstrap";
import { toast } from "react-hot-toast";
import dayjs from "dayjs";
import LoadingView from "../../layout/LoadingView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEdit,
  faTrash,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { ITax, ITaxes } from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute } from "../../enums";
import handleException from "../../helpers/exceptions";
import { useTranslation } from "react-i18next";

export default function ListTaxes() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [openAddTaxeModal, setOpenAddTaxModal] = useState(false);
  const [taxes, setTaxes] = useState<ITaxes[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openEditTaxModal, setOpenEditTaxModal] = useState(false);
  const [taxToDelete, setTaxToDelete] = useState<ITaxes>();

  const [taxToAddOrEdit, setTaxToAddOrEdit] = useState<ITax>({
    faraDebit: null,
    name: "",
    taxCode: "",
    pf: false,
    pj: false,
  });

  const { requiredAttribute } = useAuthContext();

  const isTaxesAdmin = requiredAttribute(AccountAttribute.TaxesAdministrator);

  const fetchTaxesList = async () => {
    try {
      const res = await client.getTaxesList();

      setTaxes(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchTaxesList();
  }, []);

  const handleAddEditTax = async () => {
    if (!taxToAddOrEdit) return;
    let _errors: { [key: string]: any } = {};

    if (taxToAddOrEdit.faraDebit === null) {
      _errors.tType = t("taxes.errors_type");
    }

    if (!taxToAddOrEdit.name.trim()) {
      _errors.taxeName = t("taxes.errors_name");
    }

    if (!taxToAddOrEdit.taxCode.trim()) {
      _errors.taxCode = t("taxes.errors_code");
    }

    if (!taxToAddOrEdit.pf && !taxToAddOrEdit.pj) {
      _errors.pType = t("taxes.errors_pf");
    }

    setErrors(_errors);

    if (_errors.tType || _errors.taxeName || _errors.taxCode || _errors.pType)
      return;

    setIsSubmitting(true);
    try {
      if (openEditTaxModal && taxToAddOrEdit?.id) {
        const taxId = taxToAddOrEdit.id;
        delete taxToAddOrEdit.id;
        await client.editTax(taxId, taxToAddOrEdit);
        toast.success(t("taxes.success_modify"));
      } else {
        await client.addNewTax(taxToAddOrEdit);
        toast.success(t("taxes.success_add"));
      }
      setOpenAddTaxModal(false);
      setOpenEditTaxModal(false);
      setTaxToAddOrEdit({
        faraDebit: null,
        name: "",
        taxCode: "",
        pf: false,
        pj: false,
      });

      fetchTaxesList();
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }

    setIsSubmitting(false);
  };

  const validateNumber = (input: any) => {
    const regex = /^\d+(\d{0,0})?$/; // Regular expression to match numbers with up to 2 decimal places
    const value = input.value;

    if (!regex.test(value)) {
      // If the input doesn't match the pattern, clear the input value
      input.value = value.slice(0, -1); // Remove the last character
    }
  };

  const handleDeleteTax = async () => {
    try {
      if (taxToDelete?.id) {
        await client.deleteTax(taxToDelete.id);
      }
      toast.success(t("taxes.success_delete"));
      setTaxToDelete(undefined);
      fetchTaxesList();
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5 text-uppercase text-center">{t("taxes.title")}</h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                    {isTaxesAdmin && (
                      <Button
                        variant="outline-primary"
                        className="m-1"
                        //   style={{ float: "right" }}
                        onClick={() => setOpenAddTaxModal(true)}
                      >
                        <FontAwesomeIcon icon={faAdd} className="me-2" />
                        {t("taxes.add")}
                      </Button>
                    )}
                  </Col>
                  <Col lg={12}>
                    {taxes && taxes.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h5 className="mb-0">{t("taxes.none")}</h5>
                      </div>
                    ) : (
                      <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                          {isLoading ? (
                            <LoadingView />
                          ) : (
                            <Table
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="border-0">#</th>
                                  <th className="border-0">{t("date")}</th>
                                  <th className="border-0">{t("taxes.table_name")}</th>
                                  <th className="border-0">{t("taxes.table_type")}</th>
                                  <th className="border-0">{t("taxes.table_code")}</th>
                                  <th className="border-0">{t("taxes.table_business_type")}</th>
                                  <th className="border-0"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderBy(taxes, ["createdAt"], ["desc"]).map(
                                  (tax, i) => (
                                    <tr key={i}>
                                      <td>
                                        <Card.Link
                                          href="#"
                                          className="text-primary fw-bold"
                                        >
                                          {i + 1}
                                        </Card.Link>
                                      </td>
                                      <td>
                                        {dayjs(tax.createdAt).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: "pre-wrap" }}>
                                        {tax.name}
                                      </td>
                                      <td>
                                        {tax.faraDebit
                                         ? t("taxes.no_debit")
                                         : t("taxes.debit")}
                                      </td>
                                      <td>{tax.taxCode}</td>
                                      <td>
                                        {tax.pf && tax.pj
                                          ? "PF / PJ"
                                          : tax.pf
                                          ? "PF"
                                          : "PJ"}
                                      </td>

                                      <td>
                                        <Button
                                          style={{ marginRight: "10px" }}
                                          variant="primary"
                                          size="sm"
                                          onClick={() => {
                                            setTaxToAddOrEdit({
                                              ...taxToAddOrEdit,
                                              id: tax.id,
                                              faraDebit: tax.faraDebit,
                                              name: tax.name,
                                              taxCode: tax.taxCode,
                                              pf: tax.pf,
                                              pj: tax.pj,
                                            });
                                            setOpenEditTaxModal(true);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faEdit} />
                                        </Button>
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          onClick={() => {
                                            setOpenConfirmDelete(true);
                                            setTaxToDelete(tax);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          )}
                        </Card.Body>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal de adaugare taxa */}
      <Modal
        show={openAddTaxeModal || openEditTaxModal}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setOpenAddTaxModal(false);
          setOpenEditTaxModal(false);
          setTaxToAddOrEdit({
            faraDebit: null,
            name: "",
            taxCode: "",
            pf: false,
            pj: false,
          });
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {openEditTaxModal ? t("taxes.modify") : t("taxes.modal_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
              {t("taxes.type")}
                {errors.tType && (
                  <>
                    <br />
                    <span className="error-message">{errors.tType}</span>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={2} className="mb-3">
                <input
                  type="radio"
                  value="false"
                  className="form-check-input"
                  checked={taxToAddOrEdit.faraDebit === false}
                  onChange={(event) => {
                    if (!taxToAddOrEdit) return;
                    setTaxToAddOrEdit({
                      ...taxToAddOrEdit,
                      faraDebit: event.target.value === "true",
                    });
                  }}
                  id="cuDebit"
                />
                <label
                  htmlFor="cuDebit"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("taxes.debit")}
                </label>
              </Col>

              <Col md={10} className="mb-3">
                <input
                  type="radio"
                  value="true"
                  className="form-check-input"
                  checked={taxToAddOrEdit.faraDebit === true}
                  onChange={(event) => {
                    if (!taxToAddOrEdit) return;
                    setTaxToAddOrEdit({
                      ...taxToAddOrEdit,
                      faraDebit: event.target.value === "true",
                    });
                  }}
                  id="faraDebit"
                />
                <label
                  htmlFor="faraDebit"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("taxes.no_debit")}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={8} className="mb-3">
                <Form.Group id="contractName">
                  <Form.Label>{t("taxes.name")}</Form.Label>
                  <Form.Control
                    id="taxeName"
                    className={errors.taxeName && "error-control"}
                    type="text"
                    value={taxToAddOrEdit?.name && taxToAddOrEdit.name}
                    onChange={({ target: { value } }) => {
                      setTaxToAddOrEdit({ ...taxToAddOrEdit, name: value });
                    }}
                    required
                  />
                </Form.Group>
                {errors.taxeName && (
                  <span className="error-message">{errors.taxeName}</span>
                )}
              </Col>
              <Col md={4} className="mb-3">
                <Form.Group id="taxCode">
                  <Form.Label>{t("taxes.code")}</Form.Label>
                  <Form.Control
                    id="taxCode"
                    className={errors.taxCode && "error-control"}
                    type="text"
                    value={taxToAddOrEdit?.taxCode && taxToAddOrEdit.taxCode}
                    onChange={(val) => {
                      validateNumber(val.target);
                      if (!taxToAddOrEdit) return;
                      setTaxToAddOrEdit({
                        ...taxToAddOrEdit,
                        taxCode: val.target.value,
                      });
                    }}
                    required
                  />
                </Form.Group>
                {errors.taxCode && (
                  <span className="error-message">{errors.taxCode}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
              {t("taxes.business_type")}
                {errors.pType && (
                  <>
                    <br />
                    <span className="error-message">{errors.pType}</span>
                  </>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12} className="mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="pf"
                  checked={taxToAddOrEdit?.pf}
                  onChange={() => {
                    if (!taxToAddOrEdit) return;
                    setTaxToAddOrEdit({
                      ...taxToAddOrEdit,
                      pf: !taxToAddOrEdit.pf,
                    });
                  }}
                />
                <label
                  htmlFor="pf"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("taxes.individual")}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="pj"
                  checked={taxToAddOrEdit?.pj}
                  onChange={() => {
                    if (!taxToAddOrEdit) return;
                    setTaxToAddOrEdit({
                      ...taxToAddOrEdit,
                      pj: !taxToAddOrEdit.pj,
                    });
                  }}
                />
                <label
                  htmlFor="pj"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("taxes.business")}
                </label>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenAddTaxModal(false);
              setOpenEditTaxModal(false);
              setTaxToAddOrEdit({
                faraDebit: null,
                name: "",
                taxCode: "",
                pf: false,
                pj: false,
              });
            }}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddEditTax}
            disabled={isSubmitting}
          >
            {t("save")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal pentru confirmare stergere */}
      <Modal
        show={openConfirmDelete}
        size="lg"
        onHide={() => {
          setTaxToDelete(undefined);
          setOpenConfirmDelete(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("taxes.delete")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <p>{t("taxes.delete_warning")}</p>
            </Row>
            <Row></Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenConfirmDelete(false);
              setTaxToDelete(undefined);
            }}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="danger"
            className="m-1"
            onClick={() => {
              handleDeleteTax();
              setOpenConfirmDelete(false);
            }}
            target="_blank"
          >
            <FontAwesomeIcon icon={faTrashCan} className="me-2" />
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table, InputGroup, Modal } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { IForumCategory, IProiectCategory } from "../../../cjt";

function ForumCategoryScreen() {
  const { register, handleSubmit, getValues, reset } = useForm();
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [loading, setLoading] = useState(false);
  const [forumCategories, setForumCategories] = useState<IForumCategory[] | null>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<IForumCategory>();
  const [newLabel, setNewLabel] = useState<string>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  //const [formData, setFormData] = useState<IProiectCategory | null>();


  
  const handleRegisterForumCategory = async () => {
    const formData = getValues() as IForumCategory;
    try {
      setLoading(true);
      
      if (!formData.label || formData.label.trim().length === 0) {
        toast.error(t("category.min_limit"));
        return;
      }

      const data = await client.createForumCategory(formData);
      toast.success(t("category.success_add"));
      fetchForumCategories();
      reset();
      return data;
    } catch (error: any) {
      toast.error(t("category.error_add") + error.message as string);
    } finally {
      setLoading(false);
    }
  };

  const fetchForumCategories = async () => {
    try {
      setLoading(true);
      const categories = await client.getForumCategories();
      setForumCategories(categories)
    } catch (error: any) {
      toast.error((t("topic.error")) + error.message as string);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = (event: any) => {
    event.preventDefault();
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  useEffect(() => {
    fetchForumCategories();
  }, []);

  
  const handleUpdateForumCategory = async (event: any) => {
    try {
      setLoading(true);
      
      if (!newLabel || newLabel.trim().length === 0) {
        toast.error(t("category.min_limit"));
        return;
      }
      
      const data = await client.updateForumCategory(selectedCategory!.id, newLabel!);
      toast.success(t("category.success_modify"));
      fetchForumCategories();
      reset();
      return data;
    } catch (error: any) {
      toast.error(t("category.error_modify") + error.message as string);
    } finally {
      setLoading(false);
      setShowEditModal(false);
    }
  };

  const handleDeleteForumCategory = async (event: any) => {
      try {
        setLoading(true);
        const categories = await client.deleteForumCategory(selectedCategory!.id);
        toast.success(t("category.success_delete"));
        fetchForumCategories();
        reset();
      } catch (error: any) {
        toast.error(t("topic.error") + error.message as string);
      } finally {
        setLoading(false);
        setShowDeleteModal(false);
      }
  }


  return (
    <>
      <Container>
      <Row>
        <Col xs={12} className="mt-5">
          <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0">
                <Form>
                  <Row>
                    <h5>{t("calendar.insert_new_category")}</h5>
                    <Col md={12} className="mb-3 mt-2">
                    <Form.Group className="d-flex align-items-center" id="depunere-start">
                      <Form.Label style={{marginRight:"1em"}}>{t("calendar.category_name")}:</Form.Label>
                      <Form.Control type="text" {...register("label", { required: true })} placeholder={t("category_insert")}/>
                    </Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => handleRegisterForumCategory()}
                      >
                        {t("calendar.save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                
              </Card.Body>
            </Card>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0">
                <Row>
                  <h5>{t("calendar.defined_categories")}</h5>
                  <Col md={12} className="mb-3 mt-2">
                  <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                        <thead className="thead-light">
                        <tr>
                          <th className="border-0">{t("calendar.thead_name")}</th>
                          <th className="border-0">{t("calendar.thead_options")}</th>
                        </tr>
                        </thead>

                        <tbody>
                          {forumCategories && forumCategories.length > 0 ? forumCategories?.map(element => (
                            <tr>
                              <td>
                                {element.label}
                              </td>
                                <td colSpan={1} className="text-center">
                                  <Button
                                    variant="primary me-2"
                                    onClick={() => {setShowEditModal(true); setSelectedCategory(element)}}
                                  >
                                    {t("account_modify_button")}
                                  </Button>

                                  <Button
                                    variant="danger"
                                    onClick={() => {setShowDeleteModal(true); setSelectedCategory(element)}}
                                  >
                                    {t("calendar.delete")}
                                  </Button>
                                </td>
                            </tr>
                            )) : (
                            <>
                              <tr>
                                <td colSpan={1} className="text-center">
                                {t("calendar.no_categories")}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                    </Table>
                  </Col>
                </Row>                              
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal backdrop="static" show={showEditModal}>
          <Modal.Header>
            <Modal.Title>{t("category_modify_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{t("category_modify")}</p>
            <Form.Control type="text" placeholder={t("category_insert")} defaultValue={selectedCategory?.label} onChange={(event) => {setNewLabel(event.target.value)}}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(event) => handleClose(event)}>
            {t("cancel")}
            </Button>
            <Button variant="primary" onClick={(event) => handleUpdateForumCategory(event)}>
            {t("category_modify_confirm")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" show={showDeleteModal}>
          <Modal.Header>
            <Modal.Title>{t("delete_title")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Șterge categorie</p>
            <p>{t("category_text")}<b>{selectedCategory?.label}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(event) => handleClose(event)}>
              {t("cancel")}
            </Button>
            <Button variant="primary" onClick={(event) => handleDeleteForumCategory(event)}>
            {t("delete")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default ForumCategoryScreen;

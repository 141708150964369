import {
  faDownload,
  faEdit,
  faEye,
  faFileSignature,
  faTrash,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { IGrant, ITemplate } from "../../cjt";
import MilestoneWidget from "../../components/MilestoneWidget";
import { tinymce_key } from "../../config/env";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { afterDateOrSame, businessTypeOptions } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";

export default function GrantDetails() {
  const client = useAPIClient();
  const animatedComponents = makeAnimated();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [grant, setGrant] = useState<IGrant | null>(null);
  const [formTemplates, setFormTemplates] = useState<ITemplate[]>([]);
  const [editDetails, setEditDetails] = useState(false);
  const [showAddDocument, setShowAddDocument] = useState(false);
  const [showAddCriterion, setShowAddCriterion] = useState(false);
  const [uploadFile, setUploadFile] = useState<File | undefined>(undefined);
  const [milestones, setMilestones] = useState<any>();
  const {
    control,
    register: editRegister,
    handleSubmit: editHandleSubmit,
  } = useForm();

  const {
    register: addDocumentRegister,
    handleSubmit: addDocumentHandleSubmit,
  } = useForm();

  const {
    register: addCriterionRegister,
    handleSubmit: addCriterionHandleSubmit,
  } = useForm();

  if (!id) {
    // change this to not found
    throw new Error("Missing id");
  }

  const fetchGrant = async () => {
    const res = await client.getGrant(id);
    res.projectSubmissionEnd = moment(res.projectSubmissionEnd, "YYYY-MM-DD")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    res.contestationEnd = moment(res.contestationEnd, "YYYY-MM-DD")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    setGrant(res);
  };

  const fetchFormTemplates = async () => {
    try {
      const res = await client.getTemplates();

      setFormTemplates(res);
    } catch (e) {
      const err = e as AxiosError;

      if (err.response?.status === 403) {
        toast.error(
          "Trebuie sa aveti si roulul de Administrator Formulare pentru a accesa aceasta pagina",
        );
      }
      console.log(e);
    }
  };

  useEffect(() => {
    fetchGrant();
    fetchFormTemplates();
  }, []);

  const handleRemoveDocument = async (documentId: string) => {
    await client.removeRequiredDocument(id, documentId);
    await fetchGrant();
  };

  const handleEditSubmit = async (data: any) => {
    await client.updateGrantProject(id, data);
    await fetchGrant();
    setEditDetails(false);
    toast.success("Detaliile grantului au fost actualizate");
  };

  const handleAddDocumentSubmit = async (data: any) => {
    try {
      await client.addRequiredDocument(
        id,
        data["file"][0],
        data["fileDescription"],
      );
      await fetchGrant();
      setShowAddDocument(false);
      toast.success("Documentul a fost adaugat");
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleAddCriterionSubmit = async (data: any) => {
    await client.addGrantScoringCriteria(id, data);
    await fetchGrant();
    setShowAddCriterion(false);
    toast.success("Criteriul a fost adaugat");
  };

  const handleRemoveCriterion = async (criterionId: string) => {
    await client.deleteGrantScoringCriteria(criterionId);
    await fetchGrant();
  };

  const handlePublishProject = async () => {
    await client.publishGrantProject(id);
    await fetchGrant();
    toast.success("Grantul a fost publicat");
  };

  const handleUnpublishProject = async () => {
    await client.unpublishGrantProject(id);
    await fetchGrant();
    toast.success("Grantul a fost retras");
  };

  const handleUploadPartialResults = async () => {
    if (!grant || !uploadFile) {
      return;
    }

    try {
      await client.uploadGrantSubmissionPartialResults(grant.id, uploadFile);
      await fetchGrant();
      toast.success(t("grant_apply.upload_success"));
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.upload_error"));
    }
  };

  const handleDeletePartialResults = async () => {
    if (!grant) {
      return;
    }

    try {
      await client.deleteGrantSubmissionPartialResults(grant.id);
      await fetchGrant();
      toast.success(t("grant_apply.delete_success"));
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.delete_error"));
    }
  };

  const handleUploadContestationResults = async () => {
    if (!grant || !uploadFile) {
      return;
    }

    try {
      await client.uploadGrantSubmissionContestationResults(
        grant.id,
        uploadFile,
      );
      await fetchGrant();
      toast.success(t("grant_apply.upload_success"));
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.upload_error"));
    }
  };

  const handleDeleteContestationResults = async () => {
    if (!grant) {
      return;
    }

    try {
      await client.deleteGrantSubmissionContestationResults(grant.id);
      await fetchGrant();
      toast.success(t("grant_apply.delete_success"));
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.delete_error"));
    }
  };

  const handleUploadFinalResults = async () => {
    if (!grant || !uploadFile) {
      return;
    }

    try {
      await client.uploadGrantSubmissionFinalResults(grant.id, uploadFile);
      await fetchGrant();
      toast.success(t("grant_apply.upload_success"));
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.upload_error"));
    }
  };

  const handleDeleteFinalResults = async () => {
    if (!grant) {
      return;
    }

    try {
      await client.deleteGrantSubmissionFinalResults(grant.id);
      await fetchGrant();
      toast.success(t("grant_apply.delete_success"));
    } catch (e) {
      toast.error(t("grant_apply.delete_error"));
    }
  };

  useEffect(() => {
    if (!grant) {
      setMilestones([]);
      return;
    }
    const milestones = [
      {
        title: t("grant_apply.grant_submission"),
        icon: faFileSignature,
        startDate: grant.projectSubmissionStart,
        endDate: grant.projectSubmissionEnd,
        editable: grant.active === false,
        onSubmit: async (startDate: Date, endDate?: Date) => {
          await client.updateGrantProject(grant.id, {
            projectSubmissionStart: dayjs(startDate).format("YYYY-MM-DD"),
            projectSubmissionEnd: dayjs(endDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ss"),
          });
          await fetchGrant();
          toast.success(
            "Termenele pentru depunerea proiectului au fost actualizate",
          );
        },
      },
      {
        title: t("grant_apply.grant_evaluation"),
        icon: faFileSignature,
        startDate: grant.evaluationStart,
        endDate: grant.evaluationEnd,
        editable: grant.active === false,
        onSubmit: async (startDate: Date, endDate?: Date) => {
          await client.updateGrantProject(grant.id, {
            evaluationStart: dayjs(startDate).format("YYYY-MM-DD"),
            evaluationEnd: dayjs(endDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ss"),
          });
          await fetchGrant();
          toast.success(
            "Termenele pentru evaluarea proiectului au fost actualizate",
          );
        },
      },
      {
        title: t("grant_apply.grant_results"),
        icon: faEye,
        startDate: grant.resultsAnnouncementDate,
        editable: grant.active === false,
        onSubmit: async (startDate: Date) => {
          await client.updateGrantProject(grant.id, {
            resultsAnnouncementDate: dayjs(startDate).format("YYYY-MM-DD"),
          });
          await fetchGrant();
          toast.success(
            "Termenul pentru afișare rezultatelor a fost actualizat",
          );
        },
      },
      {
        title: t("grant_apply.grant_contestation"),
        icon: faFileSignature,
        startDate: grant.contestationStart,
        endDate: grant.contestationEnd,
        editable: grant.active === false,
        onSubmit: async (startDate: Date, endDate?: Date) => {
          await client.updateGrantProject(grant.id, {
            contestationStart: dayjs(startDate).format("YYYY-MM-DD"),
            contestationEnd: dayjs(endDate)
              .endOf("day")
              .format("YYYY-MM-DDTHH:mm:ss"),
          });
          await fetchGrant();
          toast.success(
            "Termenele pentru depunerea contestațiilor au fost actualizate",
          );
        },
      },
      {
        title: t("grant_apply.grant_contestation_results"),
        icon: faEye,
        startDate: grant.contestationResultsDate,
        editable: grant.active === false,
        onSubmit: async (startDate: Date) => {
          await client.updateGrantProject(grant.id, {
            contestationResultsDate: dayjs(startDate).format("YYYY-MM-DD"),
          });
          await fetchGrant();
          toast.success(
            "Termenul pentru afișare rezultatelor contestațiilor a fost actualizat",
          );
        },
      },
      {
        title: t("grant_apply.grant_final_results"),
        icon: faTrophy,
        startDate: grant.finalResultsDate,
        editable: grant.active === false,
        onSubmit: async (startDate: Date) => {
          await client.updateGrantProject(grant.id, {
            finalResultsDate: dayjs(startDate).format("YYYY-MM-DD"),
          });
          await fetchGrant();
          toast.success(
            "Termenul pentru afișare rezultatelor finale a fost actualizat",
          );
        },
      },
    ];
    setMilestones(milestones);
    return;
  }, [grant]);

  if (!grant) {
    return <LoadingView />;
  }

  return (
    <>
      <Container>
        <Row className="mb-4">
          <Col>
            <h4>
              {grant.name} -{" "}
              {!grant.active ? (
                <Badge bg="primary">Draft</Badge>
              ) : (
                <Badge bg="success">Activ</Badge>
              )}
            </h4>
          </Col>
          <Col className="d-flex align-items-end justify-content-end">
            {!grant.active && (
              <Button
                variant="primary"
                size="sm"
                onClick={handlePublishProject}
              >
                Publică
              </Button>
            )}
            {grant.active && (
              <Button
                variant="primary"
                size="sm"
                onClick={handleUnpublishProject}
              >
                Dezactivează
              </Button>
            )}
          </Col>
        </Row>
        <Row className="mb-4">
          <Col>
            <MilestoneWidget title={t("grant.dates")} milestones={milestones} />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>
                  Detalii Proiect{" "}
                  <span className="float-end">
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => setEditDetails(!editDetails)}
                    >
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  </span>
                </Card.Title>
                <hr />
                {!editDetails && (
                  <div className="detalii-grant">
                    <p>Nume: {grant.name}</p>
                    <p>
                      Descriere:
                      <span
                        style={{ display: "inline" }}
                        dangerouslySetInnerHTML={{
                          __html: `${grant.description}`,
                        }}
                      />
                    </p>
                    <p>Buget: {grant.budget}</p>
                    <p>Sablon formular: {grant.formTemplate.name}</p>
                    <p>
                      Entități eligibile:{" "}
                      {grant.allowedBusinessTypes.join(", ")}
                    </p>
                  </div>
                )}
                {editDetails && (
                  <Form onSubmit={editHandleSubmit(handleEditSubmit)}>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Nume</Form.Label>
                      <Form.Control
                        type="text"
                        {...editRegister("name", { value: grant.name })}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="name">
                      <Form.Label>Descriere</Form.Label>
                      {/* <Controller
                        name="description"
                        control={control}
                        defaultValue={grant.description}
                        render={({ field: { onChange, value } }) => (
                          <Editor
                            apiKey={tinymce_key}
                            onEditorChange={onChange}
                            value={value}
                          />
                        )}
                      /> */}
                      <Form.Control
                        as="textarea"
                        size="lg"
                        {...editRegister("description", {
                          value: grant.description,
                        })}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="budget">
                      <Form.Label>Buget</Form.Label>
                      <Form.Control
                        type="text"
                        {...editRegister("budget", { value: grant.budget })}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formTemplate">
                      <Form.Label>Sablon formular</Form.Label>
                      <Controller
                        control={control}
                        name="formTemplate"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(val) => onChange(val)}
                            options={formTemplates}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            defaultValue={grant.formTemplate}
                            value={value}
                            className="w-100"
                          />
                        )}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="allowedBusinessTypes"
                    >
                      <Form.Label>Entități eligibile</Form.Label>
                      <Controller
                        control={control}
                        name="allowedBusinessTypes"
                        render={({ field: { onChange, value } }) => (
                          <Select
                            onChange={(val) =>
                              onChange(val.map((v) => v.value))
                            }
                            options={businessTypeOptions}
                            isMulti={true}
                            components={animatedComponents}
                            defaultValue={grant.allowedBusinessTypes.map(
                              (c) => ({
                                value: c,
                                label: c,
                              }),
                            )}
                            value={businessTypeOptions.find(
                              (c) => c.valueOf === value,
                            )}
                            closeMenuOnSelect={false}
                            className="w-100"
                          />
                        )}
                      />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                      Salvează
                    </Button>
                  </Form>
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Documente necesare</Card.Title>
                <hr />
                <Table>
                  <thead>
                    <tr>
                      <th>Nume</th>
                      <th>Descriere</th>
                      <th>Actiuni</th>
                    </tr>
                  </thead>
                  <tbody>
                    {grant.requiredDocuments.map((doc) => (
                      <tr key={doc.id}>
                        <td>{doc.name}</td>
                        <td>{doc.description}</td>
                        <td>
                          <ButtonGroup>
                            <Button
                              size="sm"
                              variant="outline-primary"
                              onClick={() =>
                                client.downloadRequiredDocument(
                                  grant.id,
                                  doc.id,
                                )
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </Button>
                            <Button
                              size="sm"
                              variant="outline-danger"
                              onClick={() => handleRemoveDocument(doc.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                <Button
                  size="sm"
                  variant="primary"
                  className="float-end"
                  onClick={() => setShowAddDocument(true)}
                >
                  Adauga document
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>

        <Row>
          {afterDateOrSame(grant.resultsAnnouncementDate) && (
            <Col lg={4}>
              <Card className="mb-4">
                <Card.Header>
                  <Card.Title>
                    <h5>Încărcare rezultate parțiale</h5>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {!grant.partialResultsDocument && (
                    <>
                      <input
                        type="file"
                        onChange={({ target: { files } }) =>
                          setUploadFile(files?.[0])
                        }
                      />
                      <Button
                        size="sm"
                        className="float-end"
                        onClick={() => handleUploadPartialResults()}
                      >
                        Încărcare
                      </Button>
                    </>
                  )}
                  {grant.partialResultsDocument && (
                    <>
                      <p>
                        <a
                          onClick={() =>
                            client.downloadPartialResultsDocument(grant.id)
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {grant.partialResultsDocument.name}
                        </a>
                      </p>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleDeletePartialResults}
                      >
                        Șterge
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )}

          {afterDateOrSame(grant.contestationResultsDate) && (
            <Col lg={4}>
              <Card className="mb-4">
                <Card.Header>
                  <Card.Title>
                    <h5>Încărcare rezultate contestații</h5>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {!grant.contestationResultsDocument && (
                    <>
                      <input
                        type="file"
                        onChange={({ target: { files } }) =>
                          setUploadFile(files?.[0])
                        }
                      />
                      <Button
                        size="sm"
                        className="float-end"
                        onClick={() => handleUploadContestationResults()}
                      >
                        Încărcare
                      </Button>
                    </>
                  )}
                  {grant.contestationResultsDocument && (
                    <>
                      <p>
                        <a
                          onClick={() =>
                            client.downloadContenstationResultsDocument(
                              grant.id,
                            )
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {grant.contestationResultsDocument.name}
                        </a>
                      </p>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleDeleteContestationResults}
                      >
                        Șterge
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )}

          {afterDateOrSame(grant.finalResultsDate) && (
            <Col lg={4}>
              <Card className="mb-4">
                <Card.Header>
                  <Card.Title>
                    <h5>Încărcare rezultate finale</h5>
                  </Card.Title>
                </Card.Header>
                <Card.Body>
                  {!grant.finalResultsDocument && (
                    <>
                      <input
                        type="file"
                        onChange={({ target: { files } }) =>
                          setUploadFile(files?.[0])
                        }
                      />
                      <Button
                        size="sm"
                        className="float-end"
                        onClick={() => handleUploadFinalResults()}
                      >
                        Încărcare
                      </Button>
                    </>
                  )}
                  {grant.finalResultsDocument && (
                    <>
                      <p>
                        <a
                          onClick={() =>
                            client.downloadFinalResultsDocument(grant.id)
                          }
                          target="_blank"
                          rel="noreferrer"
                        >
                          {grant.finalResultsDocument.name}
                        </a>
                      </p>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={handleDeleteFinalResults}
                      >
                        Șterge
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>Criterii de evaluare</Card.Title>
                <hr />
                <Table>
                  <thead>
                    <tr>
                      <th>Descriere</th>
                      <th>Scor</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {grant.projectScoringCriterias &&
                      grant.projectScoringCriterias.map((criterion) => (
                        <tr key={criterion.id}>
                          <td width="80%">{criterion.description}</td>
                          <td>{criterion.score}</td>
                          <td>
                            <Button
                              size="sm"
                              variant="outline-danger"
                              onClick={() =>
                                handleRemoveCriterion(criterion.id)
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </td>
                        </tr>
                      ))}

                    {!grant.projectScoringCriterias && (
                      <tr>
                        <td colSpan={2}>Nu exista criterii de evaluare</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Card.Footer>
                  <Button
                    size="sm"
                    variant="primary"
                    className="float-end"
                    onClick={() => setShowAddCriterion(true)}
                  >
                    Adauga criteriu
                  </Button>
                </Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Modal show={showAddDocument} onHide={() => setShowAddDocument(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adauga document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={addDocumentHandleSubmit(handleAddDocumentSubmit)}>
            <Form.Group className="mb-3" controlId="document">
              <Form.Label>Document</Form.Label>
              <Form.Control type="file" {...addDocumentRegister("file")} />
              <label>Descriere</label>
              <Form.Control
                type="text"
                {...addDocumentRegister("fileDescription")}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Adăuga
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showAddCriterion} onHide={() => setShowAddCriterion(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Adauga criteriu de evaluare</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={addCriterionHandleSubmit(handleAddCriterionSubmit)}>
            <Form.Group className="mb-3" controlId="description">
              <Form.Label>Descriere</Form.Label>
              <Form.Control
                type="text"
                {...addCriterionRegister("description")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="score">
              <Form.Label>Scor</Form.Label>
              <Form.Control type="number" {...addCriterionRegister("score")} />
            </Form.Group>
            <Button variant="primary" type="submit">
              Adăuga
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

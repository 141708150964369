import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import ModalAddSurvey from "../Modals/ModalAddSurvey";
import ModalAddQuestion from "../Modals/ModalAddQuestion";
import { useAPIClient } from "../../../helpers/api";
import { ISurvey, ISurveyQuestion } from "../../../cjt";
import ModalConfirmDeletion from "../Modals/ModalConfirmDeletion";
import ModalEditQuestion from "../Modals/ModalEditQuestion";
import handleException from "../../../helpers/exceptions";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import SurveyList from "./SurveyList";

function Sondaje() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [surveys, setSurveys] = useState<ISurvey[] | null>(null);
  const [showModalAddSurvey, setShowModalAddSurvey] = useState(false);
  const [showModalAddQuestion, setShowModalAddQuestion] = useState(false);
  const [showModalUpdateSurveyState, setShowModalUpdateSurveyState] = useState<boolean>(false);

  const [surveyToUpdate, setSurveyToUpdate] = useState<ISurvey | null>();
  const [surveyToDelete, setSurveyToDelete] = useState<ISurvey | null>();
  const [questionToDelete, setQuestionToDelete] = useState<ISurveyQuestion | null>();
  const [questionToEdit, setQuestionToEdit] = useState<ISurveyQuestion>();
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleClose = () => {
    setShowModalAddSurvey(false);
    setShowModalAddQuestion(false);
    setShowConfirmDeletion(false);
    setShowEdit(false);
    setSurveyToDelete(undefined);
    setQuestionToDelete(undefined);
    setQuestionToEdit(undefined);
  };

  const handleDeleteQuestion = async () => {
    if (questionToDelete) {
      try {
        const res = await client.deleteSurveyQuestion(questionToDelete.id);
        await fetchSurveys();
        setQuestionToDelete(undefined);
      }catch (error: any) {
        toast.error(t(error.response.data.message));
      }
    }
    
    setShowConfirmDeletion(false);
    setQuestionToDelete(null);
  };

  const handleDeleteSurvey = async () => {
    if (surveyToDelete) {
      try {
        await client.deleteSurvey(surveyToDelete.id);
        await fetchSurveys();
        setSurveyToDelete(undefined);
      } catch (error: any) {
        toast.error(t(error.response.data.message));
      }
    }
    setShowConfirmDeletion(false);
    setSurveyToDelete(null)
  };

  const handleDelete = async () => {
    if (surveyToDelete) {
      await handleDeleteSurvey();
    }
    if (questionToDelete) {
      await handleDeleteQuestion();
    }
  };

  const handleUpdateSurvey = async () => {
    if(surveyToUpdate){
      try {
        await client.editSurvey(surveyToUpdate.id, surveyToUpdate.name, !surveyToUpdate.active);
        await fetchSurveys();
        setSurveyToUpdate(undefined);
      } catch (error) {
        const errorMessages = handleException(error, t);
        errorMessages.map((message) => {
          toast.error(t("survey.error_cannot_update_survey"));
        });
      }
    }
  }

  const fetchSurveys = async () => {
    try {
      const res = await client.getSurveys();
      setSurveys(res);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(t("survey.error_cannot_load_surveys"));
      });
    }
  };
  useEffect(() => {
    fetchSurveys();
  }, []);

  const showModal = (type: string) => () => {
    if (type === "addSurvey") {
      setShowModalAddSurvey(true);
      setShowModalAddQuestion(false);
    }
    if (type === "addQuestion") {
      setShowModalAddQuestion(true);
      setShowModalAddSurvey(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 mt-5 mb-5">
            <h2 className="fw-bold mb-5 text-uppercase text-center">
              {t("survey.manage_surveys")}
            </h2>
            <Button
              variant="secondary"
              style={{ marginRight: "0.5em" }}
              onClick={showModal("addSurvey")}
            >
              {t("survey.add_survey_button")}
            </Button>
            <Button variant="secondary" onClick={showModal("addQuestion")}>
              {t("survey.add_question_button")}
            </Button>
          </div>
          {surveys && surveys.length !== 0 ? (
            <SurveyList
              surveys={surveys}
              setShowEdit={setShowEdit}
              setQuestionToEdit={setQuestionToEdit}
              setShowConfirmDeletion={setShowConfirmDeletion}
              setSurveyToUpdate={setSurveyToUpdate}
              setSurveyToDelete={setSurveyToDelete}
              setQuestionToDelete={setQuestionToDelete}
              handleUpdateSurvey={handleUpdateSurvey}
              fetchSurveys = {fetchSurveys}
            />
          ) : (
            <div>{t("survey.no_surveys_registered")}</div>
          )}
        </div>
      </div>
      <ModalAddSurvey
        showModalAddSurvey={showModalAddSurvey}
        setShowModalAddSurvey={setShowModalAddSurvey}
        setShowModalAddQuestion={setShowModalAddQuestion}
        fetchSurveys={fetchSurveys}
        handleClose={handleClose}
      />
      <ModalAddQuestion
        showModalAddQuestion={showModalAddQuestion}
        setShowModalAddQuestion={setShowModalAddQuestion}
        setShowModalAddSurvey={setShowModalAddSurvey}
        surveys={surveys}
        fetchSurveys={fetchSurveys}
        handleClose={handleClose}
      />
      <ModalConfirmDeletion
        showConfirmDeletion={showConfirmDeletion}
        surveyToDelete={surveyToDelete}
        questionToDelete={questionToDelete}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
      <ModalEditQuestion
        question={questionToEdit}
        showModalEditQuestion={showEdit}
        setShowModalEditQuestion={setShowEdit}
        fetchSurveys={fetchSurveys}
        handleClose={handleClose}
      />
    </>
  );
}

export default Sondaje;

import { useTranslation } from "react-i18next";

interface Props {
  nume: string;
  prenume: string;
  cnp: string;
}

function DataForm({ nume, prenume, cnp }: Props) {
  const { t } = useTranslation();
  return (
    <div className="container" style={{ padding: "0" }}>
      <div className="row">
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="textField1"
              value={nume}
              placeholder={nume}
              disabled
            />
            <label htmlFor="textField1">
              {t("finish_profile_last_name_label")}
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="textField2"
              value={prenume}
              placeholder={prenume}
              disabled
            />
            <label htmlFor="textField2">
              {t("finish_profile_first_name_label")}
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-floating mb-3">
            <input
              type="text"
              className="form-control"
              id="textField3"
              value={cnp}
              placeholder={cnp}
              disabled
            />
            <label htmlFor="textField3">{t("profile_cnp")}</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataForm;

import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function MilestoneDate({
  title,
  icon,
  startDate,
  endDate,
  editable,
  onSubmit,
  isDemo = false,
  isCurrent = false,
}: {
  title: string;
  icon: IconDefinition;
  startDate: Date;
  endDate?: Date;
  editable: boolean;
  onSubmit?: (startDate: Date, endDate?: Date) => void;
  isDemo?: boolean;
  isCurrent?: boolean;
}) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [newStartDate, setNewStartDate] = useState(startDate);
  const [newEndDate, setNewEndDate] = useState(endDate);
  const ref = useRef<HTMLDivElement>(null);

  const onMouseOver = useCallback(() => {
    ref.current?.classList.add("shadow");
  }, []);

  const onMouseOut = useCallback(() => {
    ref.current?.classList.remove("shadow");
  }, []);

  const onClick = useCallback(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (editable && ref.current) {
      ref.current.style.cursor = "pointer";
      ref.current.addEventListener("mouseover", onMouseOver, true);
      ref.current.addEventListener("mouseout", onMouseOut, true);
      ref.current.addEventListener("click", onClick, true);
    } else {
      if (ref.current) {
        ref.current.style.cursor = "default";
        ref.current.removeEventListener("mouseover", onMouseOver, true);
        ref.current.removeEventListener("mouseout", onMouseOut, true);
        ref.current.removeEventListener("click", onClick, true);
        ref.current.classList.remove("shadow");
      }
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("mouseover", () => {});
        ref.current.removeEventListener("mouseout", () => {});
        ref.current.removeEventListener("click", () => {});
      }
    };
  }, [editable, ref.current]);

  const handleSubmit = () => {
    // basic validation, you should handle this on the backend
    if (endDate && dayjs(newStartDate).isAfter(dayjs(newEndDate))) {
      toast.error(t("grant_apply.milestone_date_error"));
      return;
    }

    onSubmit?.(newStartDate, newEndDate ? newEndDate : undefined);
    setShow(false);
  };

  const getColor = () => {
    if (isDemo) {
      if (isCurrent) {
        return "#dc3545";
      }

      return "#17547c";
    }

    if (!endDate) {
      if (dayjs().isSame(dayjs(startDate), "day")) {
        return "#dc3545";
      }
      return "#17547c";
    }

    if (
      (dayjs().isSame(dayjs(startDate), "day") ||
        dayjs().isAfter(dayjs(startDate), "day")) &&
      (dayjs().isSame(dayjs(endDate), "day") ||
        dayjs().isBefore(dayjs(endDate), "day"))
    ) {
      return "#dc3545";
    }

    return "#17547c";
  };

  return (
    <>
      <div
        ref={ref}
        className="col-lg-2 col-sm-6 col-xs-6 p-2"
        style={{ color: getColor() }}
      >
        <FontAwesomeIcon icon={icon} size="2x" color={getColor()} />
        <p style={{ fontWeight: "900" }}>{title}</p>
        <p className="cj-date">
          {dayjs(startDate).format("DD-MM-YYYY")}{" "}
          {endDate && ` - ${dayjs(endDate).format("DD-MM-YYYY")}`}
        </p>
      </div>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Modifică datele</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>{t("start_date")}</Form.Label>
              <Form.Control
                type="date"
                value={dayjs(newStartDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setNewStartDate(dayjs(e.target.value).toDate());
                }}
              />
            </Form.Group>
            {endDate && (
              <Form.Group className="mb-3">
                <Form.Label>{t("end_date")}</Form.Label>
                <Form.Control
                  type="date"
                  value={dayjs(newEndDate).format("YYYY-MM-DD")}
                  onChange={(e) => {
                    setNewEndDate(dayjs(e.target.value).toDate());
                  }}
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Închide
          </Button>
          <Button variant="primary" onClick={() => handleSubmit()}>
            Salvează
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import {
  faCircleCheck,
  faCircleXmark,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import _ from "lodash";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IPayment, ITaxes } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import {
  PfPaymentsSearchOptions,
  PjPaymentsSearchOptions,
} from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";

export const TableList = ({
  personType,
  taxes,
}: {
  personType: string;
  taxes: ITaxes[];
}) => {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [selectedTax, setSelectedTax] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState<
    PjPaymentsSearchOptions | PfPaymentsSearchOptions
  >(
    personType === "pf"
      ? PfPaymentsSearchOptions.NUME
      : PjPaymentsSearchOptions.DENUMIRE,
  );
  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  const options = Object.keys(
    personType === "pf" ? PfPaymentsSearchOptions : PjPaymentsSearchOptions,
  ).map((key) => ({
    value: key,
    label:
      personType === "pf"
        ? PfPaymentsSearchOptions[key as keyof typeof PfPaymentsSearchOptions]
        : PjPaymentsSearchOptions[key as keyof typeof PjPaymentsSearchOptions],
  }));

  useEffect(() => {
    const timer = setTimeout(() => setTerm(debouncedTerm), 100);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  const fetchPayments = async (
    searchQuery: string,
    tax?: string,
    date?: string,
  ) => {
    try {
      setIsLoading(true);

      const res = await client.getAllPaymentsList(
        personType,
        selectedOption,
        searchQuery,
        tax,
        date,
      );

      setPayments(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  const handleChangeTax = (value: string) => {
    setSelectedTax(value);
    setDebouncedTerm("");
    fetchPayments("", value, selectedDate);
  };

  useEffect(() => {
    fetchPayments(term, selectedTax, selectedDate);
  }, [term]);

  return (
    <>
      <Form>
        <Row>
          <Col lg={6}>
            <Form.Group className="mb-3">
              <Form.Label>{t("payer")}</Form.Label>
              <InputGroup>
                <DropdownButton
                  drop="down"
                  title={selectedOption ? selectedOption : options[0].label}
                  variant="primary"
                  onSelect={(value) => {
                    setSelectedOption(
                      personType === "pf"
                        ? PfPaymentsSearchOptions[
                            value as keyof typeof PfPaymentsSearchOptions
                          ]
                        : PjPaymentsSearchOptions[
                            value as keyof typeof PjPaymentsSearchOptions
                          ],
                    );
                    setDebouncedTerm("");
                  }}
                >
                  {options.map((option) => (
                    <Dropdown.Item key={option.value} eventKey={option.value}>
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} size="xs" />
                </InputGroup.Text>

                <Form.Control
                  type="text"
                  onChange={(e) => setDebouncedTerm(e.target.value)}
                  value={debouncedTerm}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col lg={4}>
            <Form.Group className="mb-3">
              <Form.Label>{t("tax")}</Form.Label>
              <Form.Select
                id="tax"
                value={selectedTax ? selectedTax : ""}
                onChange={({ currentTarget: { value } }) =>
                  handleChangeTax(value)
                }
              >
                <option value="">Toate</option>

                {taxes.map((tax) => {
                  return (
                    <option value={tax.taxCode} key={tax.id}>
                      {tax.name}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col lg={2}>
            <Form.Group className="mb-3">
              <Form.Label>{t("date")}</Form.Label>
              <input
                type="date"
                className="form-control"
                id="date"
                required
                style={{ width: "100%" }}
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                  fetchPayments("", selectedTax, e.target.value);
                }}
                onClick={(e) => {
                  e.currentTarget.showPicker();
                }}
              />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      {payments && payments.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center mb-3 mt-3">
          <h5 className="mb-0">{t("payments_history_none")}</h5>
        </div>
      ) : isLoading ? (
        <LoadingView />
      ) : (
        <Table
          responsive
          className="table-centered table-nowrap rounded mb-0 mt-2"
        >
          <thead className="thead-light">
            <tr>
              <th className="border-0">#</th>
              <th className="border-0">{t("sum")}</th>
              <th className="border-0">{t("date")}</th>
              <th className="border-0">{t("tax")}</th>
              <th className="border-0">{t("payer")}</th>
              <th className="border-0">{t("status")}</th>
              <th className="border-0">{t("rrn")}</th>
              <th className="border-0">{t("message")}</th>
            </tr>
          </thead>
          <tbody>
            {orderBy(payments, ["createdAt"], ["desc"]).map((payment, i) => (
              <tr key={i}>
                <td>
                  <Card.Link href="#" className="text-primary fw-bold">
                    {i + 1}
                  </Card.Link>
                </td>
                <td>{payment.suma} RON</td>
                <td>{dayjs(payment.createdAt).format("DD-MM-YYYY")}</td>
                <td style={{ whiteSpace: "pre-wrap" }}>
                  {payment.denumireTaxa}
                </td>
                <td>
                  {payment.user.profile.cnp === payment.context ? (
                    payment.user.profile.nume +
                    " " +
                    payment.user.profile.prenume
                  ) : (
                    <span>
                      {
                        _.find(
                          payment.user.businesses,
                          (el) => el.cui === payment.context,
                        )?.denumire
                      }
                    </span>
                  )}
                </td>
                <td className="text-center">
                  {payment.status === "Approved" ? (
                    <FontAwesomeIcon
                      color="green"
                      size="lg"
                      icon={faCircleCheck}
                    />
                  ) : (
                    <FontAwesomeIcon
                      color="red"
                      size="lg"
                      icon={faCircleXmark}
                    />
                  )}
                </td>
                <td>{payment.rrn}</td>
                <td>{payment.mesaj}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

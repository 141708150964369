import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Col, Row, Button, Dropdown } from '@themesberg/react-bootstrap';
import { TagForm } from "./TagForms";
import { TabelRoluri } from './TabelRoluri'
import { useState } from "react";
import AdaugareRol from './AdaugareRol';


const Settings = () => {
  const [showDefault, setShowDefault] = useState(false);
  const handleClose = () => setShowDefault(false);
    
  return (
    <>
      

      <div className="d-xl-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-xl-0">
          <h4>Administrare Tag-uri SmartForms</h4>
        </div>
      </div>

      <Row>
        <Col xs={12} xl={12}>
          <TagForm />
        </Col>

        <Col xs={12} xl={4}>
          <Row>
            <Col xs={12}>
              {/* <ProfileCardWidget /> */}
            </Col>
            <Col xs={12}>
            
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAPIClient } from "../../../helpers/api";
import Options from "./Options";

const NotFound = ({ payload: options, actionProvider, ...rest }) => {
  const [categories, setCategories] = useState(null);
  const client = useAPIClient();
  const { t } = useTranslation();

  const fetchCategories = async () => {
    try {
      const res = await client.getCategories();
      setCategories(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  if (categories) {
    categories.map(
      (option) => (option["handleOption"] = actionProvider.handleQuestions),
    );
  }
  const questions = [];
  questions.push({
    id: "0",
    content: "Contact CJTimis",
    answer: t("chatbot.contact_cjtimis"),
  });
  questions.map(
    (question) => (question["handleOption"] = actionProvider.handleAnswer),
  );
  if (!categories) {
    return <div>Loading..</div>;
  }
  return (
    <>
      <Options options={questions} title="Contacteaza CJTimis"></Options>
      <Options options={categories} title="Categorii" {...rest} />
    </>
  );
};

export default NotFound;

import {
  faCloudUploadAlt,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";
import { PfForm } from "./PfForm";

const RequestDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [request, setRequest] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const client = useAPIClient();
  const [showConfirmReject, setShowConfirmReject] = useState(false);
  const [reason, setReason] = useState("");

  const { t } = useTranslation();

  const fetchUserData = async () => {
    try {
      const res = await client.getProfileRequestById(id!);
      // user = res.user.profile;

      setRequest(res);
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      // toast.error(err);
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    fetchUserData();
  }, []);

  if (id === undefined) {
    return <h1>404! Nu exista cerere cu acest id</h1>;
  }

  const handleApproveProfileModification = async () => {
    setIsDisabled(true);
    try {
      await client.approveProfileRequest(id);
      toast.success("Cererea a fost aprobata");
      toast.success("Te vom redirectiona in 2 secunde...");
      setTimeout(() => {
        navigate("/admin/cereri-modificare-date");
      }, 2000);
    } catch (err: any) {
      // toast.error(err.message);
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleRejectProfileModification = async () => {
    setIsDisabled(true);
    try {
      await client.rejectProfileRequest(id, reason);

      toast.success("Cererea a fost respinsa");
      toast.success("Te vom redirectiona in 2 secunde...");
      setTimeout(() => {
        navigate("/admin/cereri-modificare-date");
      }, 2000);
    } catch (err: any) {
      // toast.error(err.message);
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <>
      <Container className="px-0 mt-4">
        <h2 className="fw-bold mb-5 text-uppercase text-center">
          Vizualizare Cerere
        </h2>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
          {request?.status === "Pending" && (
            <ButtonGroup>
              <Button
                variant="outline-success"
                size="sm"
                onClick={handleApproveProfileModification}
                disabled={isDisabled}
              >
                Aprobă
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => setShowConfirmReject(true)}
                disabled={isDisabled}
              >
                Respinge
              </Button>
            </ButtonGroup>
          )}
        </div>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <Row>
              <Col xs={12} lg={6} className="mb-4">
                <Card border="light" className="shadow-sm">
                  <Card.Header className="border-bottom border-light d-flex justify-content-between">
                    <h5 className="mb-0">Date inițiale</h5>
                  </Card.Header>
                  <Card.Body>
                    {!isLoading &&
                      (request.old_data ? (
                        <PfForm
                          userProfile={JSON.parse(request.old_data)}
                          email={request.user.email}
                          t={t}
                        />
                      ) : (
                        request.user.profile && (
                          <PfForm
                            userProfile={request.user.profile} ///this needs to be removed
                            /// but will break current requests that do not have old data
                            email={request.user.email}
                            t={t}
                          />
                        )
                      ))}
                  </Card.Body>
                </Card>
              </Col>

              <Col xs={12} lg={6} className="mb-4">
                <Card border="light" className="shadow-sm">
                  <Card.Header className="border-bottom border-light d-flex justify-content-between">
                    <h5 className="mb-0">Date modificate</h5>
                  </Card.Header>
                  <Card.Body>
                    {!isLoading && request?.newData && (
                      <PfForm
                        userProfile={request?.newData}
                        email={request?.user?.email}
                        t={t}
                      />
                    )}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={12} className="mb-4">
            <Card border="light" className="shadow-sm">
              <Card.Header className="border-bottom border-light d-flex align-items-center">
                <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                <h5 className="mb-0">Documente Atașate</h5>
              </Card.Header>
              <Card.Body>
                <Card border="light" className="shadow-sm">
                  <Card.Body className="p-0">
                    <Table
                      responsive
                      className="table-centered rounded"
                      style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0" style={{ width: "30%" }}>
                            Nume
                          </th>
                          <th className="border-0" style={{ width: "40%" }}>
                            Fișier
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {request?.documentId === true ? (
                          <tr>
                            <td
                              className="fw-bold border-0"
                              style={{ width: "30%" }}
                            >
                              <code>Document</code>
                            </td>
                            <td className="border-0" style={{ width: "40%" }}>
                              <pre>
                                <Card.Link
                                  onClick={() =>
                                    client.getProfileRequestDocument(request.id)
                                  }
                                  target="_blank"
                                >
                                  Descarcă fișier{" "}
                                  <FontAwesomeIcon
                                    icon={faExternalLinkAlt}
                                    className="ms-1"
                                  />
                                </Card.Link>
                              </pre>
                            </td>
                          </tr>
                        ) : (
                          <tr>
                            <td>
                              <h4>Nu sunt documente adăugate!</h4>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal
        show={showConfirmReject}
        onHide={() => setShowConfirmReject(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Respinge cererea</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mt-2">Introduceti motivul respingerii cererii:</p>
          <textarea
            id="reasonInput"
            name="reason"
            value={reason}
            onChange={({ target: { value } }) => setReason(value)}
            className="form-control"
          />
        </Modal.Body>

        <Modal.Footer>
          {!isDisabled ? (
            <>
              <Button
                variant="danger"
                onClick={handleRejectProfileModification}
              >
                Confirma respingerea
              </Button>
            </>
          ) : (
            <LoadingView />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RequestDetails;

import { useState, useEffect } from "react";
import {
  Container,
  Dropdown,
  DropdownButton,
  Form,
  InputGroup,
  Button,
  Card,
} from "react-bootstrap";
import { useAPIClient } from "../../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCircleCheck,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Table } from "@themesberg/react-bootstrap";
import { UserSearchOptions } from "../../helpers/utilities";
import { IUser } from "../../cjt";
import LoadingView from "../../layout/LoadingView";
import handleException from "../../helpers/exceptions";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

const options = Object.keys(UserSearchOptions).map((key) => ({
  value: key,
  label: UserSearchOptions[key as keyof typeof UserSearchOptions],
}));

export default function ManageUsers() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [users, setUsers] = useState<IUser[]>([]);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState<UserSearchOptions>(
    UserSearchOptions.NUME
  );

  const [term, setTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(term);

  useEffect(() => {
    const timer = setTimeout(() => setTerm(debouncedTerm), 100);
    return () => clearTimeout(timer);
  }, [debouncedTerm]);

  useEffect(() => {
    searchForUsers(term);
  }, [term]);

  const searchForUsers = async (searchQuery: string) => {
    try {
      const res = await client.getUsers(selectedOption, searchQuery);
      setUsers(res);
    } catch (err: any) {
      // setError(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "100%" }}>
      <h1 className="text-center">Gestionare utilizatori</h1>

      <div className="d-flex justify-content-center flex-wrap mt-3">
        <Card
          border="light"
          className="shadow-sm mb-4"
          style={{ width: "100%" }}
        >
          <Card.Body className="pb-0">
            <Form>
              <InputGroup size="lg">
                <DropdownButton
                  drop="down"
                  title={selectedOption ? selectedOption : options[0].label}
                  variant="primary"
                  onSelect={(value) => {
                    setSelectedOption(
                      UserSearchOptions[value as keyof typeof UserSearchOptions]
                    );
                    setDebouncedTerm("");
                  }}
                >
                  {options.map((option) => (
                    <Dropdown.Item key={option.value} eventKey={option.value}>
                      {option.label}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>

                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} size="xs" />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  onChange={(e) => setDebouncedTerm(e.target.value)}
                  value={debouncedTerm}
                />
              </InputGroup>

              <Table
                responsive
                className="table-centered table-nowrap rounded mt-3 mb-0"
              >
                <thead className="thead-light">
                  <tr>
                    <th className="border-0">#</th>
                    <th className="border-0">Nume prenume</th>
                    <th className="border-0">CNP</th>
                    <th className="border-0">Email confirmat</th>
                    <th className="border-0">Verificat</th>
                    <th className="border-0">Email</th>
                    <th className="border-0">Profil completat</th>
                    <th className="border-0">Business completat</th>
                    <th className="border-0"></th>
                  </tr>
                </thead>
                <tbody>
                  {!users ? (
                    <LoadingView />
                  ) : (
                    <>
                      {users.map((user, i) => (
                        <tr key={i}>
                          <td>
                            <Card.Link
                              href="#"
                              className="text-primary fw-bold"
                            >
                              {i + 1}
                            </Card.Link>
                          </td>
                          <td>
                            {user.profile ? (
                              <a href={`/admin/user/${user.email}`}>
                                {user.profile.nume + " " + user.profile.prenume}
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{user.profile ? user.profile.cnp : "N/A"}</td>
                          <td style={{ textAlign: "center" }}>
                            {user.isEmailConfirmed ? (
                              <FontAwesomeIcon
                                color="green"
                                size="1x"
                                icon={faCircleCheck}
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="red"
                                size="1x"
                                icon={faCircleXmark}
                              />
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {user.isVerified ? (
                              <FontAwesomeIcon
                                color="green"
                                size="1x"
                                icon={faCircleCheck}
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="red"
                                size="1x"
                                icon={faCircleXmark}
                              />
                            )}
                          </td>
                          <td>{user.email}</td>
                          <td style={{ textAlign: "center" }}>
                            {user.profile ? (
                              <FontAwesomeIcon
                                color="green"
                                size="1x"
                                icon={faCircleCheck}
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="red"
                                size="1x"
                                icon={faCircleXmark}
                              />
                            )}
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {user.businesses?.length ? (
                              <FontAwesomeIcon
                                color="green"
                                size="1x"
                                icon={faCircleCheck}
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="red"
                                size="1x"
                                icon={faCircleXmark}
                              />
                            )}
                          </td>
                          <td className="text-center">
                            <Button
                              variant="primary"
                              href={`/admin/user/${user.email}`}
                              size="sm"
                            >
                              Vezi Detalii
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </Table>
            </Form>
          </Card.Body>
        </Card>

        {error ? (
          <p>Could not fetch the users. Please check your connection :( </p>
        ) : (
          <></>
        )}
      </div>
    </Container>
  );
}

import { Button, Modal, ModalProps } from "react-bootstrap";

interface IModalConfirmDeletion extends ModalProps {}
const ModalConfirmDeletion = ({
  showConfirmDeletion,
  handleClose,
  categoryToDelete,
  questionToDelete,
  handleDelete,
}: IModalConfirmDeletion) => {
  return (
    <Modal show={showConfirmDeletion} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{"Ștergere!"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Sigur doriți ștergerea {categoryToDelete && "categoriei"}{" "}
          {questionToDelete && "întrebării"} ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Anuleaza
        </Button>
        <Button variant="primary" onClick={handleDelete}>
          Sterge
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmDeletion;

import { Form as Formio } from "@formio/react";
import {
  faEye,
  faFileSignature,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Table,
} from "@themesberg/react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Container, FormGroup } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";

import { IGrantSubmission } from "../../cjt";
import CloakButton from "../../components/CloakButton";
import ContestationWidget from "../../components/ContestationWidget";
import GrantScoreWidget from "../../components/GrantScoreWidget";
import GrantSubmissionStatus from "../../components/GrantSubmissionStatus";
import MilestoneWidget from "../../components/MilestoneWidget";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { betweenDates } from "../../helpers/utilities";
import ChatBox from "../Chatbox/Chatbox";

export default function GrantApplication() {
  const client = useAPIClient();
  const { id: grantApplicationId } = useParams();
  const { t } = useTranslation();

  const [grantSubmission, setGrantSubmission] =
    useState<IGrantSubmission | null>(null);
  const [formTemplate, setFormTemplate] = useState<any>(null);
  const [requiredDocUploads, setRequiredDocUploads] = useState<any>([]);
  const [contestationText, setContesationText] = useState("");
  const [contestationFile, setContestationFile] = useState<any>(null);
  const [showSubmitContestation, setShowSubmitContestation] = useState(false);
  const [showContestation, setShowContestation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [requiredDocumentDescription, setRequiredDocDescription] = useState("");
  const [showReplaceRequiredDocument, setShowReplaceRequiredDocument] =
    useState(false);
  const [replaceDocId, setReplaceDocId] = useState("");
  const [replaceDocUploads, setReplaceDocUploads] = useState<any>([]);
  const [deleteAdditionalDocumentId, setDeleteAdditionalDocumentId] =
    useState("");
  const [showDeleteAddditionalDocument, setShowDeleteAdditionalDocument] =
    useState(false);
  const navigate = useNavigate();
  const fetchGrantProject = async () => {
    if (!grantApplicationId) {
      return;
    }

    try {
      const _grantSubmission =
        await client.getSubmittedGrant(grantApplicationId);
      const _form = await client.getTemplateById(
        _grantSubmission.project.formTemplate.id,
      );

      _grantSubmission.project.projectSubmissionEnd = moment(
        _grantSubmission.project.projectSubmissionEnd,
        "YYYY-MM-DD",
      )
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      _grantSubmission.project.contestationEnd = moment(
        _grantSubmission.project.contestationEnd,
        "YYYY-MM-DD",
      )
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      setGrantSubmission(_grantSubmission);
      // HACK remove any submit butons that exist in the components list
      const _formBody = JSON.parse(_form.body);
      const cleanedFormBody = _formBody.components.filter(
        (component: any) => component.label !== "Submit",
      );

      setFormTemplate({ components: cleanedFormBody });
    } catch (e) {
      navigate("/404");
      return;
    }
  };

  useEffect(() => {
    fetchGrantProject();
  }, []);

  const handleAdditionalUpload = async () => {
    if (!grantSubmission) {
      return;
    }

    await client.uploadAditionalGrantSubmissionDocument(
      grantSubmission?.id,
      requiredDocUploads,
      requiredDocumentDescription,
    );
    await fetchGrantProject();

    toast.success(t("grant_apply.upload_success"));
  };

  if (!grantSubmission || !formTemplate) {
    return <div>Loading...</div>;
  }

  const handleSubmitContestation = async () => {
    setIsSubmitting(true);

    try {
      await client.uploadSubmissionContestation(
        grantSubmission.id,
        contestationText,
        contestationFile,
      );
      toast.success("Contestație adăugată cu succes!");
      await fetchGrantProject();
      setShowSubmitContestation(false);
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleReplaceDoc = async () => {
    setIsSubmitting(true);
    try {
      await client.replaceRequiredDocument(
        grantSubmission.id,
        replaceDocId,
        replaceDocUploads,
      );
      toast.success("Document schimbat cu succes!");
      await fetchGrantProject();
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setShowReplaceRequiredDocument(false);
      setIsSubmitting(false);
    }
  };

  const handleDeleteAdditionalDocument = async () => {
    setIsSubmitting(true);
    try {
      await client.deleteAditionalGrantSubmissionDocument(
        grantSubmission.id,
        deleteAdditionalDocumentId,
      );
      toast.success("Document sters cu succes!");
      await fetchGrantProject();
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setShowDeleteAdditionalDocument(false);
      setIsSubmitting(false);
    }
  };

  return (
    <Container fluid>
      <Row className="mb-4">
        <Col>
          <h3 className="mb-4">
            {grantSubmission.project.name} -{" "}
            <GrantSubmissionStatus status={grantSubmission.status} t={t} />
          </h3>
        </Col>
        <Col className="d-flex align-items-end justify-content-end gap-2">
          <CloakButton
            isVisible={!!grantSubmission.project.partialResultsDocument}
            variant="outline-primary"
            size="sm"
            onClick={() =>
              client.downloadPartialResultsDocument(grantSubmission.project.id)
            }
          >
            {t("grant_apply.download_partial_results")}
          </CloakButton>
          <CloakButton
            isVisible={!!grantSubmission.project.contestationResultsDocument}
            variant="outline-primary"
            size="sm"
            onClick={() =>
              client.downloadContenstationResultsDocument(
                grantSubmission.project.id,
              )
            }
          >
            {t("grant_apply.download_contestation_results")}
          </CloakButton>
          <CloakButton
            isVisible={!!grantSubmission.project.finalResultsDocument}
            variant="outline-primary"
            size="sm"
            onClick={() =>
              client.downloadFinalResultsDocument(grantSubmission.project.id)
            }
          >
            {t("grant_apply.download_final_results")}
          </CloakButton>
          {betweenDates(
            grantSubmission.project.contestationStart,
            grantSubmission.project.contestationEnd,
          ) &&
            !grantSubmission.contestation && (
              <>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => setShowSubmitContestation(true)}
                >
                  {t("grant_apply.file_appeal")}
                </Button>
              </>
            )}
        </Col>
      </Row>
      <Row>
        <Col>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body>
              <div className="row text-center">
                <MilestoneWidget
                  title=""
                  milestones={[
                    {
                      title: t("grant_apply.grant_submission"),
                      icon: faFileSignature,
                      startDate: grantSubmission.project.projectSubmissionStart,
                      endDate: grantSubmission.project.projectSubmissionEnd,
                      editable: false,
                    },
                    {
                      title: t("grant_apply.grant_evaluation"),
                      icon: faFileSignature,
                      startDate: grantSubmission.project.evaluationStart,
                      endDate: grantSubmission.project.evaluationEnd,
                      editable: false,
                    },
                    {
                      title: t("grant_apply.grant_results"),
                      icon: faEye,
                      startDate:
                        grantSubmission.project.resultsAnnouncementDate,
                      editable: false,
                    },
                    {
                      title: t("grant_apply.grant_contestation"),
                      icon: faFileSignature,
                      startDate: grantSubmission.project.contestationStart,
                      endDate: grantSubmission.project.contestationEnd,
                      editable: false,
                    },
                    {
                      title: t("grant_apply.grant_contestation_results"),
                      icon: faEye,
                      startDate:
                        grantSubmission.project.contestationResultsDate,
                      editable: false,
                    },
                    {
                      title: t("grant_apply.grant_final_results"),
                      icon: faTrophy,
                      startDate: grantSubmission.project.finalResultsDate,
                      editable: false,
                    },
                  ]}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {grantSubmission.evaluationScore && (
        <Row className="mb-4">
          <Col>
            <GrantScoreWidget grantSubmission={grantSubmission} />
          </Col>
        </Row>
      )}

      {grantSubmission.contestation && (
        <Row>
          <Col>
            <ContestationWidget grantSubmission={grantSubmission} />
          </Col>
        </Row>
      )}

      <Row>
        <Col>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4"></h5>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${grantSubmission.project.description}`,
                }}
              />
              <hr />
              <h5 className="mt-4">{t("grant_apply.applicant")}</h5>
              <p>
                {grantSubmission.business?.denumire} - [
                {grantSubmission.business?.cui}]
              </p>
              <Formio
                form={formTemplate}
                submission={JSON.parse(grantSubmission.completedForm)}
                options={{
                  readOnly: true,
                  noAlerts: true,
                }}
              />
              <h5 className="mt-4">{t("grant_apply.required_documents")}</h5>

              <Table>
                <thead>
                  <tr>
                    <th>{t("grant_apply.document_name")}</th>
                    <th>{t("grant_apply.description")}</th>
                    <th>{t("grant_apply.document_file")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {grantSubmission &&
                    grantSubmission.requestedDocuments.map((doc) => (
                      <tr key={doc.id}>
                        <td>{doc.name}</td>
                        <td>{doc.description}</td>
                        <td>
                          <a
                            onClick={() =>
                              client.downloadSubmittedGrantDocument(
                                grantSubmission.id,
                                doc.id,
                              )
                            }
                          >
                            {doc.name}
                          </a>
                        </td>
                        {betweenDates(
                          grantSubmission.project.projectSubmissionStart,
                          grantSubmission.project.projectSubmissionEnd,
                        ) && (
                          <td>
                            <Button
                              onClick={() => {
                                setReplaceDocId(doc.id);
                                setShowReplaceRequiredDocument(true);
                              }}
                            >
                              {t("grant_apply.modify_file")}
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>

              <h5 className="mt-4">{t("grant_apply.additional_documents")}</h5>
              <Table>
                <thead>
                  <tr>
                    <th>{t("grant_apply.document_name")}</th>
                    <th>{t("grant_apply.description")}</th>
                    <th>{t("grant_apply.document_file")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {grantSubmission.additionalDocuments &&
                    grantSubmission.additionalDocuments.map((doc) => (
                      <tr key={doc.id}>
                        <td>{doc.name}</td>
                        <td>{doc.description}</td>
                        <td>
                          <a
                            onClick={() =>
                              client.downloadSubmittedGrantDocument(
                                grantSubmission.id,
                                doc.id,
                              )
                            }
                          >
                            {doc.name}
                          </a>
                        </td>
                        {betweenDates(
                          grantSubmission.project.projectSubmissionStart,
                          grantSubmission.project.projectSubmissionEnd,
                        ) && (
                          <td>
                            <Button
                              onClick={() => {
                                setDeleteAdditionalDocumentId(doc.id);
                                setShowDeleteAdditionalDocument(true);
                              }}
                            >
                              {t("grant_apply.delete_additional")}
                            </Button>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </Table>
              {betweenDates(
                grantSubmission.project.projectSubmissionStart,
                grantSubmission.project.projectSubmissionEnd,
              ) && (
                <>
                  <h5 className="mt-4">
                    {t("grant_apply.additional_documents")}
                  </h5>
                  <input
                    type="file"
                    onChange={({ target: { files } }) =>
                      setRequiredDocUploads(files?.[0])
                    }
                  />
                  <Form.Control
                    type="text"
                    placeholder={t("grant_apply.description")}
                    onChange={(event) =>
                      setRequiredDocDescription(event.target.value)
                    }
                  />
                  <Button className="mt-2" onClick={handleAdditionalUpload}>
                    {t("upload")}
                  </Button>{" "}
                </>
              )}
              <h5 className="mt-4">{t("grant_apply.messages")}</h5>
              <ChatBox id={grantSubmission.chat.id} canUserDelete={false} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={showSubmitContestation}
        size="lg"
        onHide={() => {
          setShowSubmitContestation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.appeal_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="mb-3">
            <Form.Label>{t("grant_apply.appeal_text")}</Form.Label>
            <TextareaAutosize
              className="form-control"
              onChange={({ target: { value } }) => setContesationText(value)}
            />
          </FormGroup>
          <FormGroup>
            <Form.Label>{t("grant_apply.appeal_load")}</Form.Label>
            <input
              type="file"
              className="form-control"
              onChange={({ target: { files } }) =>
                setContestationFile(files?.[0])
              }
            />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSubmitContestation(false);
            }}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleSubmitContestation()}
            disabled={isSubmitting}
          >
            {t("confirm")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showContestation}
        size="lg"
        onHide={() => {
          setShowContestation(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.appeal_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Control
              as="textarea"
              id="contestationText"
              name="contestationText"
              readOnly
              value={
                grantSubmission.contestation ? grantSubmission.contestation : ""
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowSubmitContestation(false);
            }}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleSubmitContestation()}
            disabled={isSubmitting}
          >
            {t("confirm")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showReplaceRequiredDocument}
        size="lg"
        onHide={() => {
          setShowReplaceRequiredDocument(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.reload_doc")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            onChange={({ target: { files } }) =>
              setReplaceDocUploads(files?.[0])
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowReplaceRequiredDocument(false);
            }}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleReplaceDoc()}
            disabled={isSubmitting}
          >
            {t("confirm")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteAddditionalDocument}
        size="lg"
        onHide={() => {
          setShowDeleteAdditionalDocument(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.delete_additional")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("grant_apply.delete_warning")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowReplaceRequiredDocument(false);
              setShowDeleteAdditionalDocument(false);
            }}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleDeleteAdditionalDocument()}
            disabled={isSubmitting}
          >
            {t("confirm")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

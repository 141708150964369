import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Modal, Accordion, Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { IForumTopic, IUser } from "../../../cjt";
import ChatBox from "../../Chatbox/Chatbox";
import "../forum.css";
import { useAuthContext } from "../../../context/AuthContext";
import { AccountAttribute } from "../../../enums";

function Forum_detail() {
  const { id } = useParams();
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();

  const [loading, setLoading] = useState(false);
  const [topicData, setTopicData] = useState<IForumTopic | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [projectFile, setProjectFile] = useState<any>();
  const [user, setUser] = useState<IUser | null>(null);
  const { requiredAttribute } = useAuthContext();

  useEffect(() => {
    const fetchTopicData = async () => {
      try {
        const topic = await client.getForumTopicById(id!);
        setTopicData(topic);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("A apărut o eroare în returnarea topicurilor. Te rugăm să încerci din nou.");
      }
    };

    fetchTopicData();
  }, []);

  const fetchUser = async () => {
    try {
      const user = await client.getUserFeed();
      setUser(user);
    } catch (err: any) {
        toast.error(err.message as string);
    }
  };

  const handleUploadClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleUploadUserFileModal = async () => {
    try {
      const response = await client.uploadForumUserResponseTopicFile(topicData!.id, " ", projectFile[0]);
      setLoading(false);
      toast.success("Fișierul a fost urcat cu succes");
    } catch (error) {
      setLoading(false);
      toast.error("A apărut o eroare în urcarea documentului.");
    }
    setShowModal(false);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if(!topicData){
    return <div>{t("topic_not_found")}</div>;
  }

  return (
    <>
      <Container>
      {requiredAttribute(AccountAttribute.OpenGovernmentForumAdministrator) && (
      <Accordion defaultActiveKey="0" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Button className="w-100 d-flex justify-content-between">
            <span className="h6 mb-0 fw-bold">
              {t("forum_doc_users")}
            </span>
          </Accordion.Button>
          <Accordion.Body>
            <Card.Body className="py-2 px-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0 text-center">#</th>
                  <th className="border-0 text-center">{t("user")}</th>
                  <th className="border-0 text-center">{t("forum_doc_title")}</th>
                  <th className="border-0 text-center">{t("forum_options")}</th>
                </tr>
              </thead>
              <tbody>
                {topicData?.inReviewFileData?.map((file, index) => {
                  const fileObject: {
                    id: string;
                    description: string;
                    name: string;
                    userId: string;
                    userDisplayName: string;
                  } = JSON.parse(file);
                  return (
                    <tr key={index + 1}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{fileObject?.userDisplayName}</td>
                      <td className="text-center">{fileObject.name ?? fileObject.id}</td>
                      <td className="text-center">
                      <Button variant="primary" size="sm" onClick={() => {
                            return client.getFile(fileObject.id);
                          }}>
                        {t("download_doc")}
                      </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      )}

      <Accordion defaultActiveKey="0" className="mb-3">
        <Accordion.Item eventKey="1">
          <Accordion.Button className="w-100 d-flex justify-content-between">
            <span className="h6 mb-0 fw-bold">
              {t("forum_doc_admin")}
            </span>
          </Accordion.Button>
          <Accordion.Body>
            <Card.Body className="py-2 px-0">
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0 text-center">#</th>
                  <th className="border-0 text-center">{t("user")}</th>
                  <th className="border-0 text-center">{t("forum_doc_title")}</th>
                  <th className="border-0 text-center">{t("forum_options")}</th>
                </tr>
              </thead>
              <tbody>
                {topicData?.fileData?.map((file, index) => {
                  const fileObject: {
                    id: string;
                    description: string;
                    name: string;
                  } = JSON.parse(file);
                  return (
                    <tr key={index + 1}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">Admin</td>
                      <td className="text-center">{fileObject.name ?? fileObject.id}</td>
                      <td className="text-center">
                      <Button variant="primary" size="sm" onClick={() => {
                            return client.getFile(fileObject.id);
                          }}>
                            {t("download_doc")}
                      </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            </Card.Body>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className="card border border-primary bblr-0 bbrr-0">
            <div className="card-header bg-transparent border-primary d-flex justify-content-between">
                <div>
                    <h5 className="my-0 text-primary">{topicData.title}</h5>                            
                    <div className="star">
                        {t("forum_posted_by")} <span>Admin</span>
                    </div>
                </div>
            </div>
        </div>

        <div className="card btrr-0 btlr-0 bbrr-0 bblr-0 ">
            <div className="card-body">

                <div className="tab-content p-3 text-muted">

                    <div className="tab-pane active" id="propunere1" role="tabpanel">
                        <div className="mt-3">
                            {topicData.description}
                        </div>
                        <div className="mt-3">
                            {t("forum_category")}
                            <span className="badge m-1 p-2" style={{backgroundColor:'#05A677'}}>{topicData?.category?.label}</span>
                        </div>
                    </div>
                    
                </div>

                {topicData.chat && (
                    <div className="forum-component">
                      <ChatBox id={topicData.chat.id} />
                    </div>
                )}

                <div className="rce-input-buttons" id="insertAfterSendButton" onClick={handleUploadClick}> 
                  <button type="button" color="white" className="btn btn-primary">
                    <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" style={{marginTop:"-0.2em"}}><path d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"></path></svg>
                    {t("forum_upload")}
                  </button>
                </div>

                {/* Modal for uploading documents */}
                <Modal show={showModal} onHide={handleCloseModal}>
                  <Modal.Header closeButton>
                    <Modal.Title>{t("forum_upload")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>{t("topic_add_document")}</Form.Label>
                        <Form.Control
                        type="file"
                        id="projectFile"
                        required
                        onChange={async ({ target: { files } }: any) =>
                          setProjectFile(files)
                        }
                      />
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                      {t("close")}
                    </Button>
                    <Button variant="primary" onClick={handleUploadUserFileModal}>
                      {t("send_doc")}
                    </Button>
                  </Modal.Footer>
                </Modal>

            </div>
        </div>
      </Container>
    </>
  );
}

export default Forum_detail;

import {
  faCheckCircle,
  faCircleQuestion,
  faClock,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { IHearing, IHearingRequest, ISubmission } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { formatDate } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";

export default function UserOnlineRequests() {
  const { t } = useTranslation();
  const [smartFormsList, setSmartFormsList] = useState<ISubmission[]>([]);
  const [hearingRequestList, setHearingRequestList] = useState<
    IHearingRequest[]
  >([]);
  const [hearingsList, setHearings] = useState<IHearing[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const client = useAPIClient();

  const fetchUserSmartFormRequests = async () => {
    try {
      const res = await client.getUserSmartFormRequests();

      setSmartFormsList(res);
      setIsLoading(false);
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   setIsLoading(false);
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fetchHearingRequestsForUser = async () => {
    try {
      const res = await client.getHearingsForUser();

      setHearings(res);
      setIsLoading(false);
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   setIsLoading(false);
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fetchHearingsForUser = async () => {
    try {
      const res = await client.getHearingRequestsForUser();

      setHearingRequestList(res);
      setIsLoading(false);
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   setIsLoading(false);
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const getIconForStatus = (status: string) => {
    if (status == "Pending") {
      /// need to implement enum on frontend :(( later
      return (
        <FontAwesomeIcon color="lightblue" size="xl" icon={faCircleQuestion} />
      );
    } else if (status == "Rejected") {
      return (
        <FontAwesomeIcon
          color="red"
          size="xl"
          icon={faXmarkCircle}
        ></FontAwesomeIcon>
      );
    } else if (status == "Scheduled") {
      return (
        <FontAwesomeIcon
          color="lightgreen"
          size="xl"
          icon={faClock}
        ></FontAwesomeIcon>
      );
    } else if (status == "Completed") {
      return (
        <FontAwesomeIcon
          color="green"
          size="xl"
          icon={faCheckCircle}
        ></FontAwesomeIcon>
      );
    }
  };

  const getOverLayForHearing = (hearingRequest: IHearingRequest) => {
    if (hearingRequest.status == "Pending") {
      /// need to implement enum on frontend :(( later
      return "In procesare";
    } else if (hearingRequest.status == "Rejected") {
      return (
        "Cererea a fost respinsa cu motivul: " + hearingRequest.resolveReason
      );
    } else if (hearingRequest.status == "Scheduled") {
      return "Audienta a fost programata. " + hearingRequest.resolveReason;
    } else if (hearingRequest.status == "Completed") {
      return "Audienta aferenta cererii a fost acceptata si s-a incheiat. ";
    }
  };

  useEffect(() => {
    fetchUserSmartFormRequests();
    fetchHearingRequestsForUser();
    fetchHearingsForUser();
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  const { state } = useLocation();

  return (
    <>
      <Container className="px-0">
        <h2 className="text-center">{t("sidebar_cererile_mele")}</h2>
        <Row>
          <Col xs={12} className="p-3">
            <Card>
              <Card.Body>
                <Tab.Container
                  defaultActiveKey={state ? state : "persoana-fizica"}
                >
                  <Row>
                    <Col lg={12}>
                      <Nav className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="persoana-fizica"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("my_requests_online_requests")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="persoana-fizica-cereri-audienta"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("my_requests_hearing_requests")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="my-hearings"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("my_requests_my_hearings")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="persoana-fizica" className="py-4">
                          <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                              <Form>
                                <Table
                                  responsive
                                  className="table-centered table-nowrap rounded mb-0"
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="border-0">#</th>
                                      <th className="border-0">{t("date")}</th>
                                      <th className="border-0">
                                        {t("my_requests_request_name")}
                                      </th>
                                      <th className="border-0">{t("state")}</th>
                                      <th className="border-0"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {orderBy(
                                      smartFormsList,
                                      ["createdAt"],
                                      ["desc"],
                                    ).map((smartForm, i) => (
                                      <tr key={i}>
                                        <td>
                                          <Card.Link
                                            href="#"
                                            className="text-primary fw-bold"
                                          >
                                            {i + 1}
                                          </Card.Link>
                                        </td>
                                        <td>
                                          {formatDate(smartForm.createdAt)}
                                        </td>
                                        <td>
                                          {smartForm.templateName.length > 50
                                            ? smartForm.templateName
                                                .substring(0, 50)
                                                .concat("...")
                                            : smartForm.templateName}
                                        </td>
                                        <td>{smartForm.status}</td>
                                        <td className="text-center">
                                          <Button
                                            variant="primary"
                                            href={
                                              "/account/online-requests/" +
                                              smartForm.id
                                            }
                                            size="sm"
                                          >
                                            {t("my_requests_see_details")}
                                          </Button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </Form>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane
                          eventKey="persoana-fizica-cereri-audienta"
                          className="py-4"
                        >
                          <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                              <Form>
                                <Table
                                  responsive
                                  className="table-centered table-nowrap rounded mb-0"
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="border-0">#</th>
                                      <th className="border-0">
                                        {t("creation_date")}
                                      </th>
                                      <th className="border-0">
                                        {t("my_requests_auditor")}
                                      </th>
                                      <th className="border-0">{t("state")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {hearingRequestList.map(
                                      (hearingRequest, i) => (
                                        <tr key={i}>
                                          <td>
                                            <Card.Link
                                              href="#"
                                              className="text-primary fw-bold"
                                            >
                                              {i + 1}
                                            </Card.Link>
                                          </td>
                                          <td>
                                            {formatDate(
                                              hearingRequest.createdAt,
                                            )}
                                          </td>
                                          <td>
                                            {hearingRequest.auditor.displayName}
                                          </td>
                                          <td>
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip className="m-0">
                                                  {getOverLayForHearing(
                                                    hearingRequest,
                                                  )}
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                {getIconForStatus(
                                                  hearingRequest.status,
                                                )}
                                              </span>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                      ),
                                    )}
                                  </tbody>
                                </Table>
                              </Form>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                        <Tab.Pane eventKey="my-hearings" className="py-4">
                          <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                              <Form>
                                <Table
                                  responsive
                                  className="table-centered table-nowrap rounded mb-0"
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="border-0">
                                        {t("my_requests_auditor")}
                                      </th>
                                      <th className="border-0">{t("date")}</th>
                                      <th className="border-0">
                                        {t("my_requests_participants")}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {hearingsList.map((hearing) => {
                                      return (
                                        <tr>
                                          <td>{hearing.auditor.displayName}</td>
                                          <td>
                                            {dayjs(hearing.startDate).format(
                                              "DD/MM/YYYY HH:mm",
                                            )}
                                          </td>
                                          <td>
                                            {hearing.invitees.length > 1
                                              ? "Mai multi participanti"
                                              : hearing.invitees[0]?.profile
                                                  ?.nume +
                                                " " +
                                                hearing.invitees[0]?.profile
                                                  ?.prenume}
                                          </td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </Table>
                              </Form>
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

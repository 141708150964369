import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import {
  Button,
  Form,
  Modal,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Table } from "@themesberg/react-bootstrap";
import { toast } from "react-hot-toast";
import { useAPIClient } from "../../helpers/api";
import { ITemplateBody } from "../../cjt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLink } from "@fortawesome/free-solid-svg-icons";
import { EnumMap, FormUsage } from "../../enums";
import handleException from "../../helpers/exceptions";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated";

type Props = {
  items: ITemplateBody[];
  refreshCallback: () => void;
};

export default function FormListView({ items, refreshCallback }: Props) {
  const client = useAPIClient();
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [usage, setUsage] = useState<{
    label: string;
    value: FormUsage;
  }>();
  const { t } = useTranslation();
  const animatedComponents = makeAnimated();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 500) {
      toast.error(t("form.error_length"));
      return;
    }
    setText(event.target.value);
  };

  const roles: EnumMap<FormUsage, string> = {} as any;
  Object.entries(FormUsage).forEach(([key, value]) => {
    roles[key as FormUsage] = value;
  });
  const rolesObj = Object.entries(FormUsage).map(([key, value]) => ({
    value: key as FormUsage,
    label: value,
  }));

  const handleCreated = async () => {
    if (text === "") {
      toast.error(t("form.error_name"));
      return;
    }
    if (text.length > 500) {
      toast.error(t("form.error_toolong"));
      return;
    }
    await client
      .createTemplate(text, usage?.value)
      .then(() => {
        setShow(false);
        refreshCallback();
      })
      .catch((err: any) => {
        const errorMessages = handleException(err, t);
        errorMessages.forEach((message: any) => {
          toast.error(message);
        });
      });
  };

  return (
    <>
      <h1 className="text-center mt-4 mb-4">{t("form.title")}</h1>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <Button
            variant="primary"
            className="mb-3"
            onClick={() => setShow(true)}
          >
            {t("form.add")}
          </Button>

          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0">#</th>
                <th className="border-0">{t("pj_form.name")}</th>
                <th className="border-0">{t("state")}</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {orderBy(items, ["id"], ["desc"]).map((item, i) => (
                <tr key={i}>
                  <td>{i + 1}</td>

                  <td>
                    <a
                      href={`/admin/edit-form?id=${item.template.id}`}
                      className="font-weight-bold pr-3"
                    >
                      {item.template.name.length > 50
                        ? item.template.name.substring(0, 50).concat("...")
                        : item.template.name}
                    </a>
                  </td>
                  <td>
                    {item.template.active ? (
                      <span className="badge bg-success">{t("active")}</span>
                    ) : (
                      <span className="badge bg-danger">{t("inactive")}</span>
                    )}
                  </td>

                  <td className="text-center">
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="m-0">
                          {t("form.seeas")}
                        </Tooltip>
                      }
                    >
                      <a
                        href={`/operatiuni/submit-form/${item.template.id}`}
                        className="font-weight-bold pr-3 me-3"
                        target="_blank"
                      >
                        <FontAwesomeIcon
                          icon={faExternalLink}
                          className=""
                        ></FontAwesomeIcon>
                      </a>
                    </OverlayTrigger>
                    <Button
                      href={`/admin/edit-form?id=${item.template.id}`}
                      className="font-weight-bold pr-3"
                      size="sm"
                    >
                      {t("edit")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("form.add")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>{t("pj_form.name")}</Form.Label>
          <Form.Control
            value={text}
            onChange={handleChange}
            type="text"
            placeholder={t("form.default")}
          />
          <Form.Label className="mt-2">{t("form.module")} </Form.Label>
          <Select
            options={rolesObj}
            components={animatedComponents}
            onChange={(selected: any) => {
              setUsage(selected);
            }}
            value={usage}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={handleCreated}>
            {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

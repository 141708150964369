import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { IForumCategory, IForumTopic } from "../../../cjt";
import Select from "react-select";

function Forum_create() {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();

  const { register, handleSubmit, getValues } = useForm();
  const [loading, setLoading] = useState(false);
  const [forumCategories, setForumCategories] = useState<IForumCategory[] | null>();
  const [forumCategoriesOptions, setForumCategoriesOptions] = useState<Option[]>();
  const [selectedForumCategory, setSelectedForumCategory] = useState<Option | null>(null);
  const [projectFile, setProjectFile] = useState<any>();
  const [submittedForumTopic, setSubmittedForumTopic] = useState<IForumCategory>();

  type Option = {
    label: string;
    value: string;
  };

  const onSubmitTopic = async () => {
    try {
      setLoading(true);
      const formData = getValues() as IForumTopic;
      formData.category_label = selectedForumCategory!.label
      const response = await client.createForumTopic(formData);
      setSubmittedForumTopic(response)
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t("topic.error_create"));
    }
  };

  const onSubmitFile = async () => {
    if(submittedForumTopic){
      try {
        setLoading(true);
        const response = await client.uploadForumTopicFile(submittedForumTopic.id, " ", projectFile[0]);
        toast.success(t("topic.success_create"));
        setTimeout(() => {
          navigate("/bugetare-participativa/forum");
        }, 1000);
      } catch (error) {
        deleteForum();
        setLoading(false);
        toast.error(t("topic.error_file"));
      } 
    }
  };
  
  const deleteForum = async () => {
    if(submittedForumTopic){
      try {
        setLoading(true);
        const categories = await client.deleteForumTopic(submittedForumTopic.id);
        setForumCategories(categories)
      } catch (error: any) {
        toast.error(t("topic.error") + error.message as string);
      } finally {
        setLoading(false);
      }
    }
  }

  const fetchForumCategories = async () => {
    try {
      setLoading(true);
      const categories = await client.getForumCategories();
      setForumCategories(categories)
    } catch (error: any) {
      toast.error(t("topic.error") + error.message as string);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchForumCategories();
    const forumCategoryOptions = forumCategories?.map(
      (key) => ({
        label: key.label,
        value: key.id
      })
    );
    setForumCategoriesOptions(forumCategoryOptions as Option[])
  }, []);

  useEffect(() => {
    const forumCategoryOptions = forumCategories?.map(
      (key) => ({
        label: key.label,
        value: key.id
      })
    );
    setForumCategoriesOptions(forumCategoryOptions as Option[])
  }, [forumCategories]);


  useEffect(() => {
    onSubmitFile()
  }, [submittedForumTopic]);
  



  return (
    <>
      <Container>
      <Card>
        <Card.Body>
          <div className="container-lg">
            <h1 className="text-center m-2">{t("new_topic_title")}</h1>
            <div className="container m-4 p-4 rounded">
              <form onSubmit={handleSubmit(onSubmitTopic)}>
                <Row className="mb-3">
                  <h5>{t("topic_title")}</h5>
                  <Col md={12} className="mb-2">
                    <Form.Group className="d-flex align-items-center" id="titlu">
                      <Form.Control type="text" {...register("title", { required: true })} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <h5>{t("topic_description")}</h5>
                  <Col md={12} className="mb-2">
                    <Form.Group className="d-flex align-items-center" id="descriere">
                      <Form.Control as="textarea" rows={4} {...register("description", { required: true })}/>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <h5>{t("topic_add_document")}</h5>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                    <Form.Control
                        type="file"
                        id="projectFile"
                        required
                        onChange={async ({ target: { files } }: any) =>
                          setProjectFile(files)
                        }
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <h5>{t("topic_category")}</h5>
                  <Col md={12} className="mb-2">
                    <Form.Group className="d-flex align-items-center">
                          <Select
                            className="w-100"
                            options={forumCategoriesOptions}
                            value={selectedForumCategory}
                            onChange={(selectedOption) => setSelectedForumCategory(selectedOption)}
                            required={true}
                          />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="text-center">
                <Button variant="primary" type="submit" disabled={loading}>{t("topic_create")}</Button>
                </div>
              </form>
            </div>
          </div>
        </Card.Body>
      </Card>
      </Container>
    </>
  );
}

export default Forum_create;

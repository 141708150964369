import { Form as Formio } from "@formio/react";
import {
  faEye,
  faFileSignature,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Modal, Row, Table } from "@themesberg/react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import { Card, Container, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

import { IContractFolder, IGrantSubmission } from "../../cjt";
import CloakButton from "../../components/CloakButton";
import ContestationWidget from "../../components/ContestationWidget";
import GrantScoreWidget from "../../components/GrantScoreWidget";
import GrantSubmissionStatus from "../../components/GrantSubmissionStatus";
import MilestoneWidget from "../../components/MilestoneWidget";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { afterDate, betweenDates } from "../../helpers/utilities";
import ChatBox from "../Chatbox/Chatbox";

export default function ManageGrantApplication() {
  const client = useAPIClient();
  const { id: grantApplicationId } = useParams();
  const { t } = useTranslation();
  const { requiredAttribute } = useAuthContext();

  const [grantSubmission, setGrantSubmission] =
    useState<IGrantSubmission | null>(null);
  const [formTemplate, setFormTemplate] = useState<any>(null);
  const [requiredDocUploads, setRequiredDocUploads] = useState<any>([]);
  const [rejectionReason, setRejectionReason] = useState("");
  const [contracts, setContracts] = useState<IContractFolder[]>([]);
  const [contractNumber, setContractNumber] = useState("");
  const [show, setShow] = useState(false);
  const [showRejection, setShowRejection] = useState(false);
  const [showLinkContract, setShowLinkContract] = useState(false);
  const [additionalDocumentDescription, setAdditionalDocumentDescription] =
    useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showReplaceRequiredDocument, setShowReplaceRequiredDocument] =
    useState(false);
  const [replaceDocId, setReplaceDocId] = useState("");
  const [replaceDocUploads, setReplaceDocUploads] = useState<any>([]);
  const [deleteAdditionalDocumentId, setDeleteAdditionalDocumentId] =
    useState("");
  const [showDeleteAddditionalDocument, setShowDeleteAdditionalDocument] =
    useState(false);

  const { register: registerScore, handleSubmit: handleSubmitScore } =
    useForm();
  const navigate = useNavigate();

  const fetchGrantProject = async () => {
    if (!grantApplicationId) {
      return;
    }

    try {
      const _grantSubmission =
        await client.getSubmittedGrant(grantApplicationId);
      const _form = await client.getTemplateById(
        _grantSubmission.project.formTemplate.id,
      );

      _grantSubmission.project.projectSubmissionEnd = moment(
        _grantSubmission.project.projectSubmissionEnd,
        "YYYY-MM-DD",
      )
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      _grantSubmission.project.contestationEnd = moment(
        _grantSubmission.project.contestationEnd,
        "YYYY-MM-DD",
      )
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      setGrantSubmission(_grantSubmission);
      // HACK remove any submit butons that exist in the components list
      const _formBody = JSON.parse(_form.body);
      const cleanedFormBody = _formBody.components.filter(
        (component: any) => component.label !== "Submit",
      );

      setFormTemplate({ components: cleanedFormBody });
    } catch (e: any) {
      navigate("/404");
      return;
    }
  };

  useEffect(() => {
    fetchGrantProject();
  }, []);

  const handleAdditionalUpload = async () => {
    if (!grantSubmission) {
      return;
    }

    await client.uploadAditionalGrantSubmissionDocument(
      grantSubmission?.id,
      requiredDocUploads,
      additionalDocumentDescription,
    );
    await fetchGrantProject();

    toast.success(t("grant_apply.upload_success"));
  };

  const submitScore = async (data: any) => {
    if (!grantSubmission) {
      return;
    }

    try {
      await client.submitEvaluation(grantSubmission.id, data);
      await fetchGrantProject();
      toast.success(t("grant_apply.score_success"));
      setShow(false);
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.score_error"));
    }
  };

  const handleApproval = async () => {
    if (!grantSubmission) {
      return;
    }

    try {
      await client.approveGrantSubmission(grantSubmission.id);
      await fetchGrantProject();
      toast.success(t("grant_apply.approve_success"));
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.approve_error"));
    }
  };

  const handleRejection = async () => {
    if (!grantSubmission) {
      return;
    }

    try {
      await client.rejectGrantSubmission(grantSubmission.id, rejectionReason);
      await fetchGrantProject();
      toast.success(t("grant_apply.reject_success"));
      setShowRejection(false);
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.reject_error"));
    }
  };

  const openLinkedContract = async () => {
    if (!grantSubmission) {
      return;
    }

    setShowLinkContract(true);

    const res = await client.getContractListForBusiness(
      grantSubmission.business?.id,
    );

    setContracts(res);
  };

  const handleLinkContract = async () => {
    if (!grantSubmission) {
      return;
    }

    try {
      await client.linkContractToSubmittedGrant(
        grantSubmission.id,
        contractNumber,
      );
      await fetchGrantProject();
      toast.success(t("grant_apply.link_success"));
      setShowLinkContract(false);
    } catch (e) {
      console.log(e);
      toast.error(t("grant_apply.link_error"));
    }
  };

  if (!grantSubmission || !formTemplate) {
    return <div>Loading...</div>;
  }

  const handleReplaceDoc = async () => {
    setIsSubmitting(true);
    try {
      await client.replaceRequiredDocument(
        grantSubmission.id,
        replaceDocId,
        replaceDocUploads,
      );
      toast.success("Document schimbat cu succes!");
      await fetchGrantProject();
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setShowReplaceRequiredDocument(false);
      setIsSubmitting(false);
    }
  };

  const handleDeleteAdditionalDocument = async () => {
    setIsSubmitting(true);
    try {
      await client.deleteAditionalGrantSubmissionDocument(
        grantSubmission.id,
        deleteAdditionalDocumentId,
      );
      toast.success("Document sters cu succes!");
      await fetchGrantProject();
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setShowDeleteAdditionalDocument(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Container fluid>
        <Row className="mb-4">
          <Col>
            <h3 className="mb-4">
              {grantSubmission.project.name} -{" "}
              <GrantSubmissionStatus status={grantSubmission.status} t={t} />
            </h3>
          </Col>
          <Col className="d-flex align-items-center justify-content-end">
            <CloakButton
              isVisible={
                betweenDates(
                  grantSubmission.project.evaluationStart,
                  grantSubmission.project.evaluationEnd,
                ) &&
                grantSubmission.status === "pending" &&
                (requiredAttribute(AccountAttribute.GrantsAdministrator) ||
                  requiredAttribute(AccountAttribute.GrantsEvaluator))
              }
              size="sm"
              variant="primary"
              onClick={() => setShow(true)}
            >
              {t("grant_apply.evaluate")}
            </CloakButton>
            <CloakButton
              isVisible={
                afterDate(grantSubmission.project.finalResultsDate) &&
                grantSubmission.status === "evaluated" &&
                requiredAttribute(AccountAttribute.GrantsAdministrator)
              }
              size="sm"
              variant="success"
              className="me-1"
              onClick={() => handleApproval()}
            >
              {t("grant_apply.approve")}
            </CloakButton>
            <CloakButton
              isVisible={
                afterDate(grantSubmission.project.finalResultsDate) &&
                grantSubmission.status === "evaluated" &&
                requiredAttribute(AccountAttribute.GrantsAdministrator)
              }
              size="sm"
              variant="danger"
              className="me-1"
              onClick={() => setShowRejection(true)}
            >
              {t("grant_apply.reject")}
            </CloakButton>
            <CloakButton
              isVisible={
                grantSubmission.status === "approved" &&
                !grantSubmission.contractFolder &&
                requiredAttribute(AccountAttribute.GrantsAdministrator)
              }
              size="sm"
              variant="primary"
              className="me-1"
              onClick={() => openLinkedContract()}
            >
              {t("grant_apply.link_contract")}
            </CloakButton>
            <CloakButton
              isVisible={
                grantSubmission.status === "approved" &&
                grantSubmission.contractFolder &&
                requiredAttribute(AccountAttribute.GrantsAdministrator)
              }
              size="sm"
              variant="primary"
              href={`/admin/contract-details/${grantSubmission.contractFolder?.id}`}
            >
              {t("grant_apply.view_contract")}
            </CloakButton>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body>
                <div className="row text-center">
                  <MilestoneWidget
                    title=""
                    milestones={[
                      {
                        title: t("grant_apply.grant_submission"),
                        icon: faFileSignature,
                        startDate:
                          grantSubmission.project.projectSubmissionStart,
                        endDate: grantSubmission.project.projectSubmissionEnd,
                        editable: false,
                      },
                      {
                        title: t("grant_apply.grant_evaluation"),
                        icon: faFileSignature,
                        startDate: grantSubmission.project.evaluationStart,
                        endDate: grantSubmission.project.evaluationEnd,
                        editable: false,
                      },
                      {
                        title: t("grant_apply.grant_results"),
                        icon: faEye,
                        startDate:
                          grantSubmission.project.resultsAnnouncementDate,
                        editable: false,
                      },
                      {
                        title: t("grant_apply.grant_contestation"),
                        icon: faFileSignature,
                        startDate: grantSubmission.project.contestationStart,
                        endDate: grantSubmission.project.contestationEnd,
                        editable: false,
                      },
                      {
                        title: t("grant_apply.grant_contestation_results"),
                        icon: faEye,
                        startDate:
                          grantSubmission.project.contestationResultsDate,
                        editable: false,
                      },
                      {
                        title: t("grant_apply.grant_final_results"),
                        icon: faTrophy,
                        startDate: grantSubmission.project.finalResultsDate,
                        editable: false,
                      },
                    ]}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {grantSubmission.evaluationScore && (
          <Row className="mb-4">
            <Col>
              <GrantScoreWidget grantSubmission={grantSubmission} />
            </Col>
          </Row>
        )}

        {grantSubmission.contestation && (
          <Row>
            <Col>
              <ContestationWidget grantSubmission={grantSubmission} />
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body>
                <h5 className="mb-4">{grantSubmission.project.name}</h5>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `${grantSubmission.project.description}`,
                  }}
                />
                <hr />
                <h5 className="mt-4">{t("grant_apply.applicant")}</h5>
                <p>
                  {grantSubmission.business?.denumire} - [
                  {grantSubmission.business?.cui}]
                </p>
                <Formio
                  form={formTemplate}
                  submission={JSON.parse(grantSubmission.completedForm)}
                  options={{
                    readOnly: true,
                    noAlerts: true,
                  }}
                />
                <h5 className="mt-4">{t("grant_apply.provided_documents")}</h5>

                <Table>
                  <thead>
                    <tr>
                      <th>Document</th>
                      <th>Descriere</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {grantSubmission.requestedDocuments &&
                      grantSubmission.requestedDocuments.map((doc) => (
                        <tr key={doc.id}>
                          <td>
                            <a
                              onClick={() =>
                                client.downloadSubmittedGrantDocument(
                                  grantSubmission.id,
                                  doc.id,
                                )
                              }
                            >
                              {doc.name}
                            </a>
                          </td>
                          <td>{doc.description}</td>
                          {betweenDates(
                            grantSubmission.project.projectSubmissionStart,
                            grantSubmission.project.projectSubmissionEnd,
                          ) && (
                            <td>
                              <Button
                                onClick={() => {
                                  setReplaceDocId(doc.id);
                                  setShowReplaceRequiredDocument(true);
                                }}
                              >
                                Modifica fisier
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <h5 className="mt-4">Documente aditionale</h5>
                <Table>
                  <thead>
                    <tr>
                      <th>{t("grant_apply.document_name")}</th>
                      <th>Descriere</th>
                      <th>{t("grant_apply.document_file")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {grantSubmission.additionalDocuments &&
                      grantSubmission.additionalDocuments.map((doc) => (
                        <tr key={doc.id}>
                          <td>{doc.name}</td>
                          <td>{doc.description}</td>
                          <td>
                            <a
                              onClick={() =>
                                client.downloadSubmittedGrantDocument(
                                  grantSubmission.id,
                                  doc.id,
                                )
                              }
                            >
                              {doc.name}
                            </a>
                          </td>
                          {betweenDates(
                            grantSubmission.project.projectSubmissionStart,
                            grantSubmission.project.projectSubmissionEnd,
                          ) && (
                            <td>
                              <Button
                                onClick={() => {
                                  setDeleteAdditionalDocumentId(doc.id);
                                  setShowDeleteAdditionalDocument(true);
                                }}
                              >
                                Sterge document aditional
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))}
                  </tbody>
                </Table>
                <h5 className="mt-4">
                  {t("grant_apply.additional_documents")}
                </h5>
                {betweenDates(
                  grantSubmission.project.projectSubmissionStart,
                  grantSubmission.project.projectSubmissionEnd,
                ) && (
                  <>
                    <input
                      type="file"
                      onChange={({ target: { files } }) =>
                        setRequiredDocUploads(files?.[0])
                      }
                    />
                    <Form.Control
                      type="text"
                      placeholder="Descriere"
                      onChange={(event) =>
                        setAdditionalDocumentDescription(event.target.value)
                      }
                    />
                    <Button className="mt-2" onClick={handleAdditionalUpload}>
                      Upload
                    </Button>
                  </>
                )}
                <h5 className="mt-4">Mesaje</h5>
                <ChatBox id={grantSubmission.chat.id} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.evaluation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>{t("grant_apply.criteria_description")}</th>
                <th>{t("grant_apply.score")}</th>
              </tr>
            </thead>
            <tbody>
              {grantSubmission.project.projectScoringCriterias.map(
                (criteria) => (
                  <tr key={criteria.id}>
                    <td>{criteria.description}</td>
                    <td>{criteria.score}</td>
                  </tr>
                ),
              )}
            </tbody>
          </Table>
          <Form onSubmit={handleSubmitScore(submitScore)}>
            <Form.Group className="mb-3">
              <Form.Label>{t("grant_apply.score")}</Form.Label>
              <Form.Control
                type="text"
                {...registerScore("evaluationScore", { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("grant_apply.comments")}</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...registerScore("evaluationComments", { required: true })}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              {t("grant_apply.save")}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showRejection} onHide={() => setShowRejection(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.rejection")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{t("grant_apply.rejection_reason")}</label>
          <textarea
            className="form-control"
            rows={3}
            onChange={(e) => setRejectionReason(e.target.value)}
          ></textarea>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRejection(false)}>
            {t("grant_apply.close")}
          </Button>
          <Button variant="primary" onClick={handleRejection}>
            {t("grant_apply.save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showLinkContract} onHide={() => setShowLinkContract(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.link_contract")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>{t("grant_apply.contract_number")}</label>
          <Select
            options={contracts.map((c) => ({
              label: c.name,
              value: c.id,
            }))}
            onChange={(e: any) => setContractNumber(e.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowLinkContract(false)}
          >
            {t("grant_apply.close")}
          </Button>
          <Button variant="primary" onClick={handleLinkContract}>
            {t("grant_apply.save")}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showReplaceRequiredDocument}
        size="lg"
        onHide={() => {
          setShowReplaceRequiredDocument(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reincarca document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            onChange={({ target: { files } }) =>
              setReplaceDocUploads(files?.[0])
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowReplaceRequiredDocument(false);
            }}
          >
            Renunță
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleReplaceDoc()}
            disabled={isSubmitting}
          >
            Confirmă
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteAddditionalDocument}
        size="lg"
        onHide={() => {
          setShowDeleteAdditionalDocument(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reincarca document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Esti sigur ca vrei sa stergi documentul aditional?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowReplaceRequiredDocument(false);
            }}
          >
            Renunță
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleDeleteAdditionalDocument()}
            disabled={isSubmitting}
          >
            Confirmă
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

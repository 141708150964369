import { useState } from "react";
import dayjs from "dayjs";
import { Col, Row, Form } from "@themesberg/react-bootstrap";

import { default as countries } from "../../helpers/countries.json";
import { default as judete } from "../../helpers/lista_judete.json";
import { default as localitati } from "../../helpers/localitati_simple.json";

export const PfForm = ({ userProfile, email, t }) => {
  const [userUpdates, setUserUpdates] = useState({ ...userProfile });

  const [edit, setEdit] = useState(false);

  console.log('user updates: ', userUpdates)

  return (
    <>
      {userUpdates && (
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="nume">
                <Form.Label>{t("finish_profile_last_name_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  id="nume"
                  type="text"
                  value={userUpdates && userUpdates.nume}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, nume: value })
                  }
                  placeholder={t("finish_profile_last_name_label")}
                  required
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userProfile.nume}</p>
                )} */}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("finish_profile_first_name_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  required
                  value={userUpdates && userUpdates.prenume}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, prenume: value })
                  }
                  placeholder={t("finish_profile_first_name_label")}
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userProfile && userProfile.prenume}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  required
                  type="email"
                  placeholder="name@company.com"
                  value={userUpdates.email ? userUpdates.email : email}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, email: value })
                  }
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userData.email}</p>
                )} */}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="telefon">
                <Form.Label>{t("finish_profile_phone_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  required
                  type="tel"
                  id="telefon"
                  placeholder="+40345678912"
                  value={userUpdates && userUpdates.telefon}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, telefon: value })
                  }
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userProfile.telefon}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="cnp">
                <Form.Label>
                  {userUpdates.tara === "RO" ? (
                    <label htmlFor="cnp">CNP*</label>
                  ) : (
                    <label htmlFor="cnp">ID Number</label>
                  )}
                </Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  id="cnp"
                  required
                  value={userUpdates && userUpdates.cnp}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, cnp: value })
                  }
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userProfile.cnp}</p>
                )} */}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="numar_ci">
                <Form.Label>{t("finish_profile_id_number_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  id="numar_ci"
                  required
                  value={userUpdates && userUpdates.numar_ci}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, numar_ci: value })
                  }
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userProfile.numar_ci}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="data-eliberare">
                <Form.Label>
                  {t("finish_profile_id_issued_at_label")}
                </Form.Label>
                {/* {edit ? ( */}
                <input
                  type="date"
                  className="form-control"
                  id="data_eliberare"
                  required
                  value={
                    userUpdates &&
                    dayjs(userUpdates.data_eliberare).format("YYYY-MM-DD")
                  }
                  onChange={({ target: { value } }) =>
                    setUserUpdates({
                      ...userUpdates,
                      data_eliberare: value,
                    })
                  }
                  disabled={!edit}
                  style={{ width: "100%" }}
                />
                {/* ) : (
                  <p>
                    {dayjs(userProfile.data_eliberare).format("DD.MM.YYYY")}
                  </p>
                )} */}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="data-expirare">
                <Form.Label>
                  {t("finish_profile_id_expires_at_label")}
                </Form.Label>
                {/* {edit ? ( */}
                <input
                  type="date"
                  className="form-control"
                  id="data_expirare"
                  value={
                    userUpdates &&
                    dayjs(userUpdates.data_expirare).format("YYYY-MM-DD")
                  }
                  onChange={({ target: { value } }) =>
                    setUserUpdates({
                      ...userUpdates,
                      data_expirare: value,
                    })
                  }
                  required
                  disabled={!edit}
                  style={{ width: "100%" }}
                />
                {/* ) : (
                  <p>
                    {dayjs(userProfile.data_expirare).format("YYYY-MM-DD")}
                  </p>
                )} */}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="eliberat">
                <Form.Label>
                  {t("finish_profile_id_issued_by_label")}
                </Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  id="eliberat"
                  value={userUpdates && userUpdates.eliberat}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, eliberat: value })
                  }
                  required
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userProfile.eliberat}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">Adresa</h5>
          <Row>
            <Col sm={9} className="mb-3">
              <Form.Group id="strada">
                <Form.Label>{t("finish_profile_street_label")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={userUpdates && userUpdates.strada}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, strada: value })
                  }
                  placeholder="Adresa utilizator"
                  disabled={!edit}
                />
              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group id="cod_postal">
                <Form.Label>{t("finish_profile_postal_code_label")}</Form.Label>
                <Form.Control
                  required
                  type="number"
                  id="cod_postal"
                  disabled={!edit}
                  value={userUpdates && userUpdates.cod_postal}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, cod_postal: value })
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="tara">
                <Form.Label>{t("finish_business_country_label")}</Form.Label>
                <Form.Select
                  id="tara"
                  className="form-control"
                  required
                  value={userUpdates && userUpdates.tara}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, tara: value })
                  }
                  disabled={!edit}
                >
                  {Object.keys(countries).map((countryCode) => (
                    <option key={countryCode} value={countryCode}>
                      {countries[countryCode]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <Form.Group className="mb-2" id="judet">
                <Form.Label>{t("finish_profile_county_label")}</Form.Label>
                {userUpdates.tara === 'RO' ? (
                <Form.Select
                  id="judet"
                  required
                  value={userUpdates && userUpdates.judet}
                  onChange={(event) => {
                    if (event.target.value !== "") {
                      const selectedJudet = judete.find(
                        (judet) => judet.Nume === event.target.value
                      );
                      setUserUpdates({
                        ...userUpdates,
                        judet: selectedJudet.Nume,
                      });
                    } else {
                      setUserUpdates({ ...userUpdates, judet: "" });
                    }
                  }}
                  disabled={!edit}
                >
                  <option value="">-</option>
                  {judete.map((judet) => (
                    <option
                      key={judet.Cod}
                      data-key={judet.Cod}
                      value={judet.Nume}
                    >
                      {judet.Diacritice}
                    </option>
                  ))}
                </Form.Select>
                ) : (
                  <Form.Control
                  type="text"
                  id="judet"
                  value={userUpdates && userUpdates.judet}
                  required
                  disabled={!edit}
                />
                )}
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group id="localitate">
                <Form.Label>{t("finish_profile_locality_label")}</Form.Label>
                {userUpdates.tara === 'RO' ? (
                <Form.Select
                  id="localitate"
                  required
                  value={userUpdates && userUpdates.localitate}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, localitate: value })
                  }
                  disabled={!edit}
                >
                  <option value="">-</option>
                  {localitati
                    .filter(
                      (localitate) =>
                        localitate.Cod ===
                        judete.find((judet) => judet.Nume === userUpdates.judet)
                          .Cod
                    )
                    .map((localitate) => (
                      <option
                        key={localitate.Nume + localitate.Cod}
                        value={localitate.Nume}
                      >
                        {localitate.Diacritice}
                      </option>
                    ))}
                </Form.Select>
                 ) : (
                  <Form.Control
                  type="text"
                  id="localitate"
                  value={userUpdates && userUpdates.localitate}
                  required
                  disabled={!edit}
                />
                 )}
              </Form.Group>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function ResendLinkSucces() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/login");
  };

  return (
    <Container className="account-created my-5">
      <Row className="justify-content-center">
        <Col md={6} className="text-center">
          <img
            src="/logo-timis.png"
            width="80"
            height="100"
            className="rounded mx-auto d-block pb-2"
            alt="CJT Logo"
          />
          <h2 className="fw-bold mb-5 text-center text-uppercase ">
            Consiliul Județean Timiș
          </h2>
          <h1>Email-ul a fost retrimis cu succes!</h1>
          <p>
            Vă rugam sa verificati casuta de email
          </p>
          <div className="envelope-icon my-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-mail"
            >
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
          </div>
          <Button onClick={handleClick} variant="primary">
            Către Autentificare
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ResendLinkSucces;

import { Table } from "@themesberg/react-bootstrap";
import { t } from "i18next";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { IUser, IWhiteboard } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export default function WhiteboardsList() {
  const [whiteboards, setWhiteboards] = useState<IWhiteboard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);

  const navigate = useNavigate();
  const client = useAPIClient();

  const fetchWhiteboards = async () => {
    setIsLoading(true);
    try {
      const data = await client.getAllUserWhiteboards();
      setWhiteboards(data);
    } catch (err) {
      console.log(err);
      toast.error("Failed to fetch whiteboards");
    }
    setIsLoading(false);
  };

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setIsLoading(false);
      setUser(_user);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchUser();
    fetchWhiteboards();
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <Container>
        <h1 className="text-center mt-4 mb-4">{t("whiteboard.title")}</h1>
        <Card>
          <Card.Body>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">{t("whiteboard.name")}</th>
                  <th className="border-0"></th>
                </tr>
              </thead>
              <tbody>
                {orderBy(whiteboards, ["id"], ["desc"]).map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td className="text-center">
                      {user && (
                        <Button
                          //href={`/whiteboard/session?id=${item.id}`}
                          className="btn-default font-weight-bold pr-3"
                          size="sm"
                          onClick={async () => {
                            navigate(`/whiteboard/session?id=${item.id}`);
                          }}
                        >
                          {t("whiteboard.join")}
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

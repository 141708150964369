import { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { ICalendar, IProiectCategory, ISubmission, IUser } from "../../../cjt";
import dayjs from "dayjs";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ITemplateBody } from "../../../cjt";
import FormScreen from "../../../components/Forms/FormScreen";
import ViewOnlineRequestById from "../../OnlineRequests/ViewOnlineRequestById";
import { useLocation } from 'react-router-dom';

function DepunereProiecte() {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const animatedComponents = makeAnimated()
  const [loading, setLoading] = useState(true);
  const [calendarActive, setCalendarActive] = useState<ICalendar>();
  const [form, setForm] = useState<ITemplateBody | null>();
  const [submittedForm, setSubmittedForm] = useState<ISubmission | null>();
  const [allSubmittedForm, setAllSubmittedForm] = useState<ISubmission[] | null>();
  const [proiectCategories, setProiectCategories] = useState<IProiectCategory[] | null>();
  const [proiectCategoriesOptions, setProiectCategoriesOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get('tab') || '0';
  const [user, setUser] = useState<IUser | null>(null);
  const [allowUserType, setAllowUserType] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const fetchCalendarData = async () => {
    try {
      const requests = await client.getActiveCalendar();
      setCalendarActive(requests);
      
      if (requests) {
        const form = await client.getTemplateById(requests.formTemplate.id);
        setForm(form);
        
        const activeSubmissionForm = await client.getSubmittedFormByCalendarId(requests.id);
        setSubmittedForm(activeSubmissionForm);
        
        const allSubmissionForm = await client.getAllSubmittedFormByUser();
        setAllSubmittedForm(allSubmissionForm.reverse());

        const categories = await client.getProiectCategories();
        setProiectCategoriesOptions(categories.map((category: any) => ({
          label: category.denumire,
          value: category.id
        })));
      }else{
        const allSubmissionForm = await client.getAllSubmittedFormByUser();
        setAllSubmittedForm(allSubmissionForm.reverse());
      }
      
      const _user = await client.getUserFeed();
      setUser(_user);
      
      setLoading(false);
    } catch (error: any) {
      toast.error(error.message as string);
      setLoading(false);
    }
  };
  
  const handleSelectChange = (selected: any) => {
    const updatedSelectedOptions = selected.map((option: any) => ({
      value: option.value,
      label: option.label,
    }));
    setSelectedOptions(updatedSelectedOptions);
    setSelectedCategory(updatedSelectedOptions);
  };
  
  useEffect(() => {
    fetchCalendarData();
  }, []);
  
  useEffect(() => {
    if (user && calendarActive) {
      const typesInBusinesses = user.businesses?.map(business => business.tip) as string[];
      const typesInCalendar = calendarActive.allowed_types as string[];
      const isAnyTypePresent = typesInCalendar?.some(type => typesInBusinesses?.includes(type));
      if (isAnyTypePresent) {
        setAllowUserType(true);
      }
    }
  }, [user, calendarActive]);
  

  if(loading){
    return <p>Se incarca...</p>
  }
  return (
    <>
      <Container className="mt-5 mb-6">

        <div className="card-body card">
          <Tab.Container defaultActiveKey={activeTab}>
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            key="0"
                            eventKey="0"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("grant_apply.title")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            key="1"
                            eventKey="1"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("calendar.my_submissions")}
                          </Nav.Link>
                        </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="0" className="py-4">
                  <Row>
                    <Col>
                      {calendarActive ? (
                        dayjs(calendarActive.depunere_proiecte_end).add(1, 'day').isAfter(new Date()) &&
                        dayjs(calendarActive.depunere_proiecte_start).isBefore(new Date()) &&
                        !submittedForm ? (
                          allowUserType ? (
                            <>
                              <Row className="mb-3">
                                <h5>{t("calendar.choose_category")}</h5>
                                <Col md={12} className="mb-3">
                                  <Form.Group className="d-flex align-items-center">
                                    <Select
                                      options={proiectCategoriesOptions}
                                      isMulti={true}
                                      components={animatedComponents}
                                      onChange={(selected) => {
                                        handleSelectChange(selected);
                                      }}
                                      value={selectedOptions}
                                      closeMenuOnSelect={false}
                                      className="w-100"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>

                              {selectedOptions && selectedOptions.length > 0 && (<FormScreen
                                id={calendarActive.formTemplate.id}
                                set={() => {}}
                                calendar={calendarActive}
                                proiectCategories={selectedOptions}
                                redirectURL={'/bugetare-participativa/depunere-proiecte?tab=1'}
                              />)}
                            </>
                          ) : (
                            <p>{t("calendar.inactive_form_for_user")} <a href="/bugetare-participativa/proces">{t("calendar.inactive_form_for_user2")}</a></p>
                          )
                        ) : (
                          <>
                            <p>{t("calendar.inactive_submission_period")} <a href="/bugetare-participativa/proces">{t("calendar.inactive_form_for_user2")}</a> {t("calendar.inactive_submission_period2")}</p>
                          </>
                        )
                      ) : (
                        <>
                          <p>{t("calendar.no_defined_calendar")} <a href="/bugetare-participativa/proces">{t("calendar.here")}</a> {t("calendar.no_defined_calendar2")}.</p>
                        </>
                      )}
                    </Col>
                  </Row>

                  </Tab.Pane>

                  <Tab.Pane eventKey="1" className="py-4">
                    <Table>
                      <thead className="thead-light">
                        <tr>
                          <th className="border-0">{t("calendar.form_name")}</th>
                          <th className="border-0">{t("calendar.go_to_form")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {allSubmittedForm && allSubmittedForm.length > 0 ? (
                          allSubmittedForm.map((item) => (
                            <tr key={item.id}>
                              <td
                                style={{
                                  wordBreak: "break-all",
                                  whiteSpace: "pre-wrap",
                                }}
                              >
                                {t("grant_apply.title")} {item.calendar?.denumire}
                              </td>
                              <td>
                                <div>
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    href={"/bugetare-participativa/vizualizare-detalii-proiect/" + item.id}
                                  >
                                    {t("calendar.see_submission")}
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={2} className="text-center">

                              {t("calendar.no_info_submission")}.
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
            <Row>
              {/* {allSubmittedForm.map((item) => {<p>{item.id}</p> })} */}
            </Row>
            
          </Tab.Container>
        </div>
      </Container>
    </>
  );
}

export default DepunereProiecte;

import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import handleException from "../../../helpers/exceptions";
import { IProiect, IUser, IComments } from "../../../cjt";
import dayjs from "dayjs"
import VotareProiecte from "./VotareProiecte";

function ProiectVotareDetalii() {
  const { id } = useParams();
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();

  const [loading, setLoading] = useState(true);
  const [proiectData, setProiectData] = useState<IProiect | null>(null);
  const [voted, setVoted] = useState<boolean | undefined>(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [comment, setComment] = useState<string>('');
  const [proiectComments, setProiectComments] = useState<IComments[] | null>(null);

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setUser(_user);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

    const fetchProiectById = async () => {
      try {
        const proiect = await client.getProiectById(id!);
        setProiectData(proiect);
      } catch (err) {
        const errorMessages = handleException(err, t);
        errorMessages.forEach((message: any) => {
        //   toast.error(message);
        });
      } finally {
        setLoading(false);
      }
    };

    const fetchProjectComments = async () => {
      try {
        const comments = await client.getCommentsByProiectId(id!);
        setProiectComments(comments);
      } catch (err) {
        const errorMessages = handleException(err, t);
        errorMessages.forEach((message: any) => {
        //   toast.error(message);
        });
      } finally {
        setLoading(false);
      }
    };
    
  useEffect(() => {
    fetchProiectById();
    fetchUser();
    fetchProjectComments();
  }, [voted]);

  useEffect(() => {
    //const hasUserVotedOnThisProject = proiectData?.vote.some(vote => vote.user.id === user?.id);
    if(dayjs(proiectData?.calendar.vot_proiecte_end).add(1, 'day').isAfter(new Date()) && dayjs(proiectData?.calendar.vot_proiecte_start).isBefore(new Date())){
        const hasVotedOnCalendar = proiectData?.calendar?.vote?.some(vote => vote.user.id === user?.id);
        setVoted(hasVotedOnCalendar)
    }
  }, [proiectData]);

  const handleVoteProiect = async () => {
    try {
      const requests = await client.voteProiect(id!);
      setVoted(true);
      toast.success(t("project_voting.success_vote"));
    } catch (error: any) {
      toast.error(t("project_voting.error_vote"));
    }
  }

  const handleCommentProiect = async () => {
    try {
      const requests = await client.commentProiect(id!, comment);
      toast.success(t("project_voting.success_comm"));
    } catch (error: any) {
      toast.error(error.message);
    }
  }

  const handleChangeCommentField = (e: any) => {
    setComment(e.target.value);
  };



  if (loading) {
    return <div>Loading...</div>;
  }

  if (!proiectData) {
    return <div>{t("project_voting.not_found")}</div>;
  }

  return (
    <>
      <Container>
      {dayjs(proiectData.calendar.vot_proiecte_end).add(1, 'day').isAfter(new Date()) && dayjs(proiectData.calendar.vot_proiecte_start).isBefore(new Date()) && (
      <div className="input-group mb-3">
        {voted ? (
        <button className="btn btn-info btn-wave waves-effect waves-light" type="button" disabled>{t("calendar.has_voted_button")}</button>
        ) : (
        <button className="btn btn-success btn-wave waves-effect waves-light" type="button" onClick={() => handleVoteProiect()}>{t("calendar.vote_button")}</button>)
        }

      </div>
      )}
      <div className="card border border-primary bblr-0 bbrr-0">
            <div className="card-header bg-transparent border-primary d-flex justify-content-between">
                <div>
                    <h5 className="my-0 text-primary">{t("calendar.general_info")}</h5>                            
                    <div className="star">
                        <span>{proiectData.vote.length} {proiectData.vote.length === 1 ? <>{t("calendar.vote")}</> : <>{t("calendar.votes")}</>}</span>
                    </div>
                </div>
                {dayjs(proiectData.calendar.vot_proiecte_end).add(1, 'day').isAfter(new Date()) && dayjs(proiectData.calendar.vot_proiecte_start).isBefore(new Date()) && (
                    <div className="d-flex pagina-activa" style={{color: "green", fontWeight: "900", flexDirection: "row", alignItems:"center"}}>
                        <b>{t("calendar.voting_active")}</b>
                        <span className="live-icon"></span>
                    </div>
                )}
            </div>

            <div className="card-body">
                    <div className="table-responsive">
                        <table className="table mb-0 table-md-layout-fixed">
                            <thead>
                                <tr>
                                    <th style={{width: "50%", whiteSpace:"normal"}}>{t("grant_apply.grant_name")}</th>
                                    <th style={{width: "25%",  whiteSpace:"normal"}}>{t("calendar.voting_period")}</th>
                                    <th style={{width: "25%",  whiteSpace:"normal"}}>{t("grant.category")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{width: "50%",  whiteSpace:"normal"}}><h5 className="card-title">”{proiectData.title}”</h5></td>
                                    <td style={{width: "25%",  whiteSpace:"normal"}}><b>{dayjs(proiectData.calendar.vot_proiecte_start).format("DD-MM-YYYY")} - {dayjs(proiectData.calendar.vot_proiecte_end).format("DD-MM-YYYY")}</b></td>
                                    <td style={{width: "25%", whiteSpace:"normal"}}>                                           
                                    <ul>
                                        {proiectData.category.map(el => <li key={el.id}><a href="#">{el.denumire}</a></li>)}
                                    </ul>
                                </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div>
        </div>

        <div className="card btrr-0 btlr-0 bbrr-0 bblr-0 ">
            <div className="card-body">

                <div className="d-flex align-items-center justify-content-center">
                  <img
                    className="custom-width-detail card-img-top card-img-bottom mb-3"
                    src={proiectData.imageId}
                    alt="Imagine proiect"
                  ></img>
                </div>
                <h4 className="card-title">{t("calendar.project_description")}</h4>
                <p className="card-title-desc">{t("calendar.project_description_explanation")}.</p>

                <div className="tab-content p-3 text-muted">

                    <div className="tab-pane active" id="propunere1" role="tabpanel">
                        <div className="row mt-3">
                          <div dangerouslySetInnerHTML={{ __html: proiectData.description }}></div>
                            <p className="p-0 mb-0 mt-2"><b>{t("localization")}:</b> {proiectData.localizare}</p>
                            <p className="p-0 mb-0"><b>{t("calendar.beneficiary")}:</b> {proiectData.beneficiar}</p>
                            <p className="p-0 mb-0"><b>{t("calendar.total_bugdet")}:</b> {proiectData.buget} lei</p>
                        </div>
                    </div>
                    
                </div>

            </div>
        </div>
        {/* <div className="card custom-card btrr-0 btlr-0">
            <div className="card-header">
                <h5 className="my-0 text-primary">Forum Discuții</h5>
            </div>
            <div className="card-body">
                <ul className="list-unstyled forum-discutii">
                {proiectComments && (
                    proiectComments.map((comment, index) => (
                        <li>
                            <div>
                                <span className="avatar avatar-sm bg-primary-transparent avatar-rounded forum-discutii-avatar">
                                    {index+1}
                                </span>
                                <p className="mb-2">
                                    <b>{comment.user.profile.prenume} {comment.user.profile.nume}</b> a adăugat următorul comentariu:
                                    <span className="float-md-end fs-11 text-muted ">{' '}{dayjs(comment.created).format("YYYY-MM-DD, HH:mm:ss")}</span>
                                </p>
                                <div className="text-muted mb-0">
                                    <div className="body-clarificari">
                                        <p>{JSON.parse(comment.comment).comment}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))
                )}
                </ul>
            </div>
            <div className="card-footer">
                <div className="d-flex flex-column lh-1">
                    <div>
                        <label className="form-label mb-3">Adăugați un comentariu</label>
                        <textarea className="form-control" style={{ height: '150px' }} onChange={handleChangeCommentField}></textarea>
                        <div className="mt-3 mb-3"><b>* Atenție, fiecare comentariu lăsat va fi salvat și afișat în pagină!</b></div>
                </div>
                <div className="flex-fill mt-2">
                    <div className="input-group">
                        <button className="btn btn-primary btn-wave waves-effect waves-light" type="submit" onClick={handleCommentProiect}>TRIMITE RĂSPUNSUL</button>
                    </div>
                </div>
            </div>
        </div>
        </div> */}
      </Container>
    </>
  );
}

export default ProiectVotareDetalii;

import { Form } from "@formio/react";
import {
  faEye,
  faFileSignature,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Row, Table } from "@themesberg/react-bootstrap";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Card, Container } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { IBusiness, IGrant } from "../../cjt";
import MilestoneWidget from "../../components/MilestoneWidget";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";

export default function ApplyForGrant() {
  const client = useAPIClient();
  const { id: grantProjectId, businessId } = useParams();
  const { t } = useTranslation();

  const [business, setBusiness] = useState<IBusiness | null>(null);
  const [grantProject, setGrantProject] = useState<IGrant | null>(null);
  const [requiredDocUploads, setRequiredDocUploads] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formTemplate, setFormTemplate] = useState<any>(null);
  const formRef = useRef<any>(null);

  const fetchGrantProject = async () => {
    if (!grantProjectId) {
      return;
    }

    try {
      const _grant = await client.getGrant(grantProjectId);
      const _form = await client.getTemplateById(_grant.formTemplate.id);
      _grant.projectSubmissionEnd = moment(
        _grant.projectSubmissionEnd,
        "YYYY-MM-DD",
      )
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      _grant.contestationEnd = moment(_grant.contestationEnd, "YYYY-MM-DD")
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      setGrantProject(_grant);

      // HACK remove any submit butons that exist in the components list
      const _formBody = JSON.parse(_form.body);
      const cleanedFormBody = _formBody.components.filter(
        (component: any) => component.label !== "Submit",
      );

      setFormTemplate({ components: cleanedFormBody });
    } catch (e) {
      console.log(e);
    }
  };

  const fetchBusinessDetails = async () => {
    if (!businessId) {
      return;
    }

    try {
      const user = await client.getUserFeed();

      if (!user.businesses) {
        alert("You do not have any businesses");
        return;
      }

      const business = user.businesses.find((b: any) => b.id === businessId);

      if (business) {
        setBusiness(business);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinessDetails();
    fetchGrantProject();
  }, []);

  const handleFormReady = (form: any) => {
    formRef.current = form;
  };

  const handleRequiredDocUpload = (event: any) => {
    setRequiredDocUploads([...requiredDocUploads, event.target.files[0]]);
  };

  const handleSubmit = async () => {
    if (!grantProject || !businessId) {
      return;
    }

    try {
      setIsSubmitting(true);
      const res = await formRef.current.submit();

      if (grantProject.requiredDocuments.length !== requiredDocUploads.length) {
        // alert("Please upload all required documents");
        // return;
      }

      await client.submitGrantProject(
        grantProject.id,
        businessId,
        JSON.stringify(res),
        requiredDocUploads,
      );
      setIsSubmitting(false);
      window.location.href = "/finantare/proiecte-depuse";
    } catch (err: any) {
      setIsSubmitting(false);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  if (!grantProject || !formTemplate) {
    return <div>Loading...</div>;
  }

  const milestones = [
    {
      title: t("grant_apply.grant_submission"),
      icon: faFileSignature,
      startDate: grantProject.projectSubmissionStart,
      endDate: grantProject.projectSubmissionEnd,
      editable: false,
    },
    {
      title: t("grant_apply.grant_evaluation"),
      icon: faFileSignature,
      startDate: grantProject.evaluationStart,
      endDate: grantProject.evaluationEnd,
      editable: false,
    },
    {
      title: t("grant_apply.grant_results"),
      icon: faEye,
      startDate: grantProject.resultsAnnouncementDate,
      editable: false,
    },
    {
      title: t("grant_apply.grant_contestation"),
      icon: faFileSignature,
      startDate: grantProject.contestationStart,
      endDate: grantProject.contestationEnd,
      editable: false,
    },
    {
      title: t("grant_apply.grant_contestation_results"),
      icon: faEye,
      startDate: grantProject.contestationResultsDate,
      editable: false,
    },
    {
      title: t("grant_apply.grant_final_results"),
      icon: faTrophy,
      startDate: grantProject.finalResultsDate,
      editable: false,
    },
  ];

  return (
    <Container fluid>
      <h3 className="mb-4">{t("grant_apply.title")}</h3>
      <Row>
        <Col>
          <MilestoneWidget title="Termene proiect" milestones={milestones} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">{grantProject.name}</h5>
              <p>{grantProject.theme}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: `${grantProject.description}`,
                }}
              />
              <hr />
              <h5 className="mt-4">{t("grant_apply.applicant")}</h5>
              <p>
                {business?.denumire} - [{business?.cui}]
              </p>
              <Form formReady={handleFormReady} src={formTemplate} />
              <h5 className="mt-4">{t("grant_apply.required_documents")}</h5>

              <Table>
                <thead>
                  <tr>
                    <th>{t("grant_apply.required_documents")}</th>
                    <th>{t("grant_apply.description")}</th>
                    <th>{t("grant_apply.upload_required_documents")}</th>
                  </tr>
                </thead>
                <tbody>
                  {grantProject.requiredDocuments.map((doc, index) => (
                    <tr key={doc.id}>
                      <td>
                        <a
                          onClick={() =>
                            client.downloadRequiredDocument(
                              grantProject.id,
                              doc.id,
                            )
                          }
                        >
                          {doc.name}
                        </a>
                      </td>
                      <td>{doc.description}</td>
                      <td>
                        <input
                          type="file"
                          onChange={handleRequiredDocUpload}
                          required
                        />{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                className="mt-4"
                onClick={handleSubmit}
              >
                {t("grant_apply.submit")}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table  } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPIClient } from "../../../helpers/api";
import { ISurvey, ISurveyAnswer, ISurveyQuestion, ISurveyUserAnswer } from "../../../cjt";



function SurveyResults() {
  const { id } = useParams();
  const { register, handleSubmit, getValues } = useForm();
  const navigate = useNavigate();
  const client = useAPIClient();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [survey, setSurvey] = useState<ISurvey | null>(null);
  const [numberOfSubmissions, setNumberOfSubmissions] = useState<number>(0);

  const fetchSurvey = async () => {
    try {
      const res = await client.getSurveyByid(id!);
      setSurvey(res);
    } catch (err: any) {
      toast.error(t("survey.error_cannot_load_surveys"));
    }
  };

  const countNumberOFSubmissions = (survey: ISurvey) => {
    let hasSubmitted = false;
  };

  useEffect(() => {
    fetchSurvey();
  }, []);

  // useEffect(() => {
  //   console.log(survey?.questions.length)
  //     survey?.questions.forEach((question: ISurveyQuestion) => {
  //       question.answers.forEach((answer: ISurveyAnswer) => {
  //         console.log('====usr===> ', answer.userEntries.length)
  //       });
  //     });
  // }, [survey]);


  return (
    <>
      <Container className="mt-5 mb-6">
        <Card>
          <Card.Body> 
            <h3 className="text-center">{t("survey.survey_results")}</h3>
            {survey && (
              <Form>
                {survey.questions.map((question: ISurveyQuestion) => (
                  <div key={question.id} className="mt-4">
                    <p className="mb-0"><b>{question.question}</b></p>
                    <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                        <thead className="thead-light">
                          <tr>
                            <th className="border-0" style={{width: "50%"}}>{t("survey.survey_results_ans")}</th>
                            <th className="border-0" style={{width: "50%"}}>{t("survey.survey_results_ans_number")}</th>
                          </tr>
                        </thead>
                        </Table>
                      {question.answers.map((answer: ISurveyAnswer) => (
                        <>
                    <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                        <tbody>
                            <tr>
                              <td style={{width: "50%"}}>
                                <Form.Check
                                  type="radio"
                                  id={`answer-${answer.id}`}
                                  label={answer.answer}
                                  value={answer.id}
                                  disabled={true}
                                /> 
                              </td>
                              <td style={{width: "50%"}}>
                                <span>{answer.userEntries.length} {t("survey.survey_results_ans").toLowerCase()}</span>
                              </td>
                            </tr>
                            
                        </tbody>
                    </Table>
                        </>
                      ))}
                  </div>
                ))}

              </Form>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default SurveyResults;

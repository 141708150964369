import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IGrantSubmission } from "../../cjt";
import { useAPIClient } from "../../helpers/api";

export default function ListAppliedGrants() {
  const client = useAPIClient();
  const [grants, setGrants] = useState<IGrantSubmission[]>([]);
  const { t } = useTranslation();

  const fetchGrants = async () => {
    try {
      const res = await client.getSubmittedGrants();

      setGrants(res);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchGrants();
  }, []);

  const getSubmissionStatus = (grant: IGrantSubmission) => {
    if (grant.status === "pending") {
      return <Badge bg="primary"> {t("grant_apply.submitted")}</Badge>;
    }

    if (grant.status === "evaluated") {
      return <Badge bg="primary"> {t("grant_apply.evaluated")}</Badge>;
    }

    if (grant.status === "approved") {
      return <Badge bg="success"> {t("grant_apply.approved")}</Badge>;
    }

    if (grant.status === "rejected") {
      return <Badge bg="danger"> {t("grant_apply.rejected")}</Badge>;
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">{t("grant_my_applications")}</h5>
              <Table>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("category")}</th>
                    <th>{t("business")}</th>
                    <th>{t("status")}</th>
                    <th>{t("grant.upload_date")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {grants.map((grant) => (
                    <tr key={grant.id}>
                      <td>{grant.project.name}</td>
                      <td>{grant.projectCategory.name}</td>
                      <td>{grant.business?.denumire}</td>
                      <td>{getSubmissionStatus(grant)}</td>
                      <td>{moment(grant.createdAt).format("YYYY-MM-DD")}</td>
                      <td>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          href={`/finantare/proiecte-depuse/${grant.id}`}
                        >
                          {t("view_more")}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

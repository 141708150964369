import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import {
  validateCaptchaCompleted,
  validateEmail,
  validatePassword,
  validatePasswordsMatch,
} from "../../helpers/validators";
import LoadingView from "../../layout/LoadingView";
import { Routes } from "../../routes";
import CaptchaForm from "../Captcha/CaptchaForm";

function Register() {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [weakPassword, setWeakPassoword] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const changeLanguage = () => {
    if (i18n.language === "ro") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ro");
      localStorage.setItem("language", "ro");
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const submitForm = async (data: FieldValues) => {
    setLoading(true);
    try {
      validateCaptchaCompleted(captchaResponse);
      validatePasswordsMatch(data.password, data.passwordConfirmation);
      validateEmail(data.email);
      try {
        validatePassword(data.password);
      } catch {
        setWeakPassoword(true);
        throw t("PASSWORD_WEAK");
      }
      await client.signUp(data.email, data.password, captchaResponse);
      navigate("/account-created");
    } catch (e: any) {
      // toast.error(t(e.response?.data?.statusCode ?? "999"));
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
      recaptchaRef.current?.reset();
    }
  };

  return (
    <div>
      <div className="row authentication mx-0">
        <div
          className="col-xxl-6 col-xl-7 col-lg-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="mt-3 mb-5 d-flex d-xl-none">
            <img
              className="ui centered big image w-100-mobile"
              src="/poca2.png"
              style={{ width: "80%", maxWidth: "450px", margin: "auto" }}
            ></img>
          </div>
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="login-mobile px-5">
                <div className="mt-3 d-flex flex-column align-items-center align-center">
                  <img
                    src="/logo-timis.png"
                    width="58"
                    height="76"
                    className="rounded d-block pb-2"
                    alt="CJT Logo"
                    style={{ marginRight: "1em" }}
                  />
                  <h3 className="fw-bold mb-0 text-uppercase text-center">
                    {t("institution")}
                  </h3>
                </div>
                <p className="h5 fw-semibold text-center text-muted">
                  {t("register_page_title")}
                </p>
                <div className="mb-3 mt-md-4 form-style-new">
                  <div
                    className="alert alert-danger"
                    style={{ display: weakPassword ? "block" : "none" }}
                  >
                    <p>{t("password_contains")}</p>
                    <ul>
                      <li>{t("min_char")}</li>
                      <li>{t("min_lowercase")}</li>
                      <li>{t("min_uppercase")}</li>
                      <li>{t("min_special")}</li>
                    </ul>
                  </div>
                  <div className="mt-3">
                    <Form
                      onSubmit={handleSubmit(submitForm)}
                      className="row gy-3"
                    >
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          {t("email_label")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={t("email_placeholder")}
                          {...register("email", { required: true })}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>{t("password_label")}</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("password_placeholder")}
                          {...register("password", { required: true })}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicConfirmPassword">
                        <Form.Label>
                          {t("register_page_password_confirmation_label")}
                        </Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("password_placeholder")}
                          {...register("passwordConfirmation", {
                            required: true,
                          })}
                          required
                        />
                      </Form.Group>
                      <Form.Group controlId="formBasicCheckbox"></Form.Group>
                      <CaptchaForm
                        onCaptchaSolved={(response: string | null) => {
                          return setCaptchaResponse(response);
                        }}
                        captchaRef={recaptchaRef}
                      />
                      <div className="d-grid">
                        {loading ? (
                          <LoadingView />
                        ) : (
                          <Button
                            variant="primary"
                            type="submit"
                            className="mt-3"
                          >
                            {t("register_page_register_button")}
                          </Button>
                        )}
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        {t("register_page_already_account")}{" "}
                        <a href="/login" className="text-primary fw-bold">
                          {t("register_page_login_button")}
                        </a>
                      </p>
                    </div>
                    <div>
                      <Button variant="link" onClick={() => changeLanguage()}>
                        {i18n.language === "ro" ? "🇷🇴 RO" : "🇬🇧 EN"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-block text-muted fw-bold mb-3 d-block d-xl-none"
            style={{ width: "90%", textAlign: "center" }}
          >
            <p
              className="text-center text-muted mt-3 mb-3"
              style={{ fontSize: "0.7em" }}
            >
              {t("poca.text1")}
              <br></br>
              <a
                target="_blank"
                className="text-primary fw-bold text-decoration-none"
                href="http://www.poca.ro"
              >
                www.poca.ro
              </a>
              <br></br>
              {t("poca.text2")}{" "}
              <a
                target="_blank"
                className="text-primary fw-bold text-decoration-none"
                href="https://www.fonduri-ue.ro"
              >
                www.fonduri-ue.ro
              </a>
              .{t("poca.text3")}
              <br></br>
              <br></br>
              <a href={t("footer.terms.link")} className="mx-2">
                {t("footer.terms")}
              </a>
              <a href={t("footer.privacy.link")}>{t("footer.privacy")}</a>
              <br></br>
              {t("credits")}
              <a
                className="text-primary fw-bold text-decoration-none"
                href="https://ici.ro"
              >
                {t("ici")}
              </a>
              .
            </p>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-5 col-lg-5 d-xl-block d-none px-0">
          <div
            className="authentication-cover"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              backgroundImage: "url(piata-unirii.jpg)",
            }}
          >
            <div
              className="d-flex py-3 mb-3"
              style={{
                justifyContent: "center",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <img
                className="ui centered big image w-100-mobile"
                src="/poca2.png"
                style={{ width: "40%", maxWidth: "450px" }}
              ></img>
            </div>
            <div
              className="d-block text-muted fw-bold"
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#d8dee9",
                zIndex: "100",
              }}
            >
              <p
                className="text-center text-muted mt-3 mb-3"
                style={{ fontSize: "0.7em", width: "90%", margin: "auto" }}
              >
                {t("poca.text1")}
                <br></br>
                <a
                  target="_blank"
                  className="text-primary fw-bold text-decoration-none"
                  href="http://www.poca.ro"
                >
                  www.poca.ro
                </a>
                <br></br>
                {t("poca.text2")}{" "}
                <a
                  target="_blank"
                  className="text-primary fw-bold text-decoration-none"
                  href="https://www.fonduri-ue.ro"
                >
                  www.fonduri-ue.ro
                </a>
                .{t("poca.text3")}
                <br></br>
                <br></br>
                <a href={t("footer.terms.link")} className="mx-2">
                  {t("footer.terms")}
                </a>
                <a href={t("footer.privacy.link")}>{t("footer.privacy")}</a>
                <br></br>
                {t("credits")}
                <a
                  className="text-primary fw-bold text-decoration-none"
                  href="https://ici.ro"
                >
                  {t("ici")}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;

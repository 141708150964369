import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { useAPIClient } from "./helpers/api";



i18n.use(initReactI18next).init({
  partialBundledLanguages: true,
  resources: {},
  lng: "ro",
  interpolation: {
    escapeValue: false,
  },
  ns:['translation'],
  fallbackLng: false
});

export default i18n;

import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { MessageBox } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { IUser } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";

export default function ChatBox({
  id,
  inputDisabled = false,
  canUserDelete = true,
}: {
  id: string;
  inputDisabled?: boolean;
  canUserDelete?: boolean;
}) {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [chatContent, setChatContent] = useState<any[]>();
  const [message, setMessage] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const currentRoute = useLocation();

  const fetchChatContent = async () => {
    try {
      const res = await client.getChatContentById(id);

      setChatContent(res.chatContent);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleTextareaChange = (value: string) => {
    setMessage(value);
  };

  const handleSend = async () => {
    try {
      if (!message || message == "") {
        toast.error(t("chatbot.error_empty"));
        return;
      }
      const user = await client.getUserFeed();
      await client.sendChatBoxMessage(
        id,
        message,
        user.profile.nume + " " + user.profile.prenume,
        currentRoute.pathname,
      );
      setMessage("");
      fetchChatContent();
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleDeleteReplyAsAdmin = async (index: number) => {
    const res = await client
      .deleteChatReply(id, index)
      .finally(() => {
        fetchChatContent();
      })
      .catch((err: any) => {
        toast.error(t("chatbot.error_delete"));
        console.log(err);
      });
    setChatContent(res.chatContent);
  };

  const fetchUser = async () => {
    try {
      const user = await client.getUserFeed();
      setCurrentUser(user);
    } catch (err: any) {
      handleException(err, t).map((message) => {
        toast.error(message);
      });
    }
  };
  useEffect(() => {
    fetchUser();
    fetchChatContent();
  }, []);
  useEffect(() => {}, [chatContent]);

  return (
    <Card className="border mb-2 mt-2">
      <>
        <div className="mx-3">
          {chatContent?.map((reply, index) => {
            return (
              <MessageBox
                key={index}
                styles={{ whiteSpace: "pre-wrap" }}
                className={
                  reply.user.includes(
                    currentUser?.profile.nume +
                      " " +
                      currentUser?.profile.prenume,
                  )
                    ? "mt-2 ps-8"
                    : "mt-2"
                }
                id="1"
                position={
                  reply.user.includes(
                    currentUser?.profile.nume +
                      " " +
                      currentUser?.profile.prenume,
                  )
                    ? "right"
                    : "left"
                }
                type={"text"}
                title={reply.user}
                text={reply.text}
                focus={false}
                date={reply.timestamp}
                notch={true}
                removeButton={canUserDelete}
                replyButton={false}
                titleColor="red"
                forwarded={false}
                status="sent"
                retracted={false}
                onRemoveMessageClick={() => {
                  if (!canUserDelete) return;
                  handleDeleteReplyAsAdmin(index);
                }}
              />
            );
          })}

          {!inputDisabled && (
            <div className="input-group mt-4 mb-4">
              <input
                type="text"
                value={message}
                placeholder="Type here..."
                onChange={({ target: { value } }) =>
                  handleTextareaChange(value)
                }
                className="form-control"
              />
              <Button
                variant="primary"
                className="btn bnt-outline-secondary"
                type="button"
                onClick={handleSend}
              >
                <FontAwesomeIcon icon={faPaperPlane} className="me-2" />
              </Button>
            </div>
          )}
        </div>
      </>
    </Card>
  );
}

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Overlay,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import {
  IBusiness,
  IProjectFolder,
  IProjectPhase,
  IProjectTask,
} from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import i18n from "../../i18n";
import { ViewSwitcher } from "./Gantt package files/components/view-switcher";

// Init

type BusinessOptions = {
  label: string;
  value: string;
};
const GanttGraph = ({
  phase,
  fetchParent,
  project,
}: {
  phase: IProjectPhase;
  fetchParent?: any;
  project: IProjectFolder;
}) => {
  phase.startDate = new Date(phase.startDate);
  phase.endDate = new Date(phase.endDate);
  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isChecked, setIsChecked] = useState(true);

  /// -- modal states
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [openAddTask, setOpenAddTask] = useState(false);
  const [openEditTask, setOpenEditTask] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [doubleClickedTask, setDoubleClickedTask] = useState<Task | null>(null);
  const [doubleClickedITask, setDoubleClickedITask] =
    useState<IProjectTask | null>(null);
  const [taskName, setTaskName] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const animatedComponents = makeAnimated();
  const [taskParticipants, setTaskParticipants] = useState<BusinessOptions[]>();
  const [progress, setProgress] = useState<number>(-1);
  /// ----
  const [phaseTasks, setPhaseTasks] = useState(phase.tasks);
  const [taskBudget, setTaskBudget] = useState("");
  const [trigger, setTrigger] = useState(false);

  /// file uploads
  const [taskFile, setTaskFile] = useState<any>();
  const [fileDescription, setFileDescription] = useState("");

  /// attribute permission
  const { requiredAttribute, userId } = useAuthContext();
  const isProjectAdmin = requiredAttribute(
    AccountAttribute.ProjectsAdministrator,
  );

  const uploadRef = useRef<HTMLInputElement | null>(null);

  const toggleTrigger = () => {
    setTrigger(!trigger);
  };

  const allOptions = useMemo(() => {
    return project.participants.map((business) => {
      return { label: business.denumire, value: business.id };
    });
  }, [project, phase]);

  const getITaskforTaskObject = (task: Task): IProjectTask | undefined => {
    const foundTask: IProjectTask | undefined = phaseTasks.find(
      (phaseTask: IProjectTask) => phaseTask.id === task.id,
    );

    return foundTask;
  };

  const client = useAPIClient();
  const { t } = useTranslation();
  let columnWidth = 65;
  if (view === ViewMode.Year) {
    columnWidth = 350;
  } else if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    setPhaseTasks(phase.tasks);

    const phaseProject: Task = {
      id: phase.id,
      type: "project",
      name: phase.name,
      start: new Date(
        new Date(phase.startDate).getFullYear(),
        new Date(phase.startDate).getMonth(),
        new Date(phase.startDate).getDate(),
      ),
      end: new Date(
        new Date(phase.endDate).getFullYear(),
        new Date(phase.endDate).getMonth(),
        new Date(phase.endDate).getDate(),
      ),
      progress: 0,
      hideChildren: false,
      displayOrder: 1,
    };
    var phaseProgress = 0;
    var loadedTasks: Task[] = phaseTasks.map((phaseTask, i) => {
      phaseProgress += phaseTask.progress;
      return {
        id: phaseTask.id,
        type: "task",
        name: phaseTask.name,
        start: new Date(phaseTask.startDate),
        end: new Date(phaseTask.endDate),
        progress: phaseTask.progress,
        project: phase.id,
        dependencies: phaseTask.dependencies,
        displayOrder: i,
      };
    });
    phaseProject.progress = phaseProgress / phaseTasks.length;
    setTasks([phaseProject, ...loadedTasks]);
  }, [phaseTasks, trigger, phase]);

  const handlePhaseTaskUpload = async (task: IProjectTask | null) => {
    if (!project || !task) return;
    try {
      const sendFile = taskFile;
      //console.log(files, sendFile);
      const res = client.uploadTaskFile(task.id, sendFile, fileDescription);
      res.then(() => {
        setTaskFile(null);
        setFileDescription("");

        fetchParent();
        if (uploadRef && uploadRef.current) uploadRef.current.value = "" as any;
      });
      const newTask = await res;
      task.fileData = newTask.fileData;
      setDoubleClickedITask(task);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
    }
  };

  const handleTaskUpdate = async (task: Task) => {
    try {
      await client.editProjectTask(
        task.id,
        task.name,
        task.dependencies,
        task.progress,
        task.start,
        task.end,
      );
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
      return false;
    }
  };
  const handleTaskChange = (task: Task) => {
    if (task.start.getTime() < phase.startDate.getTime()) {
      //task.start = phase.startDate;

      toast.error(t("PROJECT_TASK_OUT_OF_PHASE_RANGE"));
      return false;
    }
    if (task.end.getTime() > phase.endDate.getTime()) {
      //task.end = phase.endDate;
      toast.error(t("PROJECT_TASK_OUT_OF_PHASE_RANGE"));
      return false;
    }
    try {
      handleTaskUpdate(task);
    } catch (err) {
      return false;
    }
    const newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    setTasks(newTasks);
  };

  const handleTaskDelete = async (task: Task) => {
    try {
      const res = await client.deleteProjectTask(task.id);
      fetchParent();
      setTasks(tasks.filter((t) => t.id !== task.id));
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
      return false;
    }

    return true;
  };

  const handleProgressChange = async (task: Task) => {
    try {
      await client
        .editProjectTask(
          task.id,
          undefined, // name
          undefined, // dependencies
          task.progress, // progress
          undefined, // startdate
          undefined, // enddate
          undefined, // participants
        )
        .then(() => {
          fetchParent();
        });
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
      //console.log(err);
      fetchParent();
      return;
    }
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    //console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task: Task) => {
    if (task.type == "project") return;
    //alert("On Double Click event Id:" + task.id);
    setDoubleClickedTask(task);
    const phaseTask = getITaskforTaskObject(task);
    if (!phaseTask) {
      //console.log("Phase task does not exist!");
      toast.error(t("PHASE_TASK_NOT_FOUND"));
      return;
    }
    setTaskName(phaseTask.name);
    setProgress(phaseTask.progress);
    if (phaseTask.participants)
      setSelectedOptions(
        phaseTask.participants?.map((business) => {
          return { label: business.denumire, value: business.id };
        }),
      );
    setDoubleClickedITask(phaseTask);
    setTaskBudget(phaseTask.budget?.toString());
    setOpenEditTask(true);
  };

  const handleClick = (task: Task) => {
    //console.log("On Click event Id:" + task.id);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    //console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    //console.log("On expander click Id:" + task.id);
  };

  const handleAddTask = async () => {
    try {
      let _errors: { [key: string]: any } = {};

      if (!startDate) {
        _errors.startDate = t("PROJECT_ADD_TASK_ERROR_INPUTSDATE");
      } else if (new Date(startDate) < phase.startDate) {
        _errors.startDate = t("PROJECT_ADD_TASK_ERROR_STARTDATE_BEFORE"); //"Data de inceput introdusa nu poate fi precedenta datei de inceput a fazei";
      }

      const dateObj = new Date(endDate);

      if (!endDate) {
        _errors.endDate = t("PROJECT_ADD_TASK_ERROR_INPUTEDATE");
      } else if (
        new Date(
          dateObj.getTime() + dateObj.getTimezoneOffset() * 60 * 1000,
        ).getTime() >= phase.endDate.getTime()
      ) {
        _errors.endDate = t("PROJECT_ADD_TASK_ERROR_ENDDATE_AFTER");
      }
      if (startDate >= endDate)
        _errors.endDate = t("PROJECT_ADD_TASK_ERROR_SAFTEREDATE"); //"Data de finalizare trebuie sa fie minim cu o zi dupa data inceperii!";
      if (!taskName) {
        _errors.taskName = t("PROJECT_ADD_TASK_ERROR_INPUTNAME");
      }

      setErrors(_errors);

      if (_errors.startDate || _errors.endDate || _errors.taskName) return;

      try {
        const res = await client.addProjectTask(
          taskName,
          startDate,
          endDate,
          phase.id,
        );

        toast.success(t("PROJECT_ADD_TASK_SUCCESS"));

        setstartDate("");
        setendDate("");
        setTaskName("");
        setOpenAddTask(false);

        phaseTasks.push(res);
      } catch (e) {
        // console.log(e);
        const errorMessages = handleException(e, t);
        errorMessages.forEach((message) => {
          return toast.error(message);
        });
      } finally {
        setIsSubmitting(false);
        fetchParent();
        toggleTrigger();
      }
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
    }
  };

  const handleSelectChange = (selected: any) => {
    const updatedSelectedOptions = selected.map((option: any) => ({
      value: option.value,
      label: option.label,
    }));
    setSelectedOptions(updatedSelectedOptions);
  };

  const handleDeletePhase = async (phase: IProjectPhase) => {
    try {
      await client.deleteProjectPhase(phase.id).then(() => {
        fetchParent();
      });
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
    }
  };

  if (!tasks || tasks.length <= 0) return;
  return (
    <>
      <div>
        <span className="d-flex" style={{ justifyContent: "space-between" }}>
          <h3 className="mt-2">{t("PROJECT_PHASE_GRAPH")}</h3>
          {isProjectAdmin && (
            <Button
              variant="success"
              className="mb-3"
              style={{ float: "right" }}
              onClick={() => {
                setOpenAddTask(true);
              }}
            >
              {t("PROJECT_ADD_TASK")}
            </Button>
          )}
        </span>

        {isProjectAdmin ? (
          <Gantt
            tasks={tasks}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onClick={handleClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
            locale={i18n.language}
          />
        ) : (
          <Gantt
            tasks={tasks}
            viewMode={view}
            onProgressChange={handleProgressChange}
            onExpanderClick={handleExpanderClick}
            onDoubleClick={handleDblClick}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
            locale={i18n.language}
          />
        )}

        <ViewSwitcher
          onViewModeChange={(viewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
        />

        {isProjectAdmin && (
          <Button
            className="mt-3 mx-3"
            variant="danger"
            style={{ float: "right" }}
            type="submit"
            onClick={() => {
              handleDeletePhase(phase);
            }}
            disabled={isSubmitting}
          >
            {t("PROJECT_DELETE_PHASE")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        )}
      </div>

      {/* MODALS */}
      <Modal
        show={openAddTask}
        size="lg"
        onHide={() => {
          setOpenAddTask(false);
          setTaskName("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_ADD_TASK")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>{t("PROJECT_ADD_TASK_START_DATE")}</Form.Label>
                <Form.Control
                  type="date"
                  id="startDate"
                  name="startDate"
                  className={errors.startDate && "error-control"}
                  onClick={(e) => {
                    const targetElement = e.target as HTMLInputElement;
                    targetElement.showPicker();
                  }}
                  value={startDate && startDate}
                  onChange={({ target: { value } }) => setstartDate(value)}
                />
              </Form.Group>
              {errors.startDate && (
                <span className="error-message">{errors.startDate}</span>
              )}
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>
                  {t("PROJECT_ADD_TASK_ENDDATE")}
                  <OverlayTrigger
                    overlay={
                      <Tooltip>
                        {
                          t(
                            "PROJECT_ADD_TASK_ENDDATE_TOOLTIP",
                          ) /* {Data de finalizare trebuie sa fie minim cu o zi dupa
                        data inceperii!} */
                        }
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="date"
                  id="endDate"
                  name="endDate"
                  className={errors.endDate && "error-control"}
                  onClick={(e) => {
                    const targetElement = e.target as HTMLInputElement;
                    targetElement.showPicker();
                  }}
                  value={endDate && endDate}
                  onChange={({ target: { value } }) => setendDate(value)}
                />
              </Form.Group>
              {errors.endDate && (
                <span className="error-message">{errors.endDate}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2" id="judet">
                <Form.Label>{t("PROJECT_ADD_TASK_NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  id="phaseName"
                  required
                  className={errors.phaseName && "error-control"}
                  value={taskName && taskName}
                  onChange={({ target: { value } }) => {
                    setTaskName(value);
                  }}
                />
                {errors.phaseName && (
                  <span className="error-message">{errors.phaseName}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddTask}
            disabled={isSubmitting}
          >
            {t("add")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal editare task */}
      <Modal
        show={openEditTask}
        size="lg"
        onHide={() => {
          setOpenEditTask(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{doubleClickedTask?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group>
                <Form.Label>{t("PROJECT_EDIT_TASK_NAME")}</Form.Label>
                <Form.Control
                  type="text"
                  id="name"
                  name="taskName"
                  className={errors.taskName && "error-control"}
                  value={taskName && taskName}
                  onChange={({ target: { value } }) => setTaskName(value)}
                  disabled={!isProjectAdmin}
                />
              </Form.Group>
              {errors.taskName && (
                <span className="error-message">{errors.startDate}</span>
              )}
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{t("PROJECT_ADD_TASK_INCHARGE")}</Form.Label>
                <Select
                  options={allOptions}
                  isMulti={true}
                  components={animatedComponents}
                  onChange={(selected: any) => {
                    handleSelectChange(selected);
                  }}
                  styles={{}}
                  value={selectedOptions}
                  closeMenuOnSelect={false}
                  isDisabled={!isProjectAdmin}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2" id="judet">
                <Form.Label>{t("PROJECT_EDIT_TASK_PROGRESS")}</Form.Label>
                <OverlayTrigger overlay={<Tooltip>{progress}</Tooltip>}>
                  <Form.Range
                    id="progress"
                    min={0}
                    max={100}
                    required
                    className={errors.progress && "form-range error-control"}
                    value={progress && progress}
                    onChange={({ target: { value } }) => {
                      console.log(value);
                      setProgress(parseInt(value));
                    }}
                    disabled={
                      !isProjectAdmin &&
                      !doubleClickedITask?.participants?.some(
                        (business) =>
                          (business.user as unknown as string) === userId,
                      )
                    }
                  />
                </OverlayTrigger>

                {errors.phaseName && (
                  <span className="error-message">{errors.phaseName}</span>
                )}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group className="mb-2" id="budget">
                <Form.Label>{t("PROJECT_EDIT_TASK_BUDGET")}</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Ex: 123.45"
                  id="budget"
                  required
                  max={0xffffffffff}
                  className={errors.taskBudget && "error-control"}
                  value={taskBudget && taskBudget}
                  onChange={({ target: { value } }) => setTaskBudget(value)}
                  disabled={!isProjectAdmin}
                />
                {errors.taskBudget && (
                  <span className="error-message">{errors.taskBudget}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row className="p-2">
            <span className="alert alert-info">
              Pentru actualizarea progresului, folositi slider-ul din diagrama
              Gantt
            </span>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {isProjectAdmin && (
            <Button
              variant="danger"
              onClick={() => {
                if (!doubleClickedTask) return;
                handleTaskDelete(doubleClickedTask);
                setOpenEditTask(false);
                setTaskName("");
                // setSelectedTemplate("");
              }}
            >
              {t("PROJECT_DELETE_TASK")}
            </Button>
          )}
          {(isProjectAdmin ||
            doubleClickedITask?.participants?.some(
              (business) => (business.user as unknown as string) === userId,
            )) && (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setOpenEditTask(false);
                  setTaskName("");
                  // setSelectedTemplate("");
                }}
              >
                Renunță
              </Button>
              <Button
                variant="primary"
                onClick={async () => {
                  if (!doubleClickedTask) return;
                  try {
                    const res = await client
                      .editProjectTask(
                        doubleClickedTask.id,
                        taskName == "" ? undefined : taskName,
                        undefined, // dependencies
                        progress == -1 ? undefined : progress, // progress
                        undefined, // startdate
                        undefined, // enddate
                        selectedOptions.map((option) => option.value),
                        parseInt(taskBudget) ?? 0,
                      )
                      .then(() => {
                        setOpenEditTask(false);
                        if (taskName != "") {
                          doubleClickedTask.name = taskName;

                          setTaskName("");
                        }
                        setProgress(-1);
                        setSelectedOptions([]);
                        setTasks(
                          tasks.map((t) =>
                            t.id === doubleClickedTask.id
                              ? doubleClickedTask
                              : t,
                          ),
                        );
                        fetchParent();
                      });
                  } catch (err) {
                    const errorMessages = handleException(err, t);
                    errorMessages.forEach((message) => {
                      return toast.error(message);
                    });
                  }
                }}
                disabled={isSubmitting}
              >
                Salveaza
                {isSubmitting && (
                  <Spinner animation="border" variant="secondary" size="sm" />
                )}
              </Button>
            </>
          )}
        </Modal.Footer>
        <Modal.Header>
          <Modal.Title>{t("PROJECT_TASK_DOCUMENTS")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-3">
          <Row>
            {(isProjectAdmin ||
              doubleClickedITask?.participants?.some(
                (business) => (business.user as unknown as string) === userId,
              )) && (
              <>
                <Form.Control
                  ref={uploadRef}
                  type="file"
                  id="taskFile"
                  required
                  disabled={
                    !isProjectAdmin &&
                    !doubleClickedITask?.participants?.some(
                      (business) =>
                        (business.user as unknown as string) === userId,
                    )
                  }
                  onChange={async ({ target: { files } }: any) => {
                    if (!doubleClickedTask) return;
                    const sendFile = files[0];
                    // console.log(files, sendFile);
                    // const res = client.uploadTaskFile(
                    //   doubleClickedTask.id,
                    //   sendFile
                    // );
                    // res.then(() => {
                    //   fetchParent();
                    //   toggleTrigger();
                    // });
                    // const task = await res;
                    // setDoubleClickedITask(task);
                    console.log(sendFile);
                    setTaskFile(sendFile);
                    //console.log(res);
                  }}
                />

                <Form.Label className="mt-2">
                  {t("PROJECT_TASK_DOCUMENT_DESCRIPTION")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  id="fileDescription"
                  name="fileDescription"
                  value={fileDescription}
                  className={errors.fileDescription && "error-control"}
                  onChange={({ target: { value } }) =>
                    setFileDescription(value)
                  }
                />
                {errors.description && (
                  <span className="error-message">{errors.description}</span>
                )}

                <Button
                  className="mt-3"
                  variant="success"
                  type="submit"
                  onClick={() => handlePhaseTaskUpload(doubleClickedITask)}
                  disabled={isSubmitting}
                >
                  {"Salveaza document"}
                  {isSubmitting && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                </Button>
              </>
            )}
            <hr className="mt-2"></hr>
            {doubleClickedITask?.fileData?.length == 0 &&
              t("PROJECT_NO_DOCUMENT_UPLOADED")}
            {doubleClickedITask?.fileData?.map((file, index) => {
              const fileObject: {
                id: string;
                description: string;
                name: string;
              } = JSON.parse(file);
              return (
                <Row>
                  <OverlayTrigger
                    overlay={<Tooltip>{fileObject.description}</Tooltip>}
                  >
                    <a
                      onClick={() => {
                        return client.getFile(fileObject.id);
                      }}
                    >
                      {index + 1}
                      {". "} {fileObject.name ?? fileObject.id}
                    </a>
                  </OverlayTrigger>
                </Row>
              );
            })}
          </Row>
        </Modal.Body>
        {/* <Modal.Footer>Adauga</Modal.Footer> */}
      </Modal>
    </>
  );
};

export default GanttGraph;

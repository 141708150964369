import {
  faDownload,
  faLocationArrow,
  faUpload,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { ISmartForm } from "../../cjt";
import DataForm from "../../components/Forms/DataForm";
import { SmartForm } from "../../components/Forms/SmartForm";
import { RequestStatus } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { formatDate } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";
import ChatBox from "../Chatbox/Chatbox";

export default function ViewOnlineRequestById() {
  const { id } = useParams();
  const client = useAPIClient();
  const navigate = useNavigate();
  const [onlineRequestData, setOnlineRequestData] = useState<ISmartForm | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [status, setStatus] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");
  const { t } = useTranslation();
  const [hasLocation, setHasLocation] = useState<boolean>(false);

  const [showManualRegistrationNumber, setShowManualRegistrationNumber] =
    useState<boolean>(false);

  const [manualRegistrationNumber, setManualRegistrationNumber] =
    useState<string>("");

  const [manualRegistrationDate, setManualRegistrationDate] =
    useState<string>("");

  const [showUploadResponse, setShowUploadResponse] = useState<boolean>(false);

  const [responseFile, setResponseFile] = useState<any>(null);

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [location, setLocation] = useState<{
    latitude: string;
    longitude: string;
  }>({ latitude: "0", longitude: "0" });
  const fetchOnlineRequestById = async () => {
    try {
      const res = await client.getOnlineRequestsById(id!);
      setOnlineRequestData(res);

      if (res.submission.userLocation != null) {
        const location = JSON.parse(JSON.parse(res.submission.userLocation));
        console.log(location);
        setHasLocation(true);
        setLocation(location);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      // toast.error("Nu s-a gasit cererea!");
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  // const changeOnlineRequestStatus = async () => {
  //   if (onlineRequestData === null) {
  //     toast.error("Cererea nu a fost gasita!");
  //     return;
  //   }

  //   if (onlineRequestData.submission.reg_nr === null) {
  //     toast.error("Cererea nu are numar de inregistrare!");
  //     return;
  //   }

  //   if (reason === "") {
  //     toast.error("Va rugam sa introduceti un motiv!");
  //     return;
  //   }

  //   try {
  //     await client.updateOnlineRequestStatus(
  //       onlineRequestData.submission.id,
  //       status,
  //       reason
  //     );
  //     fetchOnlineRequestById();
  //     setReason("");
  //     setShowReason(false);
  //     toast.success("Statusul a fost actualizat!");
  //   } catch (err) {
  //     // console.log(err);
  //     //toast.error("Statusul nu a putut fi actualizat!");
  //     const errorMessages = handleException(err, t);
  //     errorMessages.map((message) => {
  //       toast.error(message);
  //     });
  //   }
  // };

  const handleClose = () => {
    setShowConfirmDeletion(false);
    setShowReason(false);
  };

  const handleDeleteOnlineRequest = async () => {
    if (onlineRequestData === null) {
      toast.error("Cererea nu a fost gasita!");
      return;
    }

    try {
      await client.deleteOnlineRequest(onlineRequestData.submission.id);
      toast.success("Cererea a fost stearsa!");
      navigate("/admin/online-requests");
    } catch (err) {
      // console.log(err);
      // toast.error("Cererea nu a putut fi stearsa!");
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleAddRegNumber = async () => {
    if (onlineRequestData === null) {
      toast.error("Cererea nu a fost gasita!");
      return;
    }

    try {
      await client.addOnlineRequestRegNumber(onlineRequestData.submission.id);
      fetchOnlineRequestById();
      toast.success("Numarul de inregistrare a fost adaugat!");
    } catch (err) {
      // console.log(err);
      // toast.error("Numarul de inregistrare nu a putut fi adaugat!");
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
      setShowManualRegistrationNumber(true);
    }
  };

  const handleAddRegNumberManual = async () => {
    if (onlineRequestData === null) {
      toast.error("Cererea nu a fost gasita!");
      return;
    }

    try {
      await client.addOnlineRequestRegNumberManual(
        onlineRequestData.submission.id,
        manualRegistrationNumber,
        manualRegistrationDate,
      );
      await client.updateOnlineRequestStatus(
        onlineRequestData.submission.id,
        RequestStatus.ManuallyRegistered,
        "Numar de inregistrare adaugat manual",
      );
      fetchOnlineRequestById();
      toast.success("Numarul de inregistrare a fost adaugat!");
      setShowManualRegistrationNumber(false);
    } catch (err) {
      // console.log(err);
      // toast.error("Numarul de inregistrare nu a putut fi adaugat!");
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
      setShowManualRegistrationNumber(true);
    }
  };

  const handleResponseFileUpload = async (onlineRequestData: ISmartForm) => {
    if (onlineRequestData === null) {
      toast.error("Cererea nu a fost gasita!");
      return;
    }

    if (responseFile === null) {
      toast.error("Va rugam sa selectati un fisier!");
      return;
    }

    setIsSubmitting(true);

    try {
      await client.uploadSubmissionResponse(
        onlineRequestData.submission.id,
        responseFile[0],
      );
      fetchOnlineRequestById();
      toast.success("Raspunsul a fost incarcat!");
      setShowUploadResponse(false);
      setIsSubmitting(false);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    fetchOnlineRequestById();
  }, []);

  const buildGoogleMapsURL = () => {
    console.log(location.latitude, location.longitude);
    const baseUrl = "https://www.google.com/maps";
    const coordinates = `${location.latitude},${location.longitude}`;
    const queryParam = `q=${encodeURIComponent(coordinates)}`;

    return `${baseUrl}?${queryParam}`;
  };

  return (
    <Container className="mt-5">
      <>
        <Card className="p-4 mb-4">
          {onlineRequestData && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isLoading ? (
                  <LoadingView />
                ) : (
                  <>
                    <h2 className="pt-1 mb-4 text-break">
                      {onlineRequestData.submission.templateName}
                    </h2>

                    <Form>
                      <Form.Group id="formStatus">
                        <label htmlFor="regNumber">Stare</label>
                        {/* <Form.Select
                          id="formStatus"
                          className="form-control "
                          required
                          value={
                            onlineRequestData.submission &&
                            onlineRequestData.submission.status
                          }
                          onChange={({ currentTarget: { value } }) => {
                            setStatus(value);
                            setShowReason(true);
                          }}
                        >
                          <option key="TRANSMIS" value="TRANSMIS">
                            TRANSMIS
                          </option>
                          <option key="PROCESARE" value="PROCESARE">
                            PROCESARE
                          </option>
                          <option key="RESPINS" value="RESPINS">
                            RESPINS
                          </option>
                          <option key="SOLUTIONAT" value="SOLUTIONAT">
                            SOLUTIONAT
                          </option>
                        </Form.Select> */}
                        <p>{onlineRequestData.submission.status}</p>
                      </Form.Group>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "end",
                          marginTop: "20px",
                        }}
                      >
                        <div>
                          <label htmlFor="regNumber">Număr înregistrare</label>
                          {!onlineRequestData.submission.reg_nr ? (
                            <>
                              <br></br>
                              <Button
                                variant="primary"
                                style={{ marginLeft: "5px" }}
                                onClick={() => handleAddRegNumber()}
                              >
                                Adaugă
                              </Button>
                            </>
                          ) : (
                            <>
                              <p>
                                {onlineRequestData.submission.reg_nr}
                                {" / "}
                                {dayjs(
                                  onlineRequestData.submission.registrationDate,
                                ).format("DD.MM.YYYY")}
                              </p>
                              {onlineRequestData.submission.status ===
                                RequestStatus.ManuallyRegistered && (
                                <>
                                  <Button
                                    size="sm"
                                    className="mx-2 mb-2 d-flex"
                                    style={{
                                      float: "right",
                                      justifyContent: "right",
                                    }}
                                    onClick={() => setShowUploadResponse(true)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faUpload}
                                      className="mx-1"
                                    ></FontAwesomeIcon>
                                    Incarca raspuns
                                  </Button>
                                </>
                              )}
                              {(onlineRequestData.submission.eloHasFile ||
                                onlineRequestData.submission
                                  .responseFileData) && (
                                <>
                                  <span>
                                    <Button
                                      size="sm"
                                      className="mx-2 d-flex"
                                      style={{
                                        float: "right",
                                        justifyContent: "right",
                                      }}
                                      onClick={async () => {
                                        try {
                                          const res =
                                            client.getSubmissionResponse(
                                              onlineRequestData.submission.id,
                                            );
                                        } catch (err) {
                                          console.log(err);
                                        }
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faDownload}
                                        className="mx-1"
                                      ></FontAwesomeIcon>
                                      Descarca raspuns
                                    </Button>
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </div>

              <div>
                {hasLocation &&
                  location &&
                  (parseInt(location.latitude) != 0 ||
                    parseInt(location.longitude) != 0) && (
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => {
                        const url = buildGoogleMapsURL();
                        window.open(url, "_blank");
                      }}
                    >
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </Button>
                  )}
                {onlineRequestData.submission.user && (
                  <>
                    <p className="pt-3">{t("profile_data")}</p>

                    <DataForm
                      nume={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.nume
                      }
                      prenume={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.prenume
                      }
                      cnp={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.cnp
                      }
                    />
                  </>
                )}
              </div>

              {onlineRequestData === null ? (
                <></>
              ) : (
                <>
                  <hr className="mb-4"></hr>

                  <SmartForm
                    form={JSON.parse(onlineRequestData.template)}
                    submission={JSON.parse(onlineRequestData.body)}
                    options={{
                      readOnly: true,
                      viewAsHtml: true,
                      // renderMode: "html",
                    }}
                  />
                  <hr className="mt-4"></hr>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {onlineRequestData.submission.comment && (
                      <p className="mb-4">
                        Detalii: {onlineRequestData.submission.comment}
                      </p>
                    )}
                    <div>
                      <Button
                        variant="danger"
                        className="mt-3"
                        style={{ float: "right" }}
                        onClick={() => setShowConfirmDeletion(true)}
                      >
                        Șterge cererea
                      </Button>
                    </div>
                  </div>

                  {onlineRequestData.submission.chat && (
                    <>
                      <hr className="mt-4"></hr>
                      <ChatBox id={onlineRequestData.submission.chat.id} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Card>
      </>
      {/* <Modal show={showReason} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Comentariu"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Adăugați motivul trecerii în starea "{status}"</p>
          <input
            type="text"
            className="form-control"
            id="reason"
            value={reason && reason}
            onChange={({ currentTarget: { value } }) => setReason(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuleaza
          </Button>
          <Button variant="primary" onClick={changeOnlineRequestStatus}>
            Adaugă
          </Button>
        </Modal.Footer>
      </Modal> */}

      <Modal show={showConfirmDeletion} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{"Ștergere!"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Sigur doriți ștergerea cererii ?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Anuleaza
          </Button>
          <Button variant="primary" onClick={handleDeleteOnlineRequest}>
            Sterge
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showManualRegistrationNumber}
        onHide={() => setShowManualRegistrationNumber(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{"Adaugă număr înregistrare"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Text>
            <FontAwesomeIcon
              icon={faWarning}
              color="red"
              className="mx-1"
            ></FontAwesomeIcon>
            Numarul de inregistrare nu a putut fi preluat automat din cauza unei
            erori. Acesta va fi introdus manual in campurile de mai jos.
          </Form.Text>
          <Form.Label>Introduceți numărul de înregistrare</Form.Label>
          <input
            type="text"
            className="form-control"
            id="regNumber"
            value={manualRegistrationNumber}
            onChange={({ currentTarget: { value } }) => {
              setManualRegistrationNumber(value);
            }}
          />

          <Form.Label>Introduceți data înregistrării</Form.Label>
          <input
            type="date"
            className="form-control"
            id="registrationDate"
            value={manualRegistrationDate}
            onChange={({ currentTarget: { value } }) => {
              setManualRegistrationDate(value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowManualRegistrationNumber(false)}
          >
            Anuleaza
          </Button>
          <Button variant="primary" onClick={handleAddRegNumberManual}>
            Adaugă
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUploadResponse}
        size="lg"
        onHide={() => {
          setShowUploadResponse(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adauga un raspuns cererii</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Control
                type="file"
                id="projectFile"
                required
                onChange={async ({ target: { files } }: any) =>
                  setResponseFile(files)
                }
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            onClick={() => handleResponseFileUpload(onlineRequestData!)}
            disabled={isSubmitting}
          >
            {t("PROJECT_SAVE_DOCUMENT")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

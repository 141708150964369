import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  Modal,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { IHearing, IUser } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { formatDate } from "../../helpers/utilities";
import { UserSearchOptions } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";

const userOptions = Object.keys(UserSearchOptions).flatMap((key) =>
  key == "EMAIL" || key == "NUME"
    ? {
        value: key,
        label: UserSearchOptions[key as keyof typeof UserSearchOptions],
      }
    : [],
);

function AllScheduledHearings() {
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [searchOption, setSearchOption] = useState<UserSearchOptions>(
    UserSearchOptions.EMAIL,
  );
  const client = useAPIClient();
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [hearings, setHearings] = useState<IHearing[] | null>(null);
  const [user, setUser] = useState<IUser | null>(null);
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { state } = useLocation();
  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    desiredPerson: "",
    hearingReason: "",
  });

  const getUserLabel = (user: IUser, option: UserSearchOptions) => {
    if (option == UserSearchOptions.EMAIL) return user.email;
    if (option == UserSearchOptions.NUME)
      return (
        user.profile.nume +
        " " +
        user.profile.prenume +
        " (" +
        user.profile.cnp +
        ")"
      );
    if (option == UserSearchOptions.CNP) return user.profile.cnp;
  };

  const searchForUsers = async (searchQuery: string) => {
    if (searchQuery.length < 3) {
      return;
    }
    try {
      const res = await client.getUsers(searchOption, searchQuery);
      const filteredRes = res.flatMap((resUser: IUser) =>
        resUser.profile !== null && resUser.id !== user?.id
          ? { label: getUserLabel(resUser, searchOption), value: resUser.id }
          : [],
      );
      setSelectOptions(filteredRes);
    } catch (err: any) {
      //setError(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const addUser = async (selected: any) => {
    try {
      setSelectedOptions(selected);
    } catch (err: any) {
      //setError(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setUser(_user);
      setFormData({
        ...formData,
        desiredPerson: _user.profile?.nume + " " + _user.profile?.prenume,
      });
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fecthHearings = async () => {
    try {
      setHearings(await client.getHearings());
    } catch (e) {
      // if (e instanceof AxiosError) {
      // }
      // setIsSubmitting(false);
      // console.log(e);
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleSubmit = async () => {
    if (user === null) {
      return;
    }

    if (!user.auditor) {
      toast.error("Utilizatorul nu este auditor!");
      return;
    }

    try {
      setIsSubmitting(true);
      await client.createHearing(
        user.auditor.id,
        formData.hearingReason,
        selectedOptions.map((selectedUser: any) => selectedUser.value),
        formData.startDate,
        formData.endDate,
        "pf",
      );
      setOpen(false);
      setIsSubmitting(false);
      fecthHearings();
      toast.success("Audiența a fost creată cu succes");
    } catch (e) {
      // if (e instanceof AxiosError) {
      //   if (e.response?.data.message == "hearing_overlap")
      //     toast.error("Intervalul se suprapune cu altă audiență");
      //   else {
      //     toast.error("Mesaj eroare" + e.response?.data.message);
      //   }
      // } else {
      //   toast.error("Eroare necuonscută");
      // }
      setIsSubmitting(false);
      //console.log(e);
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const getInviteesNames = (hearing: IHearing) => {
    if (hearing.context === "pf") {
      if (hearing.invitees.length > 1) {
        return "Mai mulți participanți";
      } else {
        if (hearing.invitees[0].profile === null) {
          return hearing.invitees[0].email;
        }
        return (
          hearing.invitees[0].profile.nume +
          " " +
          hearing.invitees[0].profile.prenume
        );
      }
    } else if (hearing.context === "pj") {
      return hearing.invitedBusiness?.denumire ?? "N/A";
    }
  };

  const getFullInviteeNames = (hearing: IHearing) => {
    return hearing.invitees
      .map(
        (invitee: IUser) =>
          invitee.profile?.nume + " " + invitee.profile?.prenume,
      )
      .join(", ");
  };

  const getContext = (hearing: IHearing) => {
    if (hearing.context == "pj") return "Persoana Juridica";
    else if (hearing.context == "pf") return "Persoana fizica";
    else return "N/A";
  };

  useEffect(() => {
    fetchUser();
    fecthHearings();
    if (state) {
      setOpen(true);
      console.log(state);
      setSelectedOptions([
        {
          label: getUserLabel(state.benefficiary, searchOption),
          value: state.benefficiary.id,
        },
      ]);
    }
  }, []);

  return (
    <Container className="mt-5">
      {user?.auditor && (
        <Button className="mb-3" onClick={() => setOpen(true)}>
          Crează o audiență
        </Button>
      )}

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Auditor</th>
            <th>Context</th>
            <th>Solicitant</th>
            <th>Motiv</th>
            <th>Data de începere</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {hearings?.map((hearing) => (
            <tr key={hearing.id}>
              <td>{hearing.auditor.realName}</td>
              <td>{getContext(hearing)}</td>
              <td>
                <OverlayTrigger
                  overlay={
                    <Tooltip className="m-0">
                      {getFullInviteeNames(hearing)}
                    </Tooltip>
                  }
                >
                  <p>{getInviteesNames(hearing)}</p>
                </OverlayTrigger>
              </td>
              <td>{hearing.hearingReason}</td>

              <td>
                {moment
                  (hearing.startDate, true)
                  .format(`HH:mm, DD/MM/YYYY `) +
                  "GMT+" +
                  moment.tz("Europe/Bucharest").utcOffset() / 60}
              </td>
              <td>
                <Button
                  className="btn btn-sm"
                  variant="primary"
                  href={"/admin/hearing?id=" + hearing.id}
                >
                  Vezi programarea &gt;
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header>
          <Modal.Title>Crează audiență</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Auditor</Form.Label>
            <Form.Control
              type="text"
              id="desiredPerson"
              value={formData.desiredPerson}
              onChange={({ target: { value } }) =>
                setFormData({ ...formData, desiredPerson: value })
              }
              disabled
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Motiv</Form.Label>
            <Form.Control
              type="text"
              id="hearingReason"
              value={formData.hearingReason}
              onChange={({ target: { value } }) =>
                setFormData({ ...formData, hearingReason: value })
              }
            />
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label>Adaugă Participanți</Form.Label>
            <Col sm={2}>
              <DropdownButton
                drop="down"
                title={searchOption ? searchOption : userOptions[0].label}
                variant="primary"
                onSelect={(value) => {
                  setSelectOptions([]);
                  setSearchOption(
                    UserSearchOptions[value as keyof typeof UserSearchOptions],
                  );
                }}
              >
                {userOptions.flatMap((option) => (
                  <Dropdown.Item key={option.value} eventKey={option.value}>
                    {option.label}
                  </Dropdown.Item>
                ))}
              </DropdownButton>
            </Col>

            <Col sm={10}>
              <Select
                options={selectOptions}
                value={selectedOptions}
                components={makeAnimated()}
                filterOption={() => true}
                closeMenuOnSelect={false}
                onChange={(selected: any) => {
                  addUser(selected);
                }}
                onInputChange={(input: string) => {
                  searchForUsers(input);
                }}
                isMulti={true}
                styles={{}}
              />
            </Col>
          </Form.Group>

          <Form.Group className="mb-3">
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Datele introduse mai jos sunt conform fusului orar al
                  Romaniei(GMT+2/3)
                </Tooltip>
              }
            >
              <Form.Label>Setați data de început pentru audiență</Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="datetime-local"
              id="datetime"
              name="startDate"
              value={formData.startDate}
              onChange={({ target: { value } }) =>
                setFormData({ ...formData, startDate: value })
              }
            />
          </Form.Group>
          <Form.Group>
            <OverlayTrigger
              overlay={
                <Tooltip>
                  Datele introduse mai jos sunt conform fusului orar al
                  Romaniei(GMT+2/3)
                </Tooltip>
              }
            >
              <Form.Label>Setați data de sfârșit pentru audiență</Form.Label>
            </OverlayTrigger>
            <Form.Control
              type="datetime-local"
              id="datetime"
              name="endDate"
              value={formData.endDate}
              onChange={({ target: { value } }) =>
                setFormData({ ...formData, endDate: value })
              }
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            Close
          </Button>
          {!isSubmitting ? (
            <Button variant="primary" onClick={() => handleSubmit()}>
              Save changes
            </Button>
          ) : (
            <LoadingView />
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AllScheduledHearings;

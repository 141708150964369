import React, { useState } from "react";
import { Form, Button, Modal, ModalProps } from "react-bootstrap";
import { useAPIClient } from "../../../helpers/api";
import { toast } from "react-hot-toast";
import handleException from "../../../helpers/exceptions";
import { useTranslation } from "react-i18next";

interface IModalAddSurveyProps extends ModalProps {}

function ModalAddSurvey({
  showModalAddSurvey,
  setShowModalAddSurvey,
  setShowModalAddQuestion,
  fetchSurveys,
  handleClose,
}: IModalAddSurveyProps) {
  const [surveyName, setSurveyName] = useState("");
  const client = useAPIClient();
  const { t } = useTranslation();

  const addSurvey = async () => {
    if (surveyName === "") {
      toast.error("Introduceți titlul sondajului");
      return;
    }

    try {
      await client.addSurvey(surveyName);
      setShowModalAddSurvey(false);
      setShowModalAddQuestion(false);
      setSurveyName("");
      fetchSurveys();
    } catch (err) {
      //   toast.error(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  return (
    <Modal show={showModalAddSurvey} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("survey.new_survey")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{t("survey.survey_name")}</Form.Label>
        <Form.Control
          type="text"
          value={surveyName}
          onChange={(e) => setSurveyName(e.target.value)}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {t("grant_apply.close")}
        </Button>
        <Button variant="primary" onClick={addSurvey}>
          {t("calendar.add")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddSurvey;

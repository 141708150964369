let api_url = "https://api.e-timis.ro";
///https://cjtimis2-api.ici.ro
let ws_url = "wss://api.e-timis.ro";
export let tinymce_key = process.env.REACT_APP_TINYMCE_APIKEY;
export let captcha_site_key = "6LcjqxsoAAAAAHgva-D9VscSJ7HKh79sYMo_a8dh"; // cine se mai atinge de asta 100 de lei!!!

// Only change here if you want to use your own keys
if (process.env.REACT_APP_NODE_ENV !== "production") {
  api_url = "http://localhost:3090";
  ws_url = "ws://localhost:3090";
  captcha_site_key = "6LcjqxsoAAAAAHgva-D9VscSJ7HKh79sYMo_a8dh";
}

export const BASE_URL = api_url;
export const WS_URL = ws_url;
/* Validations */

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])(.+)$/;
export const MIN_PASSWORD = 8;
export const MAX_PASSWORD = 32;

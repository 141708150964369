import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import _ from "lodash";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IPayment } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export default function ListUserPayments() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [payments, setPayments] = useState<IPayment[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPayments = async () => {
    try {
      setIsLoading(true);
      const res = await client.getPaymentsList();

      setPayments(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5  text-center">{t("payments.list")}</h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                    {payments && payments.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h5 className="mb-0">{t("payments_history_none")}</h5>
                      </div>
                    ) : (
                      <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                          {isLoading ? (
                            <LoadingView />
                          ) : (
                            <Table
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="border-0">#</th>
                                  <th className="border-0">{t("sum")}</th>
                                  <th className="border-0">{t("date")}</th>
                                  <th className="border-0">{t("tax")}</th>
                                  <th className="border-0">{t("payer")}</th>
                                  <th className="border-0">{t("status")}</th>
                                  <th className="border-0">{t("rrn")}</th>
                                  <th className="border-0">{t("message")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderBy(payments, ["createdAt"], ["desc"]).map(
                                  (payment, i) => (
                                    <tr key={i}>
                                      <td>
                                        <Card.Link
                                          href="#"
                                          className="text-primary fw-bold"
                                        >
                                          {i + 1}
                                        </Card.Link>
                                      </td>
                                      <td>{payment.suma} RON</td>
                                      <td>
                                        {dayjs(payment.createdAt).format(
                                          "DD-MM-YYYY",
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: "pre-wrap" }}>
                                        {payment.denumireTaxa}
                                      </td>
                                      <td>
                                        {payment.user.profile.cnp ===
                                        payment.context ? (
                                          payment.user.profile.nume +
                                          " " +
                                          payment.user.profile.prenume
                                        ) : (
                                          <span>
                                            {
                                              _.find(
                                                payment.user.businesses,
                                                (el) =>
                                                  el.cui === payment.context,
                                              )?.denumire
                                            }
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {payment.status === "Approved" ? (
                                          <FontAwesomeIcon
                                            color="green"
                                            size="lg"
                                            icon={faCircleCheck}
                                          />
                                        ) : (
                                          <FontAwesomeIcon
                                            color="red"
                                            size="lg"
                                            icon={faCircleXmark}
                                          />
                                        )}
                                      </td>
                                      <td>{payment.rrn}</td>
                                      <td>{payment.mesaj}</td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </Table>
                          )}
                        </Card.Body>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import React from "react";

type Props = {
  children: React.ReactNode;
  actions: {
    handleHello: () => void;
    handleSendChatMessage: (message: string) => void;
  };
};

const MessageParser = ({ children, actions }: Props) => {
  const parse = (message: string) => {
    const lowerCaseMessage = message.toLowerCase();

    if (
      lowerCaseMessage.includes("hello") ||
      lowerCaseMessage.includes("hi") ||
      lowerCaseMessage.includes("salut") ||
      lowerCaseMessage.includes("buna") ||
      lowerCaseMessage.includes("bună") ||
      lowerCaseMessage.includes("salutare") ||
      lowerCaseMessage.includes("salutări") ||
      lowerCaseMessage.includes("salutari") ||
      lowerCaseMessage.includes("buna ziua") ||
      lowerCaseMessage.includes("bună ziua") ||
      lowerCaseMessage.includes("buna dimineata") ||
      lowerCaseMessage.includes("bună dimineața") ||
      lowerCaseMessage.includes("buna seara") ||
      lowerCaseMessage.includes("bună seara")
    ) {
      actions.handleHello();
    } else {
      actions.handleSendChatMessage(lowerCaseMessage);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;

import {
  faCheckCircle,
  faCircleQuestion,
  faClock,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Container, Table } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IHearingRequest } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { formatDate } from "../../helpers/utilities";

function AllHearingRequests() {
  const client = useAPIClient();
  const [items, setItems] = useState<IHearingRequest[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const getData = async () => {
      try {
        const requests = await client.getHearingRequests();
        setItems(requests);
      } catch (e) {
        // toast.error("Eroare la aducerea datelor");
        const errorMessages = handleException(e, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
      }
    };

    getData();
  }, []);

  const getIconForStatus = (status: string) => {
    if (status == "Pending") {
      /// need to implement enum on frontend :(( later
      return (
        <FontAwesomeIcon color="lightblue" size="xl" icon={faCircleQuestion} />
      );
    } else if (status == "Rejected") {
      return (
        <FontAwesomeIcon
          color="red"
          size="xl"
          icon={faXmarkCircle}
        ></FontAwesomeIcon>
      );
    } else if (status == "Scheduled") {
      return (
        <FontAwesomeIcon
          color="lightgreen"
          size="xl"
          icon={faClock}
        ></FontAwesomeIcon>
      );
    } else if (status == "Completed") {
      return (
        <FontAwesomeIcon
          color="green"
          size="xl"
          icon={faCheckCircle}
        ></FontAwesomeIcon>
      );
    }
  };

  const getOverLayForHearing = (hearingRequest: IHearingRequest) => {
    if (hearingRequest.status == "Pending") {
      /// need to implement enum on frontend :(( later
      return "In procesare";
    } else if (hearingRequest.status == "Rejected") {
      return (
        "Cererea a fost respinsa cu motivul: " + hearingRequest.resolveReason
      );
    } else if (hearingRequest.status == "Scheduled") {
      return "Audienta a fost programata. " + hearingRequest.resolveReason;
    } else if (hearingRequest.status == "Completed") {
      return "Audienta aferenta cererii a fost acceptata si s-a incheiat. ";
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="fw-bold mb-5 text-uppercase text-center">
        Cereri de audiență
      </h2>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0">#</th>
                <th className="border-0">Solicitant</th>
                <th className="border-0">Data solicitarii</th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {!(items.length === 0) ? (
                items.map((item, index) => {
                  if (item.status != "Pending")
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {item.user.profile.nume +
                            " " +
                            item.user.profile.prenume}
                        </td>
                        <td>{formatDate(item.createdAt)}</td>
                        <td>
                          <OverlayTrigger
                            overlay={
                              <Tooltip className="m-0">
                                {getOverLayForHearing(item)}
                              </Tooltip>
                            }
                          >
                            <span className="mx-3">
                              {getIconForStatus(item.status)}
                            </span>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    );
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {item.user.profile.nume +
                          " " +
                          item.user.profile.prenume}
                      </td>
                      <td>{formatDate(item.createdAt)}</td>
                      <td>
                        <Button
                          href={`/admin/hearing-request?id=${item.id}`}
                          size="sm"
                        >
                          Detalii
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className="text-center">
                    Nu există cereri de audiență
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default AllHearingRequests;

import {
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Card, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusinessRequest, IProfileRequest } from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";

export default function ListBusinessRequests() {
  const [businessRequests, setBusinessRequests] = useState<IBusinessRequest[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const client = useAPIClient();
  const { t } = useTranslation();

  const { userId } = useAuthContext();

  const fetchBusinessRequests = async () => {
    if (userId) {
      try {
        // const res = await client.getProfileRequestsByUserId(userId); // ruta esta ca admin
        const res = await client.getOwnBusinessRequests(); // daca e admin ii da toate cererile
        setBusinessRequests(res);
        setIsLoading(false);
      } catch (err: any) {
        // if (err.message === "Network Error") {
        //   setIsLoading(false);
        //   toast.error("Eroare de retea");
        // }
        const errorMessages = handleException(err, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
      }
    }
  };

  useEffect(() => {
    fetchBusinessRequests();
  }, []);

  if (isLoading) {
    return <Spinner animation="border" variant="primary" />;
  }

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body className="pb-0">
        {businessRequests && businessRequests.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center mb-3">
            <h5 className="mb-0">{t("profile_unsolved_requests")}</h5>
          </div>
        ) : (
          <Form>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>

                  <th className="border-0">
                    {t("account_list_pf_request_sent_info")}
                  </th>
                  <th className="border-0 text-center">
                    {t("account_list_pf_request_status")}
                  </th>
                  <th className="border-0 text-center">{"Document"}</th>
                </tr>
              </thead>
              <tbody>
                {businessRequests.map((businessRequest, i) => (
                  <tr key={i}>
                    <td>
                      <Card.Link href="#" className="text-primary fw-bold">
                        {i + 1}
                      </Card.Link>
                    </td>

                    <td>
                      <div>
                        <b>{businessRequest.denumire}</b>
                        <br />
                        <b>{t("change_profile.identification")}</b>
                        <br />
                        {businessRequest.cui}, <br />
                        {businessRequest.tip} <br />
                        <b>{t("change_profile.address")}</b>
                        <br />
                        {businessRequest.strada}, {businessRequest.cod_postal},{" "}
                        {businessRequest.tara}, {businessRequest.judet},{" "}
                        {businessRequest.localitate},{" "}
                        {businessRequest.cod_postal}
                      </div>
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {businessRequest.status === "Approved" ? (
                        <FontAwesomeIcon
                          color="green"
                          size="xl"
                          icon={faCircleCheck}
                        />
                      ) : businessRequest.status === "Rejected" ? (
                        <OverlayTrigger
                          overlay={
                            <Tooltip>
                              {" "}
                              {t("change_profile.rejection_reason")} {" "}
                              {businessRequest.rejectReason}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            color="red"
                            size="xl"
                            icon={faCircleXmark}
                          />
                        </OverlayTrigger>
                      ) : (
                        <FontAwesomeIcon
                          color="lightblue"
                          size="xl"
                          icon={faCircleQuestion}
                        />
                      )}
                    </td>
                    <td
                      style={{ textAlign: "center", verticalAlign: "middle" }}
                    >
                      {businessRequest?.documentId ? (
                        <>
                          <pre>
                            <a
                              onClick={() =>
                                client.getBusinessRequestDocument(
                                  businessRequest.id,
                                )
                              }
                              target="_blank"
                            >
                              {t("download_file")}{" "}
                              <FontAwesomeIcon
                                icon={faExternalLinkAlt}
                                className="ms-1"
                              />
                            </a>
                          </pre>
                        </>
                      ) : (
                        <span
                          style={{
                            textAlign: "center",
                            verticalAlign: "middle",
                          }}
                        >
                          {t("change_profile.attached_doc")}
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Form>
        )}
      </Card.Body>
    </Card>
  );
}

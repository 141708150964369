import React, { useState } from "react";
import { Form, Button, Modal, ModalProps } from "react-bootstrap";
import { useAPIClient } from "../../../helpers/api";
import { toast } from "react-hot-toast";
import handleException from "../../../helpers/exceptions";
import { useTranslation } from "react-i18next";

interface IModalAddCategoryProps extends ModalProps {}

function ModalAddCategory({
  showModalAddCategory,
  setShowModalAddCategory,
  setShowModalAddQuestion,
  fetchCategories,
  handleClose,
}: IModalAddCategoryProps) {
  const [categoryName, setCategoryName] = useState("");
  const client = useAPIClient();
  const { t } = useTranslation();

  const addCategory = async () => {
    if (categoryName === "") {
      toast.error("Introduceți categoria");
      return;
    }

    try {
      await client.addCategory(categoryName);
      setShowModalAddCategory(false);
      setShowModalAddQuestion(false);
      setCategoryName("");
      fetchCategories();
    } catch (err) {
      //   toast.error(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
      console.log(err);
    }
  };

  return (
    <Modal show={showModalAddCategory} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{"Categorie noua"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Label>{"Denumire categorie"}</Form.Label>
        <Form.Control
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
          // required
          // placeholder={"Denumire categorie"}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Închide
        </Button>
        <Button variant="primary" onClick={addCategory}>
          Adaugă
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddCategory;

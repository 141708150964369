import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import CategoryList from "./CategoryList";
import ModalAddCategory from "./Modals/ModalAddCategory";
import ModalAddQuestion from "./Modals/ModalAddQuestion";
import { useAPIClient } from "../../helpers/api";
import { ICategory, IQuestion } from "../../cjt";
import ModalConfirmDeletion from "./Modals/ModalConfirmDeletion";
import ModalEditQuestion from "./Modals/ModalEditQuestion";
import handleException from "../../helpers/exceptions";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

function ManageChatBot() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [categories, setCategories] = useState<ICategory[] | null>(null);
  const [showModalAddCategory, setShowModalAddCategory] = useState(false);
  const [showModalAddQuestion, setShowModalAddQuestion] = useState(false);

  const [categoryToDelete, setCategoryToDelete] = useState<ICategory>();
  const [questionToDelete, setQuestionToDelete] = useState<IQuestion>();
  const [questionToEdit, setQuestionToEdit] = useState<IQuestion>();
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const handleClose = () => {
    setShowModalAddCategory(false);
    setShowModalAddQuestion(false);
    setShowConfirmDeletion(false);
    setShowEdit(false);
    setCategoryToDelete(undefined);
    setQuestionToDelete(undefined);
    setQuestionToEdit(undefined);
  };

  const handleDeleteQuestion = async () => {
    if (questionToDelete) {
      try {
        const res = await client.deleteQuestion(questionToDelete.id);
        await fetchCategories();
        setQuestionToDelete(undefined);
      } catch (error) {
        const errorMessages = handleException(error, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
      }
    }
    setShowConfirmDeletion(false);
  };

  const handleDeleteCategory = async () => {
    if (categoryToDelete) {
      try {
        await client.deleteCategory(categoryToDelete.id);
        await fetchCategories();
        setCategoryToDelete(undefined);
      } catch (error) {
        // console.log(error);
        const errorMessages = handleException(error, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
      }
    }
    setShowConfirmDeletion(false);
  };

  const handleDelete = async () => {
    if (categoryToDelete) {
      await handleDeleteCategory();
    }
    if (questionToDelete) {
      await handleDeleteQuestion();
    }
  };

  const fetchCategories = async () => {
    try {
      const res = await client.getCategories();
      setCategories(res);
    } catch (err) {
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);

  const showModal = (type: string) => () => {
    if (type === "addCategory") {
      setShowModalAddCategory(true);
      setShowModalAddQuestion(false);
    }
    if (type === "addQuestion") {
      setShowModalAddQuestion(true);
      setShowModalAddCategory(false);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-12 mt-5 mb-5">
            <h2 className="fw-bold mb-5 text-uppercase text-center">
              Administrare ChatBot
            </h2>
            <Button
              variant="secondary"
              style={{ marginRight: "0.5em" }}
              onClick={showModal("addCategory")}
            >
              Adaugă Categorie
            </Button>
            <Button variant="secondary" onClick={showModal("addQuestion")}>
              Adaugă Intrebare
            </Button>
          </div>
          {categories && categories.length !== 0 ? (
            <CategoryList
              categories={categories}
              setShowEdit={setShowEdit}
              setQuestionToEdit={setQuestionToEdit}
              setShowConfirmDeletion={setShowConfirmDeletion}
              setCategoryToDelete={setCategoryToDelete}
              setQuestionToDelete={setQuestionToDelete}
            />
          ) : (
            <div>Nu sunt categorii introduse!</div>
          )}
        </div>
      </div>
      <ModalAddCategory
        showModalAddCategory={showModalAddCategory}
        setShowModalAddCategory={setShowModalAddCategory}
        setShowModalAddQuestion={setShowModalAddQuestion}
        fetchCategories={fetchCategories}
        handleClose={handleClose}
      />
      <ModalAddQuestion
        showModalAddQuestion={showModalAddQuestion}
        setShowModalAddQuestion={setShowModalAddQuestion}
        setShowModalAddCategory={setShowModalAddCategory}
        categories={categories}
        fetchCategories={fetchCategories}
        handleClose={handleClose}
      />
      <ModalConfirmDeletion
        showConfirmDeletion={showConfirmDeletion}
        categoryToDelete={categoryToDelete}
        questionToDelete={questionToDelete}
        handleDelete={handleDelete}
        handleClose={handleClose}
      />
      <ModalEditQuestion
        question={questionToEdit}
        showModalEditQuestion={showEdit}
        setShowModalEditQuestion={setShowEdit}
        fetchCategories={fetchCategories}
        handleClose={handleClose}
      />
    </>
  );
}

export default ManageChatBot;

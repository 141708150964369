import { Table } from "@themesberg/react-bootstrap";
import { t } from "i18next";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { Button, Card, Container, Form, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { IUser, IWhiteboard } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export default function WhiteboardsList() {
  const [whiteboards, setWhiteboards] = useState<IWhiteboard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [showDeleteWhiteboard, setShowDeleteWhiteboard] = useState(false);
  const [text, setText] = useState("");
  const [whiteboardToModify, setWhiteboardToModify] = useState("");
  const [showRenameWhiteboard, setShowRenameWhiteboard] = useState(false);
  const [newWhiteboardName, setNewWhiteboardName] = useState("");
  const navigate = useNavigate();
  const client = useAPIClient();

  const fetchWhiteboards = async () => {
    setIsLoading(true);
    try {
      const data = await client.getWhiteboards();
      setWhiteboards(data);
    } catch (err) {
      console.log(err);
      toast.error("Failed to fetch whiteboards");
    }
    setIsLoading(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setIsLoading(false);
      setUser(_user);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleCreated = async () => {
    try {
      await client.createWhiteboard(text);
      setShow(false);
      fetchWhiteboards();
      setText("");
      toast.success("Whiteboard created");
    } catch (err) {
      console.log(err);
      toast.error("Failed to create whiteboard");
    }
  };

  useEffect(() => {
    fetchUser();
    fetchWhiteboards();
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <Container>
        <h1 className="text-center mt-4 mb-4">{t("whiteboard.title")}</h1>
        <Card>
          <Card.Body>
            <Button
              className="mb-4"
              variant="primary"
              onClick={() => setShow(true)}
            >
              {t("whiteboard.add")}
            </Button>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">#</th>
                  <th className="border-0">{t("whiteboard.name")}</th>
                  <th className="border-0"></th>
                  <th className="border-0"></th>
                  <th className="border-0"></th>
                </tr>
              </thead>
              <tbody>
                {orderBy(whiteboards, ["id"], ["desc"]).map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item.name}</td>
                    <td className="text-center">
                      {user && (
                        <Button
                          //href={`/whiteboard/session?id=${item.id}`}
                          className="btn-default font-weight-bold pr-3"
                          size="sm"
                          onClick={async () => {
                            try {
                              await client.addUserToWhiteboard(
                                item.id,
                                user.id,
                              );
                            } catch (err: any) {
                              const errorMessages = handleException(err, t);
                              errorMessages.forEach((message) => {
                                toast.error(message);
                              });
                            }
                            navigate(`/whiteboard/session?id=${item.id}`);
                          }}
                        >
                          {t("whiteboard.join")}
                        </Button>
                      )}
                    </td>
                    <td>
                      <Button
                        className="btn-default font-weight-bold pr-3"
                        variant="danger"
                        size="sm"
                        onClick={() => {
                          setWhiteboardToModify(item.id);
                          setShowDeleteWhiteboard(true);
                        }}
                      >
                        {t("delete")}
                      </Button>
                    </td>
                    <td>
                      <Button
                        className="btn-default font-weight-bold pr-3"
                        variant="primary"
                        size="sm"
                        onClick={() => {
                          setWhiteboardToModify(item.id);
                          setShowRenameWhiteboard(true);
                        }}
                      >
                        {t("whiteboard.confirm_rename")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>

      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("whiteboard.add")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>{t("whiteboard.name")}</Form.Label>
          <Form.Control
            value={text}
            onChange={handleChange}
            type="text"
            placeholder={t("whiteboard.default")}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
          {t("close")}
          </Button>
          <Button variant="primary" onClick={handleCreated}>
          {t("save")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDeleteWhiteboard}
        onHide={() => setShowDeleteWhiteboard(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("whiteboard.delete")}</Modal.Title>
        </Modal.Header>
        <p className="p-2">{t("whiteboard.warning")}</p>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowDeleteWhiteboard(false)}
          >
            {t("close")}
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              await client.deleteWhiteboard(whiteboardToModify);
              await fetchWhiteboards();
              setShowDeleteWhiteboard(false);
            }}
          >
            {t("delete")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showRenameWhiteboard}
        onHide={() => setShowRenameWhiteboard(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("whiteboard.rename")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <input
            type="text"
            placeholder="Type here..."
            onChange={({ target: { value } }) => setNewWhiteboardName(value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowRenameWhiteboard(false)}
          >
            {t("close")}
          </Button>
          <Button
            variant="primary"
            onClick={async () => {
              try {
                await client.renameWhiteboard(
                  whiteboardToModify,
                  newWhiteboardName,
                );
                await fetchWhiteboards();
              } catch (err: any) {
                const errorMessages = handleException(err, t);
                errorMessages.map((message) => {
                  toast.error(message);
                });
              }
              setShowRenameWhiteboard(false);
            }}
          >
            {t("whiteboard.confirm_rename")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "@themesberg/react-bootstrap";
import { Form, InputGroup } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import filter from "lodash/filter";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { ISubmission } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export default function ListOnlineRequests() {
  const [onlineRequestsList, setOnlineRequestsList] = useState<ISubmission[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(true);
  const client = useAPIClient();
  const [onlineRequestsFiltered, setOnlineRequestsFiltered] = useState<
    ISubmission[]
  >([]);
  const [filters, setFilters] = useState<string[]>([]);
  const { t } = useTranslation();
  const [status, setStatus] = useState("");

  const fetchOnlineRequests = async () => {
    try {
      const res = await client.getOnlineRequests();
      setOnlineRequestsList(res);
      setFilterOptionsFromRequests(res);
      setOnlineRequestsFiltered(res);
      setIsLoading(false);
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   setIsLoading(false);
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const setFilterOptionsFromRequests = async (requests: ISubmission[]) => {
    let filters = Array.from(new Set(requests.map((request) => request.status)));
    
    setFilters(filters);
  };

  useEffect(() => {
    fetchOnlineRequests();
  }, []);

  const handleSearch = async (chars: string) => {
    if (!chars || chars === "") {
      setOnlineRequestsFiltered(onlineRequestsList);
    } else {
      const filtered = filter(onlineRequestsList, (item) => {
        return (
          (item.user.profile.nume + " " + item.user.profile.prenume)
            .toLowerCase()
            .includes(chars.toLowerCase()) ||
          (item.user.profile.prenume + " " + item.user.profile.nume)
            .toLowerCase()
            .includes(chars.toLowerCase())
        );
      });
      setOnlineRequestsFiltered(filtered);
    }
  };

  const handleChangeStatus = async (value: string) => {
    setStatus(value);
    if (!value || value === "") {
      setOnlineRequestsFiltered(onlineRequestsList);
    } else {
      const filtered = filter(onlineRequestsList, (item) => {
        return item.status === value;
      });
      setOnlineRequestsFiltered(filtered);
    }
  };

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5 text-uppercase text-center">
              Cereri online
            </h2>
            <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey="persoana-fizica">
                  <Row>
                    <Col lg={12}>
                      <Nav className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="persoana-fizica"
                            className="mb-sm-3 mb-md-0"
                          >
                            Cereri Online
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="persoana-fizica" className="py-4">
                          <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                              {isLoading ? (
                                <LoadingView />
                              ) : (
                                <Form>
                                  <Row>
                                    <Col md={6} className="mb-3">
                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          Cautare dupa utilizator
                                        </Form.Label>
                                        <InputGroup>
                                          <InputGroup.Text>
                                            <FontAwesomeIcon icon={faSearch} />
                                          </InputGroup.Text>
                                          <Form.Control
                                            type="text"
                                            placeholder="Cauta"
                                            onChange={(e) => {
                                              handleSearch(e.target.value);
                                            }}
                                          />
                                        </InputGroup>
                                      </Form.Group>
                                    </Col>
                                    <Col md={6} className="mb-3">
                                      <Form.Group className="mb-3">
                                        <Form.Label>Stare cerere</Form.Label>
                                        <Form.Select
                                          id="status"
                                          value={status ? status : ""}
                                          onChange={({
                                            currentTarget: { value },
                                          }) => handleChangeStatus(value)}
                                        >
                                          <option value="">Toate</option>
                                          {/* <option value="TRANSMIS">
                                            Transmis
                                          </option>
                                          <option value="PROCESARE">
                                            Procesare
                                          </option>
                                          <option value="RESPINS">
                                            Respins
                                          </option>
                                          <option value="SOLUTIONAT">
                                            Solutionat
                                          </option> */}
                                          {filters.map((filter) => {
                                            return (
                                              <option value={filter}>
                                                {filter}
                                              </option>
                                            );
                                          })}
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th className="border-0">#</th>
                                        <th className="border-0">Data</th>
                                        <th className="border-0">Denumire</th>
                                        <th className="border-0">Stare</th>
                                        <th className="border-0">Utilizator</th>
                                        <th className="border-0 text-center"></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {orderBy(
                                        onlineRequestsFiltered,
                                        ["createdAt"],
                                        ["desc"],
                                      ).map((onlineRequest, i) => (
                                        <tr key={i}>
                                          <td>
                                            <Card.Link
                                              href="#"
                                              className="text-primary fw-bold"
                                            >
                                              {i + 1}
                                            </Card.Link>
                                          </td>
                                          <td>
                                            {dayjs(
                                              onlineRequest.createdAt,
                                            ).format("DD-MM-YYYY")}
                                          </td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >
                                            {onlineRequest.templateName.length >
                                            50
                                              ? onlineRequest.templateName
                                                  .substring(0, 50)
                                                  .concat("...")
                                              : onlineRequest.templateName}
                                          </td>
                                          <td>{onlineRequest.status}</td>
                                          <td>
                                            {onlineRequest.user.profile.nume}{" "}
                                            {onlineRequest.user.profile.prenume}
                                          </td>
                                          <td className="text-center">
                                            <Button
                                              variant="primary"
                                              size="sm"
                                              href={
                                                "/admin/online-requests/" +
                                                onlineRequest.id
                                              }
                                            >
                                              Detalii
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </Form>
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

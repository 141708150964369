import { Dispatch, SetStateAction, useState } from "react";

type PropsL = {
  setUserLocation: Dispatch<
    SetStateAction<{ latitude: number; longitude: number }>
  >;
};

const LocationComponent = ({ setUserLocation }: PropsL) => {
  const [location, setLocation] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isButtonHidden, setButtonHidden] = useState<boolean>(false);

  const handleButtonClick = () => {
    setIsButtonDisabled(true);

    // Get the user's location using the Geolocation API
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocation(`Latitude: ${latitude}, Longitude: ${longitude}`);
          setUserLocation({ latitude, longitude });
          setIsButtonDisabled(true);
          setButtonHidden(true);
        },
        (error) => {
          console.error("Error getting location: ", error.message);
          setLocation(
            "Locatia nu a putut fi gasita. Verificati setarile browser-ului."
          );
          setIsButtonDisabled(false);
        }
      );
    } else {
      console.error("Geolocation is not supported in this browser");
      setLocation("Error: Geolocation is not supported in this browser.");
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="mt-3 mb-4">
      <h3>Acest formular necesita localizare</h3>
      <div style={{ display: "flex", justifyContent: "start" }}>
        <button
          style={isButtonHidden ? { display: "none" } : { display: "block" }}
          onClick={handleButtonClick}
          disabled={isButtonDisabled}
          className="btn btn-primary"
        >
          Obtine locatia
        </button>

        {location !== "" && (
          <input
            type="text"
            value={location}
            disabled
            className={isButtonDisabled ? "form-control" : "form-control mx-2"}
          />
        )}
      </div>
    </div>
  );
};

export default LocationComponent;

import React, { useState, useEffect } from "react";
import { Card, Button } from "@themesberg/react-bootstrap";
import { MdClose } from "react-icons/md";
import "../../assets/css/tags.css";
import { toast } from "react-hot-toast";
import { useAPIClient } from "../../helpers/api";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { t } from "i18next";

export const TagForm = () => {
  const client = useAPIClient();
  const [error, setError] = useState("");
  const [tags, setTags] = useState([]);
  const [newTags, setnewTags] = useState([]);
  const [deletedTags, setDeletedTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getAllTags = async () => {
    client
      .getFormsCategories()
      .then((data) => {
        const updatedTags = data.map((label) => ({
          id: label.id,
          name: label.label,
        }));
        setTags(updatedTags);
      })
      .catch((err) => toast.error(err.message));
  };

  useEffect(() => {
    getAllTags();
    return () => {
      // Cleanup any resources or subscriptions before the component is unmounted
    };
  }, []);

  const addTags = async () => {
    setIsLoading(true);

    const addTagPromises = newTags.map((tag) => {
      tag.name = tag.name.trim();
      return client.createLabel(tag.name);
    });
    const deleteTagPromises = deletedTags.map((tag) =>
      client.deleteLabel(tag.id)
    );

    if (newTags.length === 0 && deletedTags.length === 0) {
      setIsLoading(false);
      toast.error(t("tag.error_change"));
      return;
    }
    await Promise.all([...addTagPromises, ...deleteTagPromises])
      .then(() => {
        toast.success(t("tag.success_update"));
      })
      .catch((err) => toast.error(err.message))
      .finally(() => {
        getAllTags();
      });

    setnewTags([]);
    setDeletedTags([]);
    setIsLoading(false);
  };

  const handleTags = (event) => {
    if (
      event.key === "Enter" &&
      event.target.value !== "" &&
      event.target.value.length <= 50
    ) {
      const tagRegex = /^[a-zA-Z 0-9]*$/;
      if (!tagRegex.test(event.target.value)) {
        setError(t("tag.error_regex"));
        return;
      }

      const newTag = {
        id: "0000",
        name: event.target.value,
      };

      // Check if the tag already exists
      if (
        tags.some(
          (tag) => tag.name.toLowerCase() === newTag.name.trim().toLowerCase()
        )
      ) {
        setError(t("tag.error_exist"));
        return;
      }

      setTags([...tags, newTag]);
      setnewTags([...newTags, newTag]);
      event.target.value = "";
    }
  };

  //Remove tags by clicking the cross sign
  const removeTags = (index) => {
    const removedTag = tags[index];
    setTags((prevTags) => prevTags.filter((tag, i) => i !== index));

    // Check if the removed tag is present in 'newTags' state
    if (!newTags.some((tag) => tag.id === removedTag.id)) {
      setDeletedTags((prevDeletedTags) => [...prevDeletedTags, removedTag]);
    }
  };

  const handleError = () => {
    setError("");
  };

  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <Card.Title className="text-center mt-4 mb-4">
          {t("tag.manage")}
        </Card.Title>
        <div className="tags form-control" style={{ display: "flex" }}>
          {tags.length > 0 ? (
            tags.map((tag, index) => (
              <div className="single-tag" key={index}>
                <span>{tag.name}</span>
                <i onClick={() => removeTags(index)}>
                  <MdClose />
                </i>
              </div>
            ))
          ) : (
            <></>
          )}

          <input
            type="text"
            onKeyDown={(event) => handleTags(event)}
            onChange={handleError}
            placeholder={t("tag.name_cat")}
          />
        </div>
        <div className="error">{error}</div>

        <div className="mt-3">
          <OverlayTrigger
            overlay={
              <Tooltip className="m-0">
               {t("tag.name_enter")}
              </Tooltip>
            }
          >
            <Button
              variant="primary"
              type="submit"
              onClick={addTags}
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : t("tag.save")}
            </Button>
          </OverlayTrigger>
        </div>
      </Card.Body>
    </Card>
  );
};

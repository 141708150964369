import { Card } from "@themesberg/react-bootstrap";
import { t } from "i18next";

import { IGrantSubmission } from "../cjt";

export default function GrantScoreWidget({
  grantSubmission,
}: {
  grantSubmission: IGrantSubmission;
}) {
  return (
    <Card>
      <Card.Header>
        <h3>
          {t("grant_apply.evaluation_score")}: {grantSubmission.evaluationScore}{" "}
          {t("grant_apply.points")}
        </h3>
      </Card.Header>
      <Card.Body>
        <Card.Text>
          {t("grant_apply.evaluation_comments")}
          <p>{grantSubmission.evaluationComments}</p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Container, Table } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { Alert, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IAuditor } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";

function AuditorList() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [items, setItems] = useState<IAuditor[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [auditorToDeleteId, setAuditorToDeleteId] = useState<string>("");
  const handleClose = () => setShowDeleteAlert(false);

  useEffect(() => {
    const getData = async () => {
      try {
        const requests = await client.getHearingAuditors();
        setItems(requests);
      } catch (e) {
        // toast.error("Eroare la aducerea datelor");
        const errorMessages = handleException(e, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
      }
    };

    getData();
  }, []);

  const handleDeleteAuditor = async (id: string) => {
    try {
      setIsLoading(true);
      await client.deleteAuditor(id);
      toast.success("Auditorul a fost șters cu success!");
      toast.success("Te vom redirectiona in 2 secunde...");
      setTimeout(() => {
        window.location.assign("/admin/auditor-list");
      }, 2000);
    } catch (error) {
      //toast.error("A apărut o eroare în ștergerea auditorului!");
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
      setIsLoading(false);
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="fw-bold mb-5 text-uppercase text-center">
        Lista Auditorilor
      </h2>
      <Card border="light" className="shadow-sm mb-4">
        <Card.Body className="pb-0">
          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0"
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0">#</th>
                <th className="border-0">Nume Afișat</th>
                <th className="border-0">Auditor</th>
                <th className="border-0">Opțiuni</th>
              </tr>
            </thead>
            <tbody>
              {!(items.length === 0) ? (
                items.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.displayName}</td>
                    <td>{item.realName}</td>
                    <td>
                      <OverlayTrigger
                        overlay={
                          <Tooltip className="m-0">Șterge Auditor</Tooltip>
                        }
                      >
                        <Button
                          variant="danger"
                          size="sm"
                          onClick={() => {
                            setShowDeleteAlert(true);
                            setAuditorToDeleteId(item.id);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    Nu exită informații despre auditori
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal
        as={Modal.Dialog}
        centered
        show={showDeleteAlert}
        onHide={handleClose}
      >
        <Modal.Header>
          <Modal.Title className="h6">Ștergere Auditor</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={handleClose}
            disabled={isLoading}
          />
        </Modal.Header>
        <Modal.Body>
          <Alert variant="danger">
            <Alert.Heading className="d-flex align-items-center mt-2">
              Atenție!
            </Alert.Heading>

            <p>
              Prin ștergerea unui auditor se vor șterge și toate cererile /
              programările efectuate pentru acesta.
            </p>
            <hr />
            <p className="mb-0">
              Sunteți sigur că doriți să eliminați acest auditor?
            </p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            className="my-3"
            onClick={() => handleDeleteAuditor(auditorToDeleteId)}
            disabled={isLoading}
          >
            Da, șterge Auditor
          </Button>
          <Button
            variant="primary"
            className="ms-auto"
            onClick={handleClose}
            disabled={isLoading}
          >
            Nu, închide fereastra
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default AuditorList;

import { Navigate, Outlet } from "react-router-dom";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { useAuthContext } from "../context/AuthContext";
import Footer from "./Footer";

function ProtectedAdminZone() {
  const { isAdmin } = useAuthContext();

  if (!isAdmin()) {
    return <Navigate to={"/login"} />;
  }

  return (
    <>
      <Sidebar />
      <main className="content">
        <div className="d-flex  mt-3" style={{justifyContent: "center", width: "100%"}}>
          <img className="ui centered big image w-100-mobile" src="/poca2.png" style={{width: "40%", maxWidth: "450px"}}></img>
        </div>
        <Navbar />
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
export default ProtectedAdminZone;

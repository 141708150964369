import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const { t } = useTranslation();
  return (
    <Container className="mt-5">
      <h2 className="fw-bold mb-5 text-uppercase text-center">
        {t("privacy.title")}
      </h2>
      {t("privacy.text")}
    </Container>
  );
}

export default PrivacyPolicy;

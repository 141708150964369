import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table  } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPIClient } from "../../../helpers/api";
import { ISurvey, ISurveyAnswer, ISurveyQuestion, ISurveyUserAnswer, IUser } from "../../../cjt";



function TakeSurvey() {
  const { id } = useParams();
  const { register, handleSubmit, getValues } = useForm();
  const navigate = useNavigate();
  const client = useAPIClient();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [survey, setSurvey] = useState<ISurvey | null>(null);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);

  const fetchSurvey = async () => {
    try {
      const res = await client.getSurveyByid(id!);
      setSurvey(res);
    } catch (err: any) {
      toast.error(t("survey.error_cannot_load_surveys"));
    }
  };

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setUser(_user);
    } catch (error: any) {
      toast.error(error.message as string);
    }
  };

  const handleRegisterAnswears = async () => {
    try {
      setLoading(true);
      const surveyFormData = getValues() as any;
      for (const key in surveyFormData) {
        if (Object.prototype.hasOwnProperty.call(surveyFormData, key)) {
          const value = surveyFormData[key] as string;
          const res = await client.registerSurveyAnswearByAnswerId(value);
          console.log(res)
        }
      }
      
      toast.success(t("survey.success_load_surveys"));
      toast.success(t("redirect_2_s"));
      setTimeout(() => {
        window.location.assign("/bugetare-participativa/chestionare");
      }, 2000);

    } catch (err: any) {
      setLoading(false);
      toast.error(t("survey.error_cannot_register_surveys"));
    }
  };


  useEffect(() => {
    fetchSurvey();
    fetchUser();
    console.log(survey)
  }, []);


  const checkIfUserHasSubmittedSurvey = (survey: ISurvey) => {
    let hasSubmitted = false;
    survey.questions.forEach((question: ISurveyQuestion) => {
      question.answers.forEach((answer: ISurveyAnswer) => {
        answer.userEntries.forEach((userAnswer: ISurveyUserAnswer) => {
          if (userAnswer.user.id === user?.id) {
            hasSubmitted = true;
          }
        });
      });
    });
    return hasSubmitted;
  };

  return (
    <>
      <Container className="mt-5 mb-6">
        <Card>
          <Card.Body>
            <h3 className="text-center mb-5">{t("survey.TakeSurvey")}</h3>
            {survey && !checkIfUserHasSubmittedSurvey(survey) ? (
              <Form className="px-2">
                {survey.questions.map((question: ISurveyQuestion) => (
                  <div key={question.id} className="mb-4">
                    <p className="mb-0"><b>{question.question}</b></p>
                      {question.answers.map((answer: ISurveyAnswer) => (
                          <Form.Check
                            type="radio"
                            id={`answer-${answer.id}`}
                            label={answer.answer}
                            value={answer.id}
                            {...register(`question-${question.id}`, { required: true })}
                          />
                      ))}
                  </div>
                ))}

                <Button variant="primary" onClick={() => handleRegisterAnswears()} disabled={loading}>
                {t("survey.submit_survey")} 
                </Button>
              </Form>
            ) : (
              <p>{t("survey.user_already_took_survey")}</p>
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default TakeSurvey;

import { OverlayTrigger } from "@themesberg/react-bootstrap";
import md5 from "md5";
import { useCallback, useEffect, useState } from "react";
import { Badge, Container, Row, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { RiMapPinLine } from "react-icons/ri";

import { IUser } from "../../cjt";
import ModuleButton from "../../components/ModuleButton";
import { useAuthContext } from "../../context/AuthContext";
import { EModules } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";
import { Routes } from "../../routes";
import FinishProfile from "../CompleteProfile/FinishProfile";

function Dashboard() {
  const client = useAPIClient();
  const [profile, setProfile] = useState<IUser | undefined>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getFeed = useCallback(async () => {
    try {
      const data = await client.getUserFeed();
      console.log(data);
      setProfile(data);
    } catch (error: any) {
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getFeed();
  }, [getFeed]);

  if (profile !== null) {
    return <UserScreen loading={loading} profile={profile} />;
  } else {
    return <FinishProfile onCompleted={getFeed} />;
  }
}

function UserScreen({
  loading,
  profile,
}: {
  loading: boolean;
  profile: IUser | undefined;
}) {
  const { t } = useTranslation();
  const { hasWhiteboard, showModule } = useAuthContext();
  if (loading) {
    return <LoadingView />;
  }

  return (
    <>
      <Container>
        <div className="row">
          <div className="col-xl-12">
            <div className="card custom-card">
              <div className="card-body">
                <div className="d-flex flex-wrap align-items-top justify-content-between gap-2">
                  <div>
                    <div className="d-flex flex-wrap gap-2">
                      <div>
                        <span className="avatar avatar-rounded avatar-lg">
                          <img
                            src={`https://www.gravatar.com/avatar/${md5(
                              `${profile?.email}`,
                            )}?size=160`}
                            alt="Imagine profil"
                            style={{ borderRadius: "50%" }}
                          />
                        </span>
                      </div>
                      <div className="mx-3">
                        <h4
                          className="mb-0 d-flex align-items-center"
                          style={{ fontWeight: 900 }}
                        >
                          <a href="javascript:void(0);">
                            {profile?.profile.nume} {profile?.profile.prenume}
                          </a>
                        </h4>
                        <a
                          href="javascript:void(0);"
                          className="fw-semibold d-flex align-items-center"
                        >
                          <RiMapPinLine className="me-1 align-middle" />
                          <div>
                            {profile?.profile.strada},{" "}
                            {profile?.profile.localitate},{" "}
                            {profile?.profile.judet},{" "}
                            {profile?.profile.cod_postal}
                          </div>
                        </a>
                        <div className="popular-tags">
                          {profile?.isActive ? (
                            <Badge bg="success" className="me-1">
                              {t("dashboard.status_active")}
                            </Badge>
                          ) : (
                            <Badge bg="warning" className="me-1">
                              {t("dashboard.status_inactive")}
                            </Badge>
                          )}

                          {profile?.isEmailConfirmed ? (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              overlay={
                                <Tooltip>
                                  {t("dashboard.success_email")}
                                </Tooltip>
                              }
                            >
                              <Badge bg="success" className="me-1">
                                {t("dashboard.email_confirmed")}
                              </Badge>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              overlay={
                                <Tooltip>{t("dashboard.confirm")}</Tooltip>
                              }
                            >
                              <Badge bg="warning" className="me-1">
                                {t("dashboard.email_not_confirmed")}
                              </Badge>
                            </OverlayTrigger>
                          )}

                          {profile?.isVerified ? (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              overlay={
                                <Tooltip>
                                  {t("dashboard.success_verified")}
                                </Tooltip>
                              }
                            >
                              <Badge bg="success" className="me-1">
                                {t("dashboard.account_verified")}
                              </Badge>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              trigger={["hover", "focus"]}
                              overlay={
                                <Tooltip>
                                  {t("dashboard.account_verify")}
                                </Tooltip>
                              }
                            >
                              <Badge bg="warning" className="me-1">
                                {t("dashboard.account_not_verified")}
                              </Badge>
                            </OverlayTrigger>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="btn-list mb-2">
                      <a href="/account" className="btn btn-primary">
                        {t("dashboard.profile_data")}
                      </a>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="mt-4 mx-1">
                  <h4>{t("dashboard.services")}</h4>
                  <Row className="mb-5">
                    <ModuleButton
                      disabled={false}
                      iconName="UiChecks"
                      buttonText={t("online_requests")}
                      route="/operatiuni/forms"
                    ></ModuleButton>
                    <ModuleButton
                      disabled={false}
                      iconName="PersonVideo3"
                      buttonText={t("online_hearings")}
                      route="/operatiuni/request-audience"
                    ></ModuleButton>
                    {hasWhiteboard && (
                      <ModuleButton
                        disabled={false}
                        iconName="Brush"
                        buttonText={t("my_whiteboards")}
                        route="/whiteboard/my-whiteboards"
                      ></ModuleButton>
                    )}
                    <ModuleButton
                      disabled={false}
                      iconName="Wallet"
                      buttonText={t("sidebar_payments.online")}
                      route="/operatiuni/plati"
                    ></ModuleButton>
                  </Row>
                  {showModule(EModules.Grants) ||
                    (showModule(EModules.OpenGovernment) && (
                      <>
                        {" "}
                        <h4>{t("dashboard.projects_apply")}</h4>
                        <Row className="mb-5">
                          {showModule(EModules.Grants) && (
                            <ModuleButton
                              disabled={false}
                              iconName="UiChecks"
                              buttonText={t("dashboard.grants")}
                              route="/finantare/proiecte-disponibile"
                            ></ModuleButton>
                          )}
                          {showModule(EModules.OpenGovernment) && (
                            <ModuleButton
                              disabled={false}
                              iconName="UiChecks"
                              buttonText={t("dashboard.budgeting")}
                              route="/bugetare-participativa/depunere-proiecte"
                            ></ModuleButton>
                          )}
                        </Row>
                      </>
                    ))}
                  <Row></Row>
                </div>
                {/* {<div className="card custom-card border bg-primary-transparent shadow-none">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <h5 className="fw-semibold mb-0">
                          {t("dashboard.invoices")}
                        </h5>
                      </div>
                      <div className="col-lg-6 text-end">
                        <a
                          href={Routes.Plati.path}
                          className="btn btn-success"
                          role="button"
                        >
                          {t("dashboard.payments")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>} */}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4"></div>
      </Container>
    </>
  );
}

export default Dashboard;

import React from "react";
import { useTranslation } from "react-i18next";

import "./style.css";

const contractStatuses: { [key: string]: any } = {
  // const contractStatuses = {
  Opened: { index: 0, label: "contract.Opened" },
  WaitingForSignatures: { index: 1, label: "contract.WaitingForSignatures" },
  Ongoing: { index: 2, label: "contract.Ongoing" },
};

export default function ProgressStepsH({ status }: any) {
  const currentStep = contractStatuses[status].index;
  const { t } = useTranslation();

  const initialStepsArr = [
    {
      title: contractStatuses["Opened"].label,
      status:
        currentStep > contractStatuses.Opened.index
          ? "completed"
          : currentStep == contractStatuses.Opened.index
          ? "active"
          : "unvisited",
    },
    {
      title: contractStatuses["WaitingForSignatures"].label,
      status:
        currentStep > contractStatuses.WaitingForSignatures.index
          ? "completed"
          : currentStep == contractStatuses.WaitingForSignatures.index
          ? "active"
          : "unvisited",
    },
    {
      title: contractStatuses["Ongoing"].label,
      status:
        currentStep > contractStatuses.Ongoing.index
          ? "completed"
          : currentStep == contractStatuses.Ongoing.index
          ? "finished"
          : "unvisited",
    },
  ];

  return (
    <div className="containerSteps">
      <div className="cardSteps">
        <ul className="progress_bar">
          {initialStepsArr.map((step, index) => (
            <li className={step.status} key={index}>
              <span className="title">{t(step.title)}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

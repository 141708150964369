import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Editor } from "@tinymce/tinymce-react";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Container, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import "../../../assets/css/tinymce.css";
import { IProiect, ISmartForm } from "../../../cjt";
import DataForm from "../../../components/Forms/DataForm";
import { SmartForm } from "../../../components/Forms/SmartForm";
import { tinymce_key } from "../../../config/env";
import { DosarStatus } from "../../../enums";
import { useAPIClient } from "../../../helpers/api";
import handleException from "../../../helpers/exceptions";
import LoadingView from "../../../layout/LoadingView";
import ChatBox from "../../Chatbox/Chatbox";

export default function ViewOnlineRequestById() {
  const { id } = useParams();
  const client = useAPIClient();
  const navigate = useNavigate();
  const [onlineRequestData, setOnlineRequestData] = useState<ISmartForm | null>(
    null,
  );
  const [dosarData, setDosarData] = useState<IProiect | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmApprove, setShowConfirmApprove] = useState(false);
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [showConfirmFinantat, setShowConfirmFinantat] = useState(false);
  const [status, setStatus] = useState("");
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState<string>("");
  const { t } = useTranslation();
  const [hasLocation, setHasLocation] = useState<boolean>(false);
  const [location, setLocation] = useState<{
    latitude: string;
    longitude: string;
  }>({ latitude: "0", longitude: "0" });
  const { register, handleSubmit, getValues, reset } = useForm();
  const [statusDosar, setStatusDosar] = useState<string>("aprobat");
  const [deniedReasonInput, setDeniedReasonInput] = useState<string>("");
  const editorRef = useRef<any>(null);

  const fetchDosarByFormSubmissionId = async () => {
    try {
      const proiect = await client.getProiectFormSubmissionId(id!);
      setDosarData(proiect);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fetchOnlineRequestById = async () => {
    try {
      const res = await client.getOnlineRequestsById(id!);
      setOnlineRequestData(res);

      if (res.submission.userLocation != null) {
        const location = JSON.parse(JSON.parse(res.submission.userLocation));
        setHasLocation(true);
        setLocation(location);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleUpdateProject = async (projectStatus: string) => {
    try {
      setIsLoading(true);
      const formData = getValues() as IProiect;
      formData.status = projectStatus;
      if (editorRef.current) {
        formData.description = editorRef.current.getContent();
      }
      if (projectStatus == DosarStatus.Approved) {
        const isFormValid = validateForm();
        if (!isFormValid) {
          toast.error(t("calendar.approve_please_complete_fields"));
          return;
        }
      }
      if (projectStatus == DosarStatus.Denied && reason === "") {
        toast.error(t("calendar.reject_reason_require"));
        return;
      }

      if (formData.imageId && formData.imageId[0]) {
        const imageFile = formData.imageId[0];
        const base64String = await new Promise<string>((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.readAsDataURL(imageFile);
        });
        formData.imageId = base64String;
      }

      formData.reason = reason;
      const updateProiect = await client.updateProiect(formData, dosarData!.id);

      toast.success(t("calendar.proiect_update_success"));

      window.location.reload();
    } catch (error) {
      toast.error(t("calendar.proiect_update_error"));
    } finally {
      setIsLoading(false);
      setShowConfirmDeletion(false);
      setShowConfirmApprove(false);
    }
  };

  useEffect(() => {
    fetchOnlineRequestById();
    fetchDosarByFormSubmissionId();
  }, []);

  const handleCancelDeletion = () => {
    setShowConfirmDeletion(false);
    setShowConfirmApprove(false);
    setShowConfirmFinantat(false);
  };

  const buildGoogleMapsURL = () => {
    const baseUrl = "https://www.google.com/maps";
    const coordinates = `${location.latitude},${location.longitude}`;
    const queryParam = `q=${encodeURIComponent(coordinates)}`;

    return `${baseUrl}?${queryParam}`;
  };

  const allowedExtensions = ["jpg", "jpeg", "png"];

  const validateForm = () => {
    const requiredFields = ["title", "beneficiar", "localizare", "buget"];
    const imageField = "imageId";
    let isValid = true;

    requiredFields.forEach((field) => {
      const fieldData = getValues(field);
      if (!fieldData) {
        isValid = false;
      }
    });
    if (editorRef.current.getContent() === "") {
      isValid = false;
    }

    const imageFile = getValues(imageField);
    if (imageFile && imageFile[0] && imageFile[0].name) {
      const fileExtension = imageFile[0].name.split(".").pop().toLowerCase();
      if (!allowedExtensions.includes(fileExtension)) {
        toast.error("Fisierul trebuie sa fie o imagine (.jpg, .jpeg, .png).");
        isValid = false;
      }
    } else {
      isValid = false;
    }

    return isValid;
  };

  const isValidationPeriod =
    dayjs(dosarData?.calendar.validare_proiecte_start).isBefore(new Date()) &&
    dayjs(dosarData?.calendar.validare_proiecte_end)
      .add(1, "day")
      .isAfter(new Date());
  const isApprovalPeriod =
    dayjs(dosarData?.calendar.anunt_proiecte_castigatoare).isAfter(
      new Date(),
    ) &&
    dayjs(dosarData?.calendar.vot_proiecte_end)
      .add(1, "day")
      .isBefore(new Date());
  const showApproveButton =
    dosarData && dosarData.status === DosarStatus.Denied && isValidationPeriod;
  const showRejectButton =
    dosarData &&
    dosarData.status === DosarStatus.Approved &&
    isValidationPeriod;
  const showFinantatButton =
    dosarData && isApprovalPeriod && dosarData.status !== DosarStatus.Funded;
  const showValidationMessage =
    !showApproveButton && !showRejectButton && !isValidationPeriod;

  const generateApprovalButtons = () => {
    if (dosarData?.status === DosarStatus.Waiting && isValidationPeriod) {
      return [
        {
          variant: "primary",
          text: "Aprobă spre publicare",
          action: () => setShowConfirmApprove(true),
        },
        {
          variant: "danger",
          text: "Respinge cererea",
          action: () => setShowConfirmDeletion(true),
        },
      ];
    } else if (showApproveButton) {
      return [
        {
          variant: "primary",
          text: "Aprobă spre publicare",
          action: () => setShowConfirmApprove(true),
        },
      ];
    } else if (showRejectButton) {
      return [
        {
          variant: "danger",
          text: "Respinge cererea",
          action: () => setShowConfirmDeletion(true),
        },
      ];
    } else if (showFinantatButton) {
      return [
        {
          variant: "primary",
          text: "Acordă finanțarea",
          action: () => setShowConfirmFinantat(true),
        },
      ];
    }
    return [];
  };

  return (
    <Container className="mt-5">
      <>
        <Card className="p-4 mb-4">
          {onlineRequestData && (
            <>
              <p className="mb-0">
                {t("calendar.submitted_project_category")}: (
                {dosarData?.category.map((el) => <b>{el.denumire}, </b>)})
              </p>
              <p>
                {t("calendar.project_status")}: <b>{dosarData?.status}</b>
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isLoading ? (
                  <LoadingView />
                ) : (
                  <>
                    <h2 className="pt-1 mb-4 text-break">
                      {onlineRequestData.submission.templateName}
                    </h2>
                  </>
                )}
              </div>

              <div>
                {hasLocation &&
                  location &&
                  (parseInt(location.latitude) != 0 ||
                    parseInt(location.longitude) != 0) && (
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => {
                        const url = buildGoogleMapsURL();
                        window.open(url, "_blank");
                      }}
                    >
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </Button>
                  )}
                {onlineRequestData.submission.user && (
                  <>
                    <p className="pt-3">{t("profile_data")}</p>

                    <DataForm
                      nume={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.nume
                      }
                      prenume={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.prenume
                      }
                      cnp={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.cnp
                      }
                    />
                  </>
                )}
              </div>

              {onlineRequestData === null ? (
                <></>
              ) : (
                <>
                  <hr className="mb-4"></hr>

                  <SmartForm
                    form={JSON.parse(onlineRequestData.template)}
                    submission={JSON.parse(onlineRequestData.body)}
                    options={{
                      readOnly: true,
                      viewAsHtml: true,
                      // renderMode: "html",
                    }}
                  />
                  <hr className="mt-4"></hr>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {onlineRequestData.submission.comment && (
                      <p className="mb-4">
                        Cererea a fost{" "}
                        {onlineRequestData.submission.status.toLowerCase()}a cu
                        motivul: {onlineRequestData.submission.comment}
                      </p>
                    )}
                    <div>
                      <Form>
                        {!showValidationMessage && (
                          <>
                            <h4>
                              {t("calendar.publish_announcement_management")}
                            </h4>
                            <p>
                              <i>* {t("calendar.publish_announcement_info")}</i>
                            </p>
                            <div id="document">
                              {dosarData?.imageId ? (
                                <>
                                  <img
                                    src={dosarData?.imageId}
                                    style={{
                                      width: "250px",
                                      borderRadius: "14px",
                                    }}
                                  ></img>
                                  <br></br>
                                </>
                              ) : (
                                <></>
                              )}
                              <label className="form-label">
                                {t("calendar.project_image_attached")} (.png,
                                .jpg, .jpeg) *
                              </label>
                              <Form.Control
                                type="file"
                                {...register("imageId")}
                              />
                              {/* <input aria-required="true" type="file" id="document" className="form-control"></input>     */}
                              <Form.Control
                                type="hidden"
                                value="aprobat"
                                {...register("status", { required: true })}
                              />
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">
                                {t("calendar.project_title")} *
                              </label>
                              <div>
                                <Form.Control
                                  type="text"
                                  {...register("title", { required: true })}
                                  defaultValue={dosarData?.title}
                                />
                                {/* <input aria-required="true" value="" className="form-control" type="text" name="data[textField]"></input> */}
                              </div>
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">
                                {t("calendar.project_details")} *
                              </label>
                              {dosarData?.description}
                              {/* <Editor
                                apiKey={tinymce_key}
                                onInit={(evt, editor) =>
                                  (editorRef.current = editor)
                                }
                                initialValue={dosarData?.description}
                                init={{
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    "advlist",
                                    "autolink",
                                    "lists",
                                    "link",
                                    "image",
                                    "charmap",
                                    "preview",
                                    "anchor",
                                    "searchreplace",
                                    "visualblocks",
                                    "code",
                                    "fullscreen",
                                    "insertdatetime",
                                    "media",
                                    "table",
                                    "code",
                                    "help",
                                    "wordcount",
                                  ],
                                  toolbar:
                                    "undo redo | blocks | " +
                                    "bold italic forecolor | alignleft aligncenter " +
                                    "alignright alignjustify | bullist numlist outdent indent | " +
                                    "removeformat | help",
                                  content_style:
                                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                                }}
                              /> */}
                            </div>
                            <div className="form-group">
                              <label className="col-form-label">
                                {t("calendar.project_beneficiary")} *
                              </label>
                              <div>
                                <Form.Control
                                  type="text"
                                  {...register("beneficiar", {
                                    required: true,
                                  })}
                                  defaultValue={dosarData?.beneficiar}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="col-form-label">
                                {t("calendar.project_localization")} *
                              </label>
                              <div>
                                <Form.Control
                                  type="text"
                                  {...register("localizare", {
                                    required: true,
                                  })}
                                  defaultValue={dosarData?.localizare}
                                />
                              </div>
                            </div>

                            <div className="form-group">
                              <label className="col-form-label">
                                {t("calendar.project_budget")} *
                              </label>
                              <div>
                                <input
                                  type="number"
                                  {...register("buget", { required: true })}
                                  defaultValue={dosarData?.buget}
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {showValidationMessage && (
                          <div className="alert alert-info mt-4">
                            <span>
                              {t("calendar.not_in_validation_period")}
                            </span>
                          </div>
                        )}

                        {showRejectButton && (
                          <div className="alert alert-success mt-4">
                            <span>{t("calendar.project_publish_success")}</span>
                          </div>
                        )}

                        {showApproveButton && (
                          <div className="alert alert-danger mt-4">
                            <span>
                              {t("calendar_project_publish_rejected")}
                            </span>
                          </div>
                        )}

                        {generateApprovalButtons().map((button, index) => (
                          <Button
                            key={index}
                            variant={button.variant}
                            className={`mt-4 ${index > 0 ? "mx-2" : ""}`}
                            onClick={button.action}
                          >
                            {button.text}
                          </Button>
                        ))}
                      </Form>
                    </div>
                  </div>

                  {onlineRequestData.submission.chat && (
                    <>
                      <hr className="mt-4"></hr>
                      <ChatBox id={onlineRequestData.submission.chat.id} />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Card>
      </>
      <Modal show={showReason}>
        <Modal.Header closeButton>
          <Modal.Title>{"Comentariu"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("calendar.project_publish_rejected")} "{status}"
          </p>
          <input
            type="text"
            className="form-control"
            id="reason"
            value={reason && reason}
            onChange={({ currentTarget: { value } }) => setReason(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary">{t("contracts.cancel")}</Button>
          <Button variant="primary">{t("calendar.add")}</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmApprove}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("calendar.confirm_project_publish_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("calendar.confirm_project_publish")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCancelDeletion()}>
            {t("contracts.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateProject(DosarStatus.Approved)}
          >
            {t("grant_apply.approve")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmFinantat}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t("calendar.confirm_project_funding_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("calendar.confirm_project_funding")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCancelDeletion()}>
            {t("contracts.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateProject(DosarStatus.Funded)}
          >
            {t("grant_apply.approve")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmDeletion}>
        <Modal.Header closeButton>
          <Modal.Title>{t("grant_apply.reject")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("calendar.confirm_project_reject")}</p>
          <p>{t("calendar.add_reason")}</p>
          <input
            type="text"
            className="form-control"
            id="reason"
            value={reason && reason}
            onChange={({ currentTarget: { value } }) => setReason(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => handleCancelDeletion()}>
            {t("contracts.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={() => handleUpdateProject(DosarStatus.Denied)}
          >
            {t("grant_apply.reject")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

const CustomMessage = ({ payload }: { payload: string }) => {
  const inputText = payload;

  const makeUrlsClickable = (text: string) => {
    const urlPattern = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
    const newText = text.replace(
      urlPattern,
      '<a href="$1" target="_blank" class="answerLink"><b>Click aici</b></a>'
    );
    return { __html: newText };
  };

  return (
    <>
      <div className="react-chatbot-kit-chat-bot-message-container">
        <div className="react-chatbot-kit-chat-bot-avatar">
          <div className="react-chatbot-kit-chat-bot-avatar-container">
            <p className="react-chatbot-kit-chat-bot-avatar-letter">B</p>
          </div>
        </div>
        <div
          className="react-chatbot-kit-chat-bot-message"
          style={{ backgroundColor: "rgb(68, 107, 251)" }}
        >
          <div dangerouslySetInnerHTML={makeUrlsClickable(inputText)}></div>
          {/* <span>CU LINK: {outputText}</span> */}
          <div
            className="react-chatbot-kit-chat-bot-message-arrow"
            style={{ borderRightColor: "rgb(68, 107, 251)" }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default CustomMessage;

import { ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import React from "react";
import { useTranslation } from "react-i18next";

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked,
}) => {
  const { t } = useTranslation();
  return (
    <div className="card card-body d-block nav">
      <h4>{t("PROJECT_GRAPH_CHANGE_VIEWMODE")}</h4>

      <button
        className="btn btn-primary mx-1"
        onClick={() => onViewModeChange(ViewMode.Day)}
      >
        1d
      </button>
      <button
        className="btn btn-primary mx-1"
        onClick={() => onViewModeChange(ViewMode.Week)}
      >
        1w
      </button>
      <button
        className="btn btn-primary mx-1"
        onClick={() => onViewModeChange(ViewMode.Month)}
      >
        1m
      </button>
      <button
        className="btn btn-primary mx-1"
        onClick={() => onViewModeChange(ViewMode.Year)}
      >
        1y
      </button>

      <div className="form-check form-switch mt-2 px-5">
        <label className="form-check-label">
          <input
            className="form-check-input"
            type="checkbox"
            role="switch"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          {t("PROJECT_GRAPH_SHOW_COLUMN")}
        </label>
      </div>
    </div>
  );
};

import React from "react";
import Options from "./Options";

const Questions = ({ payload: options, actionProvider, ...rest }) => {
  if (options) {
    options.map(
      (option) => (option["handleOption"] = actionProvider.handleAnswer)
    );
  }

  if (!options) {
    return <div>Loading...</div>;
  }
  return <><Options options={options} title="Optiuni" {...rest} /></>;
};

export default Questions;

import {
  faEye,
  faFileSignature,
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ICalendar } from "../../../cjt";
import MilestoneDate from "../../../components/MilestoneDate";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";

function ProcesBugetare() {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [loading, setLoading] = useState(false);
  const [calendare, setCalendare] = useState<ICalendar[]>();
  const [calendarActive, setCalendarActive] = useState<ICalendar>();

  useEffect(() => {
    const getAllCalendar = async () => {
      try {
        const requests = await client.getAllPreviousCalendar();
        setCalendare(requests);
      } catch (error) {
        toast.error(error as string);
      }
    };

    const getCalendarActive = async () => {
      try {
        const requests = await client.getActiveCalendar();
        setCalendarActive(requests);
      } catch (error) {
        toast.error(error as string);
      }
    };

    getAllCalendar();
    getCalendarActive();
  }, []);

  return (
    <>
      <Container>
        <Card>
          <Card.Body>
            <h3 className="text-center mb-4">{calendarActive?.denumire}</h3>
            <div className="container-fluid">
              {calendarActive ? (
                <div className="row text-center">
                  <MilestoneDate
                    title={t("calendar.project_submission")}
                    icon={faFileSignature}
                    startDate={calendarActive.depunere_proiecte_start}
                    endDate={calendarActive.depunere_proiecte_end}
                    editable={false}
                  />
                  <MilestoneDate
                    title={t("calendar.project_validation")}
                    icon={faFileSignature}
                    startDate={calendarActive.validare_proiecte_start}
                    endDate={calendarActive.validare_proiecte_end}
                    editable={false}
                  />
                  <MilestoneDate
                    title={t("calendar.project_display")}
                    icon={faEye}
                    startDate={calendarActive.afisare_proiecte_start}
                    editable={false}
                  />
                  <MilestoneDate
                    title={t("calendar.project_voting")}
                    icon={faFileSignature}
                    startDate={calendarActive.vot_proiecte_start}
                    endDate={calendarActive.vot_proiecte_end}
                    editable={false}
                  />
                  <MilestoneDate
                    title={t("calendar.winning_projects")}
                    icon={faEye}
                    startDate={calendarActive.anunt_proiecte_castigatoare}
                    editable={false}
                  />
                  <MilestoneDate
                    title={t("calendar.project_implementation")}
                    icon={faTrophy}
                    startDate={calendarActive.implementare_proiecte}
                    editable={false}
                  />
                </div>
              ) : (
                <p className="mb-0">
                  <b>{t("calendar.no_active_calendar")}</b>
                </p>
              )}
              <p className="text-justify">{calendarActive?.descriere}</p>
            </div>
          </Card.Body>
        </Card>
        <Col lg={12} className="mt-4">
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body className="pb-0">
              <h5 className="fw-bold mb-2">
                {t("calendar.previous_project_submission")}
              </h5>
              <Table
                responsive
                className="table-centered table-nowrap rounded mb-0"
              >
                <thead className="thead-light">
                  <tr>
                    <th className="border-0">{t("calendar.thead_name")}</th>
                    <th className="border-0">{t("calendar.thead_dates")}</th>
                    <th className="border-0">
                      {t("calendar.thead_user_allowed_types")}
                    </th>
                    <th className="border-0 text-center">
                      {t("calendar.thead_options")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {calendare?.length === 0 ? (
                    <tr>
                      <td colSpan={4} className="text-center">
                        {t("calendar.no_previous_project_submission")}.
                      </td>
                    </tr>
                  ) : (
                    calendare?.map((element) => (
                      <tr key={element.id}>
                        <td>
                          <Card.Link href="#" className="text-primary fw-bold">
                            {element.denumire}
                          </Card.Link>
                        </td>
                        <td>
                          <b>{t("calendar.project_submission")}:</b>{" "}
                          {dayjs(element.depunere_proiecte_start).format(
                            "DD-MM-YYYY",
                          )}{" "}
                          -{" "}
                          {dayjs(element.depunere_proiecte_end).format(
                            "DD-MM-YYYY",
                          )}
                          <br></br>
                          <b>{t("calendar.project_validation")}:</b>{" "}
                          {dayjs(element.validare_proiecte_start).format(
                            "DD-MM-YYYY",
                          )}{" "}
                          -{" "}
                          {dayjs(element.validare_proiecte_end).format(
                            "DD-MM-YYYY",
                          )}{" "}
                          <br></br>
                          <b>{t("calendar.project_display")}:</b>{" "}
                          {dayjs(element.afisare_proiecte_start).format(
                            "DD-MM-YYYY",
                          )}{" "}
                          <br></br>
                          <b>{t("calendar.project_voting")}:</b>{" "}
                          {dayjs(element.vot_proiecte_start).format(
                            "DD-MM-YYYY",
                          )}{" "}
                          -{" "}
                          {dayjs(element.vot_proiecte_end).format("DD-MM-YYYY")}{" "}
                          <br></br>
                          <b>{t("calendar.winning_projects")}:</b>{" "}
                          {dayjs(element.anunt_proiecte_castigatoare).format(
                            "DD-MM-YYYY",
                          )}{" "}
                          <br></br>
                          <b>{t("calendar.project_implementation")}:</b>{" "}
                          {t("calendar.date_starting_from2")}{" "}
                          {dayjs(element.implementare_proiecte).format(
                            "DD-MM-YYYY",
                          )}
                        </td>
                        <td
                          dangerouslySetInnerHTML={{
                            __html: element.allowed_types
                              .map((type) => type + ";<br />")
                              .join(""),
                          }}
                        ></td>
                        <td className="text-center">
                          <Button
                            variant="outline-primary"
                            size="sm"
                            href={`/bugetare-participativa/proiecte-arhivate/${element.id}`}
                          >
                            {t("calendar.project_list")}
                          </Button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </>
  );
}

export default ProcesBugetare;

import React, { useEffect, useState } from "react";
import { Badge, Button, Form, Modal, ModalProps, Stack } from "react-bootstrap";
import { Trash3 } from "react-bootstrap-icons";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { MdClose } from "react-icons/md";

import { ICategory } from "../../../cjt";
import { useAPIClient } from "../../../helpers/api";
import handleException from "../../../helpers/exceptions";

interface IModalEditQuestionProps extends ModalProps {}

function ModalEditQuestion({
  showModalEditQuestion,
  setShowModalEditQuestion,
  handleClose,
  question,
  fetchCategories,
}: IModalEditQuestionProps) {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [questionContent, setQuestionContent] = useState("");
  const [answerContent, setAnswerContent] = useState("");

  const [keywords, setKeywords] = useState<string[]>([]);
  const [newKeyword, setNewKeyword] = useState<string>("");

  const addKeyword = () => {
    if (newKeyword.trim() !== "") {
      if (keywords.includes(newKeyword)) {
        toast.error("Cuvântul cheie există deja");
        return;
      }
      if (keywords.length >= 10) {
        toast.error("Nu se pot adăuga mai mult de 10 cuvinte cheie");
        return;
      }

      if (newKeyword.trim().length > 50) {
        toast.error("Cuvântul cheie nu poate avea mai mult de 50 de caractere");
        return;
      }

      if (newKeyword.trim().split(" ").length > 1) {
        toast.error("Cuvântul cheie nu poate fi format din mai multe cuvinte");
        return;
      }

      setKeywords([...keywords, newKeyword]);
      setNewKeyword("");
    } else {
      toast.error("Introduceți un cuvânt cheie!");
    }
  };

  const removeKeyword = (index: number) => {
    const updatedKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(updatedKeywords);
  };

  const fetchQuestionData = async () => {
    if (!question) return;
    try {
      const res = await client.getQuestionByid(question.id);
      setKeywords(res.keywords);
      setAnswerContent(res.answer);
      setQuestionContent(res.content);
    } catch (err) {
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchQuestionData();
  }, [question]);

  const editQuestion = async () => {
    if (questionContent === "") {
      toast.error("Introduceți întrebarea!");
      return;
    }
    if (
      questionContent == question.content &&
      JSON.stringify(keywords) === JSON.stringify(question.keywords) &&
      question.answer == answerContent
    ) {
      toast.error("Nu a fost editat nimic");
      return;
    }
    if (keywords.length === 0) {
      toast.error("Introduceți cel puțin un cuvânt cheie!");
      return;
    }
    if (answerContent === "") {
      toast.error("Introduceți răspunsul!");
      return;
    }

    try {
      const res = await client.editQuestion(
        question.id,
        questionContent,
        answerContent,
        keywords,
      );

      //await client.addAnswer(question.id, answerContent);

      setShowModalEditQuestion(false);
      toast.success("Intrebare editata cu succes");
      fetchQuestionData();
      fetchCategories();
      setQuestionContent("");
      setAnswerContent("");
      setKeywords([]);
    } catch (err) {
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };
  return (
    <Modal show={showModalEditQuestion} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{"Editeaza intrebarea"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="question" className="mt-3">
          <Form.Label>Intrebare:</Form.Label>
          <Form.Control
            type="text"
            // placeholder="Intrebare"
            value={questionContent}
            onChange={(e) => setQuestionContent(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="keyword" className="mt-3">
          <Form.Label>Adaugă cuvânt cheie:</Form.Label>
          <div className="d-flex ">
            <Form.Control
              type="text"
              // placeholder="Enter a new keyword"
              value={newKeyword}
              style={{ width: "300px", marginRight: "10px" }}
              onChange={(e) => setNewKeyword(e.target.value)}
            />
            <Button variant="primary" onClick={addKeyword}>
              Adaugă
            </Button>
          </div>
        </Form.Group>

        <Stack direction="horizontal" className="mt-3 d-flex">
          {keywords.map((keyword, index) => (
            <div className="single-tag" key={index}>
              <span>{keyword}</span>
              <i onClick={() => removeKeyword(index)}>
                <MdClose />
              </i>
            </div>
          ))}
        </Stack>
        <Form.Group controlId="question" className="mt-3">
          <Form.Label>Răspuns:</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            // placeholder="Raspuns"
            value={answerContent}
            onChange={(e) => setAnswerContent(e.target.value)}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Închide
        </Button>
        <Button variant="primary" onClick={editQuestion}>
          Editeaza
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditQuestion;

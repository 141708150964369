export const Routes = {
  // pages
  DashboardOverview: { path: "/" },
  CereriOnline: { path: "/operatiuni/forms" },
  AudienteOnline: { path: "/operatiuni/request-audience" },
  Plati: { path: "/operatiuni/plati" },
  OnlinePayments: { path: "/user-payments/online" },
  InvoicesList: { path: "/user-payments/my-invoices" },
  PaymentsList: { path: "/user-payments/my-payments" },
  ProjectList: { path: "/account/my-projects" },
  MyAccount: { path: "/account" },
  Changepassword: { path: "/account/change-password" },
  MyOnlineRequests: { path: "/account/online-requests" },
  DepunereGranturi: { path: "/finantare/depunere-proiect" },
  ListaProiecteDisponibile: { path: "/finantare/proiecte-disponibile" },
  MyWhiteboards: { path: "/whiteboard/my-whiteboards" },
  ListaProiecteDepuse: { path: "/finantare/proiecte-depuse" },
  ProcesBugetareParticipativa: { path: "/bugetare-participativa/proces" },
  DepunereProiecte: { path: "/bugetare-participativa/depunere-proiecte" },
  VotareProiecte: { path: "/bugetare-participativa/votare-proiecte" },
  Forum: { path: "/bugetare-participativa/forum" },
  Chestionare: { path: "/bugetare-participativa/chestionare" },
  TermsAndConditions: { path: "/terms" },
  PrivacyPolicy: { path: "/privacy" },

  // Admin Zone
  Admin: { path: "/admin" },
  Utilizatori: { path: "/admin/manage-users" },
  SmartForms: { path: "/admin/smart-forms" },
  CereriAudienta: { path: "/admin/hearing-requests" },
  Audiente: { path: "/admin/hearings" },
  Ocr: { path: "/admin/ocr" },
  Search: { path: "/admin/cautare" },
  Logs: { path: "/admin/logs" },
  ChatBot: { path: "/admin/chatbot" },
  ContractTemplates: { path: "/admin/contract-templates" },
  ContractList: { path: "/admin/contract-list" },
  TaxesList: { path: "/admin/taxes-list" },
  AllPaymentsList: { path: "/admin/payments-list" },
  AdminCereriOnline: { path: "/admin/online-requests" },
  SetariGenerale: { path: "/admin/settings" },
  CereriModificareDate: { path: "/admin/cereri-modificare-date" },
  AuditorList: { path: "/admin/auditor-list" },
  WhiteBoard: { path: "/whiteboard" },
  Grants: { path: "/admin/grants" },
  GrantApplications: { path: "/admin/lista-finantari-depuse" },
  GrantCategories: { path: "/admin/grant-categories" },
  DefinireCalendar: { path: "/admin/definire-calendar" },
  GestionareCategorii: { path: "/admin/definire-categorii-bugetare" },
  Sondaje: { path: "/admin/sondaje" },
  ConfigurareProiecte: { path: "/admin/configurare-proiecte" },
  VizualizareProiecte: { path: "/admin/vizualizare-proiecte" },
  Projects: { path: "/admin/project-list" },

  // components
  Accordions: { path: "/components/accordions" },
  Alerts: { path: "/components/alerts" },
  Badges: { path: "/components/badges" },
  Widgets: { path: "/widgets" },
  Breadcrumbs: { path: "/components/breadcrumbs" },
  Buttons: { path: "/components/buttons" },
  Forms: { path: "/components/forms" },
  Modals: { path: "/components/modals" },
  Navs: { path: "/components/navs" },
  Navbars: { path: "/components/navbars" },
  Pagination: { path: "/components/pagination" },
  Popovers: { path: "/components/popovers" },
  Progress: { path: "/components/progress" },
  Tables: { path: "/components/tables" },
  Tabs: { path: "/components/tabs" },
  Tooltips: { path: "/components/tooltips" },
  Toasts: { path: "/components/toasts" },
  WidgetsComponent: { path: "/components/widgets" },
};

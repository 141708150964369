import _ from "lodash";
import { useEffect, useState } from "react";
import { Card, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { ITaxes } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";
import { TableList } from "./TableList";

export default function ListAllPayments() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [taxes, setTaxes] = useState<ITaxes[]>([]);

  const fetchTaxesList = async () => {
    try {
      const res = await client.getTaxesList();
      setTaxes(res);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchTaxesList();
  }, []);

  if (taxes && taxes.length === 0) {
    return <LoadingView />;
  }

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5  text-center">{t("payments.list")}</h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                    <Card border="light" className="shadow-sm mb-4">
                      <Card.Body className="pb-0">
                        <Tab.Container defaultActiveKey="persoane-fizice">
                          <Row>
                            <Col lg={12}>
                              <Nav className="nav-tabs">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="persoane-fizice"
                                    className="mb-sm-3 mb-md-0"
                                  >
                                    {t("payments.individuals")}
                                  </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="persoane-juridice"
                                    className="mb-sm-3 mb-md-0"
                                  >
                                    {t("payments.legalPerson")}
                                  </Nav.Link>
                                </Nav.Item>
                              </Nav>
                            </Col>
                            <Col lg={12}>
                              <Tab.Content>
                                <Tab.Pane
                                  eventKey="persoane-fizice"
                                  className="py-4"
                                >
                                  <TableList personType="pf" taxes={taxes} />
                                </Tab.Pane>
                                <Tab.Pane
                                  eventKey="persoane-juridice"
                                  className="py-4"
                                >
                                  <TableList personType="pj" taxes={taxes} />
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import { createChatBotMessage } from "react-chatbot-kit";
import Categories from "./Widgets/Categories";
import Questions from "./Widgets/Questions";
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig";
import NotFound from "./Widgets/NotFound";
import CustomMessage from "./CustomMessage";

const config: IConfig = {
  initialMessages: [
    createChatBotMessage(`Salutare! Cum te pot ajuta?`, {
      widget: "categories",
    }),
  ],
  botName: "CJ Timis Bot",
  customStyles: {
    botMessageBox: {
      backgroundColor: "#446BFB",
    },
    chatButton: {
      backgroundColor: "#446BFB",
    },
  },
  customMessages: {
    custom: (props) => <CustomMessage {...props} />,
  },
  widgets: [
    {
      widgetName: "categories",
      widgetFunc: (props) => <Categories {...props} />,
      props: {},
      mapStateToProps: ["categories"],
    },
    {
      widgetName: "questions",
      widgetFunc: (props) => <Questions {...props} />,
      props: {},
      mapStateToProps: ["questions"],
    },
    {
      widgetName: "notfound",
      widgetFunc: (props) => <NotFound {...props} />,
      props: {},
      mapStateToProps: ["notfound"],
    },
  ],
};

export default config;

import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import {
  faCreditCardAlt,
  faFileWaveform,
  faKey,
  faSignOutAlt,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";
import md5 from "md5";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../context/AuthContext";
import { Routes } from "../routes";

export default function AppNavbar() {
  const { email, logout, isLoggedIn } = useAuthContext();
  const { i18n, t } = useTranslation();

  const logUserOut = () => {
    logout();
    toast.success(t("navbar_logout_message"));
  };

  const changeLanguage = () => {
    if (i18n.language === "ro") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ro");
      localStorage.setItem("language", "ro");
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            {/* <Form className="navbar-search">
              <Form.Group id="topbarSearch">
                <InputGroup className="input-group-merge search-bar">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control type="text" placeholder="Search" />
                </InputGroup>
              </Form.Group>
            </Form> */}
          </div>
          <Nav className="align-items-center">
            <Nav.Link
              style={{ fontWeight: "bold", color: "black" }}
              onClick={() => changeLanguage()}
            >
              {i18n.language === "ro" ? "🇷🇴 RO" : "🇬🇧 EN"}
            </Nav.Link>
            {isLoggedIn && (
              <Dropdown as={Nav.Item}>
                <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                  <div className="media d-flex align-items-center">
                    <Image
                      src={`https://www.gravatar.com/avatar/${md5(
                        `${email}`,
                      )}?size=160`}
                      className="user-avatar md-avatar rounded-circle"
                    />
                    <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                      <span className="mb-0 font-small fw-bold">
                        {t("navbar_my_account_label")}
                      </span>
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
                  <Dropdown.Item
                    className="fw-bold"
                    href={Routes.MyAccount.path}
                  >
                    <FontAwesomeIcon icon={faUserCircle} className="me-2" />{" "}
                    {t("account_my_profile")}
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="fw-bold"
                    href={Routes.Changepassword.path}
                  >
                    <FontAwesomeIcon icon={faKey} className="me-2" />
                    {t("navbar_change_password")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold"
                    href={Routes.PaymentsList.path}
                  >
                    <FontAwesomeIcon icon={faCreditCardAlt} className="me-2" />
                    {t("navbar_payments_history")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold"
                    href={Routes.MyOnlineRequests.path}
                  >
                    <FontAwesomeIcon icon={faFileWaveform} className="me-2" />{" "}
                    {t("online_requests")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="fw-bold"
                    href="/tutorial_utilizatori.pdf"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faUserShield} className="me-2" />{" "}
                    {t("support")}
                  </Dropdown.Item>

                  <Dropdown.Divider />

                  <Dropdown.Item className="fw-bold" onClick={logUserOut}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="text-danger me-2"
                    />{" "}
                    {t("logout")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </div>
      </Container>
    </Navbar>
  );
}

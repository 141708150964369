import {
  faCloudUploadAlt,
  faExternalLinkAlt,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { IBusinessRequest } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

const ApproveBusiness = () => {
  const client = useAPIClient();
  const { t } = useTranslation();
  const { id } = useParams();
  const [request, setRequest] = useState<IBusinessRequest | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [showConfirmReject, setShowConfirmReject] = useState(false);
  const [reason, setReason] = useState("");

  const fetchUserData = useCallback(async () => {
    try {
      const res = await client.getBusinessRequestById(id!);

      setRequest(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  }, [isLoading]);

  useEffect(() => {
    if (id === undefined) {
      return;
    }

    fetchUserData();
  }, [fetchUserData]);

  if (id === undefined) {
    return <h1>{t("request.404")}</h1>;
  }

  if (isLoading || request == null) return <LoadingView />;

  const handleApproveProfileModification = async () => {
    setIsDisabled(true);
    try {
      await client.approveBusinessRequest(id!);
      toast.success(t("request.success_approved"));
      toast.success(t("request.success_redirect"));
      setTimeout(() => {
        window.location.assign("/admin/cereri-modificare-date");
      }, 2000);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsDisabled(false);
    }
  };

  const handleRejectProfileModification = async () => {
    setIsDisabled(true);
    try {
      await client.rejectBusinessRequest(id!, reason);

      toast.success(t("request.success_reject"));
      toast.success(t("request.success_redirect"));
      setTimeout(() => {
        window.location.assign("/admin/cereri-modificare-date");
      }, 2000);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsDisabled(false);
    }
  };

  return (
    <Container className="px-0 mt-4">
      <h2 className="fw-bold mb-5 text-uppercase text-center">
        {t("request.view")}
      </h2>
      <div className="align-items-center py-4">
        {request?.status === "Pending" && (
          <>
            {request?.duplicateWarning && (
              <>
                <FontAwesomeIcon icon={faWarning} color="red"></FontAwesomeIcon>
                <Form.Label className="mx-2 mt-2">
                  {t("request.registered_CUI")}
                </Form.Label>
                <br></br>
              </>
            )}
            <ButtonGroup>
              <Button
                variant="outline-success"
                size="sm"
                onClick={handleApproveProfileModification}
                disabled={isDisabled}
              >
                {t("request.approve")}
              </Button>
              <Button
                variant="outline-danger"
                size="sm"
                onClick={() => setShowConfirmReject(true)}
                disabled={isDisabled}
              >
                {t("request.reject")}
              </Button>
            </ButtonGroup>
          </>
        )}
      </div>

      <Row>
        <Col xs={12} lg={6}>
          <Row>
            <Col xs={12} lg={12} className="mb-4">
              <Card border="light" className="shadow-sm">
                <Card.Header className="border-bottom border-light d-flex justify-content-between">
                  <h5 className="mb-0">{t("request.data")}</h5>
                </Card.Header>
                <Card.Body>
                  {!isLoading && request.user && (
                    <Form id="dateSolicitant">
                      <fieldset disabled={true}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t("request.applicant")}</Form.Label>
                          <Form.Control
                            id="numesolicitant"
                            name="numesolicitant"
                            type="text"
                            value={
                              request.user.profile.nume +
                              " " +
                              request.user.profile.prenume
                            }
                          />
                          <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("request.cnp")}</Form.Label>
                          <Form.Control
                            id="cnp"
                            name="cnp"
                            type="text"
                            value={request.user.profile.cnp}
                          />
                          <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("request.email")}</Form.Label>
                          <Form.Control
                            id="email"
                            name="email"
                            type="text"
                            value={request.user.email}
                          />
                          <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>{t("request.phone")}</Form.Label>
                          <Form.Control
                            id="telefon"
                            name="telefon"
                            type="text"
                            value={request.user.profile.telefon}
                          />
                          <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                        </Form.Group>
                      </fieldset>
                    </Form>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={12}>
              <Card border="light" className="shadow-sm">
                <Card.Header className="border-bottom border-light d-flex align-items-center">
                  <FontAwesomeIcon icon={faCloudUploadAlt} className="me-2" />
                  <h5 className="mb-0">{t("request.attached")}</h5>
                </Card.Header>
                <Card.Body>
                  <Card border="light" className="shadow-sm">
                    <Card.Body className="p-0">
                      <Table
                        responsive
                        className="table-centered rounded"
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        <thead className="thead-light">
                          <tr>
                            <th className="border-0" style={{ width: "30%" }}>
                            {t("profile_business_name")}
                            </th>
                            <th className="border-0" style={{ width: "40%" }}>
                            {t("file")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {request?.documentId != null ? (
                            <tr>
                              <td
                                className="fw-bold border-0"
                                style={{ width: "30%" }}
                              >
                                {t("finish_profile_document_label")}
                              </td>
                              <td className="border-0" style={{ width: "40%" }}>
                                <pre>
                                  <Card.Link
                                    onClick={() =>
                                      client.getBusinessRequestDocument(
                                        request.id,
                                      )
                                    }
                                    target="_blank"
                                  >
                                    {t("download_file")}{" "}
                                    <FontAwesomeIcon
                                      icon={faExternalLinkAlt}
                                      className="ms-1"
                                    />
                                  </Card.Link>
                                </pre>
                              </td>
                            </tr>
                          ) : (
                            <tr>
                              <td>
                                <h4>{t("request.nodoc")}</h4>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col xs={12} lg={6}>
          <Card border="light" className="shadow-sm ">
            <Card.Header className="border-bottom border-light d-flex justify-content-between">
              <h5 className="mb-0">{t("request.business_data")}</h5>
            </Card.Header>
            <Card.Body>
              {!isLoading && request.user && (
                <Form id="addBusinessForm">
                  <fieldset disabled={true}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.name")}</Form.Label>
                      <Form.Control
                        id="denumire"
                        name="denumire"
                        type="text"
                        value={request.denumire}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.cui")}</Form.Label>
                      <Form.Control
                        id="cui"
                        name="cui"
                        type="text"
                        value={request.cui}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.country")}</Form.Label>
                      <Form.Control id="tara" value={request.tara || "RO"} />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.county")}</Form.Label>
                      <Form.Control
                        id="judet"
                        type="text"
                        value={request.judet}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.region")}</Form.Label>
                      <Form.Control
                        id="localitate"
                        type="text"
                        value={request.localitate}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.street")}</Form.Label>
                      <Form.Control
                        id="strada"
                        type="text"
                        value={request.strada}
                      />
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.code")}</Form.Label>
                      <Form.Control
                        id="cod_postal"
                        type="text"
                        value={request.cod_postal}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("pj_form.type")}</Form.Label>
                      <Form.Control id="tip" value={request.tip} />
                    </Form.Group>
                  </fieldset>
                </Form>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={showConfirmReject}
        onHide={() => setShowConfirmReject(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("request.reject_req")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mt-2">{t("request.reason")}</p>
          <textarea
            id="reasonInput"
            name="reason"
            value={reason}
            onChange={({ target: { value } }) => setReason(value)}
            className="form-control"
          />
        </Modal.Body>

        <Modal.Footer>
          {!isDisabled ? (
            <>
              <Button
                variant="danger"
                onClick={handleRejectProfileModification}
              >
                {t("request.confirm_rejection")}
              </Button>
            </>
          ) : (
            <LoadingView />
          )}
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ApproveBusiness;

export enum AccountAttribute {
  UserAdministrator = "UserAdmin",
  FormsAdministrator = "FormsAdmin",
  HearingAdministrator = "HearingAdmin",
  ChatbotAdministrator = "ChatbotAdmin",
  LogsAdministrator = "LogsAdministrator",
  OcrAdministrator = "OcrAdministrator",
  WhiteboardAdministrator = "WhiteboardAdministrator",
  DocumentTemplateAdministrator = "DocumentTemplateAdministrator",
  ContractsAdministrator = "ContractsAdministrator",
  TaxesAdministrator = "TaxesAdministrator",
  ProjectsAdministrator = "ProjectsAdministrator",
  OpenGovernmentAdministrator = "OpenGovernmentAdministrator",
  GrantsAdministrator = "GrantsAdministrator",
  GrantsEvaluator = "GrantsEvaluator",
  DemoAccount = "DemoAccount",
  OpenGovernmentForumAdministrator = "OpenGovernmentForumAdministrator",
  SearchAdministrator = "SearchAdministrator",
}

export enum AttributeDisplayName {
  UserAdmin = "Administrator utilizatori",
  FormsAdmin = "Administrator formulare",
  HearingAdmin = "Administrator audiente",
  ChatbotAdmin = "Administrator chatbot",
  LogsAdministrator = "Administrator log-uri",
  OcrAdministrator = "Administrator OCR",
  WhiteboardAdministrator = "Administrator Whiteboard",
  DocumentTemplateAdministrator = "Administrator Template-uri Documente",
  ContractsAdministrator = "Administrator Contracte",
  TaxesAdministrator = "Administrator Taxe",
  ProjectsAdministrator = "Administrator Proiecte",
  GrantsAdministrator = "Administrator Finantare",
  OpenGovernmentAdministrator = "Administrator Guvernare Participativă",
  GrantsEvaluator = "Evaluator Finantare",
  DemoAccount = "Cont Demo",
  OpenGovernmentForumAdministrator = "Administrator forum Guvernare Participativă",
  SearchAdministrator = "Administrator căutare avansată",
}
export enum ProfileRequestStatus {
  PENDING = "Pending",
  APPROVED = "Approved",
  REJECTED = "Rejected",
}

export enum ActivateOptions {
  Activated = "activated",
  Deactivated = "deactivated",
}

export enum BusinessType {
  ONG = "ONG",
  Universitate = "Universitate",
  AutoritatePublicaLocala = "Autoritate Publica Locala",
  AltaInstitutiePublica = "Alta Institutie Publica",
  InstitutieSubordonataCJT = "Institutie Subordonata CJT",
  SRL = "SRL",
  PFA = "PFA",
}

export enum FilterGrantProjectSubmissionStatus {
  PENDING = "pending",
  EVALUATED = "evaluated",
  APPROVED = "approved",
  REJECTED = "rejected",
  ANY = "any",
}

export type EnumMap<T extends string | symbol | number, U> = {
  [K in T]: U;
};

export enum FormUsage {
  Forms = "Forms",
  GuvernareDeschisa = "Guvernare Deschisa",
  //Insert Guvernare deschisa here
}

// GuvernareDeschisa dosar status
export enum DosarStatus {
  Approved = "aprobat",
  Denied = "respins",
  Waiting = "in asteptare",
  Solved = "solutionat",
  Funded = "finantat",
}

export enum WhiteboardPermissions {
  DeleteFile = "DeleteFile",
  UploadFile = "UploadFile",
}

export enum EModules {
  Contracts = "Contracts",
  Projects = "Projects",
  OpenGovernment = "OpenGovernment",
  Grants = "Grants",
}

export enum RequestStatus {
  Received = "TRANSMIS",
  Processing = "PROCESARE",
  Denied = "RESPINS",
  Solved = "SOLUTIONAT",
  ManuallyRegistered = "INREGISTRAT",
}

import React, { useEffect } from "react";
import { Accordion, Stack, Badge } from "react-bootstrap";
import { ICategory, IQuestion } from "../../cjt";
import { Trash3Fill } from "react-bootstrap-icons";
import { MdEdit } from "react-icons/md";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@themesberg/react-bootstrap";

type QuestionListProps = {
  questions: IQuestion[];
  setShowConfirmDeletion: (show: boolean) => void;
  setShowEdit: (show: boolean) => void;
  setQuestionToDelete: (question: IQuestion) => void;
  setQuestionToEdit: (question: IQuestion) => void;
};

const QuestionList = ({
  questions,
  setShowConfirmDeletion,
  setShowEdit,
  setQuestionToDelete,
  setQuestionToEdit,
}: QuestionListProps) => {
  useEffect(() => {}, [questions]);
  return (
    <Accordion>
      {questions.map((question: IQuestion, index: number) => (
        <React.Fragment key={question.id}>
          <Accordion.Item
            eventKey={question.id.toString()}
            style={{
              marginTop: "5px",
              borderBottom: "1px solid #dee2e6",
              borderBottomLeftRadius: "0.5rem",
              borderBottomRightRadius: "0.5rem",
              borderBottomWidth: index !== questions.length - 1 ? "1px" : "0",
            }}
          >
            <Accordion.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <span>{question.content}</span>
                <span>
                  <span
                    role="button"
                    className="btn btn-danger btn-sm"
                    onClick={(event) => {
                      event.stopPropagation();
                      setQuestionToDelete(question);
                      setShowConfirmDeletion(true);
                    }}
                  >
                    <Trash3Fill />
                  </span>
                  <Button
                    className="mr-2"
                    style={{marginLeft: '10px'}}
                    variant="primary"
                    size="sm"
                    onClick={(event) => {
                      event.stopPropagation();
                      setQuestionToEdit(question);
                      setShowEdit(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {question.answer && (
                <>
                  <Stack direction="horizontal" className="mb-3 d-flex">
                    {question.keywords.map((keyword, index) => (
                      <div className="single-tag" key={index}>
                          <span>{keyword}</span>
                      </div>
                    ))}
                  </Stack>
                  <p style={{ whiteSpace: "pre-line" }}>{question.answer}</p>
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

type CategoryListProps = {
  categories: ICategory[];
  setShowConfirmDeletion: (show: boolean) => void;
  setShowEdit: (show: boolean) => void;
  setCategoryToDelete: (category: ICategory) => void;
  setQuestionToDelete: (question: IQuestion) => void;
  setQuestionToEdit: (question: IQuestion) => void;
};

const CategoryList = ({
  categories,
  setShowConfirmDeletion,
  setShowEdit,
  setCategoryToDelete,
  setQuestionToDelete,
  setQuestionToEdit,
}: CategoryListProps) => {
  return (
    <Accordion>
      {categories.map((category: ICategory) => (
        <React.Fragment key={category.id}>
          <Accordion.Item eventKey={category.id.toString()}>
            <Accordion.Header>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "95%",
                }}
              >
                <span>{category.name}</span>
                <span
                  role="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    setCategoryToDelete(category);
                    setShowConfirmDeletion(true);
                  }}
                >
                  <Trash3Fill />
                </span>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {category.questions && category.questions.length !== 0 ? (
                <QuestionList
                  questions={category.questions}
                  setQuestionToEdit={setQuestionToEdit}
                  setShowConfirmDeletion={setShowConfirmDeletion}
                  setQuestionToDelete={setQuestionToDelete}
                  setShowEdit={setShowEdit}
                />
              ) : (
                <p>Nu sunt intrebari introduse la aceasta categorie!</p>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </React.Fragment>
      ))}
    </Accordion>
  );
};

export default CategoryList;

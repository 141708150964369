import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function PaymentConfirmation() {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const paymentStatus = queryParams.get("status");
  const error = queryParams.get("error");

  return (
    <>
      <Container>
        <Row>
          <Col xs={12} className="p-3">
            <Card>
              <Card.Body style={{ textAlign: "center" }}>
                {paymentStatus === "0" ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      color="green"
                      size="2xl"
                      icon={faCircleCheck}
                    />
                    <span style={{ fontSize: "1.8rem", marginLeft: "10px" }}>
                      {t("payment_confirmation.success")}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <FontAwesomeIcon
                      color="red"
                      size="xl"
                      icon={faCircleXmark}
                    />
                    <span style={{ fontSize: "1.8rem", marginLeft: "10px" }}>
                      {error && error === "1"
                        ? t("payment_confirmation.allready_made")
                        : t("payment_confirmation.failed")}
                    </span>
                  </div>
                )}
                <Button
                  variant={paymentStatus === "0" ? "success" : "primary"}
                  href="/user-payments/my-payments"
                  //   size="sm"
                  style={{ marginTop: "20px" }}
                >
                  {t("view_payments_list")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

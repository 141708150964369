import { useEffect, useState } from "react";
import "react-chatbot-kit/build/main.css";
import { Route, Routes } from "react-router-dom";

import "./App.scss";
import { useAuthContext } from "./context/AuthContext";
import { AccountAttribute } from "./enums";
import { useAPIClient } from "./helpers/api";
import i18n from "./i18n";
import Layout from "./layout/Layout";
import LoadingView from "./layout/LoadingView";
import PageNotFound from "./layout/NotFound";
import ProtectedAdminZone from "./layout/ProtectedAdminZone";
import ProtectedRoute from "./layout/ProtectedRoute";
import Overlay from "./layout/overlay";
import Account from "./modules/Account/Account";
import ApproveBusiness from "./modules/Account/ApproveBusiness";
import ChangePassword from "./modules/Account/ChangePassword";
import UserOnlineRequestById from "./modules/Account/UserOnlineRequestById";
import UserOnlineRequests from "./modules/Account/UserOnlineRequests";
import AdminPanel from "./modules/AdminPanel/AdminPanel";
import AdminSettings from "./modules/AdminSettings/Settings";
import ConfigurareProiecte from "./modules/BugetareParticipativa/Admin/ConfigurareProiecte";
import DefinireCalendar from "./modules/BugetareParticipativa/Admin/DefinireCalendar";
import ProiectCategoryScreen from "./modules/BugetareParticipativa/Admin/ProiectCategoryScreen";
import Sondaje from "./modules/BugetareParticipativa/Admin/Sondaje";
import SurveyResults from "./modules/BugetareParticipativa/Admin/SurveyResults";
import VizualizareProiecte from "./modules/BugetareParticipativa/Admin/VizualizareProiecte";
import VizualizareProiecteDetail from "./modules/BugetareParticipativa/Admin/VizualizareProiecteDetail";
import Chestionare from "./modules/BugetareParticipativa/User/Chestionare";
import DepunereProiecte from "./modules/BugetareParticipativa/User/DepunereProiecte";
import Forum from "./modules/BugetareParticipativa/User/Forum";
import ForumCategoryScreen from "./modules/BugetareParticipativa/User/ForumCategoryScreen";
import Forum_create from "./modules/BugetareParticipativa/User/Forum_create";
import Forum_detail from "./modules/BugetareParticipativa/User/Forum_detail";
import ProcesBugetare from "./modules/BugetareParticipativa/User/ProcesBugetare";
import ProiectVotareDetalii from "./modules/BugetareParticipativa/User/ProiectVotareDetalii";
import ProiecteArhivate from "./modules/BugetareParticipativa/User/ProiecteArhivate";
import TakeSurvey from "./modules/BugetareParticipativa/User/TakeSurvey";
import VizualizareDepunereDetail from "./modules/BugetareParticipativa/User/VizualizareDepunereDetail";
import VotareProiecte from "./modules/BugetareParticipativa/User/VotareProiecte";
import ChangeDataRequestDetails from "./modules/ChangeUserDataRequests/RequestDetails";
import ListChangeUserDataRequests from "./modules/ChangeUserDataRequests/Requests";
import ManageChatBot from "./modules/Chatbot/ManageChatBot";
import ContractDetails from "./modules/Contracts/ContractDetails";
import ContractList from "./modules/Contracts/ListContracts";
import ListContractTemplates from "./modules/Contracts/ListTemplates";
import EmailConfirmed from "./modules/EmailConfirmation/ConfirmEmail";
import ApplyForGrant from "./modules/Grant/ApplyForGrant";
import GrantApplication from "./modules/Grant/GrantApplication";
import GrantDetails from "./modules/Grant/GrantDetails";
import ListAppliedGrants from "./modules/Grant/ListAppliedGrants";
import ListGrantCategories from "./modules/Grant/ListGrantCategories";
import ListGrants from "./modules/Grant/ListGrants";
import ListPublishedGrants from "./modules/Grant/ListPublishedGrants";
import ManageGrantApplication from "./modules/Grant/ManageGrantApplication";
import ManageGrantApplications from "./modules/Grant/ManageGrantApplications";
import AllHearingRequests from "./modules/Hearings/AllHearingRequests";
import AllScheduledHearings from "./modules/Hearings/AllScheduledHearings";
import AuditorList from "./modules/Hearings/AuditorList";
import HearingRequest from "./modules/Hearings/HearingRequest";
import RequestHearing from "./modules/Hearings/RequestHearing";
import ScheduledHearing from "./modules/Hearings/ScheduledHearing";
import Dashboard from "./modules/Home/Dashboard";
import Login from "./modules/Login/Login";
import ResendLink from "./modules/Login/ResendActivationLink";
import ResetPassword from "./modules/Login/ResetPassword";
import RequestLogs from "./modules/Logs/RequestLogs";
import RequestOcr from "./modules/Ocr/RequestOcr";
import ListOnlineRequests from "./modules/OnlineRequests/ListOnlineRequests";
import ViewOnlineRequestById from "./modules/OnlineRequests/ViewOnlineRequestById";
import AllPayments from "./modules/Payments/AllPayments";
import ListAllPayments from "./modules/Payments/ListAllPayments";
import ListUserInvoices from "./modules/Payments/ListUserInvoices";
import ListUserPayments from "./modules/Payments/ListUserpayments";
import PaymentConfirmation from "./modules/Payments/PaymentConfirmation";
import ListProjects from "./modules/Projects/Admin/ListProjects";
import ProjectDetails from "./modules/Projects/ProjectDetails";
import ListUserProjects from "./modules/Projects/User/ListUserProjects";
import AccountCreated from "./modules/Register/AccountCreated";
import Register from "./modules/Register/Register";
import ResendLinkSucces from "./modules/Register/ResendLinkSucces";
import Search from "./modules/Search/Search";
import CompleteForm from "./modules/SmartForms/CompleteForm";
import EditSmartForm from "./modules/SmartForms/EditSmartForm";
import SmartForms from "./modules/SmartForms/SmartForms";
import SubmitForm from "./modules/SmartForms/SubmitForm";
import ListTaxes from "./modules/Taxes/ListTaxes";
import PrivacyPolicy from "./modules/TermsConditions/Privacy/PrivacyPolicy";
import TermsAndConditions from "./modules/TermsConditions/Terms/Terms";
import ManageUser from "./modules/Users/ManageUser";
import ManageUsers from "./modules/Users/ManageUsers";
import WhiteboardsList from "./modules/Whiteboard";
import UserWhiteboards from "./modules/Whiteboard/UserWhiteboards";
import WhiteboardRedirectWithToken from "./modules/Whiteboard/WhiteboardRedirectWithToken";
import WhiteboardSession from "./modules/Whiteboard/WhiteboardSession";
import { Routes as RoutesEnum } from "./routes";

export default function App() {
  const { requiredAttribute, showModule } = useAuthContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadTranslations = async () => {
      try {
        setIsLoading(true);
        const client = useAPIClient();

        const translationData = await client.getTranslations();

        const resources = translationData;
        console.log(resources);

        i18n.addResourceBundle("ro", "translation", resources.ro);
        i18n.addResourceBundle("en", "translation", resources.en);
        i18n.setDefaultNamespace("translation");
        i18n.reloadResources();

        if (!localStorage.getItem("userProfile")) await client.getUserFeed();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    loadTranslations();
  }, []);

  if (isLoading) return <LoadingView></LoadingView>;
  return (
    <div className="App">
      <Overlay>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/operatiuni/forms" element={<SubmitForm />} />
              <Route path="/operatiuni/plati" element={<AllPayments />} />
              <Route path="/user-payments/online" element={<AllPayments />} />
              <Route path="/account" element={<Account />} />
              <Route
                path="/account/online-requests"
                element={<UserOnlineRequests />}
              />
              <Route
                path="/account/online-requests/:id"
                element={<UserOnlineRequestById />}
              />
              <Route path="/account/contracts" element={<ContractList />} />
              <Route
                path="/account/contract-details/:id"
                element={<ContractDetails />}
              />
              <Route
                path={RoutesEnum.ProjectList.path}
                element={<ListUserProjects />}
              />

              <Route
                path={"/account/project-details/:id"}
                element={<ProjectDetails />}
              />

              <Route
                path="/operatiuni/submit-form/:id"
                element={<CompleteForm />}
              />
              <Route
                path="account/change-password"
                element={<ChangePassword />}
              />
              <Route
                path="user-payments/my-payments"
                element={<ListUserPayments />}
              />
              <Route
                path="user-payments/my-invoices"
                element={<ListUserInvoices />}
              />
              <Route
                path="/operatiuni/request-audience"
                element={<RequestHearing />}
              />
              <Route
                path="/payment-confirmation"
                element={<PaymentConfirmation />}
              />
              <Route
                path="/finantare/proiecte-disponibile"
                element={<ListPublishedGrants />}
              />
              <Route
                path="/finantare/proiecte-depuse"
                element={<ListAppliedGrants />}
              />
              <Route
                path="/finantare/depunere-proiect/:id/:businessId"
                element={<ApplyForGrant />}
              />
              <Route
                path="/finantare/proiecte-depuse/:id"
                element={<GrantApplication />}
              />
              <Route
                path="/bugetare-participativa/proces"
                element={<ProcesBugetare />}
              />
              <Route
                path="/whiteboard-redirect"
                element={<WhiteboardRedirectWithToken />}
              />
              <Route
                path="/bugetare-participativa/depunere-proiecte"
                element={<DepunereProiecte />}
              />
              <Route
                path="/bugetare-participativa/votare-proiecte"
                element={<VotareProiecte />}
              />
              <Route path="/bugetare-participativa/forum" element={<Forum />} />
              <Route
                path="/bugetare-participativa/topic/:id"
                element={<Forum_detail />}
              />
              <Route
                path="/bugetare-participativa/chestionare"
                element={<Chestionare />}
              />
              <Route
                path="/bugetare-participativa/detalii-proiect/:id"
                element={<ProiectVotareDetalii />}
              />
              <Route
                path="/bugetare-participativa/proiecte-arhivate/:id"
                element={<ProiecteArhivate />}
              />
              <Route
                path="/bugetare-participativa/vizualizare-detalii-proiect/:id"
                element={<VizualizareDepunereDetail />}
              />
              <Route
                path="/bugetare-participativa/completeaza-chestionar/:id"
                element={<TakeSurvey />}
              />
              <Route
                path="/whiteboard/my-whiteboards"
                element={<UserWhiteboards />}
              />
              <Route
                path="/whiteboard/session"
                element={<WhiteboardSession />}
              />

              <Route path="*" element={<PageNotFound />} />
            </Route>

            <Route element={<ProtectedAdminZone />}>
              <Route path="/admin" element={<AdminPanel />} />

              {requiredAttribute(AccountAttribute.UserAdministrator) && (
                <>
                  <Route path="/admin/manage-users" element={<ManageUsers />} />
                  <Route
                    path="/admin/cereri-modificare-date"
                    element={<ListChangeUserDataRequests />}
                  />
                  <Route path="/admin/user/:email" element={<ManageUser />} />
                  <Route
                    path="/admin/vizualizare-cerere/:id"
                    element={<ChangeDataRequestDetails />}
                  />
                  <Route
                    path="/admin/vizualizare-business/:id"
                    element={<ApproveBusiness />}
                  />
                </>
              )}

              {requiredAttribute(AccountAttribute.FormsAdministrator) && (
                <>
                  <Route
                    path="/admin/online-requests"
                    element={<ListOnlineRequests />}
                  />
                  <Route
                    path="/admin/online-requests/:id"
                    element={<ViewOnlineRequestById />}
                  />
                  <Route path="/admin/smart-forms" element={<SmartForms />} />
                  <Route path="/admin/edit-form" element={<EditSmartForm />} />
                </>
              )}

              {requiredAttribute(AccountAttribute.HearingAdministrator) && (
                <>
                  <Route
                    path="/admin/hearing-requests"
                    element={<AllHearingRequests />}
                  />
                  <Route
                    path="/admin/hearing-request"
                    element={<HearingRequest />}
                  />
                  <Route
                    path="/admin/hearings"
                    element={<AllScheduledHearings />}
                  />
                  <Route path="/admin/hearing" element={<ScheduledHearing />} />
                  <Route path="/admin/auditor-list" element={<AuditorList />} />
                </>
              )}

              {requiredAttribute(AccountAttribute.SearchAdministrator) && (
                <Route path="/admin/cautare" element={<Search />} />
              )}

              {requiredAttribute(AccountAttribute.LogsAdministrator) && (
                <Route path="/admin/logs" element={<RequestLogs />} />
              )}

              {requiredAttribute(AccountAttribute.ChatbotAdministrator) && (
                <Route path="/admin/chatbot" element={<ManageChatBot />} />
              )}

              {requiredAttribute(
                AccountAttribute.DocumentTemplateAdministrator,
              ) && (
                <Route
                  path="/admin/contract-templates"
                  element={<ListContractTemplates />}
                />
              )}
              {requiredAttribute(AccountAttribute.ContractsAdministrator) && (
                <>
                  <Route
                    path="/admin/contract-list"
                    element={<ContractList />}
                  />

                  <Route
                    path="/admin/contract-details/:id"
                    element={<ContractDetails />}
                  />
                </>
              )}
              {requiredAttribute(AccountAttribute.ProjectsAdministrator) && (
                <>
                  <Route
                    path="/admin/project-list"
                    element={<ListProjects />}
                  />
                  <Route
                    path="/admin/project-details/:id"
                    element={<ProjectDetails />}
                  />
                </>
              )}
              {requiredAttribute(AccountAttribute.TaxesAdministrator) && (
                <>
                  <Route path="/admin/taxes-list" element={<ListTaxes />} />
                  <Route
                    path="/admin/payments-list"
                    element={<ListAllPayments />}
                  />
                </>
              )}

              {requiredAttribute(AccountAttribute.WhiteboardAdministrator) && (
                <>
                  <Route path="/whiteboard" element={<WhiteboardsList />} />
                </>
              )}

              {(requiredAttribute(AccountAttribute.GrantsAdministrator) ||
                requiredAttribute(AccountAttribute.GrantsEvaluator)) && (
                <>
                  {requiredAttribute(AccountAttribute.GrantsAdministrator) && (
                    <Route path="/admin/grants" element={<ListGrants />} />
                  )}
                  <Route
                    path="/admin/lista-finantari-depuse"
                    element={<ManageGrantApplications />}
                  />
                  <Route
                    path="/admin/proiecte-depuse/:id"
                    element={<ManageGrantApplication />}
                  />
                  {requiredAttribute(AccountAttribute.GrantsAdministrator) && (
                    <Route
                      path="/admin/grant-categories"
                      element={<ListGrantCategories />}
                    />
                  )}
                  {requiredAttribute(AccountAttribute.GrantsAdministrator) && (
                    <Route
                      path="/admin/grants/:id"
                      element={<GrantDetails />}
                    />
                  )}
                </>
              )}

              {requiredAttribute(
                AccountAttribute.OpenGovernmentAdministrator,
              ) && (
                <>
                  <Route
                    path="/admin/definire-calendar"
                    element={<DefinireCalendar />}
                  />
                  <Route
                    path="/admin/configurare-proiecte"
                    element={<ConfigurareProiecte />}
                  />
                  <Route
                    path="/admin/vizualizare-proiecte"
                    element={<VizualizareProiecte />}
                  />
                  <Route path="/admin/sondaje" element={<Sondaje />} />
                  <Route
                    path="/admin/sondaje-rezultate/:id"
                    element={<SurveyResults />}
                  />
                  <Route
                    path="/admin/vizualizare-detalii-proiecte/:id"
                    element={<VizualizareProiecteDetail />}
                  />
                  <Route
                    path="/admin/definire-categorii-bugetare"
                    element={<ProiectCategoryScreen />}
                  />
                </>
              )}

              {requiredAttribute(
                AccountAttribute.OpenGovernmentForumAdministrator,
              ) && (
                <>
                  <Route
                    path="/admin/creare-topic"
                    element={<Forum_create />}
                  />

                  <Route
                    path="/admin/forum/administrare-categorii"
                    element={<ForumCategoryScreen />}
                  />
                </>
              )}

              {/* TODO - where attribute (meme) */}
              <Route path="/admin/settings" element={<AdminSettings />} />
            </Route>
          </Route>
          {/* <Route element={<Layout />}> */}
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          {/* </Route> */}
          <Route path="/login" element={<Login />} />
          <Route path="/resend-link" element={<ResendLink />} />
          <Route path="/resend-link-succes" element={<ResendLinkSucces />} />
          <Route path="/register" element={<Register />} />
          <Route path="/account-created" element={<AccountCreated />} />
          <Route path="/confirm-email" element={<EmailConfirmed />} />
          <Route
            path={RoutesEnum.TermsAndConditions.path}
            element={<TermsAndConditions />}
          ></Route>
          <Route
            path={RoutesEnum.PrivacyPolicy.path}
            element={<PrivacyPolicy />}
          ></Route>
        </Routes>
      </Overlay>
    </div>
  );
}

import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import ReactJson from "react-json-view";
import LoadingView from "../../layout/LoadingView";
import { Log } from "../../cjt";
import { useTranslation } from "react-i18next";
import { useAPIClient } from "../../helpers/api";
import { Container } from "@themesberg/react-bootstrap";
import handleException from "../../helpers/exceptions";
import { toast } from "react-hot-toast";

const RequestLogs = () => {
  const client = useAPIClient();
  const { t }: { t: any } = useTranslation<any>();
  const [logs, setLogs] = useState<Log[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const currentPage = useRef<number>(0);

  const fetchMoreLogs = async () => {
    try {
      const { data } = await client.fetchLogs(currentPage.current, 50);
      console.log(currentPage.current);
      console.log(data);

      if (data.length > 0) {
        currentPage.current = currentPage.current + 1;
        setLogs((prevState) => [...prevState, ...data]);
      }
    } catch (error) {
      // console.error(error);
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setIsFetching(false);
    }
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight ||
      isFetching
    )
      return;

    setIsFetching(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    fetchMoreLogs();
  }, []);

  useEffect(() => {
    if (!isFetching) return;

    fetchMoreLogs();
  }, [isFetching]);

  const formatBody = (body: string) => {
    if (body === "") {
      return "Nimic de afisat";
    }
    try {
      const parsedBody = JSON.parse(body);
      return (
        <ReactJson src={parsedBody} theme="rjv-default" collapsed={true} />
      );
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return body;
    }
  };

  return (
    <Container fluid={true}>
      <h1 style={{ marginBottom: "1em" }}>{t("logs")}</h1>
      <table
        style={{
          borderCollapse: "collapse",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>User</th>
            <th>Method</th>
            <th>URL</th>
            <th>Body</th>
          </tr>
        </thead>
        <tbody>
          {logs.map((log) => (
            <tr key={log.id}>
              <td>{dayjs(log.timestamp).format("YYYY-MM-DD HH:mm:ss")}</td>
              <td>{log.user}</td>
              <td>{log.method}</td>
              <td>{log.url}</td>
              <td>{formatBody(log.body)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {isFetching && <LoadingView />}
    </Container>
  );
};

export default RequestLogs;

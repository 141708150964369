import { Button, Col, Form, Row } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

import "../../assets/css/PfForm.css";
import { IProfile } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import { default as countries } from "../../helpers/countries.json";
import handleException from "../../helpers/exceptions";
import { default as judete } from "../../helpers/lista_judete.json";
import { default as localitati } from "../../helpers/localitati_simple.json";
import {
  validateCNP,
  validateEmail,
  validateName,
  validateRoPhone,
  validateRoPostalCode,
} from "../../helpers/validators";

interface Props {
  userProfile: IProfile;
  email: string;
  t: (arg: string) => string;
  setActiveTab: (tab: string) => void;
  canModify?: boolean;
}
export const PfForm = ({
  userProfile,
  email,
  t,
  setActiveTab,
  canModify = true,
}: Props) => {
  const [userUpdates, setUserUpdates] = useState({
    ...userProfile,
    email: email,
    document: "",
  });
  const client = useAPIClient();
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [edit, setEdit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const countriesData: { [countryCode: string]: string } = countries;

  const validate = () => {
    let valid = true;
    const _errors: { [key: string]: any } = {};

    if (!userUpdates.nume.trim()) {
      _errors.nume = t("account_pf_form_empty_first_name");
      valid = false;
    }

    try {
      validateName(userUpdates.nume.trim());
    } catch (err: any) {
      _errors.nume = err.message;
      valid = false;
    }

    if (!userUpdates.prenume.trim()) {
      _errors.prenume = t("account_pf_form_empty_last_name");
      valid = false;
    }

    try {
      validateName(userUpdates.prenume.trim());
    } catch (err: any) {
      _errors.prenume = err.message;
      valid = false;
    }

    if (!userUpdates.telefon.trim()) {
      _errors.telefon = t("account_pf_form_empty_phone");
      valid = false;
    }

    try {
      validateRoPhone(userUpdates.telefon.trim());
    } catch (err: any) {
      _errors.telefon = err.message;
      valid = false;
    }

    try {
      validateEmail(userUpdates.email.trim());
    } catch (err: any) {
      _errors.email = err.message;
      valid = false;
    }

    if (userUpdates.tara === "RO") {
      try {
        validateCNP(userUpdates.cnp.trim());
      } catch (err: any) {
        _errors.cnp = err.message;
        valid = false;
      }
    }
    if (!userUpdates.cnp.trim()) {
      _errors.cnp = t("account_pf_form_empty_cnp");
      valid = false;
    }

    if (!userUpdates.numar_ci.trim()) {
      _errors.numar_ci = t("account_pf_form_empty_ci");
      valid = false;
    }

    if (!userUpdates.data_eliberare.trim()) {
      _errors.data_eliberare = t("account_pf_form_empty_date_issued_ci");
      valid = false;
    }

    if (!userUpdates.data_expirare.trim()) {
      _errors.data_expirare = t("account_pf_form_empty_date_expires_ci");
      valid = false;
    }

    const dataEliberare = new Date(userUpdates.data_eliberare);
    const dataExpirare = new Date(userUpdates.data_expirare);
    const now = new Date();

    if (dataEliberare >= dataExpirare) {
      _errors.data_eliberare = t("account_pf_form_issue_date_before_exp");
      valid = false;
    }
    if (dataExpirare <= now) {
      _errors.data_expirare = t("account_pf_form_exp_date_after_current_date");
      valid = false;
    }

    if (dataEliberare >= now) {
      _errors.data_eliberare = t(
        "account_pf_form_issue_date_before_current_date",
      );
      valid = false;
    }

    if (!userUpdates.eliberat.trim()) {
      _errors.eliberat = t("account_pf_form_empty_id_issued_by");
      valid = false;
    }

    if (userUpdates.strada === "") {
      _errors.strada = t("account_pf_form_empty_address");
      valid = false;
    }

    if (
      userUpdates.cod_postal &&
      userUpdates.cod_postal.length > 0 &&
      userUpdates.tara === "RO"
    ) {
      try {
        validateRoPostalCode(userUpdates.cod_postal);
      } catch (err: any) {
        _errors.cod_postal = err.message;
        valid = false;
      }
    }

    if (!userUpdates.judet.trim()) {
      _errors.judet = t("account_pf_form_empty_county");
      valid = false;
    }

    if (userUpdates.localitate?.trim() === "") {
      _errors.localitate = t("account_pf_form_empty_locality");
      valid = false;
    }

    if (!userUpdates.document) {
      _errors.document = t("account_pf_form_empty_document");
      valid = false;
    }

    setErrors(_errors);

    return valid;
  };

  const submitUpdatesUserProfile = async () => {
    if (!validate()) return;

    if (isEqual(userProfile, omit(userUpdates, ["email"]))) {
      setEdit(false);
      toast.error(t("account_pf_form_no_changes"));
      return;
    }

    setIsSubmitting(true);
    try {
      await client.createUserProfileRequest(
        omit(userUpdates as Omit<IProfile, "cnp">, ["cnp", "email"]),
      );
      toast.success(t("account_pf_form_sent_success"));
      setUserUpdates({
        ...userProfile,
        email: email,
        document: "",
      });
      setActiveTab("requests");
      setEdit(false);
    } catch (e) {
      // console.log(e);
      // toast.error(t("account_pf_form_error"));
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {userUpdates && (
        <Form>
          <div className="d-flex justify-content-between">
            <div className="mt-3 " style={{ textAlign: "end" }}>
              <Button
                as={Link}
                target="_blank"
                to="https://wordpress.com/log-in/link?client_id=1854&redirect_to=https%3A%2F%2Fpublic-api.wordpress.com%2Foauth2%2Fauthorize%3Fclient_id%3D1854%26response_type%3Dcode%26blog_id%3D0%26state%3D15d2ce7141e16186c0749acf2fe23ab825d3f7c99c31f17622a3c46a0f0fe589%26redirect_uri%3Dhttps%253A%252F%252Fen.gravatar.com%252Fconnect%252F%253Faction%253Drequest_access_token%26from-calypso%3D1"
                variant="primary"
              >
                {t("change_profile_photo_button")}
              </Button>
            </div>
            <div className="mt-3 " style={{ textAlign: "end" }}>
              {canModify && !edit && (
                <Button variant="primary" onClick={() => setEdit(true)}>
                  {t("account_modify_button")}
                </Button>
              )}
            </div>
          </div>
          <br />
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="nume">
                <Form.Label>{t("finish_profile_last_name_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  id="nume"
                  className={errors.nume && "error-control"}
                  type="text"
                  value={userUpdates && userUpdates.nume}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({
                      ...userUpdates,
                      nume: value,
                    })
                  }
                  required
                  disabled={!edit}
                />
                {/* ) : (
                  <p>{userProfile.nume}</p>
                )} */}
              </Form.Group>
              {errors.nume && (
                <span className="error-message">{errors.nume}</span>
              )}
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="lastName">
                <Form.Label>{t("finish_profile_first_name_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  required
                  className={errors.prenume && "error-control"}
                  value={userUpdates && userUpdates.prenume}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, prenume: value })
                  }
                  // placeholder={t("finish_profile_first_name_label")}
                  disabled={!edit}
                />
                {errors.prenume && (
                  <span className="error-message">{errors.prenume}</span>
                )}
                {/* ) : (
                  <p>{userProfile && userProfile.prenume}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="email">
                <Form.Label>Email</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  required
                  type="email"
                  value={userUpdates.email && userUpdates.email}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, email: value })
                  }
                  className={errors.email && "error-control"}
                  disabled={true}
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
                {/* ) : (
                  <p>{userData.email}</p>
                )} */}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="telefon">
                <Form.Label>{t("finish_profile_phone_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  required
                  type="tel"
                  id="telefon"
                  className={errors.telefon && "error-control"}
                  value={userUpdates && userUpdates.telefon}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, telefon: value })
                  }
                  disabled={!edit}
                />
                {errors.telefon && (
                  <span className="error-message">{errors.telefon}</span>
                )}
                {/* ) : (
                  <p>{userProfile.telefon}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="cnp">
                <Form.Label>
                  {userUpdates.tara === "RO" ? (
                    <label htmlFor="cnp">CNP*</label>
                  ) : (
                    <label htmlFor="cnp">ID Number</label>
                  )}
                </Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  id="cnp"
                  className={errors.cnp && "error-control"}
                  required
                  value={userUpdates && userUpdates.cnp}
                  disabled={true}
                />
                {errors.cnp && (
                  <span className="error-message">{errors.cnp}</span>
                )}

                {/* ) : (
                  <p>{userProfile.cnp}</p>
                )} */}
              </Form.Group>
            </Col>

            <Col md={6} className="mb-3">
              <Form.Group id="numar_ci">
                <Form.Label>{t("finish_profile_id_number_label")}</Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  className={errors.numar_ci && "error-control"}
                  id="numar_ci"
                  required
                  value={userUpdates && userUpdates.numar_ci}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, numar_ci: value })
                  }
                  disabled={!edit}
                />
                {errors.numar_ci && (
                  <span className="error-message">{errors.numar_ci}</span>
                )}
                {/* ) : (
                  <p>{userProfile.numar_ci}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="data-eliberare">
                <Form.Label>
                  {t("finish_profile_id_issued_at_label")}
                </Form.Label>
                {/* {edit ? ( */}
                <input
                  type="date"
                  id="data_eliberare"
                  className={
                    "form-control " + (errors.data_eliberare && "error-control")
                  }
                  required
                  value={
                    userUpdates &&
                    dayjs(userUpdates.data_eliberare).format("YYYY-MM-DD")
                  }
                  onChange={({ target: { value } }) =>
                    setUserUpdates({
                      ...userUpdates,
                      data_eliberare: value,
                    })
                  }
                  onClick={(e) => {
                    // const targetElement = e.target as HTMLInputElement;
                    e.currentTarget.showPicker();
                  }}
                  disabled={!edit}
                  style={{ width: "100%" }}
                />
                {errors.data_eliberare && (
                  <span className="error-message">{errors.data_eliberare}</span>
                )}

                {/* ) : (
                  <p>
                    {dayjs(userProfile.data_eliberare).format("DD.MM.YYYY")}
                  </p>
                )} */}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="data-expirare">
                <Form.Label>
                  {t("finish_profile_id_expires_at_label")}
                </Form.Label>
                {/* {edit ? ( */}
                <input
                  type="date"
                  className={
                    "form-control " + (errors.data_eliberare && "error-control")
                  }
                  id="data_expirare"
                  value={
                    userUpdates &&
                    dayjs(userUpdates.data_expirare).format("YYYY-MM-DD")
                  }
                  onChange={({ target: { value } }) =>
                    setUserUpdates({
                      ...userUpdates,
                      data_expirare: value,
                    })
                  }
                  onClick={(e) => {
                    // const targetElement = e.target as HTMLInputElement;
                    e.currentTarget.showPicker();
                  }}
                  required
                  disabled={!edit}
                  style={{ width: "100%" }}
                />
                {errors.data_expirare && (
                  <span className="error-message">{errors.data_expirare}</span>
                )}

                {/* ) : (
                  <p>
                    {dayjs(userProfile.data_expirare).format("YYYY-MM-DD")}
                  </p>
                )} */}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="eliberat">
                <Form.Label>
                  {t("finish_profile_id_issued_by_label")}
                </Form.Label>
                {/* {edit ? ( */}
                <Form.Control
                  type="text"
                  id="eliberat"
                  className={errors.eliberat && "error-control"}
                  value={userUpdates && userUpdates.eliberat}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, eliberat: value })
                  }
                  required
                  disabled={!edit}
                />
                {errors.eliberat && (
                  <span className="error-message">{errors.eliberat}</span>
                )}
                {/* ) : (
                  <p>{userProfile.eliberat}</p>
                )} */}
              </Form.Group>
            </Col>
          </Row>

          <h5 className="my-4">{t("finish_profile_street_label")}</h5>
          <Row>
            <Col sm={9} className="mb-3">
              <Form.Group id="strada">
                <Form.Label>{t("finish_profile_street_label")}</Form.Label>
                <Form.Control
                  required
                  type="text"
                  id="strada"
                  className={errors.strada && "error-control"}
                  value={userUpdates && userUpdates.strada}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, strada: value })
                  }
                  disabled={!edit}
                />
                {errors.strada && (
                  <span className="error-message">{errors.strada}</span>
                )}
              </Form.Group>
            </Col>
            <Col sm={3} className="mb-3">
              <Form.Group id="cod_postal">
                <Form.Label>{t("finish_profile_postal_code_label")}</Form.Label>
                <Form.Control
                  // required
                  type="text"
                  id="cod_postal"
                  className={errors.cod_postal && "error-control"}
                  disabled={!edit}
                  value={userUpdates && userUpdates.cod_postal}
                  onChange={({ target: { value } }) =>
                    setUserUpdates({ ...userUpdates, cod_postal: value })
                  }
                />
                {errors.cod_postal && (
                  <span className="error-message">{errors.cod_postal}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4} className="mb-3">
              <Form.Group id="tara">
                <Form.Label>{t("finish_business_country_label")}</Form.Label>
                <Form.Select
                  id="tara"
                  className="form-control"
                  required
                  value={userUpdates && userUpdates.tara}
                  onChange={({ currentTarget: { value } }) => {
                    // setUserUpdates({ ...userUpdates, tara: value });
                    setUserUpdates({
                      ...userUpdates,
                      tara: value,
                      judet: "",
                      localitate: "",
                    });
                  }}
                  disabled={!edit}
                >
                  {Object.keys(countriesData).map((countryCode) => (
                    <option key={countryCode} value={countryCode}>
                      {countriesData[countryCode]}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            {userUpdates && userUpdates.tara === "RO" ? (
              <>
                <Col sm={4} className="mb-3">
                  <Form.Group className="mb-2" id="judet">
                    <Form.Label>{t("finish_profile_county_label")}</Form.Label>
                    <Form.Select
                      id="judet"
                      required
                      value={userUpdates && userUpdates.judet}
                      onChange={(event) => {
                        if (event.currentTarget.value !== "") {
                          const selectedJudet = judete.find(
                            (judet) => judet.Nume === event.currentTarget.value,
                          );
                          if (selectedJudet) {
                            setUserUpdates({
                              ...userUpdates,
                              judet: selectedJudet.Nume,
                              localitate: "",
                            });
                          }
                        } else {
                          setUserUpdates({
                            ...userUpdates,
                            judet: "",
                            localitate: "",
                          });
                        }
                      }}
                      disabled={!edit}
                      className={errors.cod_postal && "error-control"}
                    >
                      <option value="">-</option>
                      {judete.map((judet) => (
                        <option
                          key={judet.Cod}
                          data-key={judet.Cod}
                          value={judet.Nume}
                        >
                          {judet.Diacritice}
                        </option>
                      ))}
                    </Form.Select>
                    {errors.judet && (
                      <span className="error-message">{errors.judet}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group id="localitate">
                    <Form.Label>
                      {t("finish_profile_locality_label")}
                    </Form.Label>
                    <Form.Select
                      id="localitate"
                      required
                      value={userUpdates && userUpdates.localitate}
                      onChange={({ currentTarget: { value } }) =>
                        setUserUpdates({ ...userUpdates, localitate: value })
                      }
                      disabled={!edit}
                      className={errors.localitate && "error-control"}
                    >
                      <option value="">-</option>
                      {userUpdates.judet &&
                        localitati
                          .filter((localitate) => {
                            const selectedJudet = judete.find(
                              (judet) => judet.Nume === userUpdates.judet,
                            );
                            return (
                              selectedJudet &&
                              localitate.Cod === selectedJudet.Cod
                            );
                          })
                          .map((localitate) => (
                            <option
                              key={localitate.Nume + localitate.Cod}
                              value={localitate.Nume}
                            >
                              {localitate.Diacritice}
                            </option>
                          ))}
                    </Form.Select>
                    {errors.localitate && (
                      <span className="error-message">{errors.localitate}</span>
                    )}
                  </Form.Group>
                </Col>
              </>
            ) : (
              <>
                <Col sm={4} className="mb-3">
                  <Form.Group className="mb-2" id="judet">
                    <Form.Label>{t("finish_profile_county_label")}</Form.Label>
                    <Form.Control
                      type="text"
                      id="judet"
                      required
                      className={errors.judet && "error-control"}
                      value={userUpdates && userUpdates.judet}
                      onChange={({ target: { value } }) =>
                        setUserUpdates({ ...userUpdates, judet: value })
                      }
                      disabled={!edit}
                    />
                    {errors.judet && (
                      <span className="error-message">{errors.judet}</span>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group id="localitate">
                    <Form.Label>
                      {t("finish_profile_locality_label")}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      id="localitate"
                      required
                      className={errors.localitate && "error-control"}
                      value={userUpdates && userUpdates.localitate}
                      onChange={({ target: { value } }) =>
                        setUserUpdates({ ...userUpdates, localitate: value })
                      }
                      disabled={!edit}
                    />
                    {errors.localitate && (
                      <span className="error-message">{errors.localitate}</span>
                    )}
                  </Form.Group>
                </Col>
              </>
            )}
            {edit && (
              <Col sm={12}>
                <Form.Group id="document">
                  <Form.Label>{t("finish_profile_document_label")}</Form.Label>
                  <Form.Control
                    type="file"
                    id="document"
                    // className="form-control "
                    className={errors.document && "error-control"}
                    required
                    onChange={({ target: { files } }: any) =>
                      setUserUpdates({ ...userUpdates, document: files[0] })
                    }
                  />
                  {errors.document && (
                    <span className="error-message">{errors.document}</span>
                  )}
                </Form.Group>
              </Col>
            )}
          </Row>
          <div className="mt-3">
            {edit && (
              <>
                <Button variant="default" onClick={() => setEdit(false)}>
                  {t("account_cancel_request")}
                </Button>
                <Button
                  variant="primary"
                  onClick={() => submitUpdatesUserProfile()}
                  disabled={isSubmitting}
                >
                  {t("account_send_request")}
                  {isSubmitting && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                </Button>
              </>
            )}
          </div>
        </Form>
      )}
    </>
  );
};

import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { BASE_URL } from "../../config/env";
import { useAuthContext } from "../../context/AuthContext";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import {
  validateCaptchaCompleted,
  validateEmail,
  validatePassword,
} from "../../helpers/validators";
import { Routes } from "../../routes";
import CaptchaForm from "../Captcha/CaptchaForm";

function Login() {
  const client = useAPIClient();
  const { isLoggedIn, login } = useAuthContext();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();

  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [showHelpBanner, setShowHelpBanner] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const { state } = useLocation();

  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (value: string) => setEmail(value);

  const MAX_LOGIN_ATTEMPTS = 3; // Maximum number of login attempts
  const [loginAttempts, setLoginAttempts] = useState(0); // State variable to track login attempts

  const changeLanguage = () => {
    if (i18n.language === "ro") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ro");
      localStorage.setItem("language", "ro");
    }
  };
  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);
  useEffect(() => {
    if (isLoggedIn) {
      if (state === null) {
        navigate("/");
        return;
      }
      navigate(state);
    }
  }, [navigate, isLoggedIn]);

  const submitForm = async (data: FieldValues) => {
    setLoading(true);

    try {
      validateCaptchaCompleted(captchaResponse);
      validateEmail(data.email);
      validatePassword(data.password);
      const res = await client.signIn(
        data.email,
        data.password,
        captchaResponse,
      );
      console.log("in login", res);
      login(res);
      setLoginAttempts(0);
    } catch (error: any) {
      // toast.error(t(error.response?.data?.statusCode ?? "999"));
      const errorMessages = handleException(error, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
      setLoginAttempts((prevAttempts) => prevAttempts + 1);
      if (loginAttempts >= MAX_LOGIN_ATTEMPTS - 1) {
        setShowHelpBanner(true);
      }
      recaptchaRef.current?.reset();
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async () => {
    try {
      validateEmail(email);

      await axios.post(BASE_URL + "/auth/reset-password", {
        email: email,
        captchaResponse: captchaResponse,
      });

      toast.success("Email trimis cu succes!");
      setShow(false);
    } catch (e: any) {
      // toast.error(t(e));
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };
  return (
    <>
      <div className="row authentication mx-0">
        <div
          className="col-xxl-6 col-xl-7 col-lg-12"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div className="mt-3 mb-5 d-flex d-xl-none">
            <img
              className="ui centered big image w-100-mobile"
              src="/poca2.png"
              style={{ width: "80%", maxWidth: "450px", margin: "auto" }}
            ></img>
          </div>
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="login-mobile px-5">
                <div className="mt-3 d-flex flex-column align-items-center align-center">
                  <img
                    src="/logo-timis.png"
                    width="58"
                    height="76"
                    className="rounded d-block pb-2"
                    alt="CJT Logo"
                    style={{ marginRight: "1em" }}
                  />
                  <h3 className="fw-bold mb-0 text-uppercase text-center">
                    {t("institution")}
                  </h3>
                </div>
                <p className="h5 fw-semibold text-center text-muted">
                  {t("login_page_title")}
                </p>
                <div className="mb-3 mt-md-4 form-style-new">
                  <div
                    className="alert alert-info text-center"
                    style={{ display: showHelpBanner ? "block" : "none" }}
                  >
                    <span>{t("max_login_attempts")}</span>
                  </div>
                  <div className="mt-3">
                    <Form
                      onSubmit={handleSubmit(submitForm)}
                      className="row gy-3"
                    >
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="text-center">
                          {t("email_label")}
                        </Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={t("email_placeholder")}
                          {...register("email", { required: true })}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="formBasicPassword">
                        <Form.Label>{t("password_label")}</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder={t("password_placeholder")}
                          {...register("password", { required: true })}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        className="d-flex"
                        controlId="formBasicCheckbox"
                      >
                        <p className="small">
                          <a
                            className="text-primary"
                            href="#!"
                            onClick={handleShow}
                          >
                            {t("login_page_recover_password_label")}
                          </a>
                        </p>

                        <p className="small mx-2">
                          <a className="text-primary" href="/resend-link">
                            {t("resend_activation_link")}
                          </a>
                        </p>
                      </Form.Group>
                      <div className="d-grid">
                        <CaptchaForm
                          onCaptchaSolved={(response: string | null) => {
                            return setCaptchaResponse(response);
                          }}
                          captchaRef={recaptchaRef}
                        />
                        {loading ? (
                          <span className="button mt-4 text-center">
                            <span className="spinner-border spinner-border-sm" />
                          </span>
                        ) : (
                          <>
                            <Button
                              variant="primary"
                              type="submit"
                              className="mt-4"
                            >
                              {t("login_page_login_button")}
                            </Button>
                          </>
                        )}
                      </div>
                    </Form>
                    <div className="mt-3">
                      <p className="mb-0  text-center">
                        <a href="/register" className="text-primary fw-bold">
                          {t("login_page_register_button")}
                        </a>
                      </p>
                    </div>
                    <div>
                      <Button variant="link" onClick={() => changeLanguage()}>
                        {i18n.language === "ro" ? "🇷🇴 RO" : "🇬🇧 EN"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-block text-muted fw-bold mb-3 d-block d-xl-none"
            style={{ width: "90%", textAlign: "center" }}
          >
            <p
              className="text-center text-muted mt-3 mb-3"
              style={{ fontSize: "0.7em" }}
            >
              {t("poca.text1")}
              <br></br>
              <a
                target="_blank"
                className="text-primary fw-bold text-decoration-none"
                href="http://www.poca.ro"
              >
                www.poca.ro
              </a>
              <br></br>
              {t("poca.text2")}{" "}
              <a
                target="_blank"
                className="text-primary fw-bold text-decoration-none"
                href="https://www.fonduri-ue.ro"
              >
                www.fonduri-ue.ro
              </a>
              .{t("poca.text3")}
              <br></br>
              <br></br>
              {t("credits")}
              <a
                className="text-primary fw-bold text-decoration-none"
                href="https://ici.ro"
              >
                {t("ici")}
              </a>
              .
            </p>
          </div>
        </div>
        <div className="col-xxl-6 col-xl-5 col-lg-5 d-xl-block d-none px-0">
          <div
            className="authentication-cover"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              backgroundImage: "url(piata-unirii.jpg)",
            }}
          >
            <div
              className="d-flex py-3 mb-3"
              style={{
                justifyContent: "center",
                width: "100%",
                backgroundColor: "white",
              }}
            >
              <img
                className="ui centered big image w-100-mobile"
                src="/poca2.png"
                style={{ width: "40%", maxWidth: "450px" }}
              ></img>
            </div>
            <div
              className="d-block text-muted fw-bold"
              style={{
                width: "100%",
                textAlign: "center",
                backgroundColor: "#d8dee9",
                zIndex: "100",
              }}
            >
              <p
                className="text-center text-muted mt-3 mb-3"
                style={{ fontSize: "0.7em", width: "90%", margin: "auto" }}
              >
                {t("poca.text1")}
                <br></br>
                <a
                  target="_blank"
                  className="text-primary fw-bold text-decoration-none"
                  href="http://www.poca.ro"
                >
                  www.poca.ro
                </a>
                <br></br>
                {t("poca.text2")}{" "}
                <a
                  target="_blank"
                  className="text-primary fw-bold text-decoration-none"
                  href="https://www.fonduri-ue.ro"
                >
                  www.fonduri-ue.ro
                </a>
                .{t("poca.text3")}
                <br></br>
                <br></br>
                <a href={t("footer.terms.link")} className="mx-2">
                  {t("footer.terms")}
                </a>
                <a href={t("footer.privacy.link")}>{t("footer.privacy")}</a>
                <br></br>
                {t("credits")}
                <a
                  className="text-primary fw-bold text-decoration-none"
                  href="https://ici.ro"
                >
                  {t("ici")}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t("login_page_recover_password_label")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>{t("email_label")}</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={({ target: { value } }) => handleChange(value)}
            // required
            placeholder={t("email_label")}
          />
          <span className="mt-2">
            <CaptchaForm
              onCaptchaSolved={(response: string | null) => {
                return setCaptchaResponse(response);
              }}
              captchaRef={recaptchaRef}
            />
          </span>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Închide
          </Button>
          <Button variant="primary" onClick={handleSendEmail}>
            Trimite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Login;

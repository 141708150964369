import jwtDecode from "jwt-decode";
import { createContext, useContext, useState } from "react";

import { EModules } from "../enums";
import { useAPIClient } from "../helpers/api";

const client = useAPIClient();

type State = {
  isLoggedIn: boolean;
  isAuditor: boolean;
  isVerified: boolean;
  hasWhiteboard: boolean;
  token: string | null;
  email: string | null;
  userId: string | null;
  roles: string[];
  context: string;
  relevantModules: EModules[];
};

type Context = {
  login: (res: any) => void;
  logout: () => void;
  switchProfile: (context: string) => void;
  setState: (state: State) => void;
  requiredAttribute: (attribute: string) => boolean;
  isAdmin: () => boolean;
  showModule: (module: EModules) => boolean;
  hasWhiteboard: boolean;
  isLoggedIn: boolean;
  isAuditor: boolean;
  isVerified: boolean;
  email: string | null;
  userId: string | null;
  currentProfileId: string;
};

type Token = {
  email: string;
  isAuditor: boolean;
  hasWhiteboard: boolean;
  isVerified: boolean;
  attributes: string[];
  sub: string;
  iat: number;
  exp: number;
  modules: EModules[];
};

const isJwtExpired = (token: string) => {
  if (typeof token !== "string" || !token)
    throw new Error("Invalid token provided");

  let isJwtExpired = false;
  let decoded = null;

  try {
    decoded = jwtDecode<Token>(token);
  } catch (e) {
    return false;
  }

  const currentTime = new Date().getTime() / 1000;

  if (currentTime > decoded.exp) isJwtExpired = true;

  return isJwtExpired;
};

function getJWT() {
  const token = localStorage.getItem("accessToken");

  if (token && !isJwtExpired(token)) {
    return token;
  }

  return null;
}

function getContext() {
  if (localStorage.getItem("context")) {
    return localStorage.getItem("context");
  } else {
    return null;
  }
}

function getInitialState() {
  const jwt = getJWT();
  const state = {
    isLoggedIn: false,
    isAuditor: false,
    isVerified: false,
    hasWhiteboard: false,
    token: null,
    email: null,
    userId: null,
    roles: [],
    context: "",
    relevantModules: [],
  } as State;

  if (jwt) {
    try {
      const decoded = jwtDecode<Token>(jwt);

      state.isLoggedIn = true;
      state.isAuditor = decoded.isAuditor;
      state.token = jwt;
      state.email = decoded.email;
      state.userId = decoded.sub;
      state.roles = decoded.attributes;
      state.hasWhiteboard = decoded.hasWhiteboard;
      state.context = getContext() || "";
      state.relevantModules = decoded.modules;
    } catch (e) {
      console.log(e);
    }
  }
  return state;
}

const AuthContext = createContext<Context | null>(null);
const { Provider } = AuthContext;

const AuthProvider = ({ children }: any) => {
  const [state, setState] = useState<State>(getInitialState());

  const login = (res: any) => {
    setState({
      isLoggedIn: true,
      isAuditor: res.user.auditor?.id ? true : false,
      token: res.token,
      isVerified: false,
      hasWhiteboard: res.user.joinedWhiteboards.length > 0,
      email: res.user.email,
      userId: res.user.id,
      roles: res.user.attributes,
      context: res.user.profile ? res.user.profile.id : "",
      relevantModules: res.modules ?? [],
    });

    localStorage.setItem("accessToken", res.token);
    localStorage.setItem(
      "context",
      res.user.profile ? res.user.profile.id : "",
    );
  };

  const logout = () => {
    setState({
      isLoggedIn: false,
      isAuditor: false,
      isVerified: false,
      hasWhiteboard: false,
      token: null,
      email: null,
      userId: null,
      roles: [],
      context: "",
      relevantModules: [],
    });

    localStorage.removeItem("accessToken");
    localStorage.removeItem("context");
    localStorage.removeItem("userProfile");
  };

  const switchProfile = (profileId: string) => {
    setState({
      ...state,
      context: profileId,
    });
  };

  const requiredAttribute = (attribute: string) => {
    return state.roles.includes(attribute);
  };

  const showModule = (module: EModules): boolean => {
    return state.relevantModules.includes(module);
  };

  const isAdmin = () => {
    return state.roles.length > 0;
  };

  return (
    <Provider
      value={{
        login,
        logout,
        switchProfile,
        setState,
        requiredAttribute,
        isAdmin,
        showModule,
        hasWhiteboard: state.hasWhiteboard,
        isLoggedIn: state.isLoggedIn,
        isAuditor: state.isAuditor,
        email: state.email,
        isVerified: state.isVerified,
        userId: state.userId,
        currentProfileId: state.context,
      }}
    >
      {children}
    </Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === null) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return context;
};

export { useAuthContext, AuthProvider };

import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import { useEffect, useState } from "react";
import { Container, Form } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IProfile } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import { default as countries } from "../../helpers/countries.json";
import handleException from "../../helpers/exceptions";
import { default as judete } from "../../helpers/lista_judete.json";
import { default as localitati } from "../../helpers/localitati_simple.json";
import {
  validateCNP,
  validateName,
  validateRoPhone,
  validateRoPostalCode,
} from "../../helpers/validators";
import LoadingView from "../../layout/LoadingView";

function FinishProfile({ onCompleted }: { onCompleted: () => void }) {
  const client = useAPIClient();
  const { register, setValue, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("RO");
  const [selectedJudet, setSelectedJudet] = useState("");
  const [telephoneInput, setTelephoneInput] =
    useState<intlTelInput.Plugin | null>(null);

  useEffect(() => {
    const input = document.getElementById("telefon");

    if (input) {
      const telInput = intlTelInput(input, {
        initialCountry: "ro",
        separateDialCode: true,
      });
      setTelephoneInput(telInput);
    }
  }, []);

  const { i18n, t } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  const submitForm = async (data: FieldValues) => {
    setLoading(true);
    try {
      const dataEliberare = new Date(data.data_eliberare);
      const dataExpirare = new Date(data.data_expirare);
      const now = new Date();

      // telephone
      validateRoPhone(data.telefon);
      const dialCode = telephoneInput?.getSelectedCountryData().dialCode;
      const phoneNumber = data.telefon;
      data.telefon = `${dialCode}${phoneNumber}`;

      if (selectedCountry === "RO") {
        try {
          validateCNP(data.cnp);
        } catch (e: any) {
          const errorMessages = handleException(e, t);
          errorMessages.forEach((message) => {
            toast.error(message);
          });
          return;
        }
      }

      try {
        validateName(data.nume);
        validateName(data.prenume);
      } catch (e: any) {
        const errorMessages = handleException(e, t);
        errorMessages.forEach((message) => {
          toast.error(message);
        });
        return;
      }

      if (data.cod_postal.length > 0 && selectedCountry === "RO") {
        try {
          validateRoPostalCode(data.cod_postal);
        } catch (e: any) {
          const errorMessages = handleException(e, t);
          errorMessages.forEach((message) => {
            toast.error(message);
          });
          return;
        }
      }

      if (dataEliberare >= dataExpirare) {
        toast.error(t("account_pf_form_issue_date_before_exp"));
        return;
      }
      if (dataExpirare <= now) {
        toast.error(t("account_pf_form_exp_date_after_current_date"));
        return;
      }

      if (dataEliberare >= now) {
        toast.error(t("account_pf_form_issue_date_before_current_date"));
        return;
      }

      await client.completeProfile(data as IProfile);
      onCompleted();
    } catch (error: any) {
      //console.log(error);
      // if ((error.response?.status ?? 999) === 409) {
      //   toast.error(t("finish_profile_cnp_conflict"));
      // } else {
      //   toast.error(t("finish_profile_incorrect_data_error"));
      // }
      const errorMessages = handleException(error, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCountryChange = (value: string) => {
    setSelectedCountry(value);
    setSelectedJudet("");
  };

  useEffect(() => {
    if (selectedCountry !== "RO") {
      setValue("judet", "");
      setValue("localitate", "");
    }
  }, [selectedCountry]);

  return (
    <Container className="mb-5">
      <div className="text-center mt-4">
        <span className="fs-1">{t("welcome")}</span>
        <br></br>
        <span className="fs-5">{t("finish_profile_page_title")}</span>
      </div>
      <div className="mt-5">
        <span className="fs-4">{t("finish_profile_personal_data_label")}</span>
        <br></br>
        <span className="fs-6">
          * {t("finish_profile_legal_representative_label")}
        </span>
        <br></br>
        <span className="fs-6">
          * {t("finish_profile_mandatory_fields_label")}
        </span>
      </div>
      <Form onSubmit={handleSubmit(submitForm)} className="mt-3">
        <div className="form-floating mb-3">
          <select
            id="tara"
            className="form-control"
            {...register("tara", { required: true })}
            required
            value={selectedCountry}
            onChange={({ target: { value } }) => handleCountryChange(value)}
          >
            {Object.keys(countries).map((countryCode) => (
              <option key={countryCode} value={countryCode}>
                {countries[countryCode as keyof typeof countries]}
              </option>
            ))}
          </select>
          <label htmlFor="tara">{t("finish_business_country_label")}*</label>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="cnp"
                {...register("cnp", { required: true })}
                required
              />
              {selectedCountry === "RO" ? (
                <label htmlFor="cnp">CNP*</label>
              ) : (
                <label htmlFor="cnp">ID Number</label>
              )}
            </div>

            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="nume"
                  {...register("nume", { required: true })}
                  required
                />
                <label htmlFor="nume">
                  {t("finish_profile_last_name_label")}*
                </label>
              </div>
            </div>

            <div className="col">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="prenume"
                  {...register("prenume", { required: true })}
                  required
                />
                <label htmlFor="prenume">
                  {t("finish_profile_first_name_label")}*
                </label>
              </div>
            </div>

            {selectedCountry === "RO" ? (
              <div>
                <div className="form-floating mb-3">
                  <select
                    id="judet"
                    className="form-control"
                    {...register("judet", { required: true })}
                    required
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        const selectedJudet = judete.find(
                          (judet) => judet.Nume === event.target.value,
                        );
                        setSelectedJudet(
                          selectedJudet ? selectedJudet.Cod : "",
                        );
                      } else {
                        setSelectedJudet("");
                      }
                    }}
                  >
                    <option value="">-</option>
                    {judete.map((judet) => (
                      <option
                        key={judet.Cod}
                        data-key={judet.Cod}
                        value={judet.Nume}
                      >
                        {judet.Diacritice}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="judet">
                    {t("finish_profile_county_label")}*
                  </label>
                </div>

                <div className="form-floating mb-3">
                  <select
                    id="localitate"
                    className="form-control"
                    {...register("localitate", { required: true })}
                    required
                  >
                    <option value="">-</option>
                    {localitati
                      .filter((localitate) => localitate.Cod === selectedJudet)
                      .map((localitate) => (
                        <option
                          key={localitate.Nume + localitate.Cod}
                          value={localitate.Nume}
                        >
                          {localitate.Diacritice}
                        </option>
                      ))}
                  </select>
                  <label htmlFor="localitate">
                    {t("finish_profile_locality_label")}*
                  </label>
                </div>
              </div>
            ) : (
              <div>
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="judet"
                    {...register("judet", { required: true })}
                    required
                  />
                  <label htmlFor="judet">
                    {t("finish_profile_county_label")}*
                  </label>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="localitate"
                        {...register("localitate", { required: true })}
                        required
                      />
                      <label htmlFor="localitate">
                        {t("finish_profile_locality_label")}*
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="strada"
                {...register("strada", { required: true })}
                required
              />
              <label htmlFor="strada">
                {t("finish_profile_street_label")}*
              </label>
            </div>

            <div className="row">
              <div className="col">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="cod_postal"
                    {...register("cod_postal", { required: false })}
                  />
                  <label htmlFor="cod_postal">
                    {t("finish_profile_postal_code_label")}
                  </label>
                </div>
              </div>
            </div>

            <div className="form-floating mb-3">
              <input
                type="tel"
                className="form-control"
                id="telefon"
                {...register("telefon", { required: true })}
                required
              />
              {/* <label htmlFor="telefon">
                {t("finish_profile_phone_label")}*
              </label> */}
            </div>
          </div>
        </div>

        <span className="fs-4">{t("finish_profile_id_card_details")}</span>
        <div className="row mt-3">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="numar_ci"
                {...register("numar_ci", { required: true })}
                required
              />
              <label htmlFor="numar_ci">
                {t("finish_profile_id_number_label")}*
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="eliberat"
                {...register("eliberat", { required: true })}
                required
              />
              <label htmlFor="eliberat">
                {t("finish_profile_id_issued_by_label")}*
              </label>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="date"
                className="form-control"
                id="data_eliberare"
                {...register("data_eliberare", { required: true })}
                required
                style={{ width: "100%" }}
                onClick={(e) => {
                  e.currentTarget.showPicker();
                }}
              />
              <label htmlFor="data_expirare">
                {t("finish_profile_id_issued_at_label")}*
              </label>
            </div>
          </div>

          <div className="col">
            <div className="form-floating mb-3">
              <input
                type="date"
                className="form-control"
                id="data_expirare"
                {...register("data_expirare", { required: true })}
                required
                style={{ width: "100%" }}
                onClick={(e) => {
                  e.currentTarget.showPicker();
                }}
              />
              <label htmlFor="data_expirare">
                {t("finish_profile_id_expires_at_label")}*
              </label>
            </div>
          </div>
        </div>

        <div className="d-grid gap-2 mt-3">
          {loading ? (
            <LoadingView />
          ) : (
            <button className="btn btn-primary" type="submit">
              {t("finish_profile_save_data_button")}
            </button>
          )}
        </div>
      </Form>
    </Container>
  );
}
export default FinishProfile;

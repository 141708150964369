import { faAdd, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  Row,
  Tab,
  Table,
} from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusiness, IUser } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import ListBusinessRequests from "./ListBusinessRequests";
import ListProfileRequests from "./ListProfileRequests";
import { PfForm } from "./PfForm";
import PjForm from "./PjForm";

export default function Account() {
  const client = useAPIClient();
  const [user, setUser] = useState<IUser | null>(null);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("home");
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const [openUpdateBussinessModal, setOpenUpdateBussinessModal] =
    useState(false);
  const [openDeleteBusinessConfirmation, setOpenDeleteBusinessConfirmation] =
    useState(false);
  const [businessToDelete, setBusinessToDelete] = useState<IBusiness | null>(
    null,
  );
  const [businessUpdateData, setBusinessUpdateData] =
    useState<IBusiness | null>(null);

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setIsLoading(false);
      setUser(_user);
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   setIsLoading(false);
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handlePjAdded = async () => {
    await fetchUser();
  };

  const handleDeleteBusiness = async (id: string) => {
    if (!id) return;
    try {
      await client.deleteBusinessProfile(id);
      toast.success(t("legal_person.success_delete"));
      await fetchUser();
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setOpenDeleteBusinessConfirmation(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <Container className="py-5">
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <Tab.Container
              activeKey={activeTab}
              onSelect={(k) => k && setActiveTab(k)}
            >
              <Row>
                <Col lg={12}>
                  <Nav className="nav-tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="home" className="mb-sm-3 mb-md-0">
                        {t("account_personal_data")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="business" className="mb-sm-3 mb-md-0">
                        {t("account_business_data")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="requests" className="mb-sm-3 mb-md-0">
                        {t("account_update_profile_requests")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="bRequests"
                        className="mb-sm-3 mb-md-0"
                      >
                        {t("account_business_requests")}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col lg={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="home" className="py-4 px-4">
                      {!isLoading && user && (
                        <PfForm
                          userProfile={user.profile}
                          email={user.email}
                          setActiveTab={setActiveTab}
                          t={t}
                        />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="business" className="py-4">
                      <div className="d-flex d-justify-space-between mb-4">
                        <p>{t("profile_business_associated")}</p>
                        <Button
                          variant="outline-primary"
                          className="ms-auto"
                          onClick={() => setOpen(true)}
                        >
                          <FontAwesomeIcon icon={faAdd} className="me-2" />{" "}
                          {t("profile_business_add")}
                        </Button>{" "}
                      </div>
                      <Table>
                        <thead className="thead-light">
                          <tr>
                            <th className="border-0">
                              {t("profile_business_type")}
                            </th>
                            <th className="border-0">
                              {t("profile_business_name")}
                            </th>

                            <th className="border-0">
                              {t("profile_business_cui")}
                            </th>
                            <th className="border-0"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {user?.businesses && user?.businesses.length > 0 ? (
                            user?.businesses.map((business: IBusiness) => (
                              <tr key={business.id}>
                                <td className="align-middle">{business.tip}</td>
                                <td className="align-middle">
                                  {business.denumire}
                                </td>
                                <td className="align-middle">{business.cui}</td>
                                <td className="align-middle">
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => {
                                      setBusinessToDelete(business);
                                      setOpenDeleteBusinessConfirmation(true);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Button>
                                  {/* <Button
                                    className="mr-2"
                                    style={{ marginLeft: "10px" }}
                                    variant="primary"
                                    size="sm"
                                    onClick={
                                      () => {
                                        setOpenUpdateBussinessModal(true);
                                        setBusinessUpdateData(business);
                                      }
                                      //</td>handleUpdateBusiness(business.id!)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEdit} />
                                  </Button> */}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={2} className="text-center">
                                <div className="m-4">
                                  {" "}
                                  <h5>{t("profile_business_empty")}</h5>{" "}
                                  <Button
                                    variant="outline-primary"
                                    className="m-1"
                                    onClick={() => setOpen(true)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faAdd}
                                      className="me-2"
                                    />{" "}
                                    {t("profile_business_add")}
                                  </Button>{" "}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </Tab.Pane>
                    <Tab.Pane eventKey="requests" className="py-4">
                      <p>{t("profile_data_requests_list")}</p>
                      {activeTab === "requests" && user && (
                        <ListProfileRequests />
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="bRequests" className="py-4">
                      <p>{t("profile_data_bRequests_list")}</p>
                      {activeTab === "bRequests" && user && (
                        <ListBusinessRequests />
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Card.Body>
        </Card>
        <PjForm
          open={open}
          setOpen={setOpen}
          onSubmit={handlePjAdded}
          updateModel={false}
          updateData={null}
        />
        <PjForm
          open={openUpdateBussinessModal}
          setOpen={setOpenUpdateBussinessModal}
          onSubmit={handlePjAdded}
          updateModel={true}
          updateData={businessUpdateData}
        />
        <Modal
          show={openDeleteBusinessConfirmation}
          onHide={() => {
            setOpenDeleteBusinessConfirmation(false);
            setBusinessToDelete(null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t("PROFILE_BUSINESS_DELETE_CONFIRM_TITLE")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t("PROFILE_BUSINESS_DELETE_CONFIRM_TEXT") +
                businessToDelete?.denumire +
                " ?"}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => handleDeleteBusiness(businessToDelete?.id!)}
            >
              {t("change_profile.business_removal")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAPIClient } from "../../helpers/api";

import Select from "react-select";
import { Container } from "react-bootstrap";
import { IBusiness, IUser } from "../../cjt";
import { use } from "i18next";
import handleException from "../../helpers/exceptions";
import { useTranslation } from "react-i18next";
export interface OptionType {
  id: string;
  name: string;
  tip: string;
  instance: IUser | IBusiness;
  email: string;
}

function SelectUser({
  setpUser,
}: {
  setpUser: Dispatch<SetStateAction<OptionType | null>>;
}) {
  const client = useAPIClient();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<OptionType | null>(null);
  const [taxes, setTaxes] = useState({});
  const [user, setUser] = useState<IUser>();
  const [usingAsUser, setUsingAsUser] = useState<IUser | IBusiness | null>();
  const [options, setOptions] = useState<
    {
      value: string;
      label: string;
      tip: string;
      instance: IUser | IBusiness;
      email: string;
    }[]
  >([]);
  const handleSelectChange = (selected: any) => {
    setSelected(selected);
    setpUser(selected);
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await client.getUserFeed();
        console.log(user);
        setUser(user);
        if (!user) return;

        setSelected({
          id: user?.profile.cnp,
          name: `CNP: ${user?.profile.cnp} - ${user?.profile.nume} ${user?.profile.prenume}`,
          tip: "pf",
          instance: user,
          email: user?.email,
        });
        setpUser({
          id: user?.profile.cnp,
          name: `CNP: ${user?.profile.cnp} - ${user?.profile.nume} ${user?.profile.prenume}`,
          tip: "pf",
          instance: user,
          email: user?.email,
        });

        setIsLoading(false);
        return user;
      } catch (err: any) {
        const errorMessages = handleException(err, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
      }
    };

    fetchUser(); // Call the fetchUser function directly
  }, []);
  useEffect(() => {
    if (!user) return;

    const userOption = {
      label: `CNP: ${user.profile.cnp} - ${user.profile.nume} ${user.profile.prenume}`,
      value: user.profile.cnp,
      tip: "pf",
      instance: user,
      email: user.email,
    };

    if (!user.businesses?.length) {
      setOptions([userOption]);
    } else {
      const businessOptions = user.businesses.map((business) => ({
        label: `CUI: ${business.cui} - ${business.denumire}`,
        value: business.cui,
        tip: "pj",
        instance: business,
        email: user.email,
      }));

      setOptions([...businessOptions, userOption]);
    }
  }, [user]);

  return (
    <>
      <div>
        <Select
          value={
            selected
              ? {
                  value: selected.id,
                  label: selected.name,
                  tip: selected.tip,
                  instance: selected.instance,
                  email: selected.email,
                }
              : null
          }
          onChange={(selectedOption) =>
            handleSelectChange(
              selectedOption
                ? {
                    id: selectedOption.value,
                    name: selectedOption.label,
                    tip: selectedOption.tip,
                    instance: selectedOption.instance,
                    email: selectedOption.email,
                  }
                : null
            )
          }
          options={options}
          placeholder="Selecteaza de catre cine se efectueaza plata"
        />
      </div>
    </>
  );
}

export default SelectUser;

import {
  faAdd,
  faCheck,
  faEdit,
  faPlus,
  faProjectDiagram,
  faTrash,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import "gantt-task-react/dist/index.css";
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  Nav,
  OverlayTrigger,
  Row,
  Spinner,
  Tab,
  Tooltip,
} from "react-bootstrap";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  IBusiness,
  IContractFolder,
  IContractTemplate,
  IProjectFolder,
  IProjectPhase,
  IUser,
} from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { downloadBase64File } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";
import ChatBox from "../Chatbox/Chatbox";
import GanttGraph from "./ProjectPhase";

export default function ProjectDetails() {
  const { id } = useParams();
  const client = useAPIClient();
  const { t } = useTranslation();
  const [project, setProject] = useState<IProjectFolder | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [openAddPhase, setOpenAddPhase] = useState(false);
  const [openAddParticipants, setOpenAddParticipants] = useState(false);
  const [openAddDocumentsToProject, setOpenAddDocumentsToProject] =
    useState(false);
  const [openAddDocumentsToPhase, setOpenAddDocumentsToPhase] = useState(false);
  const [openEditProjectBudget, setOpenEditProjectBudget] = useState(false);
  const [openEditProjectDescription, setOpenEditProjectDescription] =
    useState(false);
  const [openEditPhaseBudget, setOpenEditPhaseBudget] = useState(false);

  const [openDeleteParticipant, setOpenDeleteParticipant] = useState(false);
  const [participantToDelete, setParticipantToDelete] =
    useState<IBusiness | null>();
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [phaseBudget, setPhaseBudget] = useState("");
  const [edittedPhase, setEdittedPhase] = useState<IProjectPhase | null>(null);
  const [projectBudget, setProjectBudget] = useState("");
  const [phaseName, setPhaseName] = useState("");
  const [phases, setPhases] = useState<IProjectPhase[]>([]);
  const [phaseDescription, setPhaseDescription] = useState("");
  const [projectDescription, setProjectDescription] = useState("");

  /// for add participants modal
  const [bussinesses, setBusinesses] = useState<IBusiness[]>([]);
  const [selectedBusinesses, setSelectedBusinesses] = useState<
    IBusiness[] | null
  >(null);

  ///Files states
  const [projectFile, setProjectFile] = useState<any>();
  const [phaseFile, setPhaseFile] = useState<any>();
  const [fileDescription, setFileDescription] = useState("");
  const [currentPhase, setCurrentPhase] = useState<IProjectPhase | null>(null);

  const { requiredAttribute } = useAuthContext();
  const isProjectAdmin = requiredAttribute(
    AccountAttribute.ProjectsAdministrator,
  );

  const fetchProjectById = async () => {
    try {
      const res = await client.getProjectById(id!);
      if (isProjectAdmin) {
        // const legalPerson = await client.getLegalPersonByCui(
        //   res.beneficiary.cui
        // );
        const legalPersons = await Promise.all(
          res.participants?.map(async (participant: any) => {
            const legalPersonObj = await client.getLegalPersonByCui(
              participant.id,
            );
            return { ...participant, legalPersonObj };
          }),
        );
        //setLegalPerson(legalPersons);
      } else {
        const legalPerson = await client.getUserFeed();
        //setLegalPerson([legalPerson]);
      }
      setProject(res);
      setPhases(res?.phases);

      setIsLoading(false);
    } catch (err) {
      // console.log(err);

      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchProjectById();
  }, []);

  const handleAddPhase = async () => {
    if (!project) return;
    let _errors: { [key: string]: any } = {};

    if (!startDate) {
      _errors.startDate = "Introduceti data de inceput!";
    }
    if (!endDate) {
      _errors.endDate = "Introduceti data finalizarii!";
    }

    if (!phaseBudget) {
      _errors.budget = "Introduceti bugetul fazei!";
    }
    if (!phaseName) {
      _errors.phaseName = "Introduceti numele fazei";
    }
    if (!phaseDescription) {
      _errors.description = "Introduceti o descriere a fazei";
    }

    setErrors(_errors);

    if (
      _errors.startDate ||
      _errors.endDate ||
      _errors.budget || // uncomment when added on backend
      _errors.phaseName
    )
      return;

    const addPhaseParams: {
      [key: string]: any;
    } = {};

    addPhaseParams["projectFolderId"] = project.id;
    addPhaseParams["startDate"] = startDate;
    addPhaseParams["endDate"] = endDate;
    addPhaseParams["budget"] = phaseBudget;
    addPhaseParams["name"] = phaseName;
    addPhaseParams["description"] = phaseDescription;

    setIsSubmitting(true);

    try {
      await client.addProjectPhase(addPhaseParams).then(async () => {
        toast.success(t("PHASE_ADD_SUCCESS"));
        await fetchProjectById();

        setstartDate("");
        setendDate("");
        setOpenAddPhase(false);
        setPhaseBudget("");
        setPhaseName("");
        setPhaseDescription("");
      });
    } catch (e) {
      // console.log(e);
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  /// --- HANDLE FILE UPLOADS
  const handleProjectFileUpload = async () => {
    if (!project) return;
    try {
      const sendFile = projectFile[0];

      const res = await client
        .uploadProjectFile(project.id, sendFile, fileDescription)
        .then(() => {
          fetchProjectById();
          setOpenAddDocumentsToProject(false);
          setProjectFile(null);
          setFileDescription("");
        });
      console.log(res);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
    }
  };

  const handlePhaseFileUpload = async (phase: IProjectPhase | null) => {
    if (!project || !phase) return;
    try {
      const sendFile = phaseFile[0];

      const res = await client
        .uploadPhaseFile(phase.id, sendFile, fileDescription)
        .then(() => {
          fetchProjectById();
          setOpenAddDocumentsToPhase(false);
          setPhaseFile(null);
          setFileDescription("");
        });
      console.log(res);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        return toast.error(message);
      });
    }
  };

  const handleCuiSearch = async (searchQuery: string) => {
    try {
      const res = await client.getBusinesses(searchQuery);
      const notAddedBusinesses = res.filter((business) => {
        return !project?.participants.some(
          (participant) => participant.id === business.id,
        );
      });
      setBusinesses(notAddedBusinesses);
    } catch (err: any) {
      // console.log(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleEditProjectFolder = async () => {
    if (!project) return;
    try {
      const res = await client
        .editProjectFolder(
          project.id,
          project?.name,
          projectBudget == "" ? undefined : projectBudget,
          projectDescription == "" ? undefined : projectDescription,
        )
        .then(() => {
          fetchProjectById();
          setOpenEditProjectBudget(false);
          setOpenEditProjectDescription(false);
        });
    } catch (err: any) {
      // console.log(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleEditProjectPhase = async (phase: IProjectPhase | null) => {
    if (!project || !phase) return;
    try {
      const res = await client
        .editProjectPhase(phase.id, phase?.name, phaseBudget)
        .then(() => {
          fetchProjectById();
          setOpenEditPhaseBudget(false);
        });
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleAddBusiness = async () => {
    if (!project) return;
    try {
      if (selectedBusinesses && selectedBusinesses.length > 0) {
        // add project
        const businessIdsArray = selectedBusinesses.map((business) => {
          return business.id!;
        });
        const res = await client
          .addParticipantsToProject(project.id, businessIdsArray)
          .then(() => {
            toast.success(t("PROJECT_PARTICIPANTS_ADD_SUCCESS"));
            setOpenAddParticipants(false);
            setSelectedBusinesses(null);
            fetchProjectById();
          });
      }
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }

    setIsSubmitting(false);
  };

  const handleDeleteBusiness = async () => {
    console.log(participantToDelete, project);
    if (!project || !participantToDelete) return;
    try {
      // add project

      const res = await client
        .removeParticipantFromProject(project.id, participantToDelete.id)
        .then(() => {
          toast.success(t("PROJECT_PARTICIPANTS_REMOVE_SUCCESS"));
          setOpenDeleteParticipant(false);
          setSelectedBusinesses(null);
          fetchProjectById();
        });
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }

    setIsSubmitting(false);
  };

  if (!project) {
    return (
      <Container style={{ textAlign: "center" }}>
        <Spinner />
      </Container>
    );
  }

  let sumOfPhasesBudget: number = 0;

  let sumOfPhaseTasksBudget: number[] = new Array(
    project.phases.length + 1,
  ).fill(0);
  project.phases.forEach((phase, i) => {
    sumOfPhasesBudget += phase.budget ?? 0;
    phase.tasks.forEach((task) => {
      sumOfPhaseTasksBudget[i] += task.budget ?? 0;
    });
  });

  const sumOfAllTasks = sumOfPhaseTasksBudget.reduce(
    (acc, current) => acc + current,
    0,
  );

  const budgetWarningMessages = [];
  if (sumOfPhasesBudget > project?.budget)
    budgetWarningMessages.push(
      t("PROJECT_PHASES_SUM") +
        sumOfPhasesBudget +
        " RON, " +
        t("PROJECTS_SUM_HIGHER_THAN_BUDGET") +
        project.budget +
        " RON",
    );

  if (sumOfAllTasks > project?.budget)
    budgetWarningMessages.push(
      t("PROJECT_TASKS_SUM") +
        sumOfAllTasks +
        " RON, " +
        t("PROJECTS_SUM_HIGHER_THAN_BUDGET") +
        project.budget +
        " RON",
    );

  const budgetWarningIcon =
    sumOfPhasesBudget <= project?.budget && sumOfAllTasks <= project?.budget
      ? faCheck
      : faWarning;

  return (
    <>
      <h2 className="fw-bold mb-5 text-uppercase text-center">
        {project.name}
      </h2>

      <div style={{ display: "flex" }}>
        <Container className="px-0">
          <Row xs={12}>
            <Col xs={6} className="p-1">
              <Card>
                <Card.Body>
                  <h3 className="mb-16">
                    {t("PROJECT_PARTICIPANTS")}
                    {isProjectAdmin && (
                      <Button
                        className="btn btn-success btn-sm mx-2"
                        onClick={() => {
                          setOpenAddParticipants(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    )}
                  </h3>
                  <hr></hr>
                  <Table responsive className="align-items-center table-flush">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{t("PROJECTS_PARTICIPANTS_NAME")}</th>
                        <th scope="col">{t("PROJECTS_BUSINESS_IDENTIFIER")}</th>
                        <th scope="col"> </th>
                      </tr>
                    </thead>
                    <tbody>
                      {project.participants?.map((participant: IBusiness) => {
                        return (
                          <tr key={participant.id}>
                            <td>{participant.denumire}</td>
                            <td>{participant.cui}</td>
                            <td>
                              {isProjectAdmin && (
                                <Button
                                  className="btn btn-danger btn-sm mx-2"
                                  onClick={() => {
                                    setOpenDeleteParticipant(true);
                                    setParticipantToDelete(participant);
                                  }}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              <Card className="mt-2">
                <Card.Body>
                  <h3 className="mb16">
                    {t("PROJECTS_DESCRIPTION")}
                    {isProjectAdmin && (
                      <Button
                        variant="primary"
                        className="btn-sm"
                        onClick={() => {
                          setOpenEditProjectDescription(true);
                          setProjectDescription(
                            project.description?.toString(),
                          );
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </Button>
                    )}
                  </h3>
                  <hr></hr>
                  <p>
                    {project.description ?? t("PROJECTS_DESCRIPTION_DEFAULT")}
                  </p>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={6} className="p-1">
              <Card>
                <Card.Body>
                  <h3 className="mb16">{t("PROJECTS_DETAILS")}</h3>
                  <hr></hr>
                  <h5>{t("PROJECTS_START_DATE")}</h5>
                  <p> {dayjs(project.startDate).format("DD-MM-YYYY")}</p>
                  <h5>{t("PROJECTS_END_DATE")}</h5>
                  <p> {dayjs(project.endDate).format("DD-MM-YYYY")}</p>
                  <h5>
                    {t("PROJECTS_BUDGET")}{" "}
                    {isProjectAdmin && (
                      <Button
                        variant="primary"
                        className="btn-sm"
                        onClick={() => {
                          setOpenEditProjectBudget(true);
                          setProjectBudget(project.budget?.toString());
                        }}
                      >
                        <FontAwesomeIcon icon={faEdit}></FontAwesomeIcon>
                      </Button>
                    )}
                  </h5>
                  <p>
                    {project.budget}{" "}
                    {budgetWarningMessages.length == 0 ? (
                      <FontAwesomeIcon
                        icon={budgetWarningIcon}
                      ></FontAwesomeIcon>
                    ) : (
                      <>
                        <OverlayTrigger
                          overlay={
                            <Tooltip style={{ whiteSpace: "pre-wrap" }}>
                              {budgetWarningMessages.join("\n")}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            icon={budgetWarningIcon}
                            color="red"
                          ></FontAwesomeIcon>
                        </OverlayTrigger>
                      </>
                    )}
                  </p>
                </Card.Body>
              </Card>
              <Card className="mt-2">
                <Card.Body>
                  <h3 className="mb16">
                    {t("PROJECT_DOCUMENTS")}
                    {isProjectAdmin && (
                      <Button
                        className="btn btn-success btn-sm mx-2"
                        onClick={() => {
                          setOpenAddDocumentsToProject(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </Button>
                    )}
                  </h3>

                  <hr></hr>
                  {project.fileData?.length == 0 &&
                    t("PROJECT_NO_DOCUMENT_UPLOADED")}
                  {project.fileData?.map((file, index) => {
                    const fileObject: {
                      id: string;
                      description: string;
                      name: string;
                    } = JSON.parse(file);
                    return (
                      <Row key={fileObject.id}>
                        <OverlayTrigger
                          overlay={<Tooltip>{fileObject.description}</Tooltip>}
                        >
                          <a
                            onClick={() => {
                              return client.getFile(fileObject.id);
                            }}
                          >
                            {index + 1}
                            {". "} {fileObject.name ?? fileObject.id}
                          </a>
                        </OverlayTrigger>
                      </Row>
                    );
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Card>
              <Card.Body>
                <h3
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="mt-1">{t("PROJECTS_PHASES")}</span>
                  {isProjectAdmin && (
                    <span className="mx-2">
                      <Button
                        variant="outline-primary"
                        className="m-1"
                        style={{ float: "right" }}
                        onClick={() => setOpenAddPhase(true)}
                      >
                        <FontAwesomeIcon
                          icon={faProjectDiagram}
                          className="me-2"
                        />
                        {t("PROJECTS_ADD_NEW_PHASE")}
                      </Button>
                    </span>
                  )}
                </h3>
                <hr></hr>

                {/* Card for phases */}
                {phases.length > 0 ? (
                  <Tab.Container
                    defaultActiveKey={phases[0]?.id}
                    unmountOnExit={true}
                  >
                    <Row>
                      <Col lg={12}>
                        <Nav className="nav-tabs">
                          {phases.map((phase) => {
                            return (
                              <Nav.Item key={phase.id}>
                                <Nav.Link
                                  eventKey={phase.id}
                                  className="mb-sm-3 mb-md-0"
                                >
                                  {phase.name}
                                </Nav.Link>
                              </Nav.Item>
                            );
                          })}
                        </Nav>
                      </Col>
                      <Col lg={12}>
                        <Tab.Content>
                          {phases.map((phase, index) => {
                            return (
                              <Tab.Pane
                                key={phase.id + "t"}
                                eventKey={phase.id}
                                className="py-4"
                              >
                                {
                                  <>
                                    <Card.Body>
                                      <Row>
                                        <h2>{phase.name}</h2>
                                      </Row>
                                      <Row>
                                        <h6>
                                          {t("PROJECTS_PHASE_DESCRIPTION")}
                                        </h6>
                                        <p style={{ whiteSpace: "pre-wrap" }}>
                                          {phase.description}
                                        </p>
                                      </Row>
                                      <Row>
                                        <Col>
                                          <h5>
                                            {t("PROJECTS_PHASE_START_DATE")}
                                          </h5>
                                          <p>
                                            {" "}
                                            {dayjs(phase.startDate).format(
                                              "DD-MM-YYYY",
                                            )}
                                          </p>
                                        </Col>
                                        <Col>
                                          <h5>
                                            {t("PROJECTS_PHASE_END_DATE")}
                                          </h5>
                                          <p>
                                            {" "}
                                            {dayjs(phase.endDate).format(
                                              "DD-MM-YYYY",
                                            )}
                                          </p>
                                        </Col>
                                        <Col>
                                          <h5>
                                            {t("PROJECTS_PHASE_BUDGET")}
                                            {isProjectAdmin && (
                                              <Button
                                                variant="primary"
                                                className="btn-sm"
                                                onClick={() => {
                                                  setEdittedPhase(phase);
                                                  setPhaseBudget(
                                                    phase.budget?.toString(),
                                                  );
                                                  setOpenEditPhaseBudget(true);
                                                }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faEdit}
                                                ></FontAwesomeIcon>
                                              </Button>
                                            )}
                                          </h5>
                                          <p>
                                            {phase.budget} RON{" "}
                                            {sumOfPhaseTasksBudget[index] <=
                                            phase.budget ? (
                                              <FontAwesomeIcon
                                                icon={faCheck}
                                              ></FontAwesomeIcon>
                                            ) : (
                                              <>
                                                <OverlayTrigger
                                                  overlay={
                                                    <Tooltip
                                                      style={{
                                                        whiteSpace: "pre-wrap",
                                                      }}
                                                    >{`Suma totala a activitatilor ${sumOfPhaseTasksBudget[index]} RON este mai mare decat suma alocata fazei ${phase.budget} RON `}</Tooltip>
                                                  }
                                                >
                                                  <FontAwesomeIcon
                                                    icon={faWarning}
                                                    color="red"
                                                  ></FontAwesomeIcon>
                                                </OverlayTrigger>
                                              </>
                                            )}
                                          </p>
                                        </Col>
                                      </Row>
                                      <h3 className="mb16">
                                        {t("PROJECTS_PHASE_DOCUMENTS")}
                                        {isProjectAdmin && (
                                          <Button
                                            className="d-inline btn btn-success btn-sm mx-2"
                                            onClick={() => {
                                              setOpenAddDocumentsToPhase(true);
                                              setCurrentPhase(phase);
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faPlus} />
                                          </Button>
                                        )}
                                        <hr />
                                      </h3>

                                      {phase.fileData?.length == 0 &&
                                        t("PROJECT_NO_DOCUMENT_UPLOADED")}
                                      {phase.fileData?.map((file, index) => {
                                        const fileObject: {
                                          id: string;
                                          description: string;
                                          name: string;
                                        } = JSON.parse(file);
                                        return (
                                          <Row key={fileObject.id}>
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>
                                                  {fileObject.description}
                                                </Tooltip>
                                              }
                                            >
                                              <a
                                                onClick={() => {
                                                  return client.getFile(
                                                    fileObject.id,
                                                  );
                                                }}
                                              >
                                                {index + 1}
                                                {". "}{" "}
                                                {fileObject.name ??
                                                  fileObject.id}
                                              </a>
                                            </OverlayTrigger>
                                          </Row>
                                        );
                                      })}

                                      <Row>
                                        {" "}
                                        {/*GANTT HERE ------------- */}
                                        <GanttGraph
                                          phase={phase}
                                          fetchParent={fetchProjectById}
                                          project={project}
                                        ></GanttGraph>
                                      </Row>
                                    </Card.Body>
                                  </>
                                }
                              </Tab.Pane>
                            );
                          })}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                ) : (
                  <h4 className="text-center">{t("PROJECTS_NO_PHASES")}</h4>
                )}
              </Card.Body>
            </Card>
          </Row>
          <Row>
            <Col xs={12} className="p-1">
              <Card>
                <Card.Body>
                  {
                    <>
                      <h3 className="mb16">{t("PROJECTS_CHAT")}</h3>

                      {project.chat && (
                        <ChatBox
                          id={project.chat.id}
                          canUserDelete={isProjectAdmin}
                        />
                      )}
                    </>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal pentru creare faza */}
      <Modal
        show={openAddPhase}
        size="lg"
        onHide={() => {
          setOpenAddPhase(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_ADD_NEW_PHASE")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    {t("PROJECT_ADD_NEW_PHASE_STARTDATE")}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="startDate"
                    name="startDate"
                    className={errors.startDate && "error-control"}
                    onClick={(e) => {
                      const targetElement = e.target as HTMLInputElement;
                      targetElement.showPicker();
                    }}
                    value={startDate && startDate}
                    onChange={({ target: { value } }) => setstartDate(value)}
                  />
                </Form.Group>
                {errors.startDate && (
                  <span className="error-message">{errors.startDate}</span>
                )}
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("PROJECT_ADD_NEW_PHASE_ENDDATE")}</Form.Label>
                  <Form.Control
                    type="date"
                    id="endDate"
                    name="endDate"
                    className={errors.endDate && "error-control"}
                    onClick={(e) => {
                      const targetElement = e.target as HTMLInputElement;
                      targetElement.showPicker();
                    }}
                    value={endDate && endDate}
                    onChange={({ target: { value } }) => setendDate(value)}
                  />
                </Form.Group>
                {errors.endDate && (
                  <span className="error-message">{errors.endDate}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-2" id="budget">
                  <Form.Label>{t("PROJECT_ADD_NEW_PHASE_BUDGET")}</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ex: 123.45"
                    id="budget"
                    required
                    className={errors.budget && "error-control"}
                    value={phaseBudget && phaseBudget}
                    onChange={({ target: { value } }) => setPhaseBudget(value)}
                  />
                  {errors.budget && (
                    <span className="error-message">{errors.budget}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-2" id="phaseName">
                  <Form.Label>{t("PROJECT_ADD_NEW_PHASE_NAME")}</Form.Label>
                  <Form.Control
                    type="text"
                    id="phaseName"
                    required
                    className={errors.phaseName && "error-control"}
                    value={phaseName && phaseName}
                    onChange={({ target: { value } }) => setPhaseName(value)}
                  />
                  {errors.phaseName && (
                    <span className="error-message">{errors.phaseName}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-2" id="description">
                <Form.Label>
                  {t("PROJECT_ADD_NEW_PHASE_DESCRIPTION")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  id="description"
                  name="description"
                  value={phaseDescription}
                  className={errors.description && "error-control"}
                  onChange={({ target: { value } }) =>
                    setPhaseDescription(value)
                  }
                />
                {errors.description && (
                  <span className="error-message">{errors.description}</span>
                )}
              </Form.Group>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddPhase}
            disabled={isSubmitting}
          >
            {t("add")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL FOR ADDING PARTICIPANTS */}
      <Modal
        show={openAddParticipants}
        size="lg"
        onHide={() => {
          setOpenAddParticipants(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_ADD_PARTICIPANTS")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group>
                <Form.Label>
                  {t("PROJECT_ADD_PARTICIPANTS_SELECT_BENEFICIARY")}
                </Form.Label>
                <AsyncTypeahead
                  filterBy={() => true} // Bypass client-side filtering by returning `true`. Results are already
                  // filtered by the search endpoint, so no need to do it again.
                  id="bussiness"
                  isLoading={isLoading}
                  labelKey="denumire"
                  minLength={2}
                  onSearch={handleCuiSearch}
                  onChange={(selected: any) => {
                    if (selected && selected.length > 0) {
                      setSelectedBusinesses(selected);
                    }
                  }}
                  options={bussinesses}
                  placeholder="Cautare dupa CUI ..."
                  renderMenuItemChildren={(business: any) => (
                    <>
                      <span style={{ fontWeight: "bold" }}>
                        {business.cui} / {business.denumire}
                      </span>
                    </>
                  )}
                  multiple={true}
                />
                {errors.business && (
                  <span className="error-message">{errors.business}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddBusiness}
            disabled={isSubmitting}
          >
            {t("add")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL FOR CONFIRMING DELETE PARTICIPANT */}
      <Modal
        show={openDeleteParticipant}
        size="lg"
        onHide={() => {
          setOpenDeleteParticipant(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_DELETE_PARTICIPANT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Text>
                {t("project_participant")}
                <b>
                  {participantToDelete?.denumire} / {participantToDelete?.cui}
                </b>
                {t("project_participant_removed")}
              </Form.Text>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="danger"
            type="submit"
            onClick={handleDeleteBusiness}
            disabled={isSubmitting}
          >
            {t("PROJECT_CONFIRM_DELETE")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Documente */}
      <Modal
        show={openAddDocumentsToProject}
        size="lg"
        onHide={() => {
          setOpenAddDocumentsToProject(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_ADD_DOCUMENT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Control
                type="file"
                id="projectFile"
                required
                onChange={async ({ target: { files } }: any) =>
                  setProjectFile(files)
                }
              />
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-2" id="fileDescription">
              <Form.Label>{t("PROJECT_ADD_DOCUMENT_DESCRIPTION")}</Form.Label>
              <Form.Control
                as="textarea"
                id="fileDescription"
                name="fileDescription"
                value={fileDescription}
                className={errors.fileDescription && "error-control"}
                onChange={({ target: { value } }) => setFileDescription(value)}
              />
              {errors.description && (
                <span className="error-message">{errors.description}</span>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            onClick={handleProjectFileUpload}
            disabled={isSubmitting}
          >
            {t("PROJECT_SAVE_DOCUMENT")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal Documente */}
      <Modal
        show={openAddDocumentsToPhase}
        size="lg"
        onHide={() => {
          setOpenAddDocumentsToPhase(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PHASE_ADD_DOCUMENT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Control
                type="file"
                id="projectFile"
                required
                onChange={async ({ target: { files } }: any) =>
                  setPhaseFile(files)
                }
              />
            </Col>
          </Row>
          <Row>
            <Form.Group className="mb-2" id="fileDescription">
              <Form.Label>{t("PROJECT_ADD_DOCUMENT_DESCRIPTION")}</Form.Label>
              <Form.Control
                as="textarea"
                id="fileDescription"
                name="fileDescription"
                value={fileDescription}
                className={errors.fileDescription && "error-control"}
                onChange={({ target: { value } }) => setFileDescription(value)}
              />
              {errors.description && (
                <span className="error-message">{errors.description}</span>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            onClick={() => handlePhaseFileUpload(currentPhase)}
            disabled={isSubmitting}
          >
            {t("PROJECT_SAVE_DOCUMENT")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openEditProjectBudget}
        size="lg"
        onHide={() => {
          setOpenEditProjectBudget(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_EDIT_BUDGET")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group className="mb-2" id="budget">
              <Form.Label>{t("PROJECT_ALLOCATED_BUDGET")}</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ex: 123.45"
                id="budget"
                required
                max={0xffffffffff}
                className={errors.projectBudget && "error-control"}
                value={projectBudget && projectBudget}
                onChange={({ target: { value } }) => setProjectBudget(value)}
              />
              {errors.projectBudget && (
                <span className="error-message">{errors.projectBudget}</span>
              )}
              {/* <Form.Label>Avertizari privind bugetul</Form.Label>
              {budgetWarningMessages.forEach((message) => {
                return (
                  <p>
                    <FontAwesomeIcon
                      icon={faWarning}
                      color="yellow"
                    ></FontAwesomeIcon>
                    {message}
                  </p>
                );
              })} */}
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            onClick={handleEditProjectFolder}
            disabled={isSubmitting}
          >
            {t("save_change")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openEditProjectDescription}
        size="lg"
        onHide={() => {
          setOpenEditProjectDescription(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_EDIT_DESCRIPTION")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group className="mb-2" id="budget">
              <Form.Label>{t("PROJECT_DESCRIPTION")}</Form.Label>
              <Form.Control
                as="textarea"
                id="projectDescription"
                name="projectDescription"
                value={projectDescription}
                className={errors.projectDescription && "error-control"}
                onChange={({ target: { value } }) =>
                  setProjectDescription(value)
                }
              />
              {errors.projectDescription && (
                <span className="error-message">
                  {errors.projectDescription}
                </span>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            onClick={handleEditProjectFolder}
            disabled={isSubmitting}
          >
            {t("save_change")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openEditPhaseBudget}
        size="lg"
        onHide={() => {
          setOpenEditPhaseBudget(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("PROJECT_PHASE_EDIT_BUDGET")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group className="mb-2" id="budget">
              <Form.Label>{t("PROJECT_PHASE_ALLOCATED_BUDGET")}</Form.Label>
              <Form.Control
                type="number"
                placeholder="Ex: 123.45"
                id="budget"
                required
                max={0xffffffffff}
                className={errors.phaseBudget && "error-control"}
                value={phaseBudget && phaseBudget}
                onChange={({ target: { value } }) => setPhaseBudget(value)}
              />
              {errors.projectBudget && (
                <span className="error-message">{errors.projectBudget}</span>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            type="submit"
            onClick={() => handleEditProjectPhase(edittedPhase)}
            disabled={isSubmitting}
          >
            {t("save_change")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatDate } from "../../helpers/utilities";
import { useAPIClient } from "../../helpers/api";
import { IHearingRequest } from "../../cjt";
import LoadingView from "../../layout/LoadingView";
import HearingRequestModal, {
  HearingRequestRejectModal,
} from "./HearingRequestModal";
import handleException from "../../helpers/exceptions";
import { useTranslation } from "react-i18next";

function HearingRequest() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [request, setRequest] = useState<IHearingRequest | null>(null);
  const [requestId, setRequestId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const toggleRejectModal = () => {
    setShowRejectModal(!showRejectModal);
  };

  const getRequest = async () => {
    if (requestId === null) {
      return;
    }

    setLoading(true);

    try {
      const request = await client.getHearingRequestById(requestId);
      setRequest(request);
    } catch (error) {
      // toast.error("Cererea nu a putut fi găsită!");
      // console.log(error);
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setRequestId(searchParams.get("id") || "");
    getRequest();
  }, [requestId]);

  if (loading || !request) {
    return <LoadingView />;
  }

  return (
    <div className="container mt-5">
      <HearingRequestModal
        show={showModal}
        onHide={toggleModal}
        desiredPerson={request.auditor}
        inviteePerson={request.user}
        hearingReason={request.hearingReason}
        context={request.context}
        id={request.id}
      />
      <HearingRequestRejectModal
        show={showRejectModal}
        onHide={toggleRejectModal}
        id={request.id}
      />
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h2 className="fw-bold mb-5 text-uppercase text-center">
            Cerere de audiență
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5">
          <h4 className="mb-4">
            Solicitare: Audiență {request.auditor.displayName}
          </h4>
          <h5>
            deschis pe: {formatDate(request.createdAt ? request.createdAt : "")}
          </h5>
          <hr></hr>
          <h4>
            {request.context === "pf" ? "Persoană fizică" : "Persoană juridică"}
          </h4>
          <p>
            {request.context === "pj" ? (
              <>
                <p className="lead">Denumire: {request.business?.denumire}</p>
                <p className="lead">CUI: {request.business?.cui}</p>
                <p className="lead">Tip: {request.business?.tip}</p>
                <h5>Reprezentata prin: </h5>
              </>
            ) : (
              <></>
            )}
          </p>
          <p className="lead">
            Nume:{" "}
            {request.user.profile.nume + " " + request.user.profile.prenume}
          </p>
          <p className="lead">Email: {request.user.email}</p>
          <p className="lead">{"CNP: " + request.user.profile.cnp}</p>
          <a
            target="_blank"
            rel="noreferrer"
            href={"/admin/user/" + request.user.email}
          >
            Vezi profilul &gt;
          </a>
          <hr></hr>
          <h4 className="mb-4">Obiectul audienței: </h4>
          <p>{request.hearingReason}</p>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <button onClick={toggleModal} className="btn btn-success">
              Aprobare
            </button>
            <button onClick={toggleRejectModal} className="btn btn-danger">
              Refuz
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HearingRequest;

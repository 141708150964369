import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

enum ConfirmationStatus {
  notConfirmed = 0,
  confirmed = 1,
  errors = -1,
}

function EmailConfirmed() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [confirmed, setConfirmed] = useState<ConfirmationStatus>(0);

  const queryParams = new URLSearchParams(window.location.search);
  const term = queryParams.get("token");

  useEffect(() => {
    //setLoading(true);

    if (term === null) {
      toast.error("Tokenul de confirmare nu a fost furnizat.");
      return;
    }
  }, []);

  const sendConfirmation = async () => {
    if (term === null) {
      toast.error("Tokenul de confirmare nu a fost furnizat.");
      return;
    }
    try {
      await client.confirmEmail(term);
      setConfirmed(ConfirmationStatus.confirmed);
    } catch (e: any) {
      // toast.error(e);
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
      setConfirmed(ConfirmationStatus.errors);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
      <div className="text-center">
        <img
          src="/logo-timis.png"
          width="80"
          height="100"
          className="rounded mx-auto d-block pb-2"
          alt="CJT Logo"
        />
        <h2 className="fw-bold mb-2 text-uppercase text-center">
          Consiliul Județean Timiș
        </h2>
        {loading ? (
          <LoadingView />
        ) : (
          <>
            {!confirmed ? (
              <Button onClick={sendConfirmation}>
                Confirma crearea contului!
              </Button>
            ) : (
              <></>
            )}
            <>
              {confirmed != 0 && (
                <>
                  <p className="fs-3">
                    <span
                      className={confirmed > 0 ? "text-success" : "text-danger"}
                    >
                      {confirmed > 0
                        ? "Email-ul dvs. a fost confirmat!"
                        : "Eroare la confirmarea contului"}
                    </span>
                  </p>
                  <p className="lead">
                    {confirmed > 0
                      ? "Puteți folosi contul pentru a vă autentifica."
                      : "Vă rugăm să consultați secțiunea de suport."}
                  </p>
                  <Button
                    variant="primary"
                    href="/login"
                    hidden={confirmed ? false : true}
                  >
                    Autentificare
                  </Button>
                </>
              )}
            </>
          </>
        )}
      </div>
    </div>
  );
}
export default EmailConfirmed;

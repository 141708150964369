import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import LoadingView from "../../layout/LoadingView";
import FormScreen from "../../components/Forms/FormScreen";

export default function CompleteForm() {
  const { id } = useParams();

  if (!id) {
    return <LoadingView></LoadingView>;
  }

  return (
    <Container className="mt-5 mb-6">
      <FormScreen id={id} set={() => {}} />
    </Container>
  );
}

import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusiness, IContractFolder, IProjectFolder } from "../../../cjt";
import { useAuthContext } from "../../../context/AuthContext";
import { AccountAttribute } from "../../../enums";
import { useAPIClient } from "../../../helpers/api";
import handleException from "../../../helpers/exceptions";
import LoadingView from "../../../layout/LoadingView";

export default function ListProjects() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [openAddProjectModal, setOpenAddProject] = useState(false);
  const [projects, setProjects] = useState<IProjectFolder[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bussinesses, setBusinesses] = useState<IBusiness[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedBusinesses, setSelectedBusinesses] = useState<
    IBusiness[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [newProjectName, setNewProjectName] = useState("");

  const { requiredAttribute } = useAuthContext();

  const isProjectAdmin = requiredAttribute(
    AccountAttribute.ProjectsAdministrator,
  );

  const fetchProjectList = async () => {
    try {
      const res: IProjectFolder[] = await client.getAllProjects(
        false /* showOnlyOwn*/,
      );

      setProjects(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const getPartenersLabel = (project: IProjectFolder /* Add interface */) => {
    var label: string = "";
    const businesses: any[] = project.participants;
    businesses.every((business, i) => {
      // use every in order to break by returning false when the condition is met
      if (label.length < 30) {
        if (30 - label.length < business.denumire.length) {
          label +=
            String(business.denumire).substring(0, 30 - label.length) + "...";
          return false;
        } else label += business.denumire;

        label += i == businesses.length - 1 ? " " : ", ";

        return true;
      } else {
        label += "...";
        return false;
      }
    });

    console.log(label);
    return label;
  };

  const handleStartDateChanged = (value: string) => {
    setStartDate(value);
  };

  const handleEndDateChanged = (value: string) => {
    setEndDate(value);
  };

  const handleClickOnDate = (event: any) => {
    event.target.showPicker();
  };

  const getFullPartenersString = (
    project: IProjectFolder /* Add interface */,
  ) => {
    var fullName: string = "";
    const businesses: Partial<IBusiness>[] = project.participants;
    businesses.forEach((business, i) => {
      fullName += business.denumire;
      fullName += i == businesses.length - 1 ? " " : ", ";
    });

    return fullName;
  };

  useEffect(() => {
    fetchProjectList();
  }, []);

  const handleCuiSearch = async (searchQuery: string) => {
    try {
      const res = await client.getBusinesses(searchQuery);
      setBusinesses(res);
    } catch (err: any) {
      // console.log(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleAddProject = async () => {
    let _errors: { [key: string]: any } = {};

    if (!newProjectName.trim()) {
      _errors.projectName = t("PROJECT_NAME_EMPTY");
    }
    if (!selectedBusinesses?.length) {
      _errors.business = t("PROJECT_MUST_ADD_BUSINESS");
    }

    if (!startDate?.length) {
      _errors.startDate = t("PROJECT_STARTDATE_EMPTY");
    }

    if (!endDate?.length) {
      _errors.endDate = t("PROJECT_ENDDATE_EMPTY");
    }

    setErrors(_errors);
    console.log("POST");
    if (_errors.projectName || _errors.business) return;

    setIsSubmitting(true);
    try {
      if (selectedBusinesses && selectedBusinesses.length > 0) {
        // add project
        const businessIdsArray = selectedBusinesses.map((business) => {
          return business.id!;
        });
        const res = await client
          .addProject(newProjectName, startDate, endDate, businessIdsArray)
          .then(() => {
            toast.success("Proiect adaugat cu succes");
            setOpenAddProject(false);
            setNewProjectName("");
            setSelectedBusinesses(null);
            fetchProjectList();
          });
      }
      //   toast.success("Proiect adaugat cu succes");
      //   setOpenAddProject(false);
      //   setNewProjectName("");
      //   setSelectedBusinesses(null);
      //   fetchProjectList();
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   toast.error("Eroare de retea");
      // }
      // if (err.response.data.error === "Forbidden") {
      //   toast.error("Nu aveti drepturi de editare contracte");
      // } else {
      //   toast.error(err.response.data.message);
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }

    setIsSubmitting(false);
  };

  console.log(projects);

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5 text-uppercase text-center">
              {t("PROJECTS")}
            </h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={12} className="mb-2">
                    {
                      <Button
                        variant="outline-primary"
                        className="m-1"
                        //   style={{ float: "right" }}
                        onClick={() => setOpenAddProject(true)}
                      >
                        <FontAwesomeIcon icon={faAdd} className="me-2" />
                        Deschide dosar
                      </Button>
                    }
                  </Col>
                  <Col lg={12}>
                    {projects && projects.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h5 className="mb-0">{t("NO_PROJECTS_ADDED")}</h5>
                      </div>
                    ) : (
                      <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                          {isLoading ? (
                            <LoadingView />
                          ) : (
                            <Table
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="border-0">#</th>
                                  <th className="border-0">
                                    {t("PROJECTS_TABLEHEAD_DATE")}
                                  </th>
                                  <th className="border-0">
                                    {t("PROJECTS_TABLEHEAD_NAME")}
                                  </th>
                                  <th className="border-0">
                                    {t("PROJECTS_TABLEHEAD_PARTNERS")}
                                  </th>
                                  <th className="border-0 text-center"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderBy(projects, ["createdAt"], ["desc"]).map(
                                  (project, i) => (
                                    <tr key={i}>
                                      <td>
                                        <Card.Link
                                          href="#"
                                          className="text-primary fw-bold"
                                        >
                                          {i + 1}
                                        </Card.Link>
                                      </td>
                                      <td>
                                        {dayjs(project.createdAt).format(
                                          "DD-MM-YYYY",
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: "pre-wrap" }}>
                                        {project.name}
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip className="m-0">
                                              {getFullPartenersString(project)}
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {getPartenersLabel(project)}
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          href={
                                            isProjectAdmin
                                              ? "/admin/project-details/" +
                                                project.id
                                              : "/account/project-details/" +
                                                project.id
                                          }
                                        >
                                          {t("PROJECT_TABLE_SEEDETAILS")}
                                        </Button>
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </Table>
                          )}
                        </Card.Body>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal de adaugare dosar */}
      <Modal
        show={openAddProjectModal}
        size="lg"
        onHide={() => setOpenAddProject(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Adauga proiect nou</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="projectName">
                  <Form.Label>Denumire proiect</Form.Label>
                  <Form.Control
                    id="projectName"
                    className={errors.projectName && "error-control"}
                    type="text"
                    value={newProjectName && newProjectName}
                    onChange={({ target: { value } }) =>
                      setNewProjectName(value)
                    }
                    required
                  />
                </Form.Group>
                {errors.projectName && (
                  <span className="error-message">{errors.projectName}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Selectați beneficiar</Form.Label>
                  <AsyncTypeahead
                    filterBy={() => true} // Bypass client-side filtering by returning `true`. Results are already
                    // filtered by the search endpoint, so no need to do it again.
                    id="bussiness"
                    isLoading={isLoading}
                    labelKey="denumire"
                    minLength={2}
                    className={errors.projectName && "error-control"}
                    onSearch={handleCuiSearch}
                    onChange={(selected: any) => {
                      console.log(selected);
                      if (selected && selected.length > 0) {
                        setSelectedBusinesses(selected);
                      }
                    }}
                    options={bussinesses}
                    placeholder="Cautare dupa CUI ..."
                    renderMenuItemChildren={(business: any) => (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {business.cui} / {business.denumire}
                        </span>
                      </>
                    )}
                    multiple={true}
                  />
                  {errors.business && (
                    <span className="error-message">{errors.business}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>Setați data de început pentru proiect</Form.Label>
                  <Form.Control
                    type="date"
                    id="datetimestart"
                    name="startDate"
                    value={startDate}
                    onChange={({ target: { value } }) =>
                      handleStartDateChanged(value)
                    }
                    className="form-control"
                    onClick={handleClickOnDate}
                  />
                  {errors.startDate && (
                    <span className="error-message">{errors.startDate}</span>
                  )}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Setați data de sfârșit pentru proiect</Form.Label>
                  <Form.Control
                    type="date"
                    id="datetimeend"
                    name="endDate"
                    value={endDate}
                    onChange={({ target: { value } }) =>
                      handleEndDateChanged(value)
                    }
                    className="form-control"
                    onClick={handleClickOnDate}
                  />
                  {errors.endDate && (
                    <span className="error-message">{errors.endDate}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenAddProject(false)}>
            Renunta
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddProject}
            disabled={isSubmitting}
          >
            Salveaza
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Form, FormGroup } from "@themesberg/react-bootstrap";
import { t } from "i18next";
import { toast } from "react-hot-toast";
import TextareaAutosize from "react-textarea-autosize";

import { IGrantSubmission } from "../cjt";
import { useAPIClient } from "../helpers/api";
import handleException from "../helpers/exceptions";

export default function ContestationWidget({
  grantSubmission,
}: {
  grantSubmission: IGrantSubmission;
}) {
  const client = useAPIClient();

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Body>
        <h5>{t("grant_apply.appeal")}</h5>
        <div className="d-flex">
          <h6 className="pe-4">{t("grant_apply.appeal_status")}</h6>
          <p>{grantSubmission.contestationStatus}</p>
        </div>
        <FormGroup>
          <Form.Label>{t("grant_apply.appeal_text")}</Form.Label>
          <TextareaAutosize
            id="contestationText"
            name="contestationText"
            className="form-control"
            readOnly
            value={
              grantSubmission.contestation ? grantSubmission.contestation : ""
            }
            rows={10}
          />
        </FormGroup>

        <Button
          variant="outline-primary"
          className="mt-4"
          onClick={() =>
            client.downloadContestationDocument(grantSubmission.id)
          }
        >
          <FontAwesomeIcon icon={faDownload} className="me-2" />{" "}
          {t("grant_apply.download_contestation_document")}
        </Button>

        {grantSubmission.eloHasFile && (
          <Button
            onClick={async () => {
              try {
                await client.getGrantSubmissionContestationDocument(
                  grantSubmission.id,
                );
              } catch (err: any) {
                const errorMessages = handleException(err, t);
                errorMessages.forEach((message) => {
                  toast.error(message);
                });
              }
            }}
          >
            {t("grant_apply.get_contestation_document_button")}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

import React from "react";
import { createCustomMessage } from "react-chatbot-kit";
import { useTranslation } from "react-i18next";

import { ICategory, IQuestion } from "../../cjt";
import { useAPIClient } from "../../helpers/api";

type Props = {
  createChatBotMessage: (msq: string, options?: {}) => void;
  setState: (state: any) => void;
  children?: React.ReactNode;
};
const ActionProvider = ({
  createChatBotMessage,
  setState,
  children,
}: Props) => {
  const client = useAPIClient();
  const { t } = useTranslation();

  const handleCategories = () => {
    const botMessage = createChatBotMessage(
      "Acestea sunt categoriile disponibile:",
      {
        widget: "categories",
      },
    );

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleQuestions = async (category: ICategory) => {
    const questions = await client.getQuestions(category.id);

    const botMessage = createChatBotMessage(category.name + " :", {
      widget: "questions",
      payload: questions,
    });

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const containsLink = (text: string) => {
    const urlPattern = /((https?|ftp):\/\/[^\s/$.?#].[^\s]*)/gi;
    return urlPattern.test(text);
  };

  const handleAnswer = (question: IQuestion) => {
    if (containsLink(question.answer)) {
      const botMessage = createCustomMessage("Test", "custom", {
        payload: question.answer,
      });

      setState((prev: any) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    } else {
      const botMessage = createChatBotMessage(question.answer);

      setState((prev: any) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    }
  };

  const handleHello = () => {
    const botMessage = createChatBotMessage(
      "Bună, eu sunt chatbotul tău. Cum te pot ajuta?",
      {
        widget: "categories",
      },
    );

    setState((prev: any) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  };

  const handleSendChatMessage = async (message: string) => {
    try {
      const res = await client.sendChatMessage(message);
      console.log("Chat", res);
      const questions: any[] = [];
      res.map((item: any) => {
        questions.push(item);
      });
      questions.push({
        id: "0",
        content: t("chatbot.not_found_question"),
        answer: t("chatbot.contact_cjtimis"),
      });
      const botMessage = createChatBotMessage(
        "Alege o intrebare de mai jos: ",
        {
          widget: "questions",
          payload: questions,
        },
      );
      setState((prev: any) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
    } catch (error: any) {
      if (error.response.data.statusCode === 404) {
        const botMessage = createChatBotMessage(
          t("chatbot.keyword_not_found"),
          {
            widget: "notfound",
          },
        );
        // const questions: any[] = [];
        // questions.push({
        //   id: "0",
        //   content: "Contact CJTimis",
        //   answer:
        //     "Suna la +40 256 406 406 sau trimite un email pe adresa cjt@cjtimis.ro.",
        // });
        // const botMessageQ = createChatBotMessage("Contactaza CJTimis", {
        //   widget: "questions",
        //   payload: questions,
        // });
        setState((prev: any) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
        }));
      }
    }
  };

  return (
    <div style={{ whiteSpace: "pre-line" }}>
      {React.Children.map(children, (child: any) => {
        return React.cloneElement(child, {
          actions: {
            handleCategories,
            handleQuestions,
            handleAnswer,
            handleHello,
            handleSendChatMessage,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;

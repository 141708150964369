import { Container } from "react-bootstrap";

function LoadingView() {
  return (
    <>
      <Container className="text-center pb-3 pt-3">
        <span className="button">
          <span className="spinner-border spinner-border-sm" />
        </span>
      </Container>
    </>
  );
}

export default LoadingView;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { BASE_URL } from "../../config/env";
import { useAuthContext } from "../../context/AuthContext";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { validateEmail, validatePassword } from "../../helpers/validators";
import { Routes } from "../../routes";
import CaptchaForm from "../Captcha/CaptchaForm";

function ResendActivationLink() {
  const client = useAPIClient();
  const { isLoggedIn, login } = useAuthContext();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();

  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [showHelpBanner, setShowHelpBanner] = useState(false);
  const [resendRrrors, setResendRrrors] = useState<Boolean>(false);
  const [captchaResponse, setCaptchaResponse] = useState<string | null>(null);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const changeLanguage = () => {
    if (i18n.language === "ro") {
      i18n.changeLanguage("en");
      localStorage.setItem("language", "en");
    } else {
      i18n.changeLanguage("ro");
      localStorage.setItem("language", "ro");
    }
  };

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    if (isLoggedIn) navigate("/");
  }, [navigate, isLoggedIn]);

  const submitForm = async (data: FieldValues) => {
    setLoading(true);

    try {
      validateEmail(data.email);
      const res = await client.resendActivationLinkEmail(
        data.email,
        captchaResponse,
      );
      console.log("in login", res);
      navigate("/resend-link-succes");
    } catch (error: any) {
      setResendRrrors(true);
      //toast.error(t(error.response?.data?.statusCode ?? "999"));
      // toast.error(t("resend_activation_link_error"));
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Container>
        <Row className="vh-100 d-flex justify-content-center align-items-center">
          <Col md={8} lg={6} xs={12}>
            <Card className="shadow">
              <Card.Body>
                <div
                  className="d-flex  mt-2 mb-3"
                  style={{ justifyContent: "center", width: "100%" }}
                >
                  <img
                    className="ui centered big image w-100-mobile"
                    src="/poca2.png"
                    style={{ width: "40%", maxWidth: "450px" }}
                  ></img>
                </div>
                <div className="mb-3 mt-md-4">
                  <img
                    src="/logo-timis.png"
                    width="80"
                    height="100"
                    className="rounded mx-auto d-block pb-2"
                    alt="CJT Logo"
                  />
                  <h2 className="fw-bold mb-2 text-uppercase text-center">
                    {t("institution")}
                  </h2>
                  <p className="mb-4 text-center">
                    {t("resend_activation_link")}
                  </p>
                  <div
                    className="alert alert-info text-center"
                    style={{ display: showHelpBanner ? "block" : "none" }}
                  >
                    <span>Mesaj Eroare / Success</span>
                  </div>
                  {!resendRrrors ? (
                    <div className="mb-3">
                      <Form onSubmit={handleSubmit(submitForm)}>
                        <Form.Group className="mb-4" controlId="formBasicEmail">
                          <Form.Label className="text-center">
                            {t("email_label")}
                          </Form.Label>
                          <Form.Control
                            type="email"
                            placeholder={t("email_placeholder")}
                            {...register("email", { required: true })}
                            required
                          />
                        </Form.Group>

                        <CaptchaForm
                          onCaptchaSolved={(response: string | null) => {
                            return setCaptchaResponse(response);
                          }}
                          captchaRef={recaptchaRef}
                        />

                        <div className="d-grid mt-3">
                          {loading ? (
                            <span className="button mt-4 text-center">
                              <span className="spinner-border spinner-border-sm" />
                            </span>
                          ) : (
                            <Button variant="primary" type="submit">
                              {t("resend_activation_link_button")}
                            </Button>
                          )}
                        </div>
                      </Form>

                      <div className="mt-3">
                        <p className="mb-0  text-center">
                          <a href="/login" className="text-primary fw-bold">
                            {t("login_page_login_button")}
                          </a>
                        </p>
                      </div>
                      <div>
                        <Button variant="link" onClick={() => changeLanguage()}>
                          {i18n.language === "ro" ? "🇷🇴 RO" : "🇬🇧 EN"}
                        </Button>
                      </div>
                    </div>
                  ) : (
                    t("resend_activation_link_error")
                  )}
                </div>
              </Card.Body>
            </Card>
          </Col>
          <p className="text-center text-muted mt-3 mb-3">
            {t("poca.text1")} <br></br>
            <a
              target="_blank"
              className="text-primary fw-bold text-decoration-none"
              href="http://www.poca.ro"
            >
              www.poca.ro
            </a>
            <br></br>
            {t("poca.text2")}{" "}
            <a
              target="_blank"
              className="text-primary fw-bold text-decoration-none"
              href="https://www.fonduri-ue.ro"
            >
              www.fonduri-ue.ro
            </a>
            <br></br>
            {t("poca.text3")}
            <br></br>
            <br></br>
            <a href={t("footer.terms.link")} className="mx-2">
              {t("footer.terms")}
            </a>
            <a href={t("footer.privacy.link")}>{t("footer.privacy")}</a>
            <br></br>
            {t("credits")}
            <a
              className="text-primary fw-bold text-decoration-none"
              href="https://ici.ro"
            >
              {t("ici")}
            </a>
            .
          </p>
        </Row>
      </Container>
    </>
  );
}

export default ResendActivationLink;

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form } from "@formio/react";
import { useAPIClient } from "../../helpers/api";
import type { ICalendar, IProiect, IProiectCategory, ITemplateBody, IUser } from "../../cjt";
import Location from "../../components/Location";
import { OptionType } from "../../modules/SmartForms/SubmitForm";
import handleException from "../../helpers/exceptions";
import { DosarStatus } from "../../enums";

type Props = {
  id: string;
  set: Dispatch<SetStateAction<OptionType | null>>;
  calendar?: ICalendar;
  redirectURL?: string;
  proiectCategories?: IProiectCategory[];
};

export default function FormScreen({ id, set, calendar, proiectCategories, redirectURL }: Props) {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [user, setUser] = useState<IUser | null>(null);
  const [form, setForm] = useState<ITemplateBody | null>(null);
  const [userLocation, setLocation] = useState<{
    latitude: number;
    longitude: number;
  }>({ latitude: 0, longitude: 0 });

  const fetchUser = async () => {
    try {
      const user = await client.getUserFeed();
      setUser(user);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const fetchForm = async () => {
    try {
      const form = await client.getTemplateById(id);
      setForm(form);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchForm();
    fetchUser();
  }, []);

  const handleCreateProject = async (submissionId: string) => {
    try {
      const formData = {
        title: " ",
        description: " ",
        status: DosarStatus.Waiting,
        beneficiar: " ",
        localizare: " ",
        buget: 0,
      } as IProiect;

      if(proiectCategories){
        const categories = proiectCategories.map((option: any) => ({
          id: option.value,
          denumire: option.label,
        })) as IProiectCategory[];
        formData.category = categories
      }
      console.log(formData);
      await client.createProiectByFormSubmissionId(submissionId, formData);
      toast.success(t("form.success_create_project"));
    } catch (error) {
      toast.error(t("form.error_create_project") + error);
    }
  };

  const handleSubmit = async (submissionData: any) => {
    if (form == null || user == null) {
      return;
    }

    if (
      userLocation.latitude === 0 &&
      userLocation.longitude === 0 &&
      form.template.needsLocation
    ) {
      toast.error(t("form.error_location"));
      return;
    }
    try {
      await client
        .sendSubmission(
          form.template.name,
          form.body,
          JSON.stringify(submissionData),
          form.template.needsLocation ? JSON.stringify(userLocation) : null,
          calendar ? calendar : undefined
        )
        .then((responseData) => {
          toast.success(t("form_send_success"));
          setForm(null);
          set(null);
          console.log(responseData);

          if (calendar) {
            handleCreateProject(responseData.id);
          }

          if (redirectURL) {
            window.location.href = redirectURL;
            //navigate(redirectURL);
          } else {
            navigate("/operatiuni/forms");
          }
        });
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  if (form === null || form.body === null || user === null) {
    return;
  }

  return (
    <>
      <div className="card-body card">
        <h2>{form.template.name}</h2>
        <hr></hr>
        <div>
          {form.template.needsLocation && (
            <Location setUserLocation={setLocation} />
          )}
        </div>
        {((form.template.needsLocation &&
          userLocation.latitude !== 0 &&
          userLocation.longitude !== 0) ||
          !form.template.needsLocation) && (
          <Form src={JSON.parse(form.body)} onSubmit={handleSubmit} />
        )}
      </div>
    </>
  );
}

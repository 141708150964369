import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, ToggleButton } from "@themesberg/react-bootstrap";
import { Button, Form, Modal, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
  ToggleButtonGroup,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusiness, IContractFolder } from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export default function ListContracts() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [openAddContractModal, setOpenAddContractModal] = useState(false);
  const [contracts, setContracts] = useState<IContractFolder[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bussinesses, setBusinesses] = useState<IBusiness[]>([]);
  const [selectedBusiness, setSelectedBusiness] = useState<IBusiness | null>(
    null,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [newContractName, setNewContractName] = useState("");
  const [showOnlyOwn, setShowOnlyOwn] = useState(true);

  const { requiredAttribute } = useAuthContext();

  const isContractAdmin = requiredAttribute(
    AccountAttribute.ContractsAdministrator,
  );

  const fetchContractList = async (showOnlyOwn: boolean) => {
    try {
      const res = await client.getContractList(showOnlyOwn);

      setContracts(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchContractList(true);
  }, []);

  const handleCuiSearch = async (searchQuery: string) => {
    try {
      const res = await client.getBusinesses(searchQuery);
      setBusinesses(res);
    } catch (err: any) {
      // console.log(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleAddContract = async () => {
    let _errors: { [key: string]: any } = {};

    if (!newContractName.trim()) {
      _errors.contractName = t("contract.error_empty");
    }
    if (!selectedBusiness?.id) {
      _errors.business = t("contract.error_business");
    }

    setErrors(_errors);

    if (_errors.contractName || _errors.business) return;

    setIsSubmitting(true);
    try {
      if (selectedBusiness && selectedBusiness.id) {
        await client.addContract(newContractName, selectedBusiness.id);
      }
      toast.success(t("contract.success_add_ctr"));
      setOpenAddContractModal(false);
      setNewContractName("");
      setSelectedBusiness(null);
      fetchContractList(true);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }

    setIsSubmitting(false);
  };

  const handleShowOnlyOwnChange = (value: string) => {
    if (showOnlyOwn.toString() === value) return;
    setShowOnlyOwn(value === "true");
    fetchContractList(value === "true");
  };

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5 text-uppercase text-center">
              {t("contracts.list")}
            </h2>

            <Card>
              <Card.Body>
                <Row>
                  <Col
                    lg={12}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    {isContractAdmin && (
                      <>
                        <Button
                          variant="outline-primary"
                          className="m-1"
                          onClick={() => setOpenAddContractModal(true)}
                        >
                          <FontAwesomeIcon icon={faAdd} className="me-2" />
                          {t("contracts.add")}
                        </Button>
                        <ToggleButtonGroup type="radio" name="options">
                          <ToggleButton
                            variant={
                              !showOnlyOwn ? "primary" : "outline-primary"
                            }
                            value="false"
                            onClick={() => handleShowOnlyOwnChange("false")}
                          >
                            {t("contracts.list_all")}
                          </ToggleButton>
                          <ToggleButton
                            variant={
                              showOnlyOwn ? "primary" : "outline-primary"
                            }
                            value="true"
                            onClick={() => handleShowOnlyOwnChange("true")}
                          >
                            {t("contracts.list_own")}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </>
                    )}
                  </Col>
                  <Col lg={12}>
                    {contracts && contracts.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h5 className="mb-0">{t("contracts.no_contracts")}</h5>
                      </div>
                    ) : (
                      <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                          {isLoading ? (
                            <LoadingView />
                          ) : (
                            <Table
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="border-0">#</th>
                                  <th className="border-0">
                                    {t("contracts.date")}
                                  </th>
                                  <th className="border-0">
                                    {t("contracts.name")}
                                  </th>
                                  <th className="border-0">
                                    {t("contracts.beneficiary")}
                                  </th>
                                  <th className="border-0 text-center">
                                    {t("state")}
                                  </th>
                                  <th className="border-0 text-center"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderBy(
                                  contracts,
                                  ["createdAt"],
                                  ["desc"],
                                ).map((contract, i) => (
                                  <tr key={i}>
                                    <td>
                                      <Card.Link
                                        href="#"
                                        className="text-primary fw-bold"
                                      >
                                        {i + 1}
                                      </Card.Link>
                                    </td>
                                    <td>
                                      {dayjs(contract.createdAt).format(
                                        "DD-MM-YYYY",
                                      )}
                                    </td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>
                                      {contract.name}
                                    </td>
                                    <td>{contract.beneficiary?.denumire}</td>
                                    <td
                                      style={{
                                        textAlign: "center",
                                        verticalAlign: "middle",
                                      }}
                                    >
                                      {contract.status === "Cancelled" ? (
                                        <Badge bg="danger" className="me-1">
                                          {t("contract_details.cancelled")}
                                        </Badge>
                                      ) : contract.status === "Opened" ? (
                                        <Badge bg="info" className="me-1">
                                          {t("contract.Opened")}
                                        </Badge>
                                      ) : contract.status ===
                                        "WaitingForSignatures" ? (
                                        <Badge
                                          bg="warning"
                                          text="dark"
                                          className="me-1"
                                        >
                                          {t("contract.WaitingForSignatures")}
                                        </Badge>
                                      ) : (
                                        <Badge bg="success" className="me-1">
                                          {t("contract.Ongoing")}
                                        </Badge>
                                      )}
                                    </td>
                                    <td className="text-center">
                                      <Button
                                        variant="outline-primary"
                                        size="sm"
                                        href={
                                          isContractAdmin
                                            ? "/admin/contract-details/" +
                                              contract.id
                                            : "/account/contract-details/" +
                                              contract.id
                                        }
                                      >
                                        {t("contracts.details")}
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </Card.Body>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal de adaugare dosar */}
      <Modal
        show={openAddContractModal}
        size="lg"
        onHide={() => setOpenAddContractModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("contracts.add_contract")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group id="contractName">
                  <Form.Label>{t("contracts.contract_name")}</Form.Label>
                  <Form.Control
                    id="contractName"
                    className={errors.contractName && "error-control"}
                    type="text"
                    value={newContractName && newContractName}
                    onChange={({ target: { value } }) =>
                      setNewContractName(value)
                    }
                    required
                  />
                </Form.Group>
                {errors.contractName && (
                  <span className="error-message">{errors.contractName}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("contracts.select_beneficiary")}</Form.Label>
                  <AsyncTypeahead
                    filterBy={() => true} // Bypass client-side filtering by returning `true`. Results are already
                    // filtered by the search endpoint, so no need to do it again.
                    id="bussiness"
                    isLoading={isLoading}
                    labelKey="denumire"
                    minLength={2}
                    className={errors.contractName && "error-control"}
                    onSearch={handleCuiSearch}
                    onChange={(selected: any) => {
                      if (selected && selected.length > 0) {
                        setSelectedBusiness(selected[0]);
                      }
                    }}
                    options={bussinesses}
                    placeholder={t("contract_cui_search")}
                    renderMenuItemChildren={(business: any) => (
                      <>
                        <span style={{ fontWeight: "bold" }}>
                          {business.cui} / {business.denumire}
                        </span>
                      </>
                    )}
                  />
                  {errors.business && (
                    <span className="error-message">{errors.business}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setOpenAddContractModal(false)}
          >
            {t("contracts.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleAddContract}
            disabled={isSubmitting}
          >
            {t("contracts.save")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import { useEffect, useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Table, Button, Modal, Form } from "@themesberg/react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useAPIClient } from "../../../helpers/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { ICalendar } from "../../../cjt";
import dayjs from "dayjs";
import { BusinessType } from "../../../enums";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ITemplateBody } from "../../../cjt";
import { isValidCalendar } from "../../../helpers/validators";

function DefinireCalendar() {
  const { register, handleSubmit, getValues, reset } = useForm();
  const client = useAPIClient();
  const animatedComponents = makeAnimated();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [openAddCalendarModal, setOpenAddCalendarModal] = useState(false);
  const [openUpdateCalendarModal, setOpenUpdateCalendarModal] = useState(false);
  const [showConfirmDeletion, setShowConfirmDeletion] = useState(false);
  const [loading, setLoading] = useState(false);
  const [calendare, setCalendare] = useState<ICalendar[]>(); 
  const [currentSelectedCalendar, setCurrentSelectedCalendar] = useState<ICalendar>(); 
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);
  const [forms, setForms] = useState<ITemplateBody[]>([]);
  const [selectedForm, setSelectedForm] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [simulateCalendar, setSimulateCalendar] = useState(false);
  const [calendarIdAction, setCalendarIdAction] = useState<string>('');

  type Option = {
    label: string;
    value: BusinessType;
  };

  const businessTypeOptions: Option[] = Object.keys(BusinessType).map(
    (key) => ({
      label: key,
      value: BusinessType[key as keyof typeof BusinessType],
    })
  );

  const handleSelectChange = (selected: any) => {
    const updatedSelectedOptions = selected.map((option: any) => ({
      value: option.value,
      label: option.label,
    }));
    setSelectedOptions(updatedSelectedOptions);
  };

  const handleSelectForm = (selected: any) => {
    const eventSelection = selected.value;
    setSelectedForm(eventSelection);
  }

  const fetchForms = async () => {
    setIsLoading(true);
    try {
      const res = await client.getTemplates();
      const templates = await Promise.all(
        res.map((template) => {
          return client.getTemplateById(template.id);
        })
      );
      setForms(templates);
    } catch (err: any) {
      toast.error(err.message);
    }
    setIsLoading(false);
  };

  const getAllCalendar = async () => {
    try {
      const requests = await client.getAllCalendar();
      setCalendare(requests);
    } catch (error) {
      toast.error(error as string);
    }
  }

  useEffect(() => {
    fetchForms();
    getAllCalendar();
  }, []);

  const handleRegisterCalendar = async () => {
    try {
      setLoading(true);
      const formData = getValues() as ICalendar;
      
      if (!selectedForm) {
        toast.error(t("calendar.please_select_form"));
        return;
      }

      if (!simulateCalendar) {
        try {
          isValidCalendar(formData);
        } catch (err: any) {
          setLoading(false);
          toast.error(err as string);
          return;
        }
      }
      formData.allowed_types = selectedOptions.map(option => option.value);
      formData.formTemplate = selectedForm;
      await client.createCalendar(formData);
      toast.success(t("calendar.save_success"));
      setOpenAddCalendarModal(false);
      reset();
    } catch (error: any) {
      toast.error(t(error.response.data.message));
    } finally {
      setLoading(false);
      getAllCalendar();
    }
  }

  const handleUpdateCalendar = async () => {
    try {
      setLoading(true);
      const formData = getValues() as ICalendar;
      
      if (!selectedForm) {
        toast.error(t("calendar.please_select_form"));
        return;
      }
  
      if (!simulateCalendar) {
        try {
          isValidCalendar(formData);
        } catch (err: any) {
          setLoading(false);
          toast.error(err as string);
          return;
        }
      }
  
      formData.allowed_types = selectedOptions.map(option => option.value);
      formData.formTemplate = selectedForm;
  
      await client.updateCalendar(currentSelectedCalendar!.id, formData);
      toast.success(t("calendar.update_success"));
      setOpenUpdateCalendarModal(false);
      reset();
    } catch (error: any) {
      toast.error("Eroare în salvarea calendarului: " + error);
      console.error(error.message as string);
    } finally {
      setLoading(false);
      getAllCalendar();
    }
  }
  

  const handleArchiveCalendar = async (id: string) => {
    try {
      setLoading(true);
      await client.ArchiveCalendar(id);
      toast.success(t("calendar.archived_success"));
    } catch (error: any) {
      toast.error(t("calendar.archived_error"));
    } finally {
      setLoading(false);
      getAllCalendar();
    }
  }

  const handleDeleteCalendar = async (id: string) => {
    setShowConfirmDeletion(false);
    try {
      setLoading(true);
      await client.deleteCalendar(id);
      toast.success(t("calendar.delete_success"));
    } catch (error: any) {
      toast.error(t("calendar.delete_error"));
    } finally {
      setLoading(false);
      getAllCalendar();
    }
  }
  

  const handleUpdateModalClick = (element: ICalendar) => {
    setOpenUpdateCalendarModal(true);
    setCurrentSelectedCalendar({ ...element });
  }

  const handleClose = () => {
    setShowConfirmDeletion(false);
  };

  const handleDeleteModal = (calendarId: string) => {
    setShowConfirmDeletion(true)
    setCalendarIdAction(calendarId)
  }


  return (
    <>
      <Container>
        <Row>
          <Col xs={12} className="p-3">
            <Card>
              <h2 className="fw-bold mb-3 mt-3 text-uppercase text-center">
              {t("calendar.admin_page_title")}
              </h2>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                      <Button
                        variant="outline-primary"
                        className="m-1 mb-3"
                        // style={{ float: "right" }}
                        onClick={() => setOpenAddCalendarModal(true)}
                      >
                        <FontAwesomeIcon icon={faAdd} className="me-2" />
                        {t("calendar.create_calendar_button")}
                      </Button>
                  </Col>
                  <Col lg={12}>
                      <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                            <Table
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="border-0">{t("calendar.thead_name")}</th>
                                  <th className="border-0">{t("calendar.thead_dates")}</th>
                                  <th className="border-0">{t("calendar.thead_user_allowed_types")}</th>
                                  <th className="border-0">{t("calendar.thead_options")}</th>
                                </tr>
                              </thead>
                              <tbody>
                              {calendare?.length === 0 ? (
                                    <tr>
                                      <td colSpan={4} className="text-center">
                                      {t("calendar.no_defined_calendars")}. 
                                      </td>
                                    </tr>
                                ) : (
                                  calendare?.map(element => (
                                    <tr key={element.id}>
                                      <td>
                                      {t("calendar.calendar_name2")}:{' '}
                                        <Card.Link href="#" className="text-primary fw-bold">
                                          {element.denumire}
                                        </Card.Link><br></br>
                                        {t("calendar.attached_form2")}:{' '}
                                          {
                                            element.formTemplate ? (
                                              <b>{element.formTemplate.name}</b>
                                            ) : (
                                              <b>{t("calendar.form_not_defined")}</b>
                                            )
                                          } 
                                      </td>
                                      <td>
                                        <b>{t("calendar.project_submission")}:</b> {dayjs(element.depunere_proiecte_start).format("DD-MM-YYYY")} - {dayjs(element.depunere_proiecte_end).format("DD-MM-YYYY")}<br></br>
                                        <b>{t("calendar.project_validation")}:</b> {dayjs(element.validare_proiecte_start).format("DD-MM-YYYY")} - {dayjs(element.validare_proiecte_end).format("DD-MM-YYYY")} <br></br>
                                        <b>{t("calendar.project_display")}:</b> {dayjs(element.afisare_proiecte_start).format("DD-MM-YYYY")} <br></br>
                                        <b>{t("calendar.project_voting")}:</b> {dayjs(element.vot_proiecte_start).format("DD-MM-YYYY")} - {dayjs(element.vot_proiecte_end).format("DD-MM-YYYY")} <br></br>
                                        <b>{t("calendar.winning_projects")}:</b> {dayjs(element.anunt_proiecte_castigatoare).format("DD-MM-YYYY")} <br></br>
                                        <b>{t("calendar.project_implementation")}:</b> {t("calendar.date_starting_from2")} {dayjs(element.implementare_proiecte).format("DD-MM-YYYY")}
                                      </td>
                                      <td dangerouslySetInnerHTML={{ __html: element.allowed_types.map(type => type + ";<br />").join('') }}></td>
                                      <td>
                                        { element.arhivat ? <p className="mb-0"><b>{t("calendar.archived_status")}</b></p> :
                                        <>
                                          <Button variant="outline-danger" size="sm" href="#" onClick={() => handleArchiveCalendar(element.id)}>
                                            {t("calendar.archive")}
                                          </Button>
                                          <br></br>
                                        </>
                                        }
                                         {dayjs(element.depunere_proiecte_start).isAfter(new Date()) && (
                                        <Button className="mt-2" variant="outline-danger" size="sm" href="#" onClick={() => handleDeleteModal(element.id)}>
                                          {t("calendar.delete")}
                                        </Button>)}
                                        {/* Modal confirmare ștergere calendar */}
                                        <Modal show={showConfirmDeletion} onHide={handleClose}>
                                          <Modal.Header closeButton>
                                            <Modal.Title>{t("calendar.delete_title")}</Modal.Title>
                                          </Modal.Header>
                                          <Modal.Body>
                                            <p>{t("calendar.delete_confirm")}</p>
                                            <p><i>* {t("calendar.delete_confirm_details")}</i></p>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                            {t("calendar.cancel")}
                                            </Button>
                                            <Button variant="primary" onClick={() =>handleDeleteCalendar(calendarIdAction)}>
                                            {t("calendar.delete")}
                                            </Button>
                                          </Modal.Footer>
                                        </Modal>

                                        {dayjs(element.depunere_proiecte_start).isAfter(new Date()) && (
                                        <Button
                                          variant="outline-primary"
                                          className="mt-1 mb-1"
                                          size="sm"
                                          // style={{ float: "right" }}
                                          onClick={() => handleUpdateModalClick(element)}
                                        >
                                          {t("calendar.update_calendar")}
                                        </Button>)}
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>

                            </Table>
                        </Card.Body>
                      </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal setare calendar */}
      <Modal
        show={openAddCalendarModal}
        size="lg"
        onHide={() => setOpenAddCalendarModal(false)}
      >
        <Form onSubmit={handleSubmit(handleRegisterCalendar)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("calendar.set_new_calendar")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
          <Row className="mb-3">
              <h5>{t("calendar.calendar_name")}</h5>
              <Col md={12} className="mb-2">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  {/* <Form.Label style={{marginRight:"1em"}}>Denumirea calendarului:</Form.Label> */}
                  <Form.Control type="text" {...register("denumire", { required: true })} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.session_description")}</h5>
              <Col md={12} className="mb-2">
                <Form.Group className="d-flex align-items-center">
                  <Form.Control as="textarea" {...register("descriere", { required: true })} rows={4} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.attached_form")}</h5>
              <Col md={12} className="mb-2">
                <select className="form-control form-select" onChange={(event) => handleSelectForm(event.target)} value={selectedForm || ''}>
                <option value="" disabled>
                {t("calendar.select_form_dropdown")}
                </option>
                {isLoading ? (
                  <option>{t("loading")}</option>
                ) : (
                  forms.map((form) => (
                    <option key={form.template.id} value={form.template.id}>
                      {form.template.name}
                    </option>
                  ))
                )}
              </select>
            </Col>
          </Row>


            <Row className="mb-3">
              <h5>{t("calendar.project_submission")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("depunere_proiecte_start", { required: true })} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-end">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_to")}:</Form.Label>
                  <Form.Control type="date" {...register("depunere_proiecte_end", { required: true })} />
                </Form.Group>
              </Col>
            </Row>
            
            <Row className="mb-3">
              <h5>{t("calendar.project_validation")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("validare_proiecte_start", { required: true })} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-end">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_to")}:</Form.Label>
                  <Form.Control type="date" {...register("validare_proiecte_end", { required: true })} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.project_display")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("afisare_proiecte_start", { required: true })}/>
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.project_voting")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("vot_proiecte_start", { required: true })} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-end">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_to")}:</Form.Label>
                  <Form.Control type="date" {...register("vot_proiecte_end", { required: true })} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.winning_projects")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("anunt_proiecte_castigatoare", { required: true })} />
                </Form.Group>
              </Col>
            </Row>
            
            <Row className="mb-3">
              <h5>{t("calendar.project_implementation")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("implementare_proiecte", { required: true })} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.allowed_user_type")}</h5>
              <Col md={12} className="mb-3">
                <Form.Group className="d-flex align-items-center">
                      <Select
                      options={businessTypeOptions}
                      isMulti={true}
                      components={animatedComponents}
                      onChange={(selected: any) => {
                        handleSelectChange(selected);
                      }}
                      //styles={{}}
                      value={selectedOptions}
                      closeMenuOnSelect={false}
                      className="w-100"
                    />
                    {/* <Form.Control {...register("allowed_types", { required: true })} /> */}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.simulare_calendar")}</h5>
              <Col md={12} className="mb-2">
                <Form.Group className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label={t("calendar.simulare_calendar")}
                    checked={simulateCalendar}
                    onChange={(e) => setSimulateCalendar(e.target.checked)}
                  />
                </Form.Group>
              </Col>
            </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setOpenAddCalendarModal(false)}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
          >
            {t("calendar.save")}
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>

      {/* Modal update calendar */}
      <Modal
        show={openUpdateCalendarModal}
        size="lg"
        onHide={() => setOpenUpdateCalendarModal(false)}
      >
        <Form onSubmit={handleSubmit(handleUpdateCalendar)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("calendar.set_new_calendar")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          
            <Row className="mb-3">
              <h5>{t("calendar.calendar_name")}</h5>

              <Col md={12} className="mb-2">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Control type="text" {...register("denumire", { required: true })} defaultValue={currentSelectedCalendar?.denumire} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.session_description")}</h5>
              <Col md={12} className="mb-2">
                <Form.Group className="d-flex align-items-center">
                  <Form.Control as="textarea" {...register("descriere", { required: true })} rows={4} defaultValue={currentSelectedCalendar?.descriere} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.attached_form")}</h5>
              <Col md={12} className="mb-2">
                <select
                  className="form-control form-select"
                  onChange={(event) => handleSelectForm(event.target)}
                  value={selectedForm || ''}
                >
                <option value="" disabled> 
                {t("calendar.select_form_dropdown")}
                </option>
                  {isLoading ? (
                    <option>{t("loading")}...</option>
                  ) : (
                    forms.map((form) => (
                      <option
                        key={form.template.id}
                        value={form.template.id}
                        // Check if the current option should be selected
                        selected={form.template.id === currentSelectedCalendar?.formTemplate.id}
                      >
                        {form.template.name}
                      </option>
                    ))
                  )}
                </select>
              </Col>
          </Row>


            <Row className="mb-3">
              <h5>{t("calendar.project_submission")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("depunere_proiecte_start", { required: true })}
                    defaultValue={currentSelectedCalendar && currentSelectedCalendar.depunere_proiecte_start ? new Date( dayjs(currentSelectedCalendar.depunere_proiecte_start).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''}
                  />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-end">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_to")}:</Form.Label>
                  <Form.Control type="date" {...register("depunere_proiecte_end", { required: true })} 
                    defaultValue={currentSelectedCalendar && currentSelectedCalendar.depunere_proiecte_end ? new Date( dayjs(currentSelectedCalendar.depunere_proiecte_end).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
            </Row>
            
            <Row className="mb-3">
              <h5>{t("calendar.project_validation")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("validare_proiecte_start", { required: true })} 
                    defaultValue={currentSelectedCalendar && currentSelectedCalendar.validare_proiecte_start ? new Date( dayjs(currentSelectedCalendar.validare_proiecte_start).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-end">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_to")}:</Form.Label>
                  <Form.Control type="date" {...register("validare_proiecte_end", { required: true })} 
                    defaultValue={currentSelectedCalendar && currentSelectedCalendar.validare_proiecte_end ? new Date( dayjs(currentSelectedCalendar.validare_proiecte_end).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.project_display")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("afisare_proiecte_start", { required: true })} 
                  defaultValue={currentSelectedCalendar && currentSelectedCalendar.afisare_proiecte_start ? new Date( dayjs(currentSelectedCalendar.afisare_proiecte_start).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.project_voting")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("vot_proiecte_start", { required: true })} defaultValue={currentSelectedCalendar && currentSelectedCalendar.vot_proiecte_start ? new Date( dayjs(currentSelectedCalendar.vot_proiecte_start).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-end">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_to")}:</Form.Label>
                  <Form.Control type="date" {...register("vot_proiecte_end", { required: true })} defaultValue={currentSelectedCalendar && currentSelectedCalendar.vot_proiecte_end ? new Date( dayjs(currentSelectedCalendar.vot_proiecte_end).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.winning_projects")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("anunt_proiecte_castigatoare", { required: true })} defaultValue={currentSelectedCalendar && currentSelectedCalendar.anunt_proiecte_castigatoare ? new Date( dayjs(currentSelectedCalendar.anunt_proiecte_castigatoare).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
            </Row>
            
            <Row className="mb-3">
              <h5>{t("calendar.project_implementation")}</h5>
              <Col md={6} className="mb-3">
                <Form.Group className="d-flex align-items-center" id="depunere-start">
                  <Form.Label style={{marginRight:"1em"}}>{t("calendar.date_starting_from")}:</Form.Label>
                  <Form.Control type="date" {...register("implementare_proiecte", { required: true })} defaultValue={currentSelectedCalendar && currentSelectedCalendar.implementare_proiecte ? new Date( dayjs(currentSelectedCalendar.implementare_proiecte).format("YYYY-MM-DD")).toISOString().split('T')[0] : ''} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.allowed_user_type")}</h5>
              
              <Col md={12} className="mb-3">
                <Form.Group className="d-flex align-items-center">
                      <Select
                      options={businessTypeOptions}
                      isMulti={true}
                      components={animatedComponents}
                      onChange={(selected: any) => {
                        handleSelectChange(selected);
                      }}
                      //styles={{}}
                      value={selectedOptions}
                      closeMenuOnSelect={false}
                      className="w-100"
                    />
                    {/* <Form.Control {...register("allowed_types", { required: true })} /> */}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <h5>{t("calendar.simulare_calendar")}</h5>
              <Col md={12} className="mb-2">
                <Form.Group className="d-flex align-items-center">
                  <Form.Check
                    type="checkbox"
                    label={t("calendar.simulare_calendar")}
                    checked={simulateCalendar}
                    onChange={(e) => setSimulateCalendar(e.target.checked)}
                  />
                </Form.Group>
              </Col>
            </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setOpenUpdateCalendarModal(false)}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
          >
            {t("calendar.save")}
          </Button>
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default DefinireCalendar;
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Container, Table } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Modal } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { t } from "i18next";

import { IGrantCategory } from "../../cjt";
import { useAPIClient } from "../../helpers/api";

export default function ListGrantCategories() {
  const client = useAPIClient();
  const [categories, setCategories] = useState<IGrantCategory[]>([]);
  const [currCategoryID, setCurrCategoryID] = useState<string | null>(null);
  const [newCategory, setNewCategory] = useState("");
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const fetchCategories = async () => {
    try {
      const res = await client.getGrantCategories();
      setCategories(res);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleAddCategory = async () => {
    try {
      await client.addGrantCategory(newCategory);
      setShow(false);
      setNewCategory("");
      fetchCategories();
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditCategory = async () => {
    if (!currCategoryID) return;

    try {
      await client.updateGrantCategory(currCategoryID, newCategory);
      setShowEdit(false);
      setNewCategory("");
      fetchCategories();
    } catch (e) {
      console.log(e);
    }
  };

  const handleCategoryDelete = async (id: string) => {
    try {
      await client.deleteGrantCategory(id);
      fetchCategories();
    } catch (e) {
      toast.error(
        t("grant.error_used"),
      );
      console.log(e);
    }
  };

  return (
    <>
      <Container>
        <Card border="light" className="shadow-sm mb-4">
          <h3 className="p-4">{t("grant.categories")}</h3>
          <Card.Body>
            <Button
              variant="primary"
              className="my-3"
              onClick={() => setShow(true)}
            >
              {t("forum_new_category")}
            </Button>
            <Table>
              <thead>
                <tr>
                  <th>{t("grant.category_name")}</th>
                  <th>{t("grant.category_code")}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category) => (
                  <tr key={category.name}>
                    <td width="45%">{category.name}</td>
                    <td width="45%">{category.code}</td>
                    <td>
                      <ButtonGroup>
                        <Button
                          size="sm"
                          variant="primary"
                          onClick={() => {
                            setCurrCategoryID(category.id);
                            setNewCategory(category.name);
                            setShowEdit(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => handleCategoryDelete(category.id)}
                        >
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("forum_new_category")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder={t("grant.category_name")}
            value={newCategory}
            onChange={({ target: { value } }) => setNewCategory(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={handleAddCategory}>
            {t("calendar.add")} 
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showEdit} onHide={() => setShowEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("grant.category_modify")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder={t("grant.category_name")}
            value={newCategory}
            onChange={({ target: { value } }) => setNewCategory(value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEdit(false)}>
            {t("close")}
          </Button>
          <Button variant="primary" onClick={handleEditCategory}>
            {t("edit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

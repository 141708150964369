import axios from "axios";
import { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import { BASE_URL } from "../../config/env";
import { useAuthContext } from "../../context/AuthContext";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import {
  validatePassword,
  validatePasswordsMatch,
} from "../../helpers/validators";
import LoadingView from "../../layout/LoadingView";
import { Routes } from "../../routes";

function ResetPassword() {
  const { t } = useTranslation();
  const { token } = useParams();
  const navigate = useNavigate();
  const client = useAPIClient();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const [weakPassword, setWeakPassoword] = useState(false);
  const { logout } = useAuthContext();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  };

  const resetPassword = async (password: string) => {
    try {
      await axios.post(
        BASE_URL + `/auth/change-password`,
        { password },
        config,
      );

      setLoading(false);
    } catch (err: any) {
      if (err.response && err.response.data.statusCode === 401) {
        err.message = t("changepass.url");
        throw err.message;
      }
      throw err;
    }
  };

  const submitForm = async (data: FieldValues) => {
    setLoading(true);
    console.log(data);

    try {
      validatePasswordsMatch(data.newPassword, data.newPasswordConfirmation);
      console.log(data.formBasicPassword);
      try {
        validatePassword(data.newPassword);
      } catch {
        setWeakPassoword(true);
        throw t("PASSWORD_WEAK");
      }
      await resetPassword(data.newPassword);
      toast.success(t("changepass.success_change"));
      logout();
      navigate("/");
    } catch (e: any) {
      // toast.error(t(e));
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container className="mt-5">
      <Card className="shadow">
        <Card.Body>
          <div
            className="d-flex  mt-2 mb-3"
            style={{ justifyContent: "center", width: "100%" }}
          >
            <img
              className="ui centered big image w-100-mobile"
              src="/poca2.png"
              style={{ width: "40%", maxWidth: "450px" }}
            ></img>
          </div>
          <div className="mb-3 mt-md-4">
            <img
              src="/logo-timis.png"
              width="80"
              height="100"
              className="rounded mx-auto d-block pb-2"
              alt="CJT Logo"
            />
            <h2 className="fw-bold mb-2 text-uppercase text-center">
              {t("institution")}
            </h2>
            <h4 className="text-center">{t("password.reset_title")}</h4>
            <div
              className="alert alert-danger"
              style={{ display: weakPassword ? "block" : "none" }}
            >
              <p>{t("password_contains")}</p>
              <ul>
                <li>{t("min_char")}</li>
                <li>{t("min_lowercase")}</li>
                <li>{t("min_uppercase")}</li>
                <li>{t("min_special")}</li>
              </ul>
            </div>
            <div className="row d-flex justify-content-center align-items-center mt-5">
              <div className="mb-3 col-4">
                <Form onSubmit={handleSubmit(submitForm)}>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>
                      {t("my_profile_change_password_title")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("my_profile_new_password")}
                      {...register("newPassword", { required: true })}
                      // required
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicConfirmPassword"
                  >
                    <Form.Label>
                      {t("my_profile_confirm_new_password")}
                    </Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("my_profile_confirm_new_password")}
                      {...register("newPasswordConfirmation", {
                        required: true,
                      })}
                      // required
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicCheckbox"
                  ></Form.Group>
                  <div className="d-grid">
                    {loading ? (
                      <LoadingView />
                    ) : (
                      <Button variant="primary" type="submit">
                        {t("my_profile_change_password")}
                      </Button>
                    )}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <p className="text-center text-muted mt-3 mb-3">
        {t("poca.text1")} <br></br>
        <a
          target="_blank"
          className="text-primary fw-bold text-decoration-none"
          href="http://www.poca.ro"
        >
          www.poca.ro
        </a>
        <br></br>
        {t("poca.text2")}{" "}
        <a
          target="_blank"
          className="text-primary fw-bold text-decoration-none"
          href="https://www.fonduri-ue.ro"
        >
          www.fonduri-ue.ro
        </a>
        <br></br>
        {t("poca.text3")}
        <br></br>
        <br></br>
        <a href={t("footer.terms.link")} className="mx-2">
          {t("footer.terms")}
        </a>
        <a href={t("footer.privacy.link")}>{t("footer.privacy")}</a>
        <br></br>
        {t("credits")}
        <a
          className="text-primary fw-bold text-decoration-none"
          href="https://ici.ro"
        >
          {t("ici")}
        </a>
        .
      </p>
    </Container>
  );
}

export default ResetPassword;

import { faDownload, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Table } from "@themesberg/react-bootstrap";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { IHearing, IRecording } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { formatDate } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";

function ScheduledHearing() {
  const [hearing, setHearing] = useState<IHearing | null>(null);
  const [hearingId, setHearingId] = useState<string | null>(null);
  const [recordings, setRecordings] = useState<IRecording[] | null>(null);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const client = useAPIClient();
  const navigate = useNavigate();

  const getRequest = async () => {
    if (hearingId === null) {
      console.log("hearingId is null");
      return;
    }

    try {
      const _hearing = await client.getHearingById(hearingId);
      const _rec = await client.listHearingRecordings(_hearing.webexMeetingId);
      setLoading(false);
      setHearing(_hearing);
      setRecordings(_rec);

      console.log(_rec);
    } catch (err: any) {
      // console.log(err);
      // toast.error(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const getRecordings = () => {
    if (hearing === null) {
      console.log("hearing is null");
      return;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const hearingId = queryParams.get("id");
    setHearingId(hearingId);

    getRequest();
    getRecordings();
  }, [hearingId]);

  const handleCancel = async () => {
    if (hearingId === null) {
      console.log("hearingId is null");
      return;
    }

    try {
      await client.deleteHearing(hearingId);
      navigate("/admin/hearings");
      toast.success("Audiența a fost anulată!");
    } catch (error) {
      // toast.error("Audiența nu a putut fi anulată!");
      const errorMessages = handleException(error, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const getRequesterName = (hearing: IHearing) => {
    if (hearing.context === "personal") {
      return (
        hearing.invitees[0].profile?.nume +
        " " +
        hearing.invitees[0].profile?.prenume
      );
    } else {
      if (hearing.invitees[0].businesses?.length === 0) {
        return hearing.invitees[0].businesses[0]?.denumire;
      }
    }
  };

  const getRequesterId = (hearing: IHearing) => {
    if (hearing.context === "personal") {
      return hearing.invitees[0].profile?.cnp;
    } else {
      if (hearing.invitees[0].businesses?.length === 0) {
        return hearing.invitees[0].businesses[0]?.cui;
      }
    }
  };

  if (hearing === null || loading) {
    return <LoadingView />;
  }

  return (
    <div className="container-xxl mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <h2 className="fw-bold mb-5 text-uppercase text-center">
            Audiență Programată
          </h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-5 card card-body">
          <h4 className="mb-4">Audiență {hearing.auditor.realName}</h4>
          <h5>
            Dată începere:{" "}
            {moment(hearing.startDate, true).format("HH:mm, DD/MM/YYYY ") +
              "GMT+" +
              moment.tz("Europe/Bucharest").utcOffset() / 60}
          </h5>
          <h5>
            Dată finalizare:{" "}
            {moment(hearing.endDate, true).format("HH:mm, DD/MM/YYYY ") +
              "GMT+" +
              moment.tz("Europe/Bucharest").utcOffset() / 60}
          </h5>
          <h5>ID Webex: {hearing.webexMeetingId}</h5>
          <hr></hr>
          {hearing.context === "pj" ? (
            <>
              <h4>Persoana Juridica</h4>
              {hearing.invitedBusiness ? (
                <>
                  <p className="lead">
                    Denumire: {hearing.invitedBusiness.denumire ?? ""}
                  </p>
                  <p className="lead">
                    CUI: {hearing.invitedBusiness.cui ?? ""}
                  </p>
                  <p className="lead">Tip: {hearing.invitedBusiness.tip}</p>
                </>
              ) : (
                <>
                  <p className="lead">
                    Business-ul asociat audientei nu mai exista!
                  </p>
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <h4 className="mb-4">Invitati la audienta: </h4>
          <Table responsive className="align-items-center table-flush">
            <thead className="thead-light">
              <tr>
                <th scope="col" style={{ width: "30%" }}>
                  Solicitant
                </th>
                <th scope="col" style={{ width: "30%" }}>
                  Email
                </th>
                <th scope="col" style={{ width: "20%" }}>
                  Vizualizare Profil
                </th>
              </tr>
            </thead>
            <tbody>
              {hearing.invitees.map((invitee) => {
                return (
                  <tr>
                    <td>
                      {invitee.profile?.nume + " " + invitee.profile?.prenume}
                    </td>
                    <td>{invitee.email}</td>
                    <td>
                      <a className="" href={"/admin/user/" + invitee.email}>
                        Vezi profilul &gt;
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <hr></hr>
          <h4 className="mb-4">Obiectul audienței: </h4>
          <p>{hearing.hearingReason}</p>
          <hr></hr>
          <div className="d-flex justify-content-between">
            <button onClick={handleCancel} className="btn btn-danger">
              Anulare audiență
            </button>
          </div>
        </div>
        <div className="col-md-6 card card-body mx-3">
          <h4 className="mb-4">Înregistrări</h4>
          <Table>
            <thead className="thead-light">
              <tr>
                <th className="border-0">Data înregistrare</th>
                <th className="border-0">Durată</th>
                <th className="border-0 " style={{ textAlign: "center" }}>
                  Acțiuni
                </th>
              </tr>
            </thead>
            <tbody>
              {recordings?.map((rec) => (
                <tr key={rec.id}>
                  <td>{formatDate(rec.timeRecorded)}</td>
                  <td>{rec.durationSeconds} secunde</td>
                  <td style={{ textAlign: "center" }}>
                    <ButtonGroup>
                      <Button
                        variant="primary"
                        size="sm"
                        href={rec.downloadUrl}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        href={rec.playbackUrl}
                        target="_blank"
                      >
                        <FontAwesomeIcon icon={faPlay} />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default ScheduledHearing;

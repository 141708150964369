import { useCallback, useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab, Table } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { ITemplateBody } from "../../cjt";
import FormScreen from "../../components/Forms/FormScreen";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export interface OptionType {
  id: string;
  name: string;
}

type Category = {
  value: string;
  label: string;
};

function SubmitForm() {
  // !!need to cleanup this component later-> we should define the correct types here for options
  const client = useAPIClient();
  const { t } = useTranslation();
  const [forms, setForms] = useState<ITemplateBody[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selected, setSelected] = useState<OptionType | null>(null);
  const [categories, setCategories] = useState<Category[]>([]);

  const fetchCategories = useCallback(async () => {
    try {
      const res = await client.getFormsCategories();
      const return_categories: Category[] = Array.isArray(res)
        ? res.map((category) => ({
            value: category.id,
            label: category.label,
          }))
        : [];
      setCategories(return_categories);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  }, []);

  useEffect(() => {
    const fetchActiveForms = async () => {
      try {
        const res = await client.getActiveTemplates();
        const templates = await Promise.all(
          res.map((template) => {
            return client.getTemplateById(template.id);
          }),
        );
        setForms(templates);
        setIsLoading(false);
      } catch (err: any) {
        const errorMessages = handleException(err, t);
        errorMessages.forEach((message) => {
          toast.error(message);
        });
      }
    };
    fetchActiveForms();
    fetchCategories();
  }, [fetchCategories]);

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <Container className="mt-5 mb-6">
        <h6 className="mt-4 mb-2">{t("payments_select_category")}</h6>
        {selected && <FormScreen id={selected.id} set={setSelected} />}

        <div className="card-body card">
          <Tab.Container defaultActiveKey={categories[0]?.value}>
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                  {categories.map((category) => {
                    return (
                      <>
                        {" "}
                        <Nav.Item>
                          <Nav.Link
                            key={category.value}
                            eventKey={category.value}
                            className="mb-sm-3 mb-md-0"
                          >
                            {category.label}
                          </Nav.Link>
                        </Nav.Item>
                      </>
                    );
                  })}
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                  {categories.map((category) => {
                    return (
                        <Tab.Pane eventKey={category.value} className="py-4">
                          <Table>
                            <thead className="thead-light">
                              <tr>
                                <th className="border-0">{t("form_direct")}</th>
                                <th className="border-0">{t("form_name")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {forms
                                .filter((form) => {
                                  return (
                                    form.template.category?.label ==
                                    category.label
                                  );
                                })
                                .map((actualForm, index) => {
                                  return (
                                    <tr key={actualForm?.template?.id}>
                                      <td>
                                        <Button
                                          variant="primary"
                                          className="mb-2"
                                          size="sm"
                                          href={
                                            "/operatiuni/submit-form/" +
                                            actualForm.template.id
                                          }
                                        >
                                          {t("payments_complete_form")}
                                        </Button>
                                      </td>
                                      <td
                                        style={{
                                          wordBreak: "break-all",
                                          whiteSpace: "pre-wrap",
                                        }}
                                      >
                                        {actualForm.template.name}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Tab.Pane>
                    );
                  })}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
}

export default SubmitForm;

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faArchive,
  faBook,
  faComments,
  faCreditCardAlt,
  faEdit,
  faFileAlt,
  faFilePdf,
  faFolder,
  faInbox,
  faMoneyBill,
  faPhone,
  faSearch,
  faSignOutAlt,
  faTasks,
  faTimes,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Accordion,
  Badge,
  Button,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "@themesberg/react-bootstrap";
import { Color } from "@themesberg/react-bootstrap/lib/esm/types";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import SimpleBar from "simplebar-react";

import { useAuthContext } from "../context/AuthContext";
import { AccountAttribute, EModules } from "../enums";
import { Routes } from "../routes";

type NavItemProps = {
  title: string;
  link: string;
  external?: boolean;
  target?: string;
  icon?: IconProp;
  image?: string;
  badgeText?: string;
  badgeBg?: string;
  badgeColor?: Color;
  pathname?: string;
  setShow(show: boolean): void;
};

const NavItem = ({
  title,
  link,
  target,
  icon,
  image,
  badgeText,
  badgeBg = "secondary",
  badgeColor = "primary",
  setShow,
}: NavItemProps) => {
  const location = useLocation();
  const classNames = badgeText
    ? "d-flex justify-content-start align-items-center justify-content-between"
    : "";
  const navItemClassName = link === location.pathname ? "active" : "";

  return (
    <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
      <Nav.Link href={link} target={target} className={classNames}>
        <span style={{ display: "flex" }}>
          {icon ? (
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />{" "}
            </span>
          ) : null}
          {image ? (
            <Image
              src={image}
              width={40}
              height={40}
              className="sidebar-icon svg-icon"
            />
          ) : null}

          <span
            className="sidebar-text"
            style={{ whiteSpace: "break-spaces", textAlign: "center" }}
          >
            {title}
          </span>
        </span>
        {badgeText ? (
          <Badge
            pill
            bg={badgeBg}
            text={badgeColor}
            className="badge-md notification-count ms-2"
          >
            {badgeText}
          </Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};

type CollapsableNavItemProps = {
  eventKey?: string;
  title: string;
  icon: IconProp;
  children: React.ReactNode;
};

const CollapsableNavItem = ({
  eventKey = "",
  title,
  icon,
  children,
}: CollapsableNavItemProps) => {
  const location = useLocation();
  const defaultKey = location.pathname.indexOf(eventKey) !== -1 ? eventKey : "";

  return (
    <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Button
          as={Nav.Link}
          className="d-flex justify-content-between align-items-center"
        >
          <span>
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />{" "}
            </span>
            <span className="sidebar-text">{title}</span>
          </span>
        </Accordion.Button>
        <Accordion.Body className="multi-level">
          <Nav className="flex-column">{children}</Nav>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default function Sidebar() {
  const [show, setShow] = useState(false);
  const showClass = show ? "show" : "";
  const { t } = useTranslation();
  const { isAdmin, requiredAttribute, isLoggedIn, showModule, logout } =
    useAuthContext();
  const location = useLocation();
  const { hasWhiteboard } = useAuthContext();
  const getEventKey = (childRoutes: string[]) => {
    const isChildRoute = childRoutes.some((route: string) =>
      location.pathname.startsWith(route),
    );

    return isChildRoute ? "admin" : ",";
  };
  const onCollapse = () => setShow(!show);
  const logUserOut = () => {
    logout();
    toast.success(t("navbar_logout_message"));
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src="/logo-timis.png" className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image className="card-img-top rounded-circle border-white" />
                </div>
                <div className="d-block">
                  <h6>Bine ați venit</h6>
                  <Button
                    as={Link}
                    variant="secondary"
                    size="sm"
                    to=""
                    className="text-dark"
                    onClick={logUserOut}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className="me-2" />{" "}
                    {t("logout")}
                  </Button>
                </div>
              </div>
              <Nav.Link
                className="collapse-close d-md-none"
                onClick={onCollapse}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                title={t("institution")}
                link=""
                image="/logo-timis.png"
                setShow={setShow}
              />

              <Dropdown.Divider className="my-3 border-indigo" />
              {isLoggedIn && (
                <>
                  <NavItem
                    title={t("sidebar_ghiseul_virtual")}
                    link={Routes.DashboardOverview.path}
                    icon={faInbox}
                    setShow={setShow}
                  />

                  <CollapsableNavItem
                    eventKey="operatiuni"
                    title={t("sidebar_operatiuni")}
                    icon={faFileAlt}
                  >
                    <NavItem
                      title={t("sidebar_cereri_online")}
                      link={Routes.CereriOnline.path}
                      setShow={setShow}
                    />
                    <NavItem
                      title={t("sidebar_audiente_online")}
                      link={Routes.AudienteOnline.path}
                      setShow={setShow}
                    />
                    <NavItem
                      title={t("sidebar_payments.online")}
                      link={Routes.Plati.path}
                      setShow={setShow}
                    />
                  </CollapsableNavItem>

                  <NavItem
                    title={t("sidebar_cererile_mele")}
                    link={Routes.MyOnlineRequests.path}
                    icon={faArchive}
                    setShow={setShow}
                  />

                  {showModule(EModules.Contracts) && (
                    <NavItem
                      title={t("my_contracts")}
                      link="/account/contracts"
                      icon={faBook}
                      setShow={setShow}
                    />
                  )}

                  {showModule(EModules.Projects) && (
                    <NavItem
                      title={t("my_projects")}
                      link={Routes.ProjectList.path}
                      icon={faFolder}
                      setShow={setShow}
                    />
                  )}
                  {hasWhiteboard && (
                    <NavItem
                      title={t("my_whiteboards")}
                      link={Routes.MyWhiteboards.path}
                      icon={faEdit}
                      setShow={setShow}
                    />
                  )}

                  <CollapsableNavItem
                    eventKey="user-payments"
                    title={t("sidebar_payments.title")}
                    icon={faCreditCardAlt}
                  >
                    <NavItem
                      title={t("navbar_payments.online")}
                      link={Routes.OnlinePayments.path}
                      setShow={setShow}
                    />

                    <NavItem
                      title={t("navbar_payments.invoices")}
                      link={Routes.InvoicesList.path}
                      setShow={setShow}
                    />

                    <NavItem
                      title={t("navbar_payments_history")}
                      link={Routes.PaymentsList.path}
                      setShow={setShow}
                    />
                  </CollapsableNavItem>
                  {showModule(EModules.Grants) && (
                    <CollapsableNavItem
                      eventKey="finantare"
                      title={t("sidebar_grant_requests")}
                      icon={faMoneyBill}
                    >
                      <NavItem
                        title={t("sidebar_grants.title")}
                        link={Routes.ListaProiecteDisponibile.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_grants.my_applications")}
                        link={Routes.ListaProiecteDepuse.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}
                  {showModule(EModules.OpenGovernment) && (
                    <CollapsableNavItem
                      eventKey="bugetare-participativa"
                      title={t("sidebar_guvernare_deschisa.title")}
                      icon={faFileAlt}
                    >
                      <NavItem
                        title={t(
                          "sidebar_guvernare_deschisa.procesul_bugetare",
                        )}
                        link={Routes.ProcesBugetareParticipativa.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t(
                          "sidebar_guvernare_deschisa.depunere_proiecte",
                        )}
                        link={Routes.DepunereProiecte.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_guvernare_deschisa.votare_proiecte")}
                        link={Routes.VotareProiecte.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_guvernare_deschisa.forum")}
                        link={Routes.Forum.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_guvernare_deschisa.chestionare")}
                        link={Routes.Chestionare.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}
                  <Dropdown.Divider className="my-3 border-indigo" />

                  {requiredAttribute(AccountAttribute.UserAdministrator) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([
                        Routes.Utilizatori.path,
                        Routes.CereriModificareDate.path,
                      ])}
                      title={t("sidebar_utilizatori")}
                      icon={faUsers}
                    >
                      <NavItem
                        title={t("sidebar_utilizatori_administrare")}
                        link={Routes.Utilizatori.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_cereri_modificare_date")}
                        link={Routes.CereriModificareDate.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}

                  {requiredAttribute(AccountAttribute.FormsAdministrator) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([
                        Routes.SmartForms.path,
                        Routes.AdminCereriOnline.path,
                      ])}
                      title={t("sidebar_forms")}
                      icon={faFolder}
                    >
                      <NavItem
                        title={t("sidebar_smart_forms")}
                        link={Routes.SmartForms.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_cereri_online_admin")}
                        link={Routes.AdminCereriOnline.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}

                  {requiredAttribute(AccountAttribute.HearingAdministrator) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([
                        Routes.CereriAudienta.path,
                        Routes.Audiente.path,
                        Routes.AuditorList.path,
                      ])}
                      title={t("sidebar_audiente")}
                      icon={faPhone}
                    >
                      <NavItem
                        title={t("sidebar_cereri_audiente")}
                        link={Routes.CereriAudienta.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_audiente")}
                        link={Routes.Audiente.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_gestionare_auditori")}
                        link={Routes.AuditorList.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}
                  {requiredAttribute(AccountAttribute.ChatbotAdministrator) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([Routes.ChatBot.path])}
                      title={"Chatbot"}
                      icon={faComments}
                    >
                      <NavItem
                        title={t("sidebar_chatbot")}
                        link={Routes.ChatBot.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}
                  {requiredAttribute(
                    AccountAttribute.ProjectsAdministrator,
                  ) && (
                    <NavItem
                      icon={faTasks}
                      title={t("sidebar_projects")}
                      link={Routes.Projects.path}
                      setShow={setShow}
                    />
                  )}
                  {requiredAttribute(
                    AccountAttribute.DocumentTemplateAdministrator ||
                      AccountAttribute.ContractsAdministrator,
                  ) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([
                        Routes.ContractTemplates.path,
                        Routes.ContractList.path,
                      ])}
                      title={t("contracts.list")}
                      icon={faBook}
                    >
                      <NavItem
                        title={t("contracts.templates")}
                        link={Routes.ContractTemplates.path}
                        setShow={setShow}
                      />
                      {requiredAttribute(
                        AccountAttribute.ContractsAdministrator,
                      ) && (
                        <NavItem
                          title={t("contracts.list")}
                          link={Routes.ContractList.path}
                          setShow={setShow}
                        />
                      )}
                    </CollapsableNavItem>
                  )}

                  {requiredAttribute(AccountAttribute.TaxesAdministrator) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([Routes.TaxesList.path])}
                      title={t("sidebar_taxes.title")}
                      icon={faCreditCardAlt}
                    >
                      <NavItem
                        title={t("sidebar_taxes.admin")}
                        link={Routes.TaxesList.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}

                  {requiredAttribute(AccountAttribute.TaxesAdministrator) && (
                    <NavItem
                      icon={faCreditCardAlt}
                      title={t("navbar_payments_history")}
                      link={Routes.AllPaymentsList.path}
                      setShow={setShow}
                    />
                  )}

                  {(requiredAttribute(AccountAttribute.GrantsAdministrator) ||
                    requiredAttribute(AccountAttribute.GrantsEvaluator)) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([
                        Routes.Grants.path,
                        Routes.GrantCategories.path,
                        Routes.GrantApplications.path,
                      ])}
                      title={t("sidebar_grants.title")}
                      icon={faMoneyBill}
                    >
                      {requiredAttribute(
                        AccountAttribute.GrantsAdministrator,
                      ) && (
                        <NavItem
                          title={t("sidebar_grants.list_categories")}
                          link={Routes.GrantCategories.path}
                          setShow={setShow}
                        />
                      )}
                      <NavItem
                        title={t("sidebar_grants.list_applications")}
                        link={Routes.GrantApplications.path}
                        setShow={setShow}
                      />
                      {requiredAttribute(
                        AccountAttribute.GrantsAdministrator,
                      ) && (
                        <NavItem
                          title={t("sidebar_grants.admin")}
                          link={Routes.Grants.path}
                          setShow={setShow}
                        />
                      )}
                    </CollapsableNavItem>
                  )}

                  {requiredAttribute(
                    AccountAttribute.WhiteboardAdministrator,
                  ) && (
                    <NavItem
                      icon={faEdit}
                      title={t("sidebar_colaborare")}
                      link={Routes.WhiteBoard.path}
                      setShow={setShow}
                    />
                  )}
                  {requiredAttribute(
                    AccountAttribute.OpenGovernmentAdministrator,
                  ) && (
                    <CollapsableNavItem
                      eventKey={getEventKey([
                        Routes.GestionareCategorii.path,
                        Routes.DefinireCalendar.path,
                        Routes.ConfigurareProiecte.path,
                        Routes.VizualizareProiecte.path,
                        Routes.Sondaje.path,
                      ])}
                      title={t("sidebar_guvernare_deschisa.title")}
                      icon={faComments}
                    >
                      <NavItem
                        title={t(
                          "sidebar_guvernare_deschisa.gestionare_categorii",
                        )}
                        link={Routes.GestionareCategorii.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t(
                          "sidebar_guvernare_deschisa.definire_calendar",
                        )}
                        link={Routes.DefinireCalendar.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t(
                          "sidebar_guvernare_deschisa.vezualizare_proiecte",
                        )}
                        link={Routes.VizualizareProiecte.path}
                        setShow={setShow}
                      />
                      <NavItem
                        title={t("sidebar_guvernare_deschisa.sondaje")}
                        link={Routes.Sondaje.path}
                        setShow={setShow}
                      />
                    </CollapsableNavItem>
                  )}
                  {requiredAttribute(AccountAttribute.SearchAdministrator) && (
                    <NavItem
                      icon={faSearch}
                      title={t("sidebar_cautare")}
                      link={Routes.Search.path}
                      setShow={setShow}
                    />
                  )}
                  {requiredAttribute(AccountAttribute.LogsAdministrator) && (
                    <NavItem
                      icon={faArchive}
                      title={t("sidebar_istoric_actiuni")}
                      link={Routes.Logs.path}
                      setShow={setShow}
                    />
                  )}
                  <Dropdown.Divider className="my-3 border-indigo" />
                  <NavItem link={""} title={""} setShow={setShow} />
                  {isAdmin() ? (
                    <Button
                      as={Link}
                      to={Routes.Admin.path}
                      variant="secondary"
                      className="upgrade-to-pro"
                    >
                      {t("navbar_administration_label")}
                    </Button>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
}

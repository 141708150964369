import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table  } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { ISurvey, ISurveyAnswer, ISurveyQuestion, ISurveyUserAnswer, IUser } from "../../../cjt";

function Chestionare() {
  const navigate = useNavigate();
  const client = useAPIClient();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [user, setUser] = useState<IUser | null>(null);
  const [activeSurveys, setActiveSurveys] = useState<ISurvey[] | null>(null);

  const fetchActiveSurveys = async () => {
    try {
      const res = await client.getActiveSurveys();
      setActiveSurveys(res);
    } catch (err: any) {
      toast.error("Eroare: Sondajele nu au putut fi încărcate");
    }
  };

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setUser(_user);
    } catch (error: any) {
      toast.error(error.message as string);
    }
  };

  useEffect(() => {
    fetchActiveSurveys();
    fetchUser();
  }, []);

  const handleRedirectTakeSurvey = (id: string) => {
    navigate("/bugetare-participativa/completeaza-chestionar/"+id)
  };

  const checkIfUserHasSubmittedSurvey = (survey: ISurvey) => {
    let hasSubmitted = false;
    survey.questions.forEach((question: ISurveyQuestion) => {
      question.answers.forEach((answer: ISurveyAnswer) => {
        answer.userEntries.forEach((userAnswer: ISurveyUserAnswer) => {
          if (userAnswer.user.id === user?.id) {
            hasSubmitted = true;
          }
        });
      });
    });
    return hasSubmitted;
  };
  

  return (
    <>
      <Container className="mt-5 mb-6">
        <div className="card-body card">
          <Tab.Container defaultActiveKey="0">
            <Row>
              <Col lg={12}>
                <Nav className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            key="0"
                            eventKey="0"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("survey.active_survey")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            key="1"
                            eventKey="1"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("survey.archived_survey")}
                          </Nav.Link>
                        </Nav.Item>
                </Nav>
              </Col>
              <Col lg={12}>
                <Tab.Content>
                        <Tab.Pane eventKey="0" className="py-4">
                          <Table>
                            <thead className="thead-light">
                              <tr>
                                <th className="border-0">{t("survey_name")}</th>
                                <th className="border-0">{t("survey_options")}</th>
                              </tr>
                            </thead>
                            <tbody>

                            {activeSurveys && activeSurveys.length > 0 ? activeSurveys?.map(survey => (
                            <tr>
                              <td>
                                {survey.name}
                              </td>
                              <td>
                                {!checkIfUserHasSubmittedSurvey(survey) ? (
                                  <Button variant="primary" onClick={() => handleRedirectTakeSurvey(survey.id)}>
                                    {t("survey.take_survey_button")}
                                  </Button> ) : (
                                  <Button variant="info" disabled={true}>
                                    {t("survey.allready_submitted_survey_button")}
                                  </Button>
                                  )
                                }
                              </td>
                            </tr>
                            )) : (
                            <>
                              <tr>
                                <td colSpan={2} className="text-center">
                                {t("survey.no_active_surveys")}.
                                </td>
                              </tr>
                            </>
                          )}
                            </tbody>
                          </Table>
                        </Tab.Pane>
                        <Tab.Pane eventKey="1" className="py-4">
                          <Table>
                            <thead className="thead-light">
                              <tr>
                                <th className="border-0">{t("survey_name")}</th>
                                <th className="border-0">{t("survey_options")}</th>
                              </tr>
                            </thead>
                            <tbody>
                                    <tr>
                                      <td colSpan={2} className="text-center">
                                        {t("survey.no_archived_surveys")}
                                      </td>
                                    </tr>
                                    {/* <tr key="0">
                                      <td
                                        style={{
                                          wordBreak: "break-all",
                                          whiteSpace: "pre-wrap",
                                        }}
                                      >
                                        Sondaj de opinie inițial
                                      </td>
                                      <td>
                                        <Button
                                          variant="primary"
                                          className="mb-2"
                                          size="sm"
                                          href="#"
                                        >
                                          Vizualizează răspunsul
                                        </Button>
                                      </td>
                                    </tr> */}
                            </tbody>
                          </Table>
                        </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </>
  );
}

export default Chestionare;

import { Form } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusinessRequest } from "../../cjt";
import { BusinessType } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import { default as countries } from "../../helpers/countries.json";
import handleException from "../../helpers/exceptions";
import { default as judete } from "../../helpers/lista_judete.json";
import { default as localitati } from "../../helpers/localitati_simple.json";
import { businessTypeOptions } from "../../helpers/utilities";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: () => void;
  updateModel: boolean;
  updateData: IBusinessRequest | null;
};

export default function PjForm({
  open,
  setOpen,
  onSubmit,
  updateModel,
  updateData,
}: Props) {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  let initialValues: IBusinessRequest = {
    id: "",
    denumire: "",
    cui: "",
    tara: "RO",
    judet: "Timis",
    localitate: "",
    strada: "",
    cod_postal: "",
    tip: BusinessType.AltaInstitutiePublica,
    document: "",
    nr_onrc: "",
  };
  if (updateModel && updateData) {
    initialValues = {
      id: updateData.id,
      denumire: updateData.denumire,
      cui: updateData.cui,
      tara: "RO",
      judet: updateData.judet,
      localitate: updateData.localitate,
      strada: updateData.strada,
      cod_postal: updateData.cod_postal,
      tip: updateData.tip,
      document: updateData.document,
      nr_onrc: updateData.nr_onrc,
    };
  }
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<IBusinessRequest>({ values: initialValues });
  const watchCountry = watch("tara");
  const watchCounty = watch("judet");

  const handleAddPj: SubmitHandler<IBusinessRequest> = async (data) => {
    setIsSubmitting(true);

    const cleanedData = Object.fromEntries(
      Object.entries(data).filter(([_, v]) => {
        if (v instanceof FileList) {
          return v.length > 0;
        }

        return v !== "" && v !== "rejectReason";
      }),
    ) as IBusinessRequest;

    if (cleanedData.document?.length > 0) {
      cleanedData.document = cleanedData.document[0];
    }

    if (updateModel) {
      try {
        await client.updateBusinessProfile(cleanedData);
        setOpen(false);
        toast.success(t("account_pf_change_success"));
        onSubmit();
      } catch (e) {
        const errorMessages = handleException(e, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
        setIsSubmitting(false);
      }
    } else {
      try {
        await client.addBusinessProfile(cleanedData);
        setOpen(false);
        toast.success(t("account_pf_form_sent_success"));
        onSubmit();
      } catch (e) {
        const errorMessages = handleException(e, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
        setIsSubmitting(false);
      }
    }

    setIsSubmitting(false);
  };

  return (
    <Modal show={open} size="lg" onHide={() => setOpen(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {!updateModel
            ? t("profile_business_add")
            : t("profile_business_edit")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(handleAddPj)}>
          <Form.Group className="mb-3" controlId="formDenumire">
            <Form.Label>{t("pj_form.name")}</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!errors.denumire}
              {...register("denumire", {
                required: t("pj_form.denumire_is_required"),
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.denumire?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.cui")}</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!errors.cui}
              {...register("cui", {
                required: t("pj_form.cui_is_required"),
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.cui?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.onrc")}</Form.Label>
            <Form.Control type="text" {...register("nr_onrc")} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.country")}</Form.Label>
            <Form.Select
              isInvalid={!!errors.tara}
              defaultValue="RO"
              {...register("tara", { required: t("pj_form.tara_is_required") })}
            >
              {Object.keys(countries).map((cc: string) => (
                <option key={cc} value={cc}>
                  {countries[cc as keyof typeof countries]}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              {errors.tara?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.county")}</Form.Label>
            {watchCountry === "RO" ? (
              <Form.Select
                isInvalid={!!errors.judet}
                {...register("judet", {
                  required: t("pj_form.judet_is_required"),
                })}
              >
                {judete.map((cc) => (
                  <option key={cc.Cod} value={cc.Nume}>
                    {cc.Nume}
                  </option>
                ))}
              </Form.Select>
            ) : (
              <Form.Control
                type="text"
                isInvalid={!!errors.judet}
                {...register("judet", {
                  required: t("pj_form.judet_is_required"),
                })}
              />
            )}
            <Form.Control.Feedback type="invalid">
              {errors.judet?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.region")}</Form.Label>
            {watchCountry === "RO" ? (
              <Form.Select defaultValue="Alba" {...register("localitate")}>
                <option value="">-</option>
                {localitati
                  .sort((a, b) => a.Nume.localeCompare(b.Nume))
                  .filter(
                    (localitate) =>
                      localitate?.Cod ===
                      judete?.find((judet) => judet?.Nume === watchCounty)?.Cod,
                  )
                  .map((localitate, index) => (
                    <option
                      key={localitate.Nume + localitate.Cod + index}
                      value={localitate.Nume}
                    >
                      {localitate.Diacritice}
                    </option>
                  ))}
              </Form.Select>
            ) : (
              <Form.Control type="text" {...register("localitate")} />
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.street")}</Form.Label>
            <Form.Control
              id="strada"
              type="text"
              isInvalid={!!errors.strada}
              {...register("strada", {
                required: t("pj_form.strada_is_required"),
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.strada?.message}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.code")}</Form.Label>
            <Form.Control
              id="cod_postal"
              type="text"
              {...register("cod_postal")}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>{t("pj_form.type")}</Form.Label>
            <Form.Select
              id="tip"
              //defaultValue={BusinessType.AltaInstitutiePublica}
              isValid={!!errors.tip}
              {...register("tip")}
            >
              <option>-</option>
              {businessTypeOptions.map((option) => (
                <option key={option.label} value={option.value}>
                  {option.value}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <Form.Group>
            <Form.Label>{t("finish_profile_document_label")}</Form.Label>
            <Form.Control
              type="file"
              isInvalid={!!errors.document}
              {...register("document", {
                required: t("pj_form.document_is_required"),
              })}
            />
            <Form.Control.Feedback type="invalid">
              {errors.document?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOpen(false)}>
          {t("cancel")}
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={isSubmitting}
          onClick={handleSubmit(handleAddPj)}
        >
          {t("save")}
          {isSubmitting && (
            <Spinner animation="border" variant="secondary" size="sm" />
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/* eslint-disable */
import { MAX_PASSWORD, MIN_PASSWORD, PASSWORD_REGEX } from "../config/env";

export const validatePassword = (value: string) => {
  if (value.length < MIN_PASSWORD) throw new Error("PASSWORD_TOO_SHORT");
  if (value.length > MAX_PASSWORD) throw new Error("PASSWORD_TOO_LONG");
  if (!PASSWORD_REGEX.test(value))
    throw new Error(
      //"Parola aleasă este slabă. Vă rugăm să respectați cerințele de complexitate.",
      "PASSWORD_WEAK",
    );
};

export const validateName = (value: string) => {
  const regex =
    /^[A-Za-zăîșțâĂÎȘȚÂ](?:[A-Za-zăîșțâĂÎȘȚÂ]*(?:[ -][A-Za-zăîșțâĂÎȘȚÂ]+)*)?$/;
  const isMatch = regex.test(value);
  if (!isMatch)
    throw new Error(
      "Te rugăm să folosești numai litere fara diacritice, spațiu sau -",
    );
};

export const validateRoPhone = (value: string) => {
  //const regex = /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/;
  const regex = /^[0-9]{1,4}[0-9]{6,14}$/;
  const isMatch = regex.test(value);
  if (!isMatch)
    throw new Error("Te rugăm să introduci un număr de telefon valid.");
};

export const validateRoPostalCode = (value: string) => {
  const regex = /^\d{6}$/;
  const isMatch = regex.test(value);
  if (!isMatch) throw new Error("Te rugăm să introduci un cod poștal valid.");
};

export const validateEmail = (value: string) => {
  if (!value) throw "Email-ul este obligatoriu.";

  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  if (!EMAIL_REGEX.test(String(value).toLowerCase()))
    throw "Email-ul nu este valid.";
};

export const validatePasswordsMatch = (value1: string, value2: string) => {
  if (value1 !== value2) throw new Error("Parolele introduse nu coincid.");
};

export const validateCaptchaCompleted = (captchaResponse: string | null) => {
  if (captchaResponse === null) {
    throw new Error("Invalid Captcha");
  }
};

export const validateCNP = (p_cnp: string) => {
  var i = 0,
    year = 0,
    hashResult = 0,
    cnp = [],
    hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
  if (p_cnp.length !== 13) {
    throw new Error("CNP invalid");
  }
  for (i = 0; i < 13; i++) {
    cnp[i] = parseInt(p_cnp.charAt(i), 10);
    if (isNaN(cnp[i])) {
      throw new Error("CNP invalid");
    }
    if (i < 12) {
      hashResult = hashResult + cnp[i] * hashTable[i];
    }
  }
  hashResult = hashResult % 11;
  if (hashResult === 10) {
    hashResult = 1;
  }
  year = cnp[1] * 10 + cnp[2];
  switch (cnp[0]) {
    case 1:
    case 2:
      {
        year += 1900;
      }
      break;
    case 3:
    case 4:
      {
        year += 1800;
      }
      break;
    case 5:
    case 6:
      {
        year += 2000;
      }
      break;
    case 7:
    case 8:
    case 9:
      {
        year += 2000;
        if (year > new Date().getFullYear() - 14) {
          year -= 100;
        }
      }
      break;
    default: {
      throw new Error("CNP invalid");
    }
  }
  if (year < 1800 || year > 2099) {
    throw new Error("CNP invalid");
  }
  if (cnp[12] === hashResult) {
    return true;
  } else {
    throw new Error("CNP invalid");
  }
};

export const validateRomanianCIF = (v: string) => {
  if (typeof v !== "string") {
    throw "CUI invalid";
  }

  let cif = v.toUpperCase();
  // remove RO from cif:
  cif = cif.indexOf("RO") > -1 ? cif.substring(2) : cif;
  // remove spaces:
  cif = cif.replace(/\s/g, "");
  // validate character length:
  if (cif.length < 2 || cif.length > 10) {
    throw new Error(
      "Lungimea CUI corectă fără RO, este între 2 și 10 caractere!",
    );
  }
  // validate that so far the resulting CIF looks like an integer value:
  if (Number.isNaN(parseInt(cif))) {
    throw "CUI invalid";
  }
  // begin testing:
  const testKey = "753217532";
  const controlNumber = parseInt(cif.substring(cif.length - 1));
  // remove control number:
  cif = cif.substring(0, cif.length - 1);
  // pad left with zeros to reach testKey length:
  while (cif.length !== testKey.length) {
    cif = "0" + cif;
  }
  let sum = 0;
  let i = cif.length;

  while (i--) {
    sum = sum + parseInt(cif.charAt(i)) * parseInt(testKey.charAt(i));
  }

  let calculatedControlNumber = (sum * 10) % 11;

  if (calculatedControlNumber === 10) {
    calculatedControlNumber = 0;
  }

  if (controlNumber === calculatedControlNumber) {
    return true;
  } else {
    throw "CIF invalid! Cifra de control calculată (${calculatedControlNumber}) diferă de cea introdusă (${controlNumber})!";
  }
};

const isDateGreaterThanToday = (date: Date) => {
  const today = new Date();
  const selectedDate = new Date(date);
  return selectedDate > today;
};

const isEndDateGreaterThanStartDate = (startDate: Date, endDate: Date) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  return start < end;
};

export const isValidCalendar = (data: any) => {
  const {
    depunere_proiecte_start,
    depunere_proiecte_end,
    validare_proiecte_start,
    validare_proiecte_end,
    afisare_proiecte_start,
    vot_proiecte_start,
    vot_proiecte_end,
    anunt_proiecte_castigatoare,
    implementare_proiecte,
  } = data;

  if (!isDateGreaterThanToday(depunere_proiecte_start)) {
    throw "Data de începere a depunerii proiectelor trebuie să fie ulterioară datei de astăzi.";
  }

  if (
    !isEndDateGreaterThanStartDate(
      depunere_proiecte_start,
      depunere_proiecte_end,
    )
  ) {
    throw "Data de sfârșit a depunerii proiectelor trebuie să fie ulterioară datei de început.";
  }

  if (
    !isEndDateGreaterThanStartDate(
      depunere_proiecte_end,
      validare_proiecte_start,
    )
  ) {
    throw "Data de începere a validării proiectelor trebuie să fie ulterioară datei de sfârșit a depunerii proiectelor.";
  }

  if (
    !isEndDateGreaterThanStartDate(
      validare_proiecte_start,
      validare_proiecte_end,
    )
  ) {
    throw "Data de sfârșit a validării proiectelor trebuie să fie ulterioară datei de început.";
  }

  if (
    !isEndDateGreaterThanStartDate(
      validare_proiecte_end,
      afisare_proiecte_start,
    )
  ) {
    throw "Data de afișare a proiectelor eligibile trebuie să fie ulterioară datei de sfârșit a validării proiectelor.";
  }

  if (
    !isEndDateGreaterThanStartDate(afisare_proiecte_start, vot_proiecte_start)
  ) {
    throw "Data de începere a votării proiectelor trebuie să fie ulterioară datei de afișare a proiectelor eligibile.";
  }

  if (!isEndDateGreaterThanStartDate(vot_proiecte_start, vot_proiecte_end)) {
    throw "Data de sfârșit a votării proiectelor trebuie să fie ulterioară datei de început.";
  }

  if (
    !isEndDateGreaterThanStartDate(
      vot_proiecte_end,
      anunt_proiecte_castigatoare,
    )
  ) {
    throw "Data de anunțare a proiectelor câștigătoare trebuie să fie ulterioară datei de sfârșit a votării proiectelor.";
  }

  if (
    !isEndDateGreaterThanStartDate(
      anunt_proiecte_castigatoare,
      implementare_proiecte,
    )
  ) {
    throw "Data de implementare a proiectelor trebuie să fie ulterioară datei de anunțare a proiectelor câștigătoare.";
  }

  return true;
};

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { Card, Row } from "@themesberg/react-bootstrap";

import MilestoneDate from "./MilestoneDate";

type Milestone = {
  title: string;
  icon: IconDefinition;
  startDate: Date;
  endDate?: Date;
  editable: boolean;
  onSubmit?: (startDate: Date, endDate?: Date) => void;
  isDemo?: boolean;
  isCurrent?: boolean;
};

type MilestoneWidgetProps = {
  title: string;
  milestones: Milestone[];
  isDemo?: boolean;
};

export default function MilestoneWidget({
  title,
  milestones,
  isDemo,
}: MilestoneWidgetProps) {
  return (
    <Card>
      <Card.Body>
        <h5 className="mb-4">{title}</h5>
        <Row className="text-center">
          {milestones.map((milestone, index) => (
            <MilestoneDate
              key={index}
              title={milestone.title}
              icon={milestone.icon}
              startDate={milestone.startDate}
              endDate={milestone.endDate}
              editable={milestone.editable}
              onSubmit={milestone.onSubmit}
              isDemo={isDemo}
              isCurrent={milestone.isCurrent}
            />
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
}

import {
  Card,
  Col,
  Row,
  Button,
  Table,
  Nav,
} from "@themesberg/react-bootstrap";
import { useAPIClient } from "../../helpers/api";
import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import { formatDate } from "../../helpers/utilities";
import { Routes } from "../../routes";
import { useTranslation } from "react-i18next";
import { IHearingRequest, IProfileRequest, ISubmission } from "../../cjt";

export default () => {
  const [dataRequests, setDataRequests] = useState<IProfileRequest[]>([]);
  const [hearingRequests, setHearingRequests] = useState<IHearingRequest[]>([]);
  const [onlineRequests, setOnlineRequests] = useState<ISubmission[]>([]);
  const [countDataRequests, setCountDataRequests] = useState(0);
  const [countHearingRequests, setCountHearingRequests] = useState(0);
  const [countOnlineRequests, setCountOnlineRequests] = useState(0);
  const client = useAPIClient();
  const { t } = useTranslation();

  const fetchData = async () => {
    try {
      const _dataRequests = await client.getProfileRequests();
      const _hearingRequests = await client.getHearingRequests();
      const _onlineRequests = await client.getOnlineRequests();

      setCountDataRequests(
        _dataRequests.filter((request: IProfileRequest) => request.status === "Pending").length
      );
      setCountHearingRequests(_hearingRequests.length);
      setCountOnlineRequests(_onlineRequests.length);

      setDataRequests(
        _dataRequests
          .reverse()
          .filter((request: IProfileRequest) => request.status === "Pending")
          .slice(-3)
      );
      setHearingRequests(_hearingRequests.reverse().slice(-3));
      setOnlineRequests(_onlineRequests.reverse().slice(-3));
    } catch (err: any) {
      if (err.message === "Network Error") {
        toast.error("Eroare de retea");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4"></div>
      <Row className="justify-content-md-center">
        <Col xs={12} sm={6} xl={4} className="mb-4">
          <Card border="light" className="shadow-sm">
            <Card.Body>
              <Row className="d-block d-xl-flex align-items-center">
                <Col xs={12} xl={12}>
                  <div className="d-sm-block">
                    <h5>Cereri Online Înregistrare</h5>
                    <h3 className="mb-1">{countOnlineRequests} cereri</h3>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <Card border="light" className="shadow-sm">
            <Card.Body>
              <Row className="d-block d-xl-flex align-items-center">
                <Col xs={12} xl={12}>
                  <div className="d-sm-block">
                    <h5>Cereri de Audiențe</h5>
                    <h3 className="mb-1">{countHearingRequests} cereri</h3>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} sm={6} xl={4} className="mb-4">
          <Card border="light" className="shadow-sm">
            <Card.Body>
              <Row className="d-block d-xl-flex align-items-center">
                <Col xs={12} xl={12}>
                  <div className="d-sm-block">
                    <h5>Cereri de Modificare Date</h5>
                    <h3 className="mb-1">{countDataRequests} cereri</h3>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs={12} xl={12} className="mb-4">
          <Row>
            <Col xs={12} xl={8} className="mb-4">
              <Row>
                <Col xs={12} className="mb-4">
                  <Card border="light" className="shadow-sm">
                    <Card.Header>
                      <Row className="align-items-center">
                        <Col>
                          <h5>Cereri Online</h5>
                        </Col>
                        <Col className="text-end">
                          <Button
                            variant="secondary"
                            size="sm"
                            href={Routes.AdminCereriOnline.path}
                          >
                            Vezi mai multe
                          </Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Table
                      responsive
                      className="align-items-center table-flush"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: "5%" }}>
                            #
                          </th>
                          <th scope="col" style={{ width: "10%" }}>
                            DATA
                          </th>
                          <th scope="col" style={{ width: "35%" }}>
                            DENUMIRE
                          </th>
                          <th scope="col" style={{ width: "10%" }}>
                            STARE
                          </th>
                          <th scope="col" style={{ width: "20%" }}>
                            UTILIZATOR
                          </th>
                          <th scope="col" style={{ width: "20%" }}>
                            VIZUALIZARE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {countOnlineRequests === 0 ? (
                          <tr>
                            <td colSpan={6} className="text-center">
                              Nu există cereri nesoluționate
                            </td>
                          </tr>
                        ) : (
                          onlineRequests.reverse().map((pt: ISubmission, i) => (
                            <tr key={pt.id}>
                              <th scope="row" style={{ width: "5%" }}>
                                {i + 1}
                              </th>
                              <td style={{ width: "10%" }}>
                                {formatDate(pt.createdAt)}
                              </td>
                              <td
                                style={{ width: "35%", whiteSpace: "pre-wrap" }}
                              >
                                {pt.templateName}
                              </td>
                              <td style={{ width: "10%" }}>{pt.status}</td>
                              <td style={{ width: "20%" }}>
                                {pt.user.profile.nume} {pt.user.profile.prenume}
                              </td>
                              <td style={{ width: "20%" }}>
                                <Button
                                  variant="primary"
                                  size="sm"
                                  href={"/admin/online-requests/" + pt.id}
                                >
                                  Vezi Detalii
                                </Button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xs={12} className="mb-4">
                  <Card border="light" className="shadow-sm">
                    <Card.Header>
                      <Row className="align-items-center">
                        <Col>
                          <h5>Cereri de Audiență</h5>
                        </Col>
                        <Col className="text-end">
                          <Button
                            variant="secondary"
                            size="sm"
                            href={Routes.CereriAudienta.path}
                          >
                            Vezi mai multe
                          </Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Table
                      responsive
                      className="align-items-center table-flush"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: "20%" }}>
                            #
                          </th>
                          <th scope="col" style={{ width: "30%" }}>
                            Solicitant
                          </th>
                          <th scope="col" style={{ width: "30%" }}>
                            Data Solicitarii
                          </th>
                          <th scope="col" style={{ width: "20%" }}>
                            Vizualizare
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {countHearingRequests === 0 ? (
                          <tr>
                            <td colSpan={4} className="text-center">
                              Nu există cereri nesoluționate
                            </td>
                          </tr>
                        ) : (
                          hearingRequests.reverse().map((pt, i) => (
                            <tr key={pt.id}>
                              <th scope="row" style={{ width: "20%" }}>
                                {i + 1}
                              </th>
                              <td style={{ width: "30%" }}>
                                {pt.user.profile.nume +
                                  " " +
                                  pt.user.profile.prenume}
                              </td>
                              <td style={{ width: "30%" }}>
                                {formatDate(pt.createdAt)}
                              </td>
                              <td style={{ width: "20%" }}>
                                {" "}
                                <Button
                                  variant="primary"
                                  size="sm"
                                  href={"admin/hearing-request?id=" + pt.id}
                                >
                                  Vezi Detalii
                                </Button>{" "}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </Col>

                <Col xs={12} className="mb-4">
                  <Card border="light" className="shadow-sm">
                    <Card.Header>
                      <Row className="align-items-center">
                        <Col>
                          <h5>Cereri de Modificare Date Utilizator</h5>
                        </Col>
                        <Col className="text-end">
                          <Button
                            variant="secondary"
                            size="sm"
                            href={Routes.CereriModificareDate.path}
                          >
                            Vezi mai multe
                          </Button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Table
                      responsive
                      className="align-items-center table-flush"
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col" style={{ width: "20%" }}>
                            #
                          </th>
                          <th scope="col" style={{ width: "30%" }}>
                            SOLICITANT
                          </th>
                          <th scope="col" style={{ width: "30%" }}>
                            DATA SOLICITĂRII
                          </th>
                          <th scope="col" style={{ width: "20%" }}>
                            VIZUALIZARE
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {countDataRequests === 0 ? (
                          <tr>
                            <td colSpan={4} className="text-center">
                              Nu există cereri nesoluționate
                            </td>
                          </tr>
                        ) : (
                          dataRequests
                            .reverse()
                            .filter((request: IProfileRequest) => request.status === "Pending")
                            .map((pt, i) => (
                              <tr key={pt.id}>
                                <th scope="row" style={{ width: "20%" }}>
                                  {i + 1}
                                </th>
                                <td style={{ width: "30%" }}>
                                  {pt.user.profile.nume +
                                    " " +
                                    pt.user.profile.prenume}{" "}
                                </td>
                                <td style={{ width: "30%" }}>
                                  {formatDate(pt.created)}
                                </td>
                                <td style={{ width: "20%" }}>
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    href={"/admin/vizualizare-cerere/" + pt.id}
                                  >
                                    Vezi Detalii
                                  </Button>{" "}
                                </td>
                              </tr>
                            ))
                        )}
                      </tbody>
                    </Table>
                  </Card>
                </Col>
              </Row>
            </Col>

            <Col xs={12} xl={4}>
              <Row>
                <Col xs={12} className="px-0">
                  <Card border="light" className="shadow-sm">
                    <Card.Body>
                      <h5>Navigație</h5>
                      <br></br>
                      <Nav
                        fill
                        variant="pills"
                        className="square flex-column vertical-tab"
                      >
                        <Nav.Link
                          href={Routes.Utilizatori.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_utilizatori")}
                        </Nav.Link>
                        <Nav.Link
                          href={Routes.SmartForms.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_smart_forms")}
                        </Nav.Link>
                        <Nav.Link
                          href={Routes.Audiente.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_audiente")}
                        </Nav.Link>
                        <Nav.Link
                          href={Routes.ChatBot.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_chatbot")}
                        </Nav.Link>
                        <Nav.Link
                          href={Routes.Logs.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_istoric_actiuni")}
                        </Nav.Link>
                        <Nav.Link
                          href={Routes.WhiteBoard.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_colaborare")}
                        </Nav.Link>
                        <Nav.Link
                          href={Routes.Ocr.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_modul_ocr")}
                        </Nav.Link>
                        <Nav.Link
                          href={Routes.SetariGenerale.path}
                          className="mb-sm-3 mb-md-3"
                          style={{ textAlign: "left" }}
                        >
                          {t("sidebar_setari")}
                        </Nav.Link>
                      </Nav>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

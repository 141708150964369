import {
  faCheck,
  faCircleCheck,
  faCircleXmark,
  faDeleteLeft,
  faEdit,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import md5 from "md5";
import { useEffect, useState } from "react";
import { Alert, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { IBusiness, IProfileRequest, IUser } from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute, AttributeDisplayName, EnumMap } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import { default as countries } from "../../helpers/countries.json";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export default function ManageUser() {
  const { requiredAttribute } = useAuthContext();
  const animatedComponents = makeAnimated();
  const client = useAPIClient();
  const { email } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<IUser | null>(null);
  const [loggedInUser, setLoggedInUser] = useState<IUser | null>(null);
  const { t } = useTranslation();
  const countriesData: { [countryCode: string]: string } = countries;
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [auditorName, setAuditorName] = useState<string>("");
  const [auditorRealName, setAuditorRealName] = useState<string>("");
  const [auditorDisplayName, setAuditorDisplayName] = useState<string>("");
  const [isEditingEmail, setIsEditingEmail] = useState<boolean>(false);
  const [newEmail, setNewEmail] = useState<string>("");

  const [profileRequests, setProfileRequests] = useState<IProfileRequest[]>([]);

  const roles: EnumMap<AccountAttribute, string> = {} as any;
  Object.entries(AttributeDisplayName).forEach(([key, value]) => {
    roles[key as AccountAttribute] = value;
  });
  const rolesObj = Object.entries(AttributeDisplayName).map(([key, value]) => ({
    value: key as AccountAttribute,
    label: value,
  }));

  const fetchUser = async () => {
    try {
      const res = await client.getUser(email!);
      setUser(res);

      const profileRequests = (
        await client.getProfileRequestsByUserId(res.id)
      ).filter((profileRequest: IProfileRequest) => {
        return profileRequest.status != "Pending";
      });
      setProfileRequests(profileRequests);
    } catch (err: any) {
      if (err.response.status == "404") {
        navigate("/admin/manage-users");
      }
    }
  };

  const handleSelectChange = (selected: any) => {
    const updatedSelectedOptions = selected.map((option: any) => ({
      value: option.value,
      label: option.label,
    }));
    setSelectedOptions(updatedSelectedOptions);
  };

  const fetchLoggedInUser = async () => {
    try {
      const res = await client.getUserFeed();
      setLoggedInUser(res);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchUser();
    fetchLoggedInUser();
    setSelectOptions(rolesObj);
    var initialSelectedOptions: any = [];
    const userAttributes = user?.attributes;
    if (userAttributes !== undefined) {
      initialSelectedOptions = userAttributes.map((value: any) => ({
        value: value,
        label: roles[value as keyof typeof roles],
      }));
    }
    setSelectedOptions(initialSelectedOptions);
  }, [email]);

  useEffect(() => {
    if (user) {
      const userAttributes = user.attributes;
      const initialSelectedOptions = userAttributes.map((value) => ({
        value: value,
        label: roles[value as keyof typeof roles],
      }));
      setSelectedOptions(initialSelectedOptions);
    }
  }, [user]);

  useEffect(() => {
    if (!user || !user.profile) return;
    const userNume = String(user?.profile.nume);
    const userPrenume = String(user?.profile.prenume);
    const finalName =
      auditorName === ""
        ? userNume + " " + userPrenume
        : auditorName + " - " + userNume + " " + userPrenume;
    setAuditorRealName(userNume + " " + userPrenume);
    setAuditorDisplayName(finalName);
  }, [auditorName, user]);

  if (!user || !user.attributes) {
    return (
      <div className="spinner-border" role="status">
        <div className="visually-hidden">Loading...</div>
      </div>
    );
  }

  const handleUserActivate = async (activate: boolean) => {
    try {
      if (activate) {
        await client.activateUser(email!);
      } else {
        await client.deactivateUser(email!);
      }
      await fetchUser();
    } catch (err: any) {
      // toast.error(err);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleVerifyUser = async () => {
    try {
      await client.verifyUser(email!).then(() => {
        toast.success("Utilizatorul este acum verificat!");
      });
      await fetchUser().then();
    } catch (err: any) {
      // toast.error(err);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleOpenModalAuditor = async () => setShowModal(true);
  const handleCloseModalAuditor = async () => setShowModal(false);
  const handleDownloadBusinessDoc = async (business: IBusiness) => {
    try {
      return await client.getBusinessDocument(business?.id!);
    } catch (err: any) {
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const handleDownloadProfileDoc = async (request: IProfileRequest) => {
    try {
      return await client.getProfileRequestDocument(request?.id!);
    } catch (err: any) {
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };
  const registerUserAsAuditor = async () => {
    try {
      await client.createAuditor(user.id, auditorDisplayName, auditorRealName);
      toast.success("Auditorul a fost creat cu success!");
      await fetchUser();
    } catch (err: any) {
      // toast.error(err);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
    setShowModal(false);
  };

  const handleUpdateAdminRoles = async () => {
    const selectedRoles = selectedOptions.map((role: any) => role.value);
    await client
      .assignAttributesAdmin(String(email), selectedRoles)
      .then(() => {
        toast.success("Rolurile au fost actualizate cu succes");
      })
      .catch((err) => {
        const errorMessages = handleException(err, t);
        errorMessages.forEach((message) => {
          toast.error(message);
        });
      });
  };

  const handleEmailEdit = async () => {
    try {
      await client.editEmail(user.id, newEmail);
      setIsEditingEmail(false);
      setNewEmail("");
      navigate("/admin/user/" + newEmail);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleInvalidateBusinessCui = async (business: IBusiness) => {
    if (!business || !business.id) return;

    try {
      await client.invalidateBusiness(business.id);

      await fetchUser();
    } catch (err: any) {
      // toast.error(err);
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  if (!user) {
    return <LoadingView />;
  }

  return (
    <section>
      <Container className="py-5">
        <Row>
          <Col lg="4">
            <Card className="mb-4">
              <Card.Body className="text-center">
                <Image
                  src={`https://www.gravatar.com/avatar/${md5(
                    `${user.email}`,
                  )}?size=150`}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: "150px" }}
                  fluid
                />

                {user.isActive ? (
                  <p className="text-muted mb-4">
                    {t("user")}{" "}
                    <span style={{ color: "green" }}>{t("active")}</span>
                  </p>
                ) : (
                  <p className="text-muted mb-4">
                    {t("user")}{" "}
                    <span style={{ color: "red" }}>{t("inactive")}</span>
                  </p>
                )}

                <div
                  className="d-flex justify-content-center mb-2"
                  style={{ flexDirection: "column" }}
                >
                  {requiredAttribute(AccountAttribute.HearingAdministrator) &&
                    !user.auditor && (
                      <Button
                        className="mb-2"
                        variant="primary"
                        onClick={handleOpenModalAuditor}
                      >
                        {t("assign_auditor")}
                      </Button>
                    )}
                  {loggedInUser?.id !== user.id &&
                    (user.isActive ? (
                      <Button
                        variant="danger"
                        onClick={() => handleUserActivate(false)}
                      >
                        {t("deactivate_account")}
                      </Button>
                    ) : (
                      <Button
                        variant="success"
                        onClick={() => handleUserActivate(true)}
                      >
                        {t("activate_account")}
                      </Button>
                    ))}
                  {!user.isVerified && (
                    <Button
                      className="mt-2"
                      variant="success"
                      onClick={() => handleVerifyUser()}
                    >
                      {t("verify_account")}
                    </Button>
                  )}
                </div>
              </Card.Body>
            </Card>

            <Card className="mb-4 mb-lg-0">
              <Card.Body className="p-3">
                <h4 className="mb-3">{t("user_roles")}:</h4>
                <div>
                  <Select
                    options={selectOptions}
                    isMulti={true}
                    components={animatedComponents}
                    onChange={(selected: any) => {
                      handleSelectChange(selected);
                    }}
                    styles={{}}
                    value={selectedOptions}
                    closeMenuOnSelect={false}
                  />
                  <i>* {t("add_roles_helper")}</i>
                </div>
                <button
                  className="mt-4 btn btn-primary"
                  style={{ marginLeft: "5px" }}
                  onClick={handleUpdateAdminRoles}
                >
                  {t("add_roles")}
                </button>
              </Card.Body>
            </Card>
          </Col>
          <Col lg="8">
            {!user.profile && (
              <>
                <Card className="mb-4 mt-2">
                  <Card.Body>
                    <h3 className="mb-4">
                      {t("finish_profile_personal_data_label")}
                    </h3>
                    <Row>
                      <Col sm="3">
                        <p>{t("email_label")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">{user.email}</p>
                      </Col>
                    </Row>
                    <hr />
                    {user.isEmailConfirmed ? (
                      <span style={{ color: "green" }}>Email Confirmat</span>
                    ) : (
                      <span style={{ color: "red" }}>Email Neconfirmat</span>
                    )}
                  </Card.Body>
                </Card>
              </>
            )}

            {user.profile && (
              <>
                <Card className="mb-4 mt-2">
                  <Card.Body>
                    <h3 className="mb-4">
                      {t("finish_profile_personal_data_label")}
                    </h3>
                    <Row>
                      <Col sm="3">
                        <p>{t("finish_profile_last_name_label")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">{user.profile.nume}</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <p>{t("finish_profile_first_name_label")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">{user.profile.prenume}</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <p>{t("email_label")}</p>
                      </Col>
                      <Col sm="9">
                        {isEditingEmail ? (
                          <div className="d-flex">
                            <Form.Control
                              id="newEmail"
                              name="newEmail"
                              type="text"
                              value={newEmail}
                              onChange={(event) =>
                                setNewEmail(event.target.value)
                              }
                              placeholder="Introdu noua adresa de e-mail"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleEmailEdit();
                                }
                              }}
                            />

                            <Button
                              onClick={() => {
                                handleEmailEdit();
                                //setIsEditingEmail(false);
                              }}
                              className="mx-2 btn-sm"
                              variant="secondary"
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>Confirma modificarea</Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="green"
                                ></FontAwesomeIcon>
                              </OverlayTrigger>
                            </Button>
                          </div>
                        ) : (
                          <p className="text-muted">
                            {user.email}{" "}
                            <Button
                              onClick={() => {
                                setIsEditingEmail(true);
                              }}
                              className="mx-2 btn-sm"
                              variant="secondary"
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    Apasa pentru a edita e-mailul acestui
                                    utilizator.
                                  </Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                ></FontAwesomeIcon>
                              </OverlayTrigger>
                            </Button>
                          </p>
                        )}
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <p>{t("finish_profile_phone_label")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">{user.profile.telefon}</p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-4 mt-2">
                  <Card.Body>
                    <h3 className="mb-4">{t("profile_data")}</h3>
                    <Row>
                      <Col sm="3">
                        <p>{t("profile_cnp")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">{user.profile.cnp}</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <p>{t("finish_profile_id_number_label")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">{user.profile.numar_ci}</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <p>
                          {t("finish_profile_id_issued_at_label")} /{" "}
                          {t("finish_profile_id_expires_at_label")}
                        </p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">
                          {dayjs(user.profile.data_eliberare).format(
                            "DD-MM-YYYY",
                          )}{" "}
                          /{" "}
                          {dayjs(user.profile.data_expirare).format(
                            "DD-MM-YYYY",
                          )}
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <p>{t("finish_profile_id_issued_by_label")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">{user.profile.eliberat}</p>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <p>{t("finish_profile_street_label")}</p>
                      </Col>
                      <Col sm="9">
                        <p className="text-muted">
                          {user.profile.strada}, {user.profile.localitate},{" "}
                          {user.profile.judet}, {user.profile.cod_postal},{" "}
                          {countriesData[user.profile.tara]}
                        </p>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </>
            )}
          </Col>
        </Row>
        <Modal
          as={Modal.Dialog}
          centered
          show={showModal}
          onHide={handleCloseModalAuditor}
        >
          <Modal.Header>
            <Modal.Title className="h6">{t("assign_auditor")}</Modal.Title>
            <Button
              variant="close"
              aria-label="Close"
              onClick={handleCloseModalAuditor}
              disabled={false}
            />
          </Modal.Header>
          <Modal.Body>
            <Alert variant="">
              <Alert.Heading className="d-flex align-items-center mt-2">
                Date necesare
              </Alert.Heading>
              <label>Nume / Funcție afișată</label>
              <input
                className="form-control"
                style={{ width: "300px" }}
                //value={formName}
                onChange={(e) => {
                  setAuditorName(e.target.value);
                }}
              />
              <label>Se va afișa în pagină:</label>
              <input
                className="form-control"
                style={{ width: "300px" }}
                value={auditorDisplayName}
                disabled
              />
              <hr />
              <p className="mb-0">
                Sunteți sigur că doriți să adăugați acest auditor?
              </p>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              className="ms-auto"
              onClick={registerUserAsAuditor}
              disabled={false}
            >
              Da, adaugă Auditor
            </Button>
            {/* <Button variant="primary" className="ms-auto" onClick={handleCloseModalAuditor} disabled={false}>Nu, închide fereastra</Button> */}
          </Modal.Footer>
        </Modal>

        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <div className="d-flex d-justify-space-between mb-4">
              <p>{t("profile_business_associated")}</p>
            </div>
            <Table>
              <thead className="thead-light">
                <tr>
                  <th className="border-0">{t("profile_business_type")}</th>
                  <th className="border-0">{t("profile_business_name")}</th>
                  <th className="border-0">{t("profile_business_cui")}</th>
                  <th className="border-0">
                    {t("finish_profile_street_label")}
                  </th>
                  <th className="border-0">{"Document"}</th>
                </tr>
              </thead>
              <tbody>
                {user?.businesses && user?.businesses.length > 0 ? (
                  user?.businesses.map((business: IBusiness) => (
                    <tr key={business.id}>
                      <td className="align-middle">{business.tip}</td>
                      <td className="align-middle">{business.denumire}</td>

                      <td className="align-middle">
                        {business.cui ? (
                          <>
                            {business.cui}
                            <Button
                              onClick={() => {
                                handleInvalidateBusinessCui(business);
                              }}
                              className="mx-2 btn-sm"
                            >
                              <OverlayTrigger
                                overlay={
                                  <Tooltip>
                                    Acest buton invalideaza CUI-ul unei persoane
                                    juridice. Este folosit pentru a elimina un
                                    CUI duplicat
                                  </Tooltip>
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faDeleteLeft}
                                  color="red"
                                ></FontAwesomeIcon>
                              </OverlayTrigger>
                            </Button>
                          </>
                        ) : (
                          <>N/A</>
                        )}
                      </td>
                      <td className="align-middle">
                        {business.strada}, {business.localitate},{" "}
                        {business.judet}, {business.cod_postal}, {business.tara}
                      </td>
                      <td
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {business?.document ? (
                          <>
                            <pre>
                              <a
                                onClick={() =>
                                  handleDownloadBusinessDoc(business)
                                }
                                target="_blank"
                              >
                                Descarcă fișier{" "}
                                <FontAwesomeIcon
                                  icon={faExternalLinkAlt}
                                  className="ms-1"
                                />
                              </a>
                            </pre>
                          </>
                        ) : (
                          <span
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Nu exista documente adăugate!
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      <p>{t("profile_business_empty")}</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card border="light" className="bg-white shadow-sm mb-4">
          <Card.Body>
            <div className="d-flex d-justify-space-between mb-4">
              <p>{t("profile_edits")}</p>
            </div>
            <Table>
              <thead className="thead-light">
                <tr>
                  <th className="border-0">{t("profile_request_status")}</th>
                  <th className="border-0">{t("profile_request_name")}</th>

                  <th className="border-0">
                    {t("finish_profile_street_label")}
                  </th>
                  <th className="border-0">{"Document"}</th>
                </tr>
              </thead>
              <tbody>
                {profileRequests && profileRequests.length > 0 ? (
                  profileRequests.map((profileRequest: IProfileRequest) => (
                    <tr key={profileRequest.id}>
                      <td className="align-middle">
                        {profileRequest.status == "Approved" ? (
                          <FontAwesomeIcon
                            color="green"
                            size="xl"
                            icon={faCircleCheck}
                          />
                        ) : (
                          profileRequest.status === "Rejected" && (
                            <FontAwesomeIcon
                              color="red"
                              size="xl"
                              icon={faCircleXmark}
                            />
                          )
                        )}
                      </td>
                      <td className="align-middle">
                        {profileRequest.nume + " " + profileRequest.prenume}
                      </td>

                      <td className="align-middle">
                        {profileRequest.strada}, {profileRequest.localitate},{" "}
                        {profileRequest.judet}, {profileRequest.cod_postal},{" "}
                        {profileRequest.tara}
                      </td>
                      <td
                        style={{ textAlign: "center", verticalAlign: "middle" }}
                      >
                        {profileRequest?.documentId ? (
                          <>
                            <pre>
                              <a
                                onClick={() =>
                                  handleDownloadProfileDoc(profileRequest)
                                }
                                target="_blank"
                              >
                                Descarcă fișier{" "}
                                <FontAwesomeIcon
                                  icon={faExternalLinkAlt}
                                  className="ms-1"
                                />
                              </a>
                            </pre>
                          </>
                        ) : (
                          <span
                            style={{
                              textAlign: "center",
                              verticalAlign: "middle",
                            }}
                          >
                            Nu exista documente adăugate!
                          </span>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={3} className="text-center">
                      <p>{t("profile_request_empty")}</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
}

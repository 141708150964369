import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  FormGroup,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IGrantSubmission } from "../../cjt";
import { FilterGrantProjectSubmissionStatus } from "../../enums";
import { useAPIClient } from "../../helpers/api";

export default function ListAppliedGrants() {
  const client = useAPIClient();
  const [grants, setGrants] = useState<IGrantSubmission[]>([]);
  const [displayedGrants, setDisplayedGrants] = useState<IGrantSubmission[]>(
    [],
  );
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("any");
  const { t } = useTranslation();

  const getStatusTransaltions = (status: string) => {
    if (status === "pending") {
      return t("grant_apply.submitted");
    }

    if (status === "evaluated") {
      return t("grant_apply.evaluated");
    }

    if (status === "approved") {
      return t("grant_apply.approved");
    }

    if (status === "rejected") {
      return t("grant_apply.rejected");
    }

    if (status === "any") {
      return t("grant_apply.any");
    }
  };

  const options = Object.keys(FilterGrantProjectSubmissionStatus).map(
    (key) => ({
      value:
        FilterGrantProjectSubmissionStatus[
          key as keyof typeof FilterGrantProjectSubmissionStatus
        ],
      label: getStatusTransaltions(
        FilterGrantProjectSubmissionStatus[
          key as keyof typeof FilterGrantProjectSubmissionStatus
        ],
      ),
    }),
  );

  const fetchGrants = async () => {
    try {
      const res = await client.getSubmittedGrants();
      setGrants(res);
      setDisplayedGrants(res);
    } catch (e) {
      console.log(e);
    }
  };

  const filterGrants = () => {
    let filteredRes = [...grants];
    if (filterName !== "") {
      filteredRes = filteredRes.filter(
        (grant: IGrantSubmission) =>
          grant.project.name.toLowerCase().indexOf(filterName.toLowerCase()) !==
          -1,
      );
    }
    if (filterStatus !== "any") {
      filteredRes = filteredRes.filter(
        (grant: IGrantSubmission) => grant.status === filterStatus,
      );
    }
    setDisplayedGrants(filteredRes);
  };

  useEffect(() => {
    fetchGrants();
  }, []);

  useEffect(() => {
    filterGrants();
  }, [filterName, filterStatus]);

  const getSubmissionStatus = (grant: IGrantSubmission) => {
    if (grant.status === "pending") {
      return <Badge bg="primary"> {t("grant_apply.submitted")}</Badge>;
    }

    if (grant.status === "evaluated") {
      return <Badge bg="primary"> {t("grant_apply.evaluated")}</Badge>;
    }

    if (grant.status === "approved") {
      return <Badge bg="success"> {t("grant_apply.approved")}</Badge>;
    }

    if (grant.status === "rejected") {
      return <Badge bg="danger"> {t("grant_apply.rejected")}</Badge>;
    }
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Body>
              <h5 className="mb-4">{t("grant.submitted")}</h5>
              <Form>
                <FormGroup>
                  <Form.Control
                    type="text"
                    placeholder={t("grant.session_name")}
                    onChange={(e) => {
                      setFilterName(e.target.value);
                    }}
                  />
                </FormGroup>
                <FormGroup className="mt-2">
                  <DropdownButton
                    drop="down"
                    title="Status"
                    variant="primary"
                    onSelect={(event) => {
                      setFilterStatus(event ? event : "any");
                    }}
                  >
                    {options.map((option) => (
                      <Dropdown.Item key={option.value} eventKey={option.value}>
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </FormGroup>
              </Form>
              <Table>
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("category")}</th>
                    <th>{t("business")}</th>
                    <th>{t("status")}</th>
                    <th>{t("grant.upload_date")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedGrants.map((grant) => (
                    <tr key={grant.id}>
                      <td>{grant.project.name}</td>
                      <td>{grant.projectCategory.name}</td>
                      <td>{grant.business?.denumire}</td>
                      <td>{getSubmissionStatus(grant)}</td>
                      <td>{moment(grant.createdAt).format("YYYY-MM-DD")}</td>

                      <td>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          href={`/admin/proiecte-depuse/${grant.id}`}
                        >
                          {t("view_more")}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

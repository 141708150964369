import { useState, useCallback } from "react";
import { Button, Container } from "react-bootstrap";
import { useLayoutEffect, useRef } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAPIClient } from "../../helpers/api";
import { ITemplate, ITemplateBody } from "../../cjt";
import { Card } from "@themesberg/react-bootstrap";
import ReactJson from "react-json-view";
import { Form, FormBuilder } from "@formio/react";
import { ActivateOptions } from "../../enums";
import Select from "react-select";
import { t } from "i18next";
import "../../assets/css/Builder.css";
import "../../assets/css/Formio.min.css";

type Props = {
  form: ITemplateBody;
  onChange: () => void;
};

type OptionType = {
  value: string;
  label: string;
};

export default function FormEditor({ form, onChange }: Props) {
  const client = useAPIClient();
  const navigate = useNavigate();
  const formContainerRef = useRef<HTMLDivElement>(null);
  
  const [formName, setFormName] = useState<string>(form.template.name);
  const [editFormName, setEditFormName] = useState<boolean>(false);
  const [jsonSchema, setSchema] = useState<any>({ components: [] });
  const [formSubmission, setFormSubmission] = useState({});
  const [needsLocation, setNeedsLocation] = useState<boolean>(false);
  const [selectedCategory, setCategory] = useState<OptionType | null>(null);
  const [categories, setCategories] = useState<OptionType[]>([]);

  const onFormChange = async (schema: any) => {
    setSchema({ ...schema, components: [...schema.components] });
    await client.editTemplate(
      form.template.name,
      JSON.stringify(schema),
      form.template.needsLocation,
      form.template.id
    );
  };
  const onFormSubmissionChange = (submission: any) => {
    setFormSubmission(submission);
  };

  const handleSelectCategory = async (selected: any) => {
    setCategory(selected);
    console.log(selected);

    try {
      await client
        .assignCategoryToTemplate(form.template.id, selected.label)
        .then(() => {
          toast.success(t("toast.success_form_cat"));
        });
    } catch (err: any) {
      console.log(err);
    }
  };

  const fetchTemplate = async () => {
    try {
      setSchema(JSON.parse(form.body));
      setNeedsLocation(form.template.needsLocation);
      setCategory({
        value: form.template.category.id,
        label: form.template.category.label,
      });
    } catch (err: any) {
      if (err.message === "Network Error") {
        toast.error(t("toast.error_form_net"));
      }
    }
  };

  const fetchCategories = useCallback(async () => {
    try {
      const res = await client.getFormsCategories();
      const return_categories: {
        value: any;
        label: any;
      }[] = Array.isArray(res)
        ? res.map((category) => ({
            value: category.id,
            label: category.label,
          }))
        : [];
      console.log(return_categories);
      setCategories(return_categories);
    } catch (err: any) {
      if (err.message === "Network Error") {
        toast.error(t("toast.error_form_net"));
      }
    }
  }, []);

  useLayoutEffect(() => {
    fetchTemplate();
    fetchCategories();
  }, []);

  const handleEditFormName = async () => {
    await client
      .editTemplate(
        formName,
        JSON.stringify(jsonSchema),
        form.template.needsLocation,
        form.template.id
      )
      .then((res: ITemplate) => {
        setEditFormName(false);
        setFormName(res.name);
        toast.success(t("toast.success_form_actualization"));
      })
      .catch((err) => toast.error(err));
  };

  const handleEditNeedsLocation = async () => {
    try {
      await client.editTemplate(
        formName,
        JSON.stringify(jsonSchema),
        !needsLocation,
        form.template.id
      );
      toast.success(t("toast.success_form_actualization"));
      setNeedsLocation(!needsLocation);
    } catch (err: any) {
      toast.error(err);
    }
  };

  const deleteFormItem = async (id: string) => {
    try {
      await client.deleteTemplate(id);

      navigate("/admin/smart-forms");
      toast.success(t("toast.success_form_delete"));
    } catch (err: any) {
      toast.error(err.response.data.message);
    }
  };

  const toggleFormItem = async (id: string) => {
    if (!form.template.active && !selectedCategory) {
      toast.error(t("toast.error_form_category"));
      return;
    }

    try {
      await client.toggleTemplate(
        id,
        form.template.active
          ? ActivateOptions.Deactivated
          : ActivateOptions.Activated
      );

      if (form.template.active) {
        toast.success(t("toast.success_form_deactivation"));
        onChange();
      } else {
        toast.success(t("toast.success_form_activation"));
        onChange();
      }
    } catch (err: any) {
      toast.error(err);
    }
  };

  return (
    <Container>
      <div
        className="row mt-4 mb-3"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div className="col-8">
          <div style={{ display: "flex", alignItems: "baseline" }}>
            {editFormName ? (
              <>
                {" "}
                <input
                  className="form-control"
                  style={{ width: "300px" }}
                  value={formName}
                  onChange={(e) => {
                    setFormName(e.target.value);
                  }}
                />
                <Button
                  onClick={handleEditFormName}
                  className="btn btn-primary"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                >
                  {t("form.modify")}
                </Button>
              </>
            ) : (
              <>
                <h3 className="mb-4 w-65">
                {t("form.config")} <b className="w-10 text-break">"{formName}"</b>
                </h3>
                <i
                  className="is-isolated fas fa-edit"
                  style={{ marginLeft: "5px" }}
                  onClick={() => {
                    setEditFormName(true);
                  }}
                ></i>
              </>
            )}
          </div>
          <h4 className="mb-2">{t("form.cat")}</h4>
          <Select
            className="w-50"
            value={selectedCategory}
            onChange={(selectedOption) => handleSelectCategory(selectedOption)}
            options={
              categories
                ? categories.map((category) => {
                    return { value: category.value, label: category.label };
                  })
                : []
            }
            placeholder={t("form.select_cat")}
          />
          <h6 className="mt-4 mb-2">
          {t("form.add_field")}
          </h6>
        </div>
        <div className="col-3">
          <div
            className="mb-4"
            style={{ display: "flex", justifyContent: "end" }}
          >
            <button
              onClick={() => {
                handleEditNeedsLocation();
              }}
              type="button"
              className="btn btn-primary mr-1"
              style={{ marginRight: "5px" }}
            >
              {needsLocation ? t("form.deact_location") : t("form.act_location")}
            </button>

            {form.template.active ? (
              <button
                onClick={() => toggleFormItem(form.template.id)}
                type="button"
                className="btn btn-warning mr-1"
                style={{ marginRight: "5px" }}
              >
                {t("form.deactivate")}
              </button>
            ) : (
              <button
                onClick={() => toggleFormItem(form.template.id)}
                type="button"
                className="btn btn-success mr-1"
                style={{ marginRight: "5px" }}
              >
                {t("form.activate")}
              </button>
            )}
            <button
              onClick={() => deleteFormItem(form.template.id)}
              type="button"
              className="btn btn-danger mr-1"
            >
              {t("delete_action")}
            </button>
          </div>
        </div>
      </div>

      <div ref={formContainerRef}>
        <FormBuilder form={jsonSchema} onChange={onFormChange} />
        <Card title="Form JSON Schema" className="my-4">
          <Card.Body>
            <Card.Title className="text-center">{t("form.json")}</Card.Title>
            <ReactJson
              src={jsonSchema}
              name={null}
              collapsed={true}
            ></ReactJson>
          </Card.Body>
        </Card>
        <Card className="my-4">
          <Card.Body>
            <Card.Title className="text-center">{t("form.rendered")}</Card.Title>
            <Form
              form={jsonSchema}
              submission={formSubmission}
              onChange={onFormSubmissionChange}
            />
          </Card.Body>
        </Card>
        <Card title="Live Form Data" className="my-4">
          <Card.Body>
            <Card.Title className="text-center">{t("form.live")}</Card.Title>
            <ReactJson
              src={formSubmission}
              name={null}
              collapsed={true}
            ></ReactJson>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}

import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../context/AuthContext";

function ProtectedRoute() {
  const { isLoggedIn } = useAuthContext();

  if (!isLoggedIn) {
    return (
      <Navigate
        to={"/login"}
        state={window.location.pathname + window.location.search}
      />
    );
  }

  return <Outlet />;
}
export default ProtectedRoute;

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function WhiteboardRedirectWithToken() {
  const navigate = useNavigate();

  const getParams = () => {
    const query = window.location.pathname;
    const paramList = query.split("/").slice(-2);
    return paramList;
  };

  // useEffect(() => {
  //   navigate("/whiteboard/" + getToken());
  // }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const whiteboardId = queryParams.get("id");
    const token = queryParams.get("token");
    if (token && whiteboardId) {
      navigate("/whiteboard/session?id=" + whiteboardId + "&token=" + token);
    }
  }, []);

  return null;
}

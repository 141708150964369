import { useState } from "react";
import { Col, Row, Card, Nav, Tab, Container } from "react-bootstrap";
import { TabelCereri } from "./TabelComp";
import { useAPIClient } from "../../helpers/api";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import handleException from "../../helpers/exceptions";
import { useTranslation } from "react-i18next";

const Requests = () => {
  type Request = {
    id: number;
    status: string;
    created: Date;
  };

  const [requestsList, setRequestsList] = useState<Request[]>([]);
  const [pjRequests, setPjRequests] = useState<Request[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const client = useAPIClient();
  const { t } = useTranslation();
  const fetchRequestsList = async () => {
    try {
      const res = await client.getProfileRequests();
      const res_pj = await client.getBusinessRequests();
      setRequestsList(res);
      setPjRequests(res_pj.sort((a: Request, b: Request) => new Date(b.created).getTime() - new Date(a.created).getTime()));
    } catch (err: any) {
      // if (err.message === "Network Error") {
      //   toast.error("Eroare de retea");
      // }
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestsList();
  }, []);

  return (
    <>
      <Container className="px-0" style={{ maxWidth: "100%" }}>
        <Row>
          <Col xs={12} className="p-3">
            <Card>
              <Card.Body>
                <Tab.Container defaultActiveKey="persoana-fizica">
                  <Row>
                    <Col lg={12}>
                      <Nav className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="persoana-fizica"
                            className="mb-sm-3 mb-md-0"
                          >
                            Cereri Persoană Fizică
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="persoana-juridica"
                            className="mb-sm-3 mb-md-0"
                          >
                            Cereri Persoană Juridică
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="istoric-pf"
                            className="mb-sm-3 mb-md-0"
                          >
                            Istoric cereri persoana fizica
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="istoric-pj"
                            className="mb-sm-3 mb-md-0"
                          >
                            Istoric cereri persoana juridica
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="persoana-fizica" className="py-4">
                          {isLoading ? (
                            <p style={{ textAlign: "center" }}>
                              Loading Data...
                            </p>
                          ) : (
                            <>
                              {requestsList.filter(
                                (request) => request.status === "Pending"
                              ).length === 0 ? (
                                <h3
                                  className="mt-4"
                                  style={{ textAlign: "center" }}
                                >
                                  Nu s-au găsit cereri!
                                </h3>
                              ) : (
                                <TabelCereri
                                  tip="PF"
                                  lista={requestsList.filter(
                                    (request) => request.status === "Pending"
                                  )}
                                />
                              )}
                            </>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="persoana-juridica" className="py-4">
                          {/* <TabelCereri tip="PJ"/> */}

                          {isLoading ? (
                            <p style={{ textAlign: "center" }}>
                              Loading Data...
                            </p>
                          ) : (
                            <>
                              {pjRequests.filter(
                                (request) => request.status === "Pending"
                              ).length === 0 ? (
                                <h3
                                  className="mt-4"
                                  style={{ textAlign: "center" }}
                                >
                                  Nu s-au găsit cereri!
                                </h3>
                              ) : (
                                <TabelCereri
                                  tip="PJ"
                                  lista={pjRequests.filter(
                                    (request) => request.status === "Pending"
                                  )}
                                />
                              )}
                            </>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="istoric-pf" className="py-4">
                          {isLoading ? (
                            <p style={{ textAlign: "center" }}>
                              Loading Data...
                            </p>
                          ) : (
                            <>
                              {requestsList.length === 0 ? (
                                <h3
                                  className="mt-4"
                                  style={{ textAlign: "center" }}
                                >
                                  Nu s-au găsit cereri!
                                </h3>
                              ) : (
                                <div>
                                  <TabelCereri
                                    tip="PF"
                                    lista={requestsList.filter(
                                      (request) => request.status != "Pending"
                                    )}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="istoric-pj" className="py-4">
                          {isLoading ? (
                            <p style={{ textAlign: "center" }}>
                              Loading Data...
                            </p>
                          ) : (
                            <>
                              {pjRequests.length === 0 ? (
                                <h3
                                  className="mt-4"
                                  style={{ textAlign: "center" }}
                                >
                                  Nu s-au găsit cereri!
                                </h3>
                              ) : (
                                <div>
                                  <TabelCereri
                                    tip="PJ"
                                    lista={pjRequests.filter(
                                      (request) => request.status != "Pending"
                                    )}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Requests;

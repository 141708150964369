import { Excalidraw, LiveCollaborationTrigger } from "@excalidraw/excalidraw";
import { ExcalidrawImperativeAPI } from "@excalidraw/excalidraw/types/types";
import {
  faCircleCheck,
  faTimesCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Row, Table } from "@themesberg/react-bootstrap";
import debounce from "lodash/debounce";
import { useEffect, useRef, useState } from "react";
import { Card, Container, Dropdown, Spinner } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Socket, io } from "socket.io-client";

import { IUploadedFile, IUser, IWhiteboard, IWhiteboardUser } from "../../cjt";
import { WS_URL } from "../../config/env";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute, WhiteboardPermissions } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { UserSearchOptions } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";
import ChatBox from "../Chatbox/Chatbox";

type WhiteboardScene = {
  version: number;
  state: any;
  elements: any[];
  files: any[];
};

export default function WhiteboardSession() {
  const socket = useRef<Socket | null>(null);
  const { t } = useTranslation();
  const client = useAPIClient();
  const [excalidrawApi, setExcalidrawApi] =
    useState<ExcalidrawImperativeAPI | null>(null);
  const [participants, setParticipants] = useState<Map<any, any>>(new Map());
  const [isConnected, setIsConnected] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [isCollabOpen, setIsCollabOpen] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [whiteboard, setWhiteboard] = useState<IWhiteboard | null>(null);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fileList, setFileList] = useState<IUploadedFile[]>([]);
  const [addUserOpen, setAddUserOpen] = useState(false);
  const [selectOptions, setSelectOptions] = useState<any>([]);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const [loggedInUser, setLoggedInUser] = useState<IUser | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [whiteboardUsers, setWhiteboardUsers] = useState<IWhiteboardUser[]>([]);
  const [isDeletingFile, setIsDeletingFile] = useState(false);
  const [user, setUser] = useState<IUser | null>(null);
  const [isSynchronizing, setIsSynchronizing] = useState(false);
  const [uploadedFileDescription, setUploadedFileDescription] =
    useState<string>("");
  const navigate = useNavigate();

  const { requiredAttribute } = useAuthContext();

  const isWhiteboardAdmin = requiredAttribute(
    AccountAttribute.WhiteboardAdministrator,
  );

  const roles = Object.entries(WhiteboardPermissions).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const debounceSave = debounce(() => {
    const scene = excalidrawApi?.getSceneElements();
    const sceneData = {
      elements: [...scene!],
      appState: { ...excalidrawApi?.getAppState() },
      files: excalidrawApi?.getFiles(),
    };
    const serz = JSON.stringify(sceneData);

    socket.current?.emit("draw", serz);
  }, 60);

  const joinWhiteboard = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const whiteboardId = queryParams.get("id");

    socket.current?.emit("join", whiteboardId);
  };

  const fetchWhiteboardUsers = async () => {
    if (!whiteboard) {
      return;
    }
    try {
      const _whiteboardUsers = await client.getWhiteboardUsers(whiteboard.id);
      setWhiteboardUsers(_whiteboardUsers);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };
  useEffect(() => {
    fetchWhiteboardUsers();
  }, [whiteboard]);

  const initializeScene = async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const whiteboardId = queryParams.get("id");
    if (!whiteboardId) {
      navigate("/404");
      return;
    }
    let resWhiteboard: IWhiteboard;
    try {
      resWhiteboard = await client.getWhiteboard(whiteboardId!);
    } catch (err: any) {
      navigate("/404");
      return;
    }
    setWhiteboard(resWhiteboard);
    fetchWhiteboardUsers();
    setFileList(resWhiteboard.files);
    const scene = JSON.parse(resWhiteboard?.content) as WhiteboardScene;
    const _user = await client.getUserFeed();

    setLoggedInUser(_user);

    if (scene) {
      const sceneData = {
        elements: [...scene.elements],
        appState: { ...scene.state, collaborators: participants },
      };

      excalidrawApi?.updateScene(sceneData);

      const files = Object.keys(scene.files).map(
        (key: any) => scene.files[key],
      );

      if (scene.files) {
        excalidrawApi?.addFiles(files);
      }

      excalidrawApi?.refresh();
    }
  };

  const parseCollaborators = (collaborators: any) => {
    const parsedCollaborators = new Map();

    collaborators.forEach((user: IUser) => {
      parsedCollaborators.set(user.id, {
        username: `${user.profile.nume} ${user.profile.prenume}`,
      });
    });

    return parsedCollaborators;
  };

  const fetchUser = async () => {
    try {
      const _user = await client.getUserFeed();
      setUser(_user);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    if (!excalidrawApi) {
      return;
    }
    const token = window.localStorage.getItem("accessToken");
    const _socket = io(WS_URL, {
      auth: {
        token,
      },
    });

    if (!_socket) {
      return;
    }

    socket.current = _socket;

    function onConnect() {
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    initializeScene();
    fetchUser();
    joinWhiteboard();

    window.addEventListener("mousedown", handleMouseDown);
    window.addEventListener("mouseup", handleMouseUp);

    socket.current.on("connect", onConnect);
    socket.current.on("disconnect", onDisconnect);
    socket.current.on("users_changed", onUsersChanged);
    socket.current.on("draw", onDraw);

    return () => {
      window.removeEventListener("mousedown", handleMouseDown);
      window.removeEventListener("mouseup", handleMouseUp);

      socket.current?.off("connect", onConnect);
      socket.current?.off("disconnect", onDisconnect);
      socket.current?.off("users_changed", onUsersChanged);
      socket.current?.off("draw", onDraw);
    };
  }, [excalidrawApi]);

  const handleMouseDown = () => {
    setIsDrawing(true);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const onUsersChanged = (data: IUser[]) => {
    const collaborators = parseCollaborators(data);
    setParticipants(new Map(collaborators));
  };

  const onDraw = (data: string) => {
    if (data === "") return;

    if (isDrawing) {
      return;
    }

    const parsedData = JSON.parse(data);
    const collaborators = parseCollaborators(parsedData.collaborators);
    const content = JSON.parse(parsedData.content);
    const sceneData = {
      elements: [...content.elements],
      state: { ...content.state },
    } as WhiteboardScene;

    excalidrawApi?.updateScene({
      elements: [...sceneData.elements],
      appState: { ...sceneData.state },
      collaborators: collaborators,
    });

    setParticipants(new Map(collaborators));
  };

  const handleCollabSelect = () => {
    setIsCollabOpen(true);
  };

  const handleBoardUpdate = () => {
    if (!isDrawing) {
      return;
    }

    debounceSave();
  };

  const handleRef = (api: ExcalidrawImperativeAPI) => {
    setExcalidrawApi(api);
  };

  const handleFileUpload = async () => {
    if (!whiteboard) {
      return;
    }

    let _errors: { [key: string]: any } = {};
    if (uploadedFile === "") {
      _errors.uploadedFile = t("whiteboard.errors_empty_file");
    }
    setErrors(_errors);

    if (_errors.uploadedFile) {
      return;
    }

    setIsSubmitting(true);

    const uploadedFileParams: {
      [key: string]: any;
    } = {};

    uploadedFileParams["uploadedFile"] = uploadedFile;
    uploadedFileParams["uploadedFileDescription"] = uploadedFileDescription;

    try {
      await client.uploadWhiteboardFile(whiteboard.id, uploadedFileParams);
      toast.success(t("whiteboard.success_file"));
      setUploadedFile("");
      await getAllFiles();
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
      setOpenFileUpload(false);
    }
  };

  const getAllFiles = async () => {
    if (whiteboard) {
      const resWhiteboard = token
        ? await client.getWhiteboard(whiteboard.id, token)
        : await client.getWhiteboard(whiteboard.id);
      setFileList(resWhiteboard.files);
    }
  };

  const searchForUsers = async (searchQuery: string) => {
    if (searchQuery.length < 3) {
      return;
    }
    try {
      const res = await client.getUsers(UserSearchOptions.EMAIL, searchQuery);
      const filteredRes = res.flatMap((resUser: IUser) =>
        resUser.profile !== null &&
        resUser.isVerified &&
        resUser.id !== loggedInUser?.id
          ? {
              label: resUser.email,
              value: resUser.id,
            }
          : [],
      );
      setSelectOptions(filteredRes);
    } catch (err: any) {
      //setError(err.message);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const addUser = async (selected: any) => {
    try {
      setSelectedOptions(selected);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };
  const handleSubmit = async () => {
    if (!whiteboard) {
      return;
    }
    setIsSubmitting(true);
    try {
      for (let selectedUser of selectedOptions) {
        await client.addUserToWhiteboard(whiteboard.id, selectedUser.value);
      }
      const selectedUsers = selectedOptions.map((selected: any) => {
        return selected.label;
      });
      await client.inviteWhiteboardUsers(whiteboard.id, selectedUsers);
      toast.success("Participanti invitați cu succes!");
      setAddUserOpen(false);
      fetchWhiteboardUsers();
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Container fluid={true} className="mt-5">
        <div className="row">
          <div className="col-sm-12">
            <div className="border" style={{ height: "80vh" }}>
              <Excalidraw
                ref={handleRef}
                onChange={() => handleBoardUpdate()}
                isCollaborating={isConnected}
                renderTopRightUI={() => (
                  <LiveCollaborationTrigger
                    isCollaborating={isConnected}
                    onSelect={handleCollabSelect}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </Container>
      <Modal
        id={"ParticipantsModal"}
        show={isCollabOpen}
        onHide={() => setIsCollabOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>{t("whiteboard.participants")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            responsive
            className="table-centered table-nowrap rounded mb-0 overflow-visible"
          >
            <thead className="thead-light">
              <tr>
                <th className="border-0">{t("whiteboard.name")}</th>
                <th className="border-0">
                  {isWhiteboardAdmin && t("whiteboard.permissions")}
                </th>
                <th className="border-0"></th>
              </tr>
            </thead>
            <tbody>
              {whiteboard &&
                whiteboardUsers &&
                whiteboardUsers.map(
                  (whiteboardUser: IWhiteboardUser, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {whiteboardUser.user.profile.nume}{" "}
                          {whiteboardUser.user.profile.prenume}
                        </td>

                        <td>
                          {isWhiteboardAdmin && (
                            <Select
                              className="position"
                              menuPosition="fixed"
                              options={roles.filter(
                                (role) =>
                                  !whiteboardUser.permissions.includes(
                                    role.value,
                                  ),
                              )}
                              value={roles.filter((role) =>
                                whiteboardUser.permissions.includes(role.value),
                              )}
                              isMulti={true}
                              onChange={async (values: any, context: any) => {
                                try {
                                  await client.changeWhiteboardPermission(
                                    whiteboard.id,
                                    whiteboardUser.user.id,
                                    values.map((option: any) => option.value),
                                  );
                                  await fetchWhiteboardUsers();
                                } catch {}
                              }}
                              styles={{}}
                              closeMenuOnSelect={false}
                            />
                          )}
                        </td>
                        <td>
                          {isWhiteboardAdmin && (
                            <Button
                              variant="danger"
                              disabled={user?.id == whiteboardUser.user.id}
                              onClick={async () => {
                                try {
                                  await client.removeWhiteboardUser(
                                    whiteboard?.id,
                                    whiteboardUser.user.id,
                                  );
                                  fetchWhiteboardUsers();
                                } catch (err: any) {
                                  const errorMessages = handleException(err, t);
                                  errorMessages.map((message) => {
                                    toast.error(message);
                                  });
                                }
                              }}
                            >
                              {t("whiteboard.remove")}
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  },
                )}
            </tbody>
          </Table>

          {isWhiteboardAdmin && (
            <Button
              className="mt-4"
              size="sm"
              onClick={() => {
                setAddUserOpen(true);
              }}
            >
              {t("whiteboard.add_participants")}
            </Button>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-primary"
            onClick={() => setIsCollabOpen(false)}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
      <Card className="mt-3">
        <Card.Body>
          <h3 className="mb16">{t("whiteboard.messages")}</h3>
          {whiteboard?.chat && (
            <ChatBox
              id={whiteboard?.chat.id}
              canUserDelete={isWhiteboardAdmin}
            />
          )}
        </Card.Body>
      </Card>
      {loggedInUser &&
        whiteboardUsers &&
        whiteboardUsers
          .find((whiteboardUser) => whiteboardUser.user.id === loggedInUser.id)
          ?.permissions.includes("UploadFile") && (
          <Card className="mt-3">
            <Card.Body>
              <Button
                variant="success"
                className="mt-1 align-self-sm-start"
                size="sm"
                style={{ float: "left" }}
                onClick={() => setOpenFileUpload(true)}
              >
                {t("whiteboard.upload")}
              </Button>
            </Card.Body>
          </Card>
        )}
      <Modal
        show={openFileUpload}
        size="lg"
        onHide={() => {
          setOpenFileUpload(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("whiteboard.upload_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group id="signedPdf">
              <Form.Label>{t("whiteboard.upload")}</Form.Label>
              <Form.Control
                type="file"
                id="uploadedFile"
                className={errors.uploadedFile && "error-control"}
                required
                onChange={({ target: { files } }: any) => {
                  setUploadedFile(files[0]);
                }}
              />
              {errors.uploadedFile && (
                <span className="error-message">{errors.uploadedFile}</span>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>{t("whiteboard.file_description")}</Form.Label>
              <Form.Control
                type="text"
                onChange={(event) => {
                  setUploadedFileDescription(event.target.value);
                }}
              ></Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUploadedFile("");
              setOpenFileUpload(false);
            }}
          >
            {t("calendar.cancel")}
          </Button>
          <Button
            variant="primary"
            onClick={handleFileUpload}
            disabled={isSubmitting}
          >
            {t("upload")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>{" "}
        </Modal.Footer>
      </Modal>

      {whiteboard === null || !fileList || fileList?.length === 0 ? (
        <Card border="light" className="mt-3">
          <Card.Body>
            <h5 className="mb-0">{t("whiteboard.nodoc")}</h5>
          </Card.Body>
        </Card>
      ) : (
        <Card border="light" className="shadow-sm mb-4">
          <Card.Body className="pb-0">
            <Button
              variant="success"
              className="mb-4"
              onClick={async () => {
                try {
                  setIsSynchronizing(true);
                  await client.synchronizeWhiteboardFilesWithDrive(
                    whiteboard.id,
                  );
                  await getAllFiles();
                  toast.success("Sincronizare cu succes!");
                } catch (err: any) {
                  const errorMessages = handleException(err, t);
                  errorMessages.map((message) => {
                    toast.error(message);
                  });
                } finally {
                  setIsSynchronizing(false);
                }
              }}
              disabled={isSynchronizing}
            >
              {t("whiteboard.synchronize")}
            </Button>
            <Table
              responsive
              className="table-centered table-nowrap rounded mb-0"
            >
              <thead className="thead-light">
                <tr>
                  <th className="border-0">{t("whiteboard.filename")}</th>
                  <th className="border-0">
                    {t("whiteboard.file_description")}
                  </th>
                  <th className="border-0"></th>
                  <th className="border-0"></th>
                  <th className="border-0 text-center">
                    {t("whiteboard.synchronized")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {fileList &&
                  fileList.map((file, index) => (
                    <tr key={index}>
                      <td>{file.name}</td>
                      <td>{file.description}</td>
                      <td>
                        <Button
                          variant="success"
                          className="m-1"
                          onClick={() =>
                            client.getWhiteboardFile(whiteboard.id, file.id)
                          }
                          target="_blank"
                        >
                          {t("whiteboard.download")}
                        </Button>
                      </td>
                      <td>
                        {loggedInUser &&
                          whiteboardUsers
                            .find(
                              (whiteboardUser) =>
                                whiteboardUser.user.id === loggedInUser.id,
                            )
                            ?.permissions.includes("DeleteFile") && (
                            <Button
                              variant="danger"
                              className="m-1"
                              onClick={async () => {
                                setIsDeletingFile(true);
                                try {
                                  await client.deleteWhiteboardFile(
                                    whiteboard.id,
                                    file.id,
                                  );
                                  await getAllFiles();
                                } catch (err: any) {
                                  const errorMessages = handleException(err, t);
                                  errorMessages.map((message) => {
                                    toast.error(message);
                                  });
                                } finally {
                                  setIsDeletingFile(false);
                                }
                              }}
                              target="_blank"
                              disabled={isDeletingFile}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                                className="me-2"
                              />
                              {t("delete")}
                            </Button>
                          )}
                      </td>
                      <td align="center">
                        <div className="w-45">
                          {/* <input
                          className="mt-3"
                          type="checkbox"
                          checked={file.isUploadedInDrive}
                        /> */}
                          {file.isUploadedInDrive ? (
                            <FontAwesomeIcon icon={faCircleCheck} />
                          ) : (
                            <FontAwesomeIcon icon={faTimesCircle} />
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
      <Card className="mt-3">
        <Card.Body>
          <h3 className="mb16">{t("whiteboard.history")}</h3>
          {whiteboard?.log && (
            <ChatBox
              id={whiteboard?.log.id}
              inputDisabled={true}
              canUserDelete={false}
            />
          )}
        </Card.Body>
      </Card>
      <Modal
        show={addUserOpen}
        onHide={() => {
          setSelectedOptions([]);
          setAddUserOpen(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>{t("whiteboard.participants_title")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group as={Row}>
            <Form.Label>{t("whiteboard.add_participants")}</Form.Label>
            <Select
              options={selectOptions}
              value={selectedOptions}
              components={makeAnimated()}
              filterOption={() => true}
              closeMenuOnSelect={false}
              onChange={(selected: any) => {
                addUser(selected);
              }}
              onInputChange={(input: string) => {
                searchForUsers(input);
              }}
              isMulti={true}
              styles={{}}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setSelectedOptions([]);
              setAddUserOpen(false);
            }}
          >
            {t("calendar.cancel")}
          </Button>
          {!isSubmitting ? (
            <Button
              variant="primary"
              onClick={() => {
                handleSubmit();
                setSelectedOptions([]);
              }}
            >
              {t("whiteboard.invite")}
            </Button>
          ) : (
            <LoadingView />
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

import { Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation();
  return (
    <Container className="mt-5">
      <h2 className="fw-bold mb-5 text-uppercase text-center">
        {t("terms.title")}
      </h2>
      <span
        style={{
          whiteSpace: "pre-wrap",
          wordWrap: "break-word",
        }}
      >
        {t("terms.text")}
      </span>
    </Container>
  );
}

export default TermsAndConditions;

import {
  Button,
  Card,
  Col,
  Container,
  Row,
  Table,
} from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { IBusiness, IGrant } from "../../cjt";
import { useAPIClient } from "../../helpers/api";

export default function ListPublishedGrants() {
  const client = useAPIClient();
  const [businesses, setBusinesses] = useState<IBusiness[]>([]);
  const [currBusiness, setCurrBusiness] = useState<IBusiness | null>(null);
  const [grants, setGrants] = useState<IGrant[]>([]);
  const { t } = useTranslation();

  const fetchBusinesses = async () => {
    try {
      const user = await client.getUserFeed();

      if (user.businesses) {
        setBusinesses(user.businesses);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchBusinesses();
  }, []);

  const handleBusinessChange = async (selectedOption: any) => {
    try {
      const res = await client.getPublishedGrants(selectedOption.value);
      setCurrBusiness(selectedOption.value);
      setGrants(res);
    } catch (e) {
      console.log(e);
    }
  };

  const handleApply = (grantId: string) => {
    if (!currBusiness) {
      toast.error(t("grant_published_select_business"));
      return;
    }

    window.location.href = `/finantare/depunere-proiect/${grantId}/${currBusiness}`;
  };

  return (
    <Container>
      <Row>
        <Col>
          <Card border="light" className="shadow-sm mb-4">
            <Card.Header>
              <h5>{t("grant_apply.grant_sessions")}</h5>
            </Card.Header>
            <Card.Body>
              <label>{t("grant_published_select_business_label")}</label>
              <Select
                options={businesses.map((b) => ({
                  label: b.denumire,
                  value: b.id,
                }))}
                onChange={handleBusinessChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {!!currBusiness && (
        <Row>
          <Col>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Header>
                <h5>{t("grant_published_projects")}</h5>
              </Card.Header>
              <Card.Body>
                {grants.length === 0 && (
                  <h5 className="my-5">
                    {t("grant_apply.no_grants_available")}
                  </h5>
                )}
                {grants.length > 0 && (
                  <Table>
                    <thead>
                      <tr>
                        <th>{t("name")}</th>
                        <th>{t("category")}</th>
                        <th>{t("actions")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {grants.map((grant) => (
                        <tr key={grant.id}>
                          <td>{grant.name}</td>
                          <td>{grant.category?.name}</td>
                          <td>
                            <Button
                              size="sm"
                              onClick={() => handleApply(grant.id)}
                            >
                              {t("grant.apply")}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
}

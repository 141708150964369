import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
} from "@themesberg/react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import dayjs from "dayjs";
import { t } from "i18next";
import moment from "moment";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { IGrant, IGrantCategory, ITemplate } from "../../cjt";
import { tinymce_key } from "../../config/env";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { businessTypeOptions } from "../../helpers/utilities";

export default function ListGrants() {
  const client = useAPIClient();
  const animatedComponents = makeAnimated();
  const {
    control,
    register,
    handleSubmit,
    unregister,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const [grantCategories, setGrantCategories] = useState<IGrantCategory[]>([]);
  const [grants, setGrants] = useState<IGrant[]>([]);
  const [forms, setForms] = useState<ITemplate[]>([]);
  const [requiredDocuments, setRequiredDocuments] = useState<number[]>([]);
  const [showAddGrant, setShowAddGrant] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const fetchGrants = async () => {
    try {
      const _grants = await client.getAllGrants();

      setGrants(_grants);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fetchForms = async () => {
    try {
      const _forms = await client.getTemplates();

      setForms(_forms);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fetchGrantCategories = async () => {
    try {
      const _grantCategories = await client.getGrantCategories();

      setGrantCategories(_grantCategories);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchGrants();
    fetchForms();
    fetchGrantCategories();
  }, []);

  const submitForm = async (data: FieldValues) => {
    setIsSubmitting(true);
    try {
      const errors: string[] = [];
      const fileKeys = Object.keys(data).filter((key) => key.includes("file"));

      fileKeys.forEach((key) => {
        if (data[key][0].size > 10 * 1024 * 1024) {
          errors.push(data[key][0].name);
        }
      });
      if (errors.length > 0) {
        errors.forEach((file) => {
          toast.error(t("grant.error_file_1") + file + t("grant.error_file_2"));
        });

        return;
      }

      data.projectSubmissionEnd = moment(
        data.projectSubmissionEnd,
        "YYYY-MM-DD",
      )
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      data.evaluationEnd = moment(data.evaluationEnd, "YYYY-MM-DD")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      data.contestationEnd = moment(data.contestationEnd, "YYYY-MM-DD")
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");

      await client.createGrantProject(data);

      await fetchGrants();
      toast.success(t("grant.success_add"));
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setShowAddGrant(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Container>
        <h2 className="fw-bold mb-5 text-uppercase text-center">
          {t("grant.title")}
        </h2>
        <Card>
          <Card.Header>
            <Button
              variant="primary"
              className="m-1"
              onClick={() => setShowAddGrant(true)}
            >
              {t("grant.add_session")}
            </Button>
          </Card.Header>
          <Card.Body>
            {grants.length === 0 && <h3>{t("grant.no_grants")}</h3>}
            {grants.length > 0 && (
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>{t("name")}</th>
                    <th>{t("grant.description")}</th>
                    <th>{t("grant.entities")}</th>
                    <th>{t("category")}</th>
                    <th>{t("grant.date_start")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {grants.map((grant) => (
                    <tr key={grant.id}>
                      <td>{grant.name}</td>
                      <td>{grant.description}</td>
                      <td>{grant.allowedBusinessTypes.join(", ")}</td>
                      <td>{grant.category?.name}</td>
                      <td>
                        {dayjs(grant.projectSubmissionStart).format(
                          "DD-MM-YYYY",
                        )}
                      </td>
                      <td>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          href={`/admin/grants/${grant.id}`}
                        >
                          {t("my_requests_see_details")}
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </Card.Body>
        </Card>
      </Container>

      <Modal
        size="xl"
        show={showAddGrant}
        onHide={() => setShowAddGrant(false)}
      >
        <Modal.Header>
          <Modal.Title>{t("grant.add_session")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(submitForm)}>
            <Form.Group className="mb-3">
              <Form.Label>{t("name")}</Form.Label>
              <Form.Control
                type="text"
                {...register("name", { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("grant.description")}</Form.Label>
              {/* <Controller
                control={control}
                defaultValue=""
                name="description"
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <Editor
                    apiKey={tinymce_key}
                    onEditorChange={(value) => onChange(value)}
                  />
                )}
                
              /> */}
              <Form.Control
                as="textarea"
                size="lg"
                {...register("description", { required: true })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("category")}</Form.Label>
              <Form.Select {...register("category", { required: true })}>
                {grantCategories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("grant.form_template")}</Form.Label>
              <Form.Select {...register("formTemplateId", { required: true })}>
                {forms.map((form) => (
                  <option key={form.id} value={form.id}>
                    {form.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("grant.available_budget")}</Form.Label>
              <Form.Control
                type="number"
                {...register("budget", { required: true })}
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.sub_start")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("projectSubmissionStart", { required: true })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.sub_end")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("projectSubmissionEnd", { required: true })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.eval_start")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("evaluationStart", { required: true })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.eval_end")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("evaluationEnd", { required: true })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.results_date")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("resultsAnnouncementDate", {
                      required: true,
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.contestation_start")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("contestationStart", { required: true })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.contestation_end")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("contestationEnd", { required: true })}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.contestation_results")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("contestationResultsDate", {
                      required: true,
                    })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t("grant.final_date")}</Form.Label>
                  <Form.Control
                    type="date"
                    {...register("finalResultsDate", { required: true })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>{t("grant.business_type")}</Form.Label>
              <Controller
                control={control}
                defaultValue={[]}
                name="allowedBusinessTypes"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    onChange={(val) => onChange(val.map((v) => v.value))}
                    options={businessTypeOptions}
                    isMulti={true}
                    components={animatedComponents}
                    value={businessTypeOptions.find((c) => c.valueOf === value)}
                    closeMenuOnSelect={false}
                    className="w-100"
                  />
                )}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t("grant.docs")}</Form.Label>
              <br />
              <ul>
                <li key={0}>
                  <label>{t("file")} 1</label>
                  <Form.Control type="file" {...register("file0")} />
                  <label>{t("grant.description")}</label>
                </li>
                <Form.Control type="text" {...register("fileDescription0")} />
                {requiredDocuments.map((_, index) => (
                  <li key={index + 1}>
                    <label className="mt-2">
                      {t("file")} {index + 2}
                    </label>
                    <Form.Control
                      type="file"
                      {...register(`file${index + 1}`)}
                    />
                    <label>{t("grant.description")}</label>
                    <Form.Control
                      type="text"
                      {...register(`fileDescription${index + 1}`)}
                    />
                  </li>
                ))}
              </ul>
              <div className="float-end mt-2">
                <Button
                  size="sm"
                  variant="outline-success"
                  onClick={() =>
                    setRequiredDocuments([...requiredDocuments, 0])
                  }
                >
                  <i className="fas fa-plus" style={{ color: "green" }}></i>
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={() => {
                    unregister(`file${requiredDocuments.length}`);
                    unregister(`fileDescription${requiredDocuments.length}`);
                    setRequiredDocuments([...requiredDocuments.slice(1)]);
                  }}
                >
                  <i className="fas fa-trash" style={{ color: "red" }}></i>
                </Button>
              </div>
            </Form.Group>
          </Form>
          {!!errors && (
            <ul>
              {Object.keys(errors).map((key, index) => (
                <li key={index}>key: {t("mandatory")}</li>
              ))}
            </ul>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowAddGrant(false)}>
            {t("cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={() => handleSubmit(submitForm)()}
          >
            {t("save")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import { Button, Modal, ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface IModalConfirmDeletion extends ModalProps {}
const ModalConfirmDeletion = ({
  showConfirmDeletion,
  handleClose,
  surveyToDelete,
  questionToDelete,
  handleDelete,
}: IModalConfirmDeletion) => {
  const { t } = useTranslation();
  return (
    <Modal show={showConfirmDeletion} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{t("delete_action")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {t("survey.confirm_delete")} <b>{surveyToDelete && t("survey.the_survey")}</b>{" "}
          <b>{questionToDelete && t("survey.the_question")}</b> ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        {t("contracts.cancel")}
        </Button>
        <Button variant="primary" onClick={handleDelete}>
        {t("calendar.delete")} 
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalConfirmDeletion;

import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Stack } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { ISmartForm } from "../../cjt";
import DataForm from "../../components/Forms/DataForm";
import { SmartForm } from "../../components/Forms/SmartForm";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { formatDate } from "../../helpers/utilities";
import ChatBox from "../Chatbox/Chatbox";

export default function UserOnlineRequestById() {
  const { id } = useParams();
  const client = useAPIClient();
  const [userFormData, setUserFormData] = useState<ISmartForm | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const fetchFormRequestById = async () => {
    try {
      const res = await client.getOnlineRequestsById(id!);
      setUserFormData(res);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchFormRequestById();
  }, []);

  return (
    <Container className="mt-5">
      <>
        <Card className="p-4 mb-4">
          {userFormData && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h1 className="text-center pt-1 mb-4">
                  {userFormData.submission.templateName}
                </h1>
                <Stack direction="horizontal">
                  <p className="d-flex">
                    {userFormData.submission.reg_nr ? (
                      userFormData.submission.reg_nr +
                      "/ " +
                      dayjs(userFormData.submission.registrationDate).format(
                        "DD.MM.YYYY",
                      )
                    ) : (
                      <></>
                    )}
                  </p>
                  <Badge
                    pill
                    bg="secondary"
                    style={{ padding: "10px", float: "right" }}
                    text="primary"
                  >
                    {userFormData.submission.status}
                  </Badge>
                </Stack>
              </div>

              {(userFormData.submission.eloHasFile ||
                userFormData.submission.responseFileData) && (
                <>
                  <div className="alert alert-success mt-2">
                    Formularul a fost solutionat
                  </div>
                  <span>
                    <Button
                      size="sm"
                      className="mx-2 d-flex"
                      style={{ float: "right", justifyContent: "right" }}
                      onClick={async () => {
                        try {
                          const res = client.getSubmissionResponse(
                            userFormData.submission.id,
                          );
                        } catch (err) {
                          console.log(err);
                        }
                      }}
                    >
                      Descarca raspunsul
                    </Button>
                  </span>
                </>
              )}
              <div>
                <p className="pt-3">{t("profile_data")}</p>

                {!isLoading && userFormData.submission.user ? (
                  <DataForm
                    nume={
                      userFormData.submission.user.profile &&
                      userFormData.submission.user.profile.nume
                    }
                    prenume={
                      userFormData.submission.user.profile &&
                      userFormData.submission.user.profile.prenume
                    }
                    cnp={
                      userFormData.submission.user.profile &&
                      userFormData.submission.user.profile.cnp
                    }
                  />
                ) : (
                  <p>Loading user data...</p>
                )}
              </div>

              <hr className="mb-4"></hr>
              {!isLoading && userFormData.template !== null && (
                <>
                  <SmartForm
                    form={JSON.parse(userFormData.template)}
                    submission={JSON.parse(userFormData.body)}
                    options={{
                      readOnly: true,
                      viewAsHtml: true,
                      // renderMode: "html",
                    }}
                  />
                  <hr className="mb-4"></hr>
                  {userFormData.submission.chat && (
                    <ChatBox id={userFormData.submission.chat.id} />
                  )}
                </>
              )}
            </>
          )}
        </Card>
      </>
    </Container>
  );
}

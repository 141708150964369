import { faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "@themesberg/react-bootstrap";
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Select from "react-select";

import { IBusiness, ITaxes, IUser } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";

export interface OptionType {
  id: string;
  name: string;
  tip: string;
  email: string;
}
interface UserOptionType extends OptionType {
  instance: IUser | IBusiness;
}
// interface TaxOptionType extends OptionType {
//   tax: IAccountPlans;
// }

function OtherPayments({ user }: { user: UserOptionType | null }) {
  const client = useAPIClient();
  const { t } = useTranslation();

  const [selectedTax, setSelectedTax] = useState<ITaxes | null>(null);
  const [taxes, setTaxes] = useState<ITaxes[] | null>(null);
  const [toPay, setToPay] = useState<string>("");
  const [show, setShow] = useState<boolean>();
  const [ghiseulRoLink, setGhiseulRoLink] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSelectChange = (selectedTax: any) => {
    setToPay("");
    setSelectedTax(selectedTax);
  };

  const getAccountPlans = async () => {
    try {
      const res = await client.getTaxesList();
      // console.log(await client.getAccountPlans());

      setTaxes(res.filter((tax: any) => tax.faraDebit === true));
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };
  useEffect(() => {
    getAccountPlans();
  }, []);

  useEffect(() => {
    setSelectedTax(null);
  }, [user]);

  if (!taxes) return;

  const userIsPf = user?.tip === "pf";
  const userIsPj = user?.tip === "pj";

  const options: {
    value: string;
    label: string;
    faraDebit: boolean | null;
  }[] = taxes
    .filter((tax) => {
      if (tax.pf && tax.pj) {
        return true;
      } else if (tax.pf && userIsPf) {
        return true;
      } else if (tax.pj && userIsPj) {
        return true;
      }
      return false;
    })
    .map((tax) => ({
      value: tax.taxCode.toString(),
      label: tax.name,
      faraDebit: tax.faraDebit,
    }));

  const getGhiseulRoLink = async () => {
    try {
      const payload = {
        idtaxa: selectedTax?.taxCode?.toString(),
        denumireTaxa: selectedTax?.name,
        faraDebit: selectedTax?.faraDebit,
        ibantaxa: "RO25TREZ4412107020102XXX",
        suma: parseFloat(toPay).toFixed(2),
        cui: user?.id,
        cuip: user?.id,
        nume:
          user?.tip === "pf" && "profile" in user.instance
            ? user?.instance?.profile?.nume +
              " " +
              user?.instance?.profile?.prenume
            : user?.tip === "pj" &&
              "denumire" in user.instance &&
              user?.instance?.denumire,
        adresa:
          user?.tip === "pf" && "profile" in user.instance
            ? user?.instance?.profile?.strada +
              ", " +
              user?.instance?.profile?.localitate +
              ", " +
              user?.instance?.profile?.judet
            : user?.tip === "pj" &&
              "strada" in user.instance &&
              user?.instance?.strada +
                ", " +
                user?.instance?.localitate +
                ", " +
                user?.instance?.judet,
        email: user?.email,
      };

      const res = await client.getGhiseulRoLink(payload);
      setGhiseulRoLink(res.redirectUrl);
    } catch (err: any) {
      console.log(err);

      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const validateDecimal = (input: any) => {
    const regex = /^\d+(\.\d{0,2})?$/; // Regular expression to match numbers with up to 2 decimal places
    const value = input.value;

    if (!regex.test(value)) {
      // If the input doesn't match the pattern, clear the input value
      input.value = value.slice(0, -1); // Remove the last character
    }
  };
  return (
    <>
      <Container className="mt-5 mb-6">
        {user && (
          <>
            {!options || options.length === 0 ? (
              <p>{t("payments.no_taxes")}</p>
            ) : (
              <>
                <Select
                  value={
                    selectedTax
                      ? {
                          value: selectedTax.taxCode,
                          label: selectedTax.name,
                          faraDebit: selectedTax.faraDebit,
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    handleSelectChange(
                      selectedOption
                        ? {
                            taxCode: selectedOption.value,
                            name: selectedOption.label,
                            faraDebit: selectedOption.faraDebit,
                          }
                        : null,
                    );
                  }}
                  options={options}
                  placeholder={t("payments.select_payment_type")}
                />
                <InputGroup className="input-group-merge mt-3">
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMoneyBill} className="mx-2" />
                    RON
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder={t("payments.insert_amount")}
                    value={toPay !== "" ? toPay : ""}
                    onChange={(val) => {
                      validateDecimal(val.target);
                      setToPay(val.target.value);
                    }}
                  />
                </InputGroup>
                <button
                  onClick={() => {
                    setShow(true);
                    getGhiseulRoLink();
                  }}
                  type="button"
                  className="btn btn-success mr-1 mt-3"
                  style={{ marginRight: "5px" }}
                  disabled={selectedTax == null || toPay === ""}
                >
                  {t("payments.pay")}
                </button>
              </>
            )}
            <Modal
              as={Modal.Dialog}
              centered
              show={show}
              backdrop="static" // Prevent closing when clicking outside the modal
              keyboard={false} // Prevent closing when pressing the Escape key
              onHide={() => {
                setShow(false);
              }}
            >
              <Modal.Header>
                <Modal.Title className="h6">
                  {t("payments.preview")}
                </Modal.Title>
                <Button
                  variant="close"
                  aria-label="Close"
                  onClick={() => {
                    setGhiseulRoLink("");
                    setShow(false);
                  }}
                  disabled={false}
                />
              </Modal.Header>
              <Modal.Body>
                <Alert variant="">
                  <label>{t("payments.user_making_payment")}:</label>
                  {user?.instance && user?.tip === "pf" ? (
                    <>
                      <input
                        className="form-control mb-3"
                        style={{ width: "300px" }}
                        value={
                          user?.tip.toUpperCase() +
                          " " +
                          (user?.instance as IUser).profile.cnp
                        }
                        disabled
                      />
                      <input
                        className="form-control mb-3"
                        style={{ width: "300px" }}
                        value={
                          (user?.instance as IUser).profile.nume +
                          " " +
                          (user?.instance as IUser).profile.prenume
                        }
                        disabled
                      />
                    </>
                  ) : (
                    <>
                      <input
                        className="form-control mb-3"
                        style={{ width: "300px" }}
                        value={
                          user?.tip.toUpperCase() +
                          " " +
                          (user?.instance as IBusiness).denumire
                        }
                        disabled
                      />
                      <input
                        className="form-control mb-3"
                        style={{ width: "300px" }}
                        value={(user?.instance as IBusiness).cui}
                        disabled
                      />
                    </>
                  )}
                  <label>{t("payments.payment_is_made_for")}:</label>
                  {/* <input
                    className="form-control mb-3"
                    style={{ width: "300px" }}
                    value={selectedTax?.tax.codClasificatieBugetara}
                    disabled
                  /> */}
                  <input
                    className="form-control mb-3"
                    style={{ width: "300px" }}
                    value={selectedTax?.name}
                    disabled
                  />
                  <label>{t("payments.amount_paid")}:</label>
                  <input
                    className="form-control mt-1"
                    style={{ width: "300px" }}
                    value={parseFloat(toPay).toFixed(2) + " RON"}
                    disabled
                  />
                  <hr />
                  <p className="mb-0">{t("payments.continue_to_ghiseul")}</p>
                </Alert>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="success"
                  className="ms-auto"
                  onClick={() => {
                    window.open(ghiseulRoLink, "_self");
                    setIsSubmitting(true);
                  }} /// handle ghiseul.ro here
                  disabled={ghiseulRoLink === "" || isSubmitting ? true : false}
                >
                  {t("payments.continue_pay")}
                  {isSubmitting && (
                    <Spinner animation="border" variant="secondary" size="sm" />
                  )}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
}

export default OtherPayments;

import { AxiosError } from "axios";

interface IBadResponse {
  statusCode: number;
  message: string[];
  error?: string;
}

function isBadResponse(
  response: IBadResponse | undefined,
): response is IBadResponse {
  if (response === undefined) return false;
  return (
    "message" in response &&
    "statusCode" in response &&
    Array.isArray(response.message)
  );
}

function handleException(rawError: any, t: any): string[] {
  const result: string[] = [];
  console.log(rawError);
  if (rawError instanceof AxiosError) {
    const backendResponse: IBadResponse = rawError.response?.data;

    if (isBadResponse(backendResponse)) {
      // we know that backendResponse is of IBadResponse type and that it has at least status and message(message is array)
      backendResponse.message.map((message) => {
        result.push(t(message));
      });
    } else {
      if (rawError.code === "ERR_NETWORK") {
        result.push(t("NETWORK_ERROR"));
      } else if (rawError.response?.status === 404) result.push(t("NOT_FOUND"));
      else if (rawError.response?.status === 403)
        result.push(t("FORBIDDEN_RESOURCE"));
      else if (rawError.response?.status === 429)
        result.push(t("TOO_MANY_REQUESTS"));
      /// an axios error but not in the standard format
      else {
        result.push(t("UNKNOWN_ERROR"));
      }
    }
  } else {
    /// not an axios error -> handle any known errors here
    /// this is mostly used to catch frontend errors
    if (rawError instanceof Error) {
      result.push(t(rawError.message));
    }
  }
  return result;
}

export default handleException;

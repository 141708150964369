import { Button } from "@themesberg/react-bootstrap";
import React, { useEffect, useState } from "react";
import { Alert, Modal, Spinner, Table } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusiness, ICreante, IUser } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import LoadingView from "../../layout/LoadingView";

export interface OptionType {
  id: string;
  name: string;
  tip: string;
  email: string;
}
interface UserOptionType extends OptionType {
  instance: IUser | IBusiness;
}
const DebitPayments = ({ user }: { user: UserOptionType | null }) => {
  const client = useAPIClient();
  const [found, setFound] = useState<boolean>(false);
  const [creante, setCreante] = useState<ICreante[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();

  const [show, setShow] = useState<boolean>();
  const [ghiseulRoLink, setGhiseulRoLink] = useState<string>("");
  const [selectedTax, setSelectedTax] = useState<ICreante | null>(null);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        let cnp_fiscal_code = "";
        if (user?.tip === "pf") {
          cnp_fiscal_code = (user?.instance as IUser).profile.cnp;
        } else {
          cnp_fiscal_code = (user?.instance as IBusiness).cui;
        }

        const response = await client.checkFiscalCodeExists(cnp_fiscal_code);
        setFound(response.found);

        if (response.found) {
          const paymentData = await client.getPaymentsDue(
            false,
            cnp_fiscal_code,
          );
          setCreante(paymentData?.creante || []);
        }

        setIsLoading(false);
      } catch (error) {
        // console.error(error);
        const errorMessages = handleException(error, t);
        errorMessages.map((message) => {
          toast.error(message);
        });
      }
    };

    fetchData();
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  if (!found) {
    return <p className="mx-1">{t("payments.no_info")}</p>;
  }

  if (creante.length === 0) {
    return <p className="mx-1">{t("payments.no_depts")}</p>;
  }

  const getGhiseulRoLink = async (creanta: ICreante) => {
    try {
      const payload = {
        idtaxa: creanta?.idCont?.toString(),
        denumireTaxa: creanta?.denumire,
        faraDebit: creanta?.faraDebit,
        ibantaxa: creanta?.iban,
        suma: (
          creanta?.debit +
          creanta?.ramasita +
          creanta?.majorari +
          creanta?.penalitati
        ).toFixed(2),
        cui: user?.id,
        cuip: user?.id,
        nume:
          user?.tip === "pf" && "profile" in user.instance
            ? user?.instance?.profile?.nume +
              " " +
              user?.instance?.profile?.prenume
            : user?.tip === "pj" &&
              "denumire" in user?.instance &&
              user?.instance?.denumire,
        adresa:
          user?.tip === "pf" && "profile" in user.instance
            ? user?.instance?.profile?.strada +
              ", " +
              user?.instance?.profile?.localitate +
              ", " +
              user?.instance?.profile?.judet
            : user?.tip === "pj" &&
              "strada" in user.instance &&
              user?.instance?.strada +
                ", " +
                user?.instance?.localitate +
                ", " +
                user?.instance?.judet,
        email: user?.email,
      };

      const res = await client.getGhiseulRoLink(payload);
      setGhiseulRoLink(res.redirectUrl);
    } catch (err: any) {
      console.log(err);

      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        return toast.error(message);
      });
    }
  };

  const formatAmount = (amount: number, digit: number) => {
    if (amount === 0) {
      return "0";
    }

    return amount.toLocaleString("ro-RO", {
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    });
  };

  return (
    <>
      {creante && (
        <Table responsive className="table-centered table-nowrap rounded mb-0">
          <thead className="thead-light">
            <tr>
              <th className="border-0">{t("payments.debit")}</th>
              <th className="border-0">{t("payments.remaining")}</th>
              <th className="border-0">{t("payments.increases")}</th>
              <th className="border-0">{t("payments.penalties")}</th>
              <th className="border-0">{t("payments.name")}</th>
              <th className="border-0">Total</th>
              <th className="border-0">{t("payments.options")}</th>
            </tr>
          </thead>
          <tbody>
            {creante.map((creanta, i) => (
              <tr key={i}>
                <td className="align-left">
                  {formatAmount(creanta.debit, 0)} RON
                </td>
                <td className="align-middle">
                  {formatAmount(creanta.ramasita, 0)} RON
                </td>
                <td className="align-middle">
                  {formatAmount(creanta.majorari, 0)} RON
                </td>
                <td className="align-middle">
                  {formatAmount(creanta.penalitati, 0)} RON
                </td>
                <td className="align-middle">{creanta.denumire}</td>
                <td className="align-middle">
                  {formatAmount(
                    creanta.debit +
                      creanta.ramasita +
                      creanta.majorari +
                      creanta.penalitati,
                    0,
                  )}{" "}
                  RON
                </td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      setSelectedTax(creanta);
                      setShow(true);
                      getGhiseulRoLink(creanta);
                    }}
                    className="btn-sm btn-success"
                  >
                    {t("payments.pay")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      <Modal
        as={Modal.Dialog}
        centered
        show={show}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Header>
          <Modal.Title className="h6">{t("payments.preview")}</Modal.Title>
          <Button
            variant="close"
            aria-label="Close"
            onClick={() => {
              setGhiseulRoLink("");
              setShow(false);
            }}
            disabled={false}
          />
        </Modal.Header>
        <Modal.Body>
          <Alert variant="">
            <label>{t("payments.user_making_payment")}:</label>
            {user?.instance && user?.tip === "pf" ? (
              <>
                <input
                  className="form-control mb-3"
                  style={{ width: "300px" }}
                  value={
                    user?.tip.toUpperCase() +
                    " " +
                    (user?.instance as IUser).profile.cnp
                  }
                  disabled
                />
                <input
                  className="form-control mb-3"
                  style={{ width: "300px" }}
                  value={
                    (user?.instance as IUser).profile.nume +
                    " " +
                    (user?.instance as IUser).profile.prenume
                  }
                  disabled
                />
              </>
            ) : (
              <>
                <input
                  className="form-control mb-3"
                  style={{ width: "300px" }}
                  value={
                    user?.tip.toUpperCase() +
                    " " +
                    (user?.instance as IBusiness).denumire
                  }
                  disabled
                />
                <input
                  className="form-control mb-3"
                  style={{ width: "300px" }}
                  value={(user?.instance as IBusiness).cui}
                  disabled
                />
              </>
            )}
            <label>{t("payments.payment_is_made_for")}:</label>
            {/* <input
      className="form-control mb-3"
      style={{ width: "300px" }}
      value={selectedTax?.tax.codClasificatieBugetara}
      disabled
    /> */}
            <input
              className="form-control mb-3"
              style={{ width: "300px" }}
              // value={selectedTax?.tax.name}
              value={selectedTax?.denumire}
              disabled
            />
            <label>{t("payments.amount_paid")}:</label>
            <input
              className="form-control mt-1"
              style={{ width: "300px" }}
              // value={selectedTax?.debit.toFixed(2) + " RON"}
              value={
                selectedTax
                  ? formatAmount(
                      selectedTax.debit +
                        selectedTax.ramasita +
                        selectedTax.majorari +
                        selectedTax.penalitati,
                      2,
                    ) + " RON"
                  : ""
              }
              disabled
            />
            <hr />
            <p className="mb-0">{t("payments.continue_to_ghiseul")} </p>
          </Alert>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            className="ms-auto"
            onClick={() => {
              window.open(ghiseulRoLink, "_self");
              setIsSubmitting(true);
            }} /// handle ghiseul.ro here
            disabled={ghiseulRoLink === "" || isSubmitting ? true : false}
          >
            {t("payments.continue_pay")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DebitPayments;

import React from "react";

import "./Options.css";

const Options = ({ title, options }) => {
  return (
    <div className="options">
      <h1 className="options-header">{title}</h1>
      <div className="options-container">
        {options.map((option) => {
          return (
            <button
              className="option-item"
              onClick={() => option.handleOption(option)}
              key={option.id}
            >
              {option.name ? option.name : option.content}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Options;

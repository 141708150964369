import { useEffect, useState } from "react";
import { Container, Form, Button, Modal, Card } from "react-bootstrap";
import { useAPIClient } from "../../../helpers/api";
import { toast } from "react-hot-toast";
import DataForm from "../../../components/Forms/DataForm";
import { useParams, useNavigate } from "react-router-dom";
import LoadingView from "../../../layout/LoadingView";
import { IProiect, ISmartForm } from "../../../cjt";
import { useTranslation } from "react-i18next";
import { SmartForm } from "../../../components/Forms/SmartForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationArrow } from "@fortawesome/free-solid-svg-icons";
import ChatBox from "../../Chatbox/Chatbox";
import handleException from "../../../helpers/exceptions";
import { formatDate } from "../../../helpers/utilities";
import { useForm } from "react-hook-form";
import { DosarStatus } from "../../../enums";
import dayjs from "dayjs";

export default function VizualizareDepunereDetail() {
  const { id } = useParams();
  const client = useAPIClient();
  const navigate = useNavigate();
  const [onlineRequestData, setOnlineRequestData] = useState<ISmartForm | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const [hasLocation, setHasLocation] = useState<boolean>(false);
  const [location, setLocation] = useState<{
    latitude: string;
    longitude: string;
  }>({ latitude: "0", longitude: "0" });
  const [dosarData, setDosarData] = useState<IProiect | null>(null);


  const fetchOnlineRequestById = async () => {
    try {
      const res = await client.getOnlineRequestsById(id!);
      setOnlineRequestData(res);

      if (res.submission.userLocation != null) {
        const location = JSON.parse(JSON.parse(res.submission.userLocation));
        setHasLocation(true);
        setLocation(location);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const fetchDosarByFormSubmissionId = async () => {
    try {
      const proiect = await client.getProiectFormSubmissionId(id!)
      setDosarData(proiect);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }    
  };


  useEffect(() => {
    fetchDosarByFormSubmissionId();
    fetchOnlineRequestById();
  }, []);

  const buildGoogleMapsURL = () => {
    const baseUrl = "https://www.google.com/maps";
    const coordinates = `${location.latitude},${location.longitude}`;
    const queryParam = `q=${encodeURIComponent(coordinates)}`;

    return `${baseUrl}?${queryParam}`;
  };

  const isValidationPeriod = dayjs(dosarData?.calendar.validare_proiecte_start).isBefore(new Date()) && dayjs(dosarData?.calendar.validare_proiecte_end).add(1, 'day').isAfter(new Date());
  const showRejectMessage = dosarData && dosarData.status === DosarStatus.Denied && isValidationPeriod;
  const showApproveMessage = dosarData && dosarData.status === DosarStatus.Approved && isValidationPeriod;
  const showValidationMessage = !showRejectMessage && !showApproveMessage && !isValidationPeriod;

  return (
    <Container className="mt-5">
      <>
        {showValidationMessage && (
            <div className="alert alert-info mt-4"><span>{t("calendar.proiect_status")}: <b>{dosarData?.status}</b>!</span></div>
        )}

        {showApproveMessage && (
            <div className="alert alert-success mt-4"><span>{t("calendar.proiect_approved_explanation")}</span>
            </div>
        )}

        {showRejectMessage && (
            <div className="alert alert-danger mt-4"><span>{t("calendar.proiect_rejected_explanation")}</span>
            <p>{t("calendar.project_reject_reason")}: <b>{dosarData.reason}</b></p>
            </div>
        )}

        <Card className="p-4 mb-4">
          {onlineRequestData && (
            <>
              <p>{t("calendar.submitted_project_category")}: ({dosarData?.category.map(el => <b>{el.denumire}, </b> )})</p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {isLoading ? (
                  <LoadingView />
                ) : (
                  <>
                    <h2 className="pt-1 mb-4 text-break">
                      {onlineRequestData.submission.templateName}
                    </h2>
                  </>
                )}
              </div>

              <div>
                {hasLocation &&
                  location &&
                  (parseInt(location.latitude) != 0 ||
                    parseInt(location.longitude) != 0) && (
                    <Button
                      variant="primary"
                      size="lg"
                      onClick={() => {
                        const url = buildGoogleMapsURL();
                        window.open(url, "_blank");
                      }}
                    >
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </Button>
                  )}
                {onlineRequestData.submission.user && (
                  <>
                    <p className="pt-3">{t("profile_data")}</p>

                    <DataForm
                      nume={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.nume
                      }
                      prenume={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.prenume
                      }
                      cnp={
                        onlineRequestData.submission.user.profile &&
                        onlineRequestData.submission.user.profile.cnp
                      }
                    />
                  </>
                )}
              </div>

              {onlineRequestData === null ? (
                <></>
              ) : (
                <>
                  <hr className="mb-4"></hr>

                  <SmartForm
                    form={JSON.parse(onlineRequestData.template)}
                    submission={JSON.parse(onlineRequestData.body)}
                    options={{
                      readOnly: true,
                      viewAsHtml: true,
                      // renderMode: "html",
                    }}
                  />
                  <hr className="mt-4"></hr>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {onlineRequestData.submission.comment && (
                      <p className="mb-4">
                        Cererea a fost{" "}
                        {onlineRequestData.submission.status.toLowerCase()}a cu
                        motivul: {onlineRequestData.submission.comment}
                      </p>
                    )}

                  </div>

                  {onlineRequestData.submission.chat && (
                    <>
                      <ChatBox id={onlineRequestData.submission.chat.id} />
                    </>
                  )}
                </>
              )}
            </>
        )}
        </Card>
      </>
    </Container>
  );
}

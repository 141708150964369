import md5 from "md5";
import { useState } from "react";
import { Col, Container, Image, Nav, Row, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RiBuildingLine, RiMapPinLine } from "react-icons/ri";

import "../../assets/css/allpayments.css";
import { IBusiness, IUser } from "../../cjt";
import { default as countries } from "../../helpers/countries.json";
import DebitPayments from "./DebitPayments";
import OtherPayments from "./OtherPayments";
import SelectUser from "./SelectUser";

export interface OptionType {
  id: string;
  name: string;
  tip: string;
  email: string;
}
interface UserOptionType extends OptionType {
  instance: IUser | IBusiness;
}

function AllPayments() {
  const [user, setUser] = useState<UserOptionType | null>(null);
  const countriesData: { [countryCode: string]: string } = countries;
  const { t } = useTranslation();

  return (
    <>
      <div style={{ display: "none" }}>
        <SelectUser setpUser={setUser} />
      </div>
      <Container className="mt-5 mb-6">
        <div className="col-xl-12">
          <div
            className="card custom-card"
            style={{
              borderBottomLeftRadius: "0px",
              borderBottomRightRadius: "0px",
            }}
          >
            <div className="card-body p-0">
              <div className="d-sm-flex align-items-center p-4 border-bottom border-block-end-dashed">
                <div className="avatar-cover">
                  <span className="avatar avatar-xxl avatar-rounded online me-3">
                    {user && (
                      <Image
                        src={`https://www.gravatar.com/avatar/${md5(
                          `${(user?.instance as IUser).email}`,
                        )}?size=150`}
                        alt="avatar"
                        className="rounded-circle"
                        style={{ width: "7em" }}
                        fluid
                      />
                    )}
                  </span>
                </div>
                {user && (
                  <>
                    {user?.instance && user?.tip == "pf" ? (
                      <>
                        <div className="flex-fill main-profile-info">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="fw-bold mb-1 text-uppercase">
                              {(user?.instance as IUser).profile.nume +
                                " " +
                                (user?.instance as IUser).profile.prenume}
                            </h6>
                            <SelectUser setpUser={setUser} />
                          </div>
                          <p className="mb-1 text-muted">
                            {t("profile_cnp")}:{" "}
                            {(user?.instance as IUser).profile.cnp}
                          </p>
                          <div className="d-flex mb-0 profile-location">
                            <span className="d-flex align-items-center me-3">
                              <RiBuildingLine className="me-1 align-middle" />
                              <div className="location-map-line">
                                {
                                  countriesData[
                                    (user?.instance as IUser).profile.tara
                                  ]
                                }
                              </div>
                            </span>
                            <span className="d-flex align-items-center ">
                              <RiMapPinLine className="me-1 align-middle" />
                              <div className="location-map-line">
                                {(user?.instance as IUser).profile.strada},{" "}
                                {(user?.instance as IUser).profile.localitate},{" "}
                                {(user?.instance as IUser).profile.judet},{" "}
                                {(user?.instance as IUser).profile.cod_postal}
                              </div>
                            </span>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="flex-fill main-profile-info">
                          <div className="d-flex align-items-center justify-content-between">
                            <h6 className="fw-bold mb-1 text-uppercase">
                              {(user?.instance as IBusiness).denumire}
                            </h6>
                            <SelectUser setpUser={setUser} />
                          </div>
                          <p className="mb-1 text-muted">
                            {t("profile_business_cui")}:{" "}
                            {(user?.instance as IBusiness).cui}
                          </p>
                          <div className="d-flex mb-0 profile-location">
                            <span className="d-flex align-items-center me-3">
                              <RiBuildingLine className="me-1 align-middle" />
                              <div className="location-map-line">
                                {
                                  countriesData[
                                    (user?.instance as IBusiness).tara
                                  ]
                                }
                              </div>
                            </span>
                            <span className="d-flex align-items-center ">
                              <RiMapPinLine className="me-1 align-middle" />
                              <div className="location-map-line">
                                {(user?.instance as IBusiness).strada},{" "}
                                {(user?.instance as IBusiness).localitate},{" "}
                                {(user?.instance as IBusiness).judet},{" "}
                                {(user?.instance as IBusiness).cod_postal}
                              </div>
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
              <div className="card-body">
                <Tab.Container defaultActiveKey="obligatii-de-plata">
                  <Row>
                    <Col lg={12}>
                      <Nav className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="obligatii-de-plata"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("payments.obligations")}
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="alte-taxe"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("payments.without_debit")}
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane
                          eventKey="obligatii-de-plata"
                          className="py-4"
                        >
                          {user && user?.tip === "pf" && (
                            <DebitPayments
                              // cnp={(user?.instance as IUser).profile.cnp}
                              user={user}
                            />
                          )}
                          {user && user?.tip === "pj" && (
                            <DebitPayments
                              // cnp={(user?.instance as IBusiness).cui}
                              user={user}
                            />
                          )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="alte-taxe" className="py-4">
                          <OtherPayments user={user} />
                        </Tab.Pane>
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}

export default AllPayments;

import { Card, Table, Form, Button } from '@themesberg/react-bootstrap';
import { persoaneFizice, persoaneJuridice } from "./data";
import dayjs from "dayjs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleQuestion, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export const TabelCereri = (props) => {

  const {tip, lista} = props;

  const TableRow = (props) => {
    const { id, created, nume, prenume, user, status } = props;
    const pjrequsername = user ? user.profile.nume + " " + user.profile.prenume : "";
    return (
        <tr>
            <td>
                {dayjs(created).format("DD-MM-YYYY HH:mm")}
            </td>
            <td>
                {tip == "PF" ? <>{nume} {prenume}</> : <>{pjrequsername}</> }
            </td>
            <td className="text-center">
            {tip == "PF" && <Button variant="primary" size="sm" href={"/admin/vizualizare-cerere/" + id}>Vizualizare Cerere</Button> }

            {tip == "PJ" && <Button variant="primary" size="sm" href={"/admin/vizualizare-business/" + id}>Vizualizare Cerere</Button> }
                
            </td>
            <td>
              {status === "Approved" ? (
                        <FontAwesomeIcon
                          color="green"
                          size="xl"
                          icon={faCircleCheck}
                        />
                      ) : status === "Rejected" ? (
                        <FontAwesomeIcon
                          color="red"
                          size="xl"
                          icon={faCircleXmark}
                        />
                      ) : (
                        <FontAwesomeIcon
                          color="lightblue"
                          size="xl"
                          icon={faCircleQuestion}
                        />
                      )}
            </td>
        </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mb-4">
      <Card.Title className="text-center">{tip == "PF" ? "Cereri persoane fizice" : "Cereri persoane juridice"}</Card.Title>
      <Card.Body className="pb-0">
        <Form>
            <Table responsive className="table-centered table-nowrap rounded mb-0">
                <thead className="thead-light">
                    <tr>
                        
                        <th className="border-0">Data Cerere</th>
                        <th className="border-0">Solicitant</th>
                        <th className="border-0 text-center">Vizualizare</th>
                        <th className="border-0">Status</th>
                    </tr>
                </thead>
                <tbody>
                    
                    {lista.map((pt) => (
                            <TableRow key={`roluri-${pt.id}`} {...pt} />
                        ))
                    }
                </tbody>
            </Table>
        </Form>
      </Card.Body>
    </Card>
  );
};
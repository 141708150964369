import * as Sentry from "@sentry/browser";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
// core styles
import "./scss/volt.scss";

const root = document.getElementById("root");

if (!root) {
  throw new Error("Root element not found");
}

if (process.env.REACT_APP_NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://84de54497f075e917b6cade16ce49974@support.e-timis.ro/3",
  });
}

const reactRoot = ReactDOM.createRoot(root);
reactRoot.render(
  <AuthProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AuthProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useAuthContext } from "../../context/AuthContext";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import {
  validatePassword,
  validatePasswordsMatch,
} from "../../helpers/validators";
import LoadingView from "../../layout/LoadingView";

function ChangePassword() {
  const { t } = useTranslation();
  const client = useAPIClient();
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const { logout } = useAuthContext();
  const [weakPassword, setWeakPassoword] = useState(false);

  const changePassword = async (password: string) => {
    try {
      await client.changePassword(password);
      setLoading(false);
    } catch (err: any) {
      if (err.response && err.response.data.statusCode === 401) {
        err.message = t("changepass.url");
        throw err.message;
      }
      throw err;
    }
  };

  const submitForm = async (data: FieldValues) => {
    setLoading(true);

    try {
      validatePasswordsMatch(data.newPassword, data.newPasswordConfirmation);
      try {
        validatePassword(data.newPassword);
      } catch (e) {
        setWeakPassoword(true);
        throw e;
      }
      await changePassword(data.newPassword);
      toast.success(t("changepass.success_change"));
      logout();
      // navigate("/account");
    } catch (e: any) {
      // toast.error(t(e));
      const errorMessages = handleException(e, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Container className="mt-5">
      <h1 className="text-center">{t("my_profile_change_password_title")}</h1>
      <div className="row d-flex justify-content-center align-items-center mt-5">
        <div className="mb-3 col-4">
          <Form onSubmit={handleSubmit(submitForm)}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>{t("my_profile_new_password")}</Form.Label>
              <div
                className="alert alert-danger"
                style={{ display: weakPassword ? "block" : "none" }}
              >
                <p>{t("password_contains")}</p>
                <ul>
                  <li>{t("min_char")}</li>
                  <li>{t("min_lowercase")}</li>
                  <li>{t("min_uppercase")}</li>
                  <li>{t("min_special")}</li>
                </ul>
              </div>
              <Form.Control
                type="password"
                placeholder={t("my_profile_new_password")}
                {...register("newPassword", { required: true })}
                // required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicConfirmPassword">
              <Form.Label>{t("my_profile_confirm_new_password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("my_profile_confirm_new_password")}
                {...register("newPasswordConfirmation", {
                  required: true,
                })}
                // required
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="formBasicCheckbox"
            ></Form.Group>
            <div className="d-grid">
              {loading ? (
                <LoadingView />
              ) : (
                <Button variant="primary" type="submit">
                  {t("my_profile_change_password")}
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    </Container>
  );
}

export default ChangePassword;

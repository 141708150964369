import {
  faBan,
  faFilePdf,
  faFileWord,
  faTrashCan,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Modal, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

import {
  IAnnex,
  IBusiness,
  IContractFolder,
  IContractTemplate,
  IUser,
} from "../../cjt";
import { useAuthContext } from "../../context/AuthContext";
import { AccountAttribute } from "../../enums";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import { downloadBase64File, formatDate } from "../../helpers/utilities";
import LoadingView from "../../layout/LoadingView";
import ChatBox from "../Chatbox/Chatbox";
import ProgressSteps from "./ProgressSteps";
import ProgressStepsH from "./ProgressStepsH";

export default function ContractDetails() {
  const { id } = useParams();
  const client = useAPIClient();
  const { t } = useTranslation();
  const [contract, setContract] = useState<IContractFolder | null>(null); //TODO: change to Contract type
  const [legalPerson, setLegalPerson] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openGenerateCtr, setOpenGenerateCtr] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [templates, setTemplates] = useState<IContractTemplate[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [openUploadContract, setOpenUploadContract] = useState(false);
  const [openUploadAnnex, setOpenUploadAnnex] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [annexNumber, setAnnexNumber] = useState("");
  const [annexDate, setAnnexDate] = useState("");
  const [annexSignDate, setAnnexSignDate] = useState("");
  const [annexPdf, setAnnexPdf] = useState("");
  const [annexName, setAnnexName] = useState("");
  const [registrationDate, setRegistrationDate] = useState("");
  const [signDate, setSignDate] = useState("");
  const [signedPdf, setSignedPdf] = useState("");
  const [signedPdfName, setSignedPdfName] = useState("");
  const [userSignedPdfName, setUserSignedPdfName] = useState("");
  const [userAnnexPdf, setUserAnnexPdf] = useState("");
  const [userAnnexPdfName, setUserAnnexPdfName] = useState("");
  const [annexPdfName, setAnnexPdfName] = useState("");
  const [value, setValue] = useState("");
  const [duration, setDuration] = useState("");
  const [annexToDelete, setAnnexToDelete] = useState<IAnnex | null>(null);
  const [annexList, setAnnexList] = useState<IAnnex[]>([]);
  const [signatureType, setSignatureType] = useState<String>("olograf");
  const [annexSignatureType, setAnnexSignatureType] =
    useState<String>("olograf");
  const [openUploadUserContract, setOpenUploadUserContract] = useState(false);
  const [openUploadUserAnnex, setOpenUploadUserAnnex] = useState(false);
  const [userSignedPdf, setUserSignedPdf] = useState("");
  const [openMarkContractFinal, setOpenMarkContractFinal] = useState(false);
  const [keysValues, setKeysValues] = useState<{ [key: string]: any }>({});
  const [openConfirmCancelContract, setOpenConfirmCancelContract] =
    useState(false);
  const [cancelReason, setCancelReason] = useState("");

  const navigate = useNavigate();
  const { requiredAttribute, isAuditor } = useAuthContext();

  const isContractAdmin = requiredAttribute(
    AccountAttribute.ContractsAdministrator,
  );

  const isHearingAdmin = requiredAttribute(
    AccountAttribute.HearingAdministrator,
  );

  const handeSignatureChange = (event: any) => {
    setSignatureType(event.target.value);
  };

  const handleAnnexSignatureChange = (event: any) => {
    setAnnexSignatureType(event.target.value);
  };

  const fetchContractById = async () => {
    try {
      const res = await client.getContractById(id!);
      if (res.beneficiary) {
        setLegalPerson(res.beneficiary.user);
      }

      if (res.registrationNumber && res.registrationDate) {
        setRegistrationNumber(res.registrationNumber);
        setRegistrationDate(res.registrationDate);
      }
      setContract(res);
      setAnnexList([...res.annexes]);
      if (isContractAdmin) {
        setTemplates(await client.getContractTemplates());
      }

      setIsLoading(false);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  useEffect(() => {
    fetchContractById();
  }, []);

  const getAllAnnexess = async () => {
    if (contract?.id !== undefined) {
      const res = await client.getAnnexListByContract(contract.id);
      setAnnexList(res);
    }
  };

  useEffect(() => {
    getAllAnnexess();
  }, [contract]);

  const fetchKeysFromTemplate = async (templateId: string) => {
    try {
      setIsSubmitting(true);
      const tplKeys = await client.getKeysFromTpl(templateId);

      const outputObject: { [key: string]: any } = {};

      for (const key of tplKeys) {
        if (contract?.beneficiary.hasOwnProperty(key)) {
          outputObject[key] = contract?.beneficiary[key as keyof IBusiness];
        } else {
          outputObject[key] = "";
        }
      }
      if (tplKeys.includes("legal_person")) {
        outputObject["legal_person"] =
          legalPerson?.profile.nume + " " + legalPerson?.profile.prenume;
      }

      setKeysValues(outputObject);

      setIsSubmitting(false);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const handleGenerateContract = async () => {
    if (!contract) return;
    let _errors: { [key: string]: any } = {};

    if (!selectedTemplate) {
      _errors.template = t("contract.errors_template");
    }

    setErrors(_errors);

    if (_errors.template) return;

    setIsSubmitting(true);
    try {
      const generatedContract = await client.generateContract(
        selectedTemplate,
        keysValues,
      );
      await client.updateContract({
        contractFolderId: contract.id,
        status: "WaitingForSignatures",
      });
      downloadBase64File(
        generatedContract,
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "contract.docx",
      );

      await fetchContractById();
      toast.success(t("contract.success_generate"));
      setSelectedTemplate("");
      setKeysValues({});
      setOpenGenerateCtr(false);
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUploadContract = async () => {
    if (!contract) return;
    let _errors: { [key: string]: any } = {};
    if (signatureType === "olograf" && !registrationNumber.trim()) {
      _errors.registrationNumber = t("contract.errors_getRegistrationNumber");
    }
    if (signatureType === "olograf" && !registrationDate) {
      _errors.registrationDate = t("contract.errors_setRegistrationDate");
    }
    if (!signDate) {
      _errors.signDate = t("contract.errors_setSignDate");
    }
    if (signedPdf === "") {
      _errors.signedPdf = t("contract.errors_loadSignedPdf");
    } else if (signedPdfName.length < 4) {
      _errors.signedPdf = t("contract.errors_notPdf");
    } else if (signedPdfName.slice(-4) !== ".pdf") {
      _errors.signedPdf = t("contract.errors_notPdf");
    }
    if (!value) {
      _errors.value = t("contract.errors_value");
    }
    if (!duration) {
      _errors.duration = t("contract.errors_duration");
    }

    setErrors(_errors);

    if (
      _errors.registrationNumber ||
      _errors.registrationDate ||
      _errors.signDate ||
      _errors.signedPdf ||
      _errors.value ||
      _errors.duration
    )
      return;

    const uploadContractParams: {
      [key: string]: any;
    } = {};

    uploadContractParams["contractFolderId"] = contract.id;
    if (signatureType === "olograf") {
      uploadContractParams["registrationNumber"] = registrationNumber.trim();
      uploadContractParams["registrationDate"] = registrationDate;
    }
    uploadContractParams["signDate"] = signDate;
    uploadContractParams["signedPdf"] = signedPdf;
    uploadContractParams["value"] = value;
    uploadContractParams["duration"] = duration;
    if (signatureType === "digital") {
      uploadContractParams["allowUpload"] = true;
    } else {
      uploadContractParams["allowUpload"] = false;
    }
    uploadContractParams["status"] = "WaitingForSignatures";

    setIsSubmitting(true);
    try {
      await client.updateContract(uploadContractParams);
      toast.success(t("contract.success_load"));
      await fetchContractById();
      setSignDate("");
      setSignedPdf("");
      setOpenUploadContract(false);
      setValue("");
      setDuration("");
    } catch (e) {
      console.log(e);

      const errorMessages = handleException(e, t);

      errorMessages.forEach((message) => {
        return toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!contract) {
    return (
      <Container style={{ textAlign: "center" }}>
        <Spinner />
      </Container>
    );
  }

  const handelUploadUserContract = async () => {
    let _errors: { [key: string]: any } = {};
    if (userSignedPdf === "") {
      _errors.userSignedPdf = t("contract.errors_loadSignedPdf");
    } else if (userSignedPdfName.length < 4) {
      _errors.userSignedPdf = t("contract.errors_notPdf");
    } else if (userSignedPdfName.slice(-4) !== ".pdf") {
      _errors.userSignedPdf = t("contract.errors_notPdf");
    }

    setErrors(_errors);

    if (_errors.userSignedPdf) {
      return;
    }

    setIsSubmitting(true);

    const uploadUserPdfParams: {
      [key: string]: any;
    } = {};

    uploadUserPdfParams["contractFolderId"] = contract.id;
    uploadUserPdfParams["userSignedPdf"] = userSignedPdf;

    try {
      await client.uploadUserPdf(uploadUserPdfParams);
      toast.success(t("contract.success_load"));
      await fetchContractById();
      setUserSignedPdf("");
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
      setOpenUploadUserContract(false);
    }
  };

  const handleUploadUserAnnex = async () => {
    let _errors: { [key: string]: any } = {};
    if (userAnnexPdfName === "") {
      _errors.userAnnexPdf = "Incarcati contractul semnat!";
    } else if (userAnnexPdfName.length < 4) {
      _errors.userAnnexPdf = "Fisierul nu este de tip pdf!";
    } else if (userAnnexPdfName.slice(-4) !== ".pdf") {
      _errors.userAnnexPdf = "Fisierul nu este de tip pdf!";
    }

    setErrors(_errors);
    if (_errors.userAnnexPdf) {
      return;
    }

    setIsSubmitting(true);

    const uploadUserPdfParams: {
      [key: string]: any;
    } = {};

    uploadUserPdfParams["userAnnexPdf"] = userAnnexPdf;

    try {
      await client.uploadUserAnnexPdf(contract.id, uploadUserPdfParams);
      toast.success(t("contract.success_loadAdditional"));
      await fetchContractById();
      setUserSignedPdf("");
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
      setOpenUploadUserAnnex(false);
    }
  };

  const markContractFinal = async () => {
    const uploadContractParams: {
      [key: string]: any;
    } = {};

    uploadContractParams["contractFolderId"] = contract.id;
    uploadContractParams["status"] = "Ongoing";

    setIsSubmitting(true);

    try {
      await client.updateContract(uploadContractParams);
      toast.success(t("contract.final"));
      await fetchContractById();
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
      setOpenMarkContractFinal(false);
    }
  };

  const handleUploadAnnex = async () => {
    let _errors: { [key: string]: any } = {};

    if (!annexNumber.trim()) {
      _errors.annexNumber = t("contract.errors_annexNumber");
    }
    if (!annexDate) {
      _errors.annexDate = t("contract.errors_annexDate");
    }
    if (!annexSignDate) {
      _errors.annexSignDate = t("contract.errors_signAnnexDate");
    }
    if (!annexPdf) {
      _errors.annexPdf = t("contract.errors_loadAdditional");
    } else if (annexPdfName.length < 4) {
      _errors.annexPdf = t("contract.errors_notPdf");
    } else if (annexPdfName.slice(-4) !== ".pdf") {
      _errors.annexPdf = t("contract.errors_notPdf");
    }

    setErrors(_errors);

    if (
      _errors.annexNumber ||
      _errors.annexDate ||
      _errors.annexSignDate ||
      _errors.annexPdf
    )
      return;

    const uploadAnnexParams: {
      [key: string]: any;
    } = {};

    uploadAnnexParams["annexNumber"] = annexNumber;
    uploadAnnexParams["annexSignDate"] = annexSignDate;
    uploadAnnexParams["annexDate"] = annexDate;
    uploadAnnexParams["annexPdf"] = annexPdf;
    if (annexName !== "") {
      uploadAnnexParams["annexName"] = annexName;
    } else {
      uploadAnnexParams["annexName"] = null;
    }

    if (annexSignatureType === "digital") {
      uploadAnnexParams["allowAnnexUpload"] = true;
    } else {
      uploadAnnexParams["allowAnnexUpload"] = false;
    }

    setIsSubmitting(true);

    try {
      await client.uploadAnnexPdf(contract.id, uploadAnnexParams);
      if (annexSignatureType === "olograf") {
        await client.confirmAnnex(contract.id);
      }
      toast.success(t("contract.success_add"));
      getAllAnnexess();
      fetchContractById();
    } catch (e) {
      const errorMessages = handleException(e, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setIsSubmitting(false);
      setOpenUploadAnnex(false);
    }
  };

  const handleAddRegNumber = async () => {
    try {
      setIsSubmitting(true);
      await client.getContractRegNumber(contract.id);
      fetchContractById();
      toast.success(t("contract.success_registrationNumber"));
      setIsSubmitting(false);
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
  };

  const goToHearingsPage = () => {
    navigate("/admin/hearings", {
      state: { benefficiary: contract.beneficiary?.user },
    });
  };

  const handleCancelContract = async () => {
    if (!cancelReason.trim()) {
      setErrors({ cancelReason: t("contract.errors_cancel") });
      return;
    }

    const updateContractParams: {
      [key: string]: any;
    } = {};

    updateContractParams["contractFolderId"] = contract.id;
    updateContractParams["status"] = "Cancelled";
    updateContractParams["cancelReason"] = cancelReason.trim();

    try {
      setIsSubmitting(true);
      console.log(cancelReason.trim());
      await client.updateContract(updateContractParams);
      fetchContractById();
      toast.success(t("contract.success_cancel"));
    } catch (err) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    } finally {
      setOpenConfirmCancelContract(false);
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <h2 className="fw-bold mb-5 text-uppercase text-center">
        {contract.name} <br></br>#{" "}
        {contract.registrationNumber
          ? contract.registrationNumber +
            " / " +
            dayjs(contract.registrationDate).format("DD-MM-YYYY")
          : "_ _ _"}
      </h2>

      <div style={{ display: "flex" }}>
        {/* <ProgressSteps status={contract.status} /> */}
        <Container className="px-xr0">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {contract.status === "Cancelled" && contract.cancelReason ? (
              <div className="mt-2">
                <h4>
                  <Badge bg="danger" className="badge-lg">
                    {t("contract_details.cancelled")}
                  </Badge>{" "}
                  - {contract.cancelReason}
                </h4>
                {/* <p>{contract.cancelReason}</p> */}
              </div>
            ) : (
              <>
                <ProgressStepsH status={contract.status} />

                <div>
                  {isContractAdmin && isHearingAdmin && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="m-0">
                          {isAuditor
                            ? t("contract_hearing_auditor")
                            : t("contract_hearing_non_auditor")}
                        </Tooltip>
                      }
                    >
                      <Button
                        size="sm"
                        variant="secondary"
                        onClick={isAuditor ? goToHearingsPage : () => {}}
                        // disabled={isAuditor}
                      >
                        <FontAwesomeIcon icon={faVideo} className="me-2" />
                        {t("contract_details.create_hearing")}
                      </Button>
                    </OverlayTrigger>
                  )}

                  {isContractAdmin && (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="m-0">
                          {t("contract_details.cancel_the_contract")}
                        </Tooltip>
                      }
                    >
                      <Button
                        size="sm"
                        variant="danger"
                        style={{ marginLeft: "10px" }}
                        onClick={() => setOpenConfirmCancelContract(true)}
                      >
                        <FontAwesomeIcon icon={faBan} className="me-2" />
                        {t("contract_details.cancel_contract")}
                      </Button>
                    </OverlayTrigger>
                  )}
                </div>
              </>
            )}
          </div>
          <Row>
            <Col xs={6} className="p-1">
              <Card>
                <Card.Body>
                  <h3 className="mb16">
                    {t("contract_details.business_info")}
                  </h3>
                  <br></br>
                  <h6>{t("contract_details.bussines_name")}</h6>
                  <p>{contract.beneficiary.denumire}</p>
                  <h6>{t("contract_details.cui")}</h6>
                  <p>{contract.beneficiary.cui}</p>
                  <h6>{t("contract_details.business_type")}</h6>
                  <p>{contract.beneficiary.tip}</p>
                  <h6>{t("contract_details.registration_number")}</h6>
                  <p>
                    {contract.beneficiary.nr_onrc
                      ? contract.beneficiary.nr_onrc
                      : "n/a"}
                  </p>
                  {legalPerson && (
                    <>
                      <h6>{t("contract_details.legal_representative")}</h6>
                      <p>
                        {legalPerson.profile.nume} {legalPerson.profile.prenume}
                      </p>
                      <h6>E-mail</h6>
                      <p>{legalPerson.email}</p>
                      <h6>{t("contract_details.phone_number")}</h6>
                      <p>{legalPerson.profile.telefon}</p>
                    </>
                  )}
                  <h6>{t("contract_details.address")}</h6>
                  <p>
                    {contract.beneficiary.strada},{" "}
                    {contract.beneficiary.cod_postal},{" "}
                    {contract.beneficiary.localitate},{" "}
                    {contract.beneficiary.judet}, {contract.beneficiary.tara}
                  </p>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={6} className="p-1">
              <Card>
                <Card.Body>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <h3 className="mb16">Date</h3>
                      <h5>{t("contract_details.creation_date")}</h5>
                      <p> {dayjs(contract.createdAt).format("DD-MM-YYYY")}</p>
                      <h5>{t("contract_details.registration_number")}</h5>
                      <p>
                        {contract.registrationNumber
                          ? contract.registrationNumber
                          : "n/a"}
                      </p>
                      <h5>{t("contract_details.sign_date")}</h5>
                      <p>
                        {contract.signDate
                          ? dayjs(contract.signDate).format("DD-MM-YYYY")
                          : "n/a"}
                      </p>

                      <h5>{t("contract_details.value")}</h5>
                      <p>{contract.value ? contract.value : "n/a"}</p>

                      <h5>{t("contract_details.duration")}</h5>
                      <p>{contract.duration ? contract.duration : "n/a"}</p>
                    </div>

                    <div>
                      {isContractAdmin &&
                        contract.status !== "Ongoing" &&
                        contract.status !== "Cancelled" &&
                        ((contract.isSignedPdfUploaded &&
                          !contract.allowUpload) ||
                          (contract.allowUpload &&
                            contract.isUserSignedPdfUploaded)) && (
                          <Row className="mb-2">
                            <Col>
                              <h6>
                                {t("contract_details.mark_contract_as_final")}
                              </h6>
                              <Button
                                variant="success"
                                // className="m-1"
                                size="sm"
                                onClick={() => setOpenMarkContractFinal(true)}
                                target="_blank"
                              >
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  className="me-2"
                                />
                                {t("contract_details.finalize")}
                              </Button>
                            </Col>
                          </Row>
                        )}
                    </div>
                  </div>
                </Card.Body>
              </Card>

              {isContractAdmin &&
                !contract.isFinal &&
                contract.status !== "Ongoing" && (
                  <Card className="mt-2">
                    <Card.Body>
                      <h3 className="mb16">
                        {" "}
                        {t("contract_details.documents")}
                      </h3>

                      {contract.status !== "Ongoing" &&
                        contract.status !== "Cancelled" && (
                          <div style={{ display: "flex" }}>
                            <Button
                              variant="outline-primary"
                              className="m-1"
                              style={{ float: "right" }}
                              onClick={() => setOpenGenerateCtr(true)}
                            >
                              <FontAwesomeIcon
                                icon={faFileWord}
                                className="me-2"
                              />
                              {t("contract_details.generate_contract")}(.docx)
                            </Button>

                            {isContractAdmin && (
                              <Button
                                variant="outline-primary"
                                className="m-1"
                                style={{ float: "right" }}
                                onClick={() => setOpenUploadContract(true)}
                              >
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  className="me-2"
                                />
                                {contract.isSignedPdfUploaded
                                  ? t(
                                      "contract_details.modify_contract_signed_by_cj_timis",
                                    )
                                  : t(
                                      "contract_details.upload_contract_signed_by_cj_timis",
                                    )}
                              </Button>
                            )}
                          </div>
                        )}
                      {!contract.isFinal && contract.isSignedPdfUploaded && (
                        <>
                          <hr></hr>
                          <h5>
                            {t("contract_details.signed_documents")}
                            {/* {contract.allowUpload ? "digital" : "olograf"} */}
                          </h5>
                          <Row>
                            <Col md={6}>
                              <Button
                                variant="success"
                                className="mt-1 align-self-sm-start"
                                // size="sm"
                                style={{ float: "left" }}
                                onClick={() => client.getSignedPdf(contract.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faFilePdf}
                                  className="me-2"
                                />
                                {contract.allowUpload
                                  ? t(
                                      "contract_details.contract_signed_by_cj_timis",
                                    )
                                  : t("contract_details.signed_contract")}
                              </Button>
                            </Col>
                            <Col>
                              {contract.allowUpload &&
                                contract.isUserSignedPdfUploaded && (
                                  <Button
                                    variant="success"
                                    className="mt-1 align-self-sm-start"
                                    // size="sm"
                                    style={{ float: "left" }}
                                    onClick={() =>
                                      client.getUserSignedPdf(contract.id)
                                    }
                                  >
                                    <FontAwesomeIcon
                                      icon={faFilePdf}
                                      className="me-2"
                                    />
                                    {t(
                                      "contract_details.signed_by_benefficiary",
                                    )}
                                  </Button>
                                )}
                              {contract.allowUpload &&
                                !contract.isUserSignedPdfUploaded && (
                                  <p style={{ color: "red" }}>
                                    {t(
                                      "contract_details.waiting_for_benefficiary_signature",
                                    )}
                                  </p>
                                )}
                            </Col>
                          </Row>
                        </>
                      )}
                      {/* {contract.status === "Ongoing" && (
                      <div style={{ marginLeft: "10px" }}>
                        <h5 className="ml-2 mt-3">Acte adiționale</h5>
                        <Button
                          variant="success"
                          className="m-1"
                          onClick={() => setOpenUploadAnnex(true)}
                        >
                          <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                          Adaugă act adițional
                        </Button>
                      </div>
                    )} */}
                    </Card.Body>
                  </Card>
                )}
              {(contract.status === "Ongoing" ||
                contract.status === "Cancelled") &&
                (contract.isSignedPdfUploaded ||
                  contract.isUserSignedPdfUploaded) &&
                contract.isFinal && (
                  <Card className="mt-2">
                    <Card.Body>
                      <h5>{t("contract_details.signed_contract")}</h5>
                      <p>
                        {t("contract_details.download_signed_contract_here")}
                      </p>
                      <Button
                        variant="success"
                        className="mt-1 align-self-sm-start"
                        // size="sm"
                        style={{ float: "left" }}
                        onClick={
                          contract.allowUpload
                            ? () => client.getUserSignedPdf(contract.id)
                            : () => client.getSignedPdf(contract.id)
                        }
                      >
                        <FontAwesomeIcon icon={faFileWord} className="me-2" />
                        {t("contract_details.download_contract")}
                      </Button>
                    </Card.Body>
                  </Card>
                )}
            </Col>
          </Row>
          {!isContractAdmin && (
            <Row>
              {(contract.status === "WaitingForSignatures" ||
                contract.status === "Cancelled") &&
                contract.allowUpload &&
                contract.isSignedPdfUploaded &&
                !contract.isUserSignedPdfUploaded && (
                  <Card>
                    <Card.Body className="mt-2">
                      <div className="d-flex flex-column">
                        <h5 className="mb16">
                          {t("contract_details.instructions")}
                        </h5>
                        <h6>{t("contract_details.step_1")}</h6>
                        <p>{t("contract_details.download_contract_text")}</p>
                        <Button
                          variant="success"
                          className="mt-1 align-self-sm-start"
                          size="sm"
                          style={{ float: "left" }}
                          onClick={() => client.getSignedPdf(contract.id)}
                        >
                          <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                          {t("contract_details.download_contract")}
                        </Button>
                        {contract.status !== "Cancelled" && (
                          <>
                            <h6 className="mt-3">
                              {t("contract_details.step_2")}
                            </h6>
                            <p>
                              {t("contract_details.sign_contract_digitally")}
                            </p>
                            <h6 className="mt-3">
                              {t("contract_details.step_3")}
                            </h6>
                            <p>{t("contract_details.upload")}</p>
                            <Button
                              variant="success"
                              className="mt-1 align-self-sm-start"
                              size="sm"
                              style={{ float: "left" }}
                              onClick={() => setOpenUploadUserContract(true)}
                            >
                              <FontAwesomeIcon
                                icon={faFileWord}
                                className="me-2"
                              />
                              {t("contract_details.upload_contract")}
                            </Button>
                          </>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                )}
              {(contract.status === "WaitingForSignatures" ||
                contract.status === "Cancelled") &&
                contract.allowUpload &&
                contract.isUserSignedPdfUploaded &&
                !contract.isFinal && (
                  <Card className="mt-2">
                    <Card.Body>
                      <div className="d-flex flex-column">
                        <h5>
                          {t("contract_details.signed_contract_uploaded")}
                        </h5>
                        <p>{t("contract_details.wait_for_operator")}</p>
                        <p>
                          {t("contract_details.download_uploaded_contract")}
                        </p>
                        <Button
                          variant="success"
                          className="mt-1 align-self-sm-start"
                          size="sm"
                          style={{ float: "left" }}
                          onClick={() => client.getSignedPdf(contract.id)}
                        >
                          <FontAwesomeIcon icon={faFilePdf} className="me-2" />
                          {t(
                            "contract_details.download_contract_signed_by_cj_timis",
                          )}
                        </Button>
                        <p className="mt-4">
                          {t(
                            "contract_details.see_contract_uploaded_by_yourself_here",
                          )}
                        </p>
                        <Button
                          variant="success"
                          className="mt-1 align-self-sm-start"
                          size="sm"
                          style={{ float: "left" }}
                          onClick={() => client.getUserSignedPdf(contract.id)}
                        >
                          <FontAwesomeIcon icon={faFileWord} className="me-2" />
                          {t("contract_details.download_contract")}
                        </Button>
                        {contract.status !== "Cancelled" && (
                          <>
                            <p className="mt-4">
                              {t(
                                "contract_details.retry_upload_in_case_of_mistake",
                              )}
                            </p>
                            <Button
                              variant="primary"
                              className="mt-1 align-self-sm-start"
                              size="sm"
                              style={{ float: "left" }}
                              onClick={() => setOpenUploadUserContract(true)}
                            >
                              <FontAwesomeIcon
                                icon={faFileWord}
                                className="me-2"
                              />
                              {t("contract_details.upload_contract")}
                            </Button>
                          </>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                )}
            </Row>
          )}
          {(contract.status === "Ongoing" ||
            contract.status === "Cancelled") && (
            <Row>
              <Col md={12} className="p-1">
                <Card>
                  <Card.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h3 className="mb16">
                          {t("contract_details.additional_documents")}
                        </h3>
                        {isContractAdmin &&
                          contract.status !== "Cancelled" &&
                          contract.annexStatus == "Closed" && (
                            <Button
                              variant="success"
                              className="m-1"
                              onClick={() => setOpenUploadAnnex(true)}
                            >
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                className="me-2"
                              />
                              {t("contract_details.add_addtional_document")}
                            </Button>
                          )}
                        {contract.annexStatus === "WaitingForSignatures" &&
                          !contract.isUserAnnexPdfUploaded &&
                          contract.allowAnnexUpload && (
                            <div className="d-flex flex-column">
                              <h5>
                                {t(
                                  "contract_details.additional_document_uploaded_for_signature",
                                )}
                              </h5>
                              <p>
                                {isContractAdmin
                                  ? t(
                                      "contract_details.version_signed_by_cj_timis",
                                    )
                                  : t(
                                      "contract_details.download_version_signed_by_cj_timis",
                                    )}
                              </p>
                              <Button
                                variant="success"
                                className="mt-1 align-self-sm-start"
                                size="sm"
                                style={{ float: "left" }}
                                onClick={() => client.getAnnexPdf(contract.id)}
                              >
                                {t(
                                  "contract_details.download_additional_docment",
                                )}
                              </Button>
                              {contract.status !== "Cancelled" && (
                                <>
                                  <p className="mt-3">
                                    {isContractAdmin
                                      ? t(
                                          "contract_details.upload_variant_signed_by_benefficiary",
                                        )
                                      : t(
                                          "contract_details.sign_variant_by_you",
                                        )}
                                  </p>

                                  <Button
                                    variant="success"
                                    className="mt-1 align-self-sm-start"
                                    size="sm"
                                    style={{ float: "left" }}
                                    onClick={() => setOpenUploadUserAnnex(true)}
                                  >
                                    {t("contract_details.add_additional_act")}
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                        {contract.annexStatus === "WaitingForSignatures" &&
                          contract.isUserAnnexPdfUploaded && (
                            <div className="d-flex flex-column">
                              {!isContractAdmin && (
                                <>
                                  <h5>
                                    {t(
                                      "contract_deatails.you_uploaded_additional_act",
                                    )}
                                  </h5>
                                  <p>
                                    {" "}
                                    {t(
                                      "contract_details.pls_wait_for_operator_confirmation",
                                    )}
                                  </p>
                                </>
                              )}
                              <p>
                                {t(
                                  "contract_details.download_variant_signet_by_cj_timis",
                                )}
                              </p>

                              <Button
                                variant="success"
                                className="mt-1 align-self-sm-start"
                                size="sm"
                                style={{ float: "left" }}
                                onClick={() => client.getAnnexPdf(contract.id)}
                              >
                                {t("contract_details.download")}
                              </Button>
                              <p className="mt-3">
                                {isContractAdmin
                                  ? t(
                                      "contract_details.variant_signed_by_benefficiary",
                                    )
                                  : t(
                                      "contract_details.variant_signed_by_yourself",
                                    )}
                              </p>
                              <Button
                                variant="success"
                                className="mt-1 align-self-sm-start"
                                size="sm"
                                style={{ float: "left" }}
                                onClick={() =>
                                  client.getUserAnnexPdf(contract.id)
                                }
                              >
                                {t("contract_details.download")}
                              </Button>
                              {contract.status !== "Cancelled" && (
                                <>
                                  <p className="mt-3">
                                    {t("contract_details.reupload_if_mistake")}
                                  </p>
                                  <Button
                                    variant="primary"
                                    className="mt-1 align-self-sm-start"
                                    size="sm"
                                    style={{ float: "left" }}
                                    onClick={() => setOpenUploadUserAnnex(true)}
                                  >
                                    {t("contract_details.upload")}
                                  </Button>
                                </>
                              )}
                            </div>
                          )}
                      </div>
                      <div>
                        {isContractAdmin &&
                          contract.annexStatus === "WaitingForSignatures" &&
                          contract.status !== "Cancelled" &&
                          (contract.allowAnnexUpload === false ||
                            (contract.allowAnnexUpload &&
                              contract.isUserAnnexPdfUploaded)) && (
                            <Row>
                              <Col>
                                <h6>
                                  {t(
                                    "contract_details.mark_additional_document_final",
                                  )}
                                </h6>
                                <Button
                                  variant="success"
                                  className="mt-1 align-self-sm-start"
                                  size="sm"
                                  style={{ float: "left" }}
                                  onClick={async () => {
                                    try {
                                      await client.confirmAnnex(contract.id);
                                      fetchContractById();
                                      toast.success(
                                        "Act aditional confirmat cu succes",
                                      );
                                    } catch (e) {
                                      toast.error(
                                        "Eroare la confirmarea actului aditional",
                                      );
                                    }
                                  }}
                                >
                                  {t("contract_details.finalize")}
                                </Button>
                                {/* </Card.Body>
                      </Card> */}
                              </Col>
                            </Row>
                          )}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          {annexList?.length !== 0 && (
            <Row>
              <Col xs={12} className="p-1">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col lg={12}>
                        <h5>{t("contract_details.additional_documents")}</h5>
                      </Col>
                      <Col lg={12}>
                        {annexList?.length === 0 ? (
                          <div className="d-flex justify-content-center align-items-center mb-3">
                            <h5 className="mb-0">
                              {t("contract_details.no_additional_documents")}
                            </h5>
                          </div>
                        ) : (
                          <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                              {isLoading ? (
                                <LoadingView />
                              ) : (
                                <Table
                                  responsive
                                  className="table-centered table-nowrap rounded mb-0"
                                >
                                  <thead className="thead-light">
                                    <tr>
                                      <th className="border-0">
                                        {t(
                                          "contract_details.additional_document_name",
                                        )}
                                      </th>
                                      <th className="border-0">
                                        {t(
                                          "contract_details.additional_document_number",
                                        )}
                                      </th>
                                      <th className="border-0">
                                        {t(
                                          "contract_details.registration_date",
                                        )}
                                      </th>
                                      <th className="border-0">
                                        {t("contract_details.sign_date")}
                                      </th>
                                      <th className="border-0"></th>
                                      {isContractAdmin && (
                                        <th className="border-0 text-center"></th>
                                      )}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {annexList?.map((annex, i) => (
                                      <tr key={i}>
                                        <td>{annex.annexName}</td>
                                        <td>{annex.annexNumber}</td>
                                        <td>
                                          {dayjs(annex.annexDate).format(
                                            "DD-MM-YYYY",
                                          )}
                                        </td>
                                        <td>
                                          {dayjs(annex.annexSignDate).format(
                                            "DD-MM-YYYY",
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <Button
                                            variant="success"
                                            className="m-1"
                                            onClick={() =>
                                              client.getFinalAnnexPdf(annex.id)
                                            }
                                            target="_blank"
                                          >
                                            <FontAwesomeIcon
                                              icon={faFilePdf}
                                              className="me-2"
                                            />
                                            {t("contract_details.download")}
                                          </Button>
                                        </td>
                                        {isContractAdmin && (
                                          <td className="text-center">
                                            {isContractAdmin && (
                                              <Button
                                                variant="error"
                                                className="m-1"
                                                onClick={async () => {
                                                  setAnnexToDelete(annex);
                                                  setOpenConfirmDelete(true);
                                                }}
                                                target="_blank"
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrashCan}
                                                  className="me-2"
                                                />
                                                {t("contract_details.delete")}
                                              </Button>
                                            )}
                                          </td>
                                        )}
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              )}
                            </Card.Body>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12} className="p-1">
              <Card>
                <Card.Body>
                  <h3 className="mb16">{t("contract_details.messages")}</h3>
                  <br></br>
                  {t("contract_details.user_chat")}
                  {contract.chat && (
                    <ChatBox
                      id={contract.chat.id}
                      canUserDelete={isContractAdmin}
                    />
                  )}
                  <hr></hr>
                  {isContractAdmin && (
                    <span>
                      <h3 className="mb16">
                        {t("contract_details.action_history")}
                      </h3>
                      <br></br>
                      {t("contract_details.action_history_for_contract")}
                      {contract.log ? (
                        <ChatBox
                          id={contract.log.id}
                          inputDisabled={true}
                          canUserDelete={false}
                        /> // TO DO: Move History in a modal
                      ) : (
                        <p>{t("contract_details.no_actions")}</p>
                      )}
                    </span>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* Modal pentru generare contract */}
      <Modal
        show={openGenerateCtr}
        size="lg"
        // scrollable={true}
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setOpenGenerateCtr(false);
          setSelectedTemplate("");
          setKeysValues({});
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("contract_details.generate_contract")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    {t("contract_details.select_a_template")}
                  </Form.Label>
                  <Typeahead
                    id="template"
                    labelKey="name"
                    onChange={(selected: any) => {
                      if (selected.length === 0) return;
                      fetchKeysFromTemplate(selected[0].id);
                      setSelectedTemplate(selected[0].id);
                    }}
                    options={templates}
                    placeholder={t(
                      "contract_details.select_a_template_placeholder",
                    )}
                    className={errors.template && "error-control"}
                    // selected={selectedTemplate}
                  />
                </Form.Group>
                {errors.template && (
                  <span className="error-message">{errors.template}</span>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <b>{t("contract_details.keys_avaialable_in_template")}</b>
              </Col>
            </Row>

            {Object.keys(keysValues).length === 0 ? (
              !isSubmitting ? (
                t("contract_details.no_available_key")
              ) : (
                <Spinner animation="border" variant="secondary" size="sm" />
              )
            ) : (
              <Row>
                {Object.entries(keysValues).map(([key]) => (
                  <Col md={6} className="mb-3" key={key}>
                    <Form.Group id="keyValue">
                      <Form.Label>{`{${key}}`}</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ whiteSpace: "pre-line" }}
                        rows={keysValues[key].length > 45 ? 5 : 1}
                        className="form-control "
                        value={keysValues && keysValues[key]}
                        onChange={({ target: { value } }) =>
                          setKeysValues({
                            ...keysValues,
                            [key]: value.replaceAll("\t", " "),
                          })
                        }
                      />
                    </Form.Group>
                  </Col>
                ))}
              </Row>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenGenerateCtr(false);
              setSelectedTemplate("");
              setKeysValues({});
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleGenerateContract}
            disabled={isSubmitting}
          >
            {t("contract_details.generate_contract")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal pentru upload contract semnnat */}
      <Modal
        show={openUploadContract}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setOpenUploadContract(false);
          // setSelectedTemplate("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("contract_details.upload_signed_contract")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <h5 className="mt-3">{t("contract_details.signature_type")}</h5>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={6}>
                <input
                  type="radio"
                  className="form-check-input"
                  value="digital"
                  id="digitalSign"
                  checked={signatureType === "digital"}
                  onChange={handeSignatureChange}
                />
                <label
                  htmlFor="digitalSign"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("contract_details.digital_signature")}
                </label>
              </Col>
              <Col md={6}>
                <input
                  type="radio"
                  value="olograf"
                  id="olografSign"
                  className="form-check-input"
                  checked={signatureType === "olograf"}
                  onChange={handeSignatureChange}
                />
                <label
                  htmlFor="olografSign"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("contract_details.olograph_signature")}
                </label>
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mb-3">
                <Form.Group className="mb-2">
                  {/* <Form.Label>
                    {t("contract_details.registration_number")}
                  </Form.Label> */}

                  {signatureType === "digital" ? (
                    !registrationNumber ? (
                      <>
                        <Form.Label>
                          {t("contract_details.registration_number")}
                        </Form.Label>
                        <p>
                          {t("contract_details.registration_number_message")}
                        </p>
                      </>
                    ) : (
                      // <>
                      //   <br></br>
                      //   <Button
                      //     variant="primary"
                      //     style={{ marginLeft: "5px" }}
                      //     onClick={() => handleAddRegNumber()}
                      //     disabled={isSubmitting}
                      //   >
                      //     {t("contract_details.obtain")}
                      //     {isSubmitting && (
                      //       <Spinner
                      //         animation="border"
                      //         variant="secondary"
                      //         size="sm"
                      //       />
                      //     )}
                      //   </Button>
                      //   {errors.registrationNumber && (
                      //     <>
                      //       <br></br>
                      //       <span className="error-message">
                      //         {errors.registrationNumber}
                      //       </span>
                      //     </>
                      //   )}
                      // </>
                      <>
                        <Form.Label>
                          {t("contract_details.registration_number")}
                        </Form.Label>
                        <p>
                          {registrationNumber}
                          {" / "}
                          {dayjs(contract.registrationDate).format(
                            "DD-MM-YYYY",
                          )}
                        </p>
                        {/* <Form.Control
                        type="text"
                        id="registrationNumber"
                        required
                        className={errors.registrationNumber && "error-control"}
                        value={registrationNumber && registrationNumber}
                        onChange={({ target: { value } }) =>
                          setRegistrationNumber(value)
                        }
                      />
                      (errors.registrationNumber &&
                      <span className="error-message">
                        {errors.registrationNumber}
                      </span> */}
                      </>
                    )
                  ) : (
                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group className="mb-2">
                          <Form.Label>
                            {t("contract_details.registration_number")}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            id="value"
                            required
                            className={
                              errors.registrationNumber && "error-control"
                            }
                            value={registrationNumber && registrationNumber}
                            onChange={({ target: { value } }) =>
                              setRegistrationNumber(value)
                            }
                          />
                          {errors.registrationNumber && (
                            <span className="error-message">
                              {errors.registrationNumber}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>{t("date")}</Form.Label>
                          <Form.Control
                            type="date"
                            name="registrationDate"
                            className={
                              errors.registrationDate && "error-control"
                            }
                            onClick={(e) => {
                              const targetElement =
                                e.target as HTMLInputElement;
                              targetElement.showPicker();
                            }}
                            value={registrationDate && registrationDate}
                            onChange={({ target: { value } }) =>
                              setRegistrationDate(value)
                            }
                          />
                        </Form.Group>
                        {errors.registrationDate && (
                          <span className="error-message">
                            {errors.registrationDate}
                          </span>
                        )}
                      </Col>
                    </Row>
                  )}
                </Form.Group>
              </Col>
              {/* <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>Data inregistrare</Form.Label>
                  <Form.Control
                    type="date"
                    id="registrationDate"
                    name="registrationDate"
                    className={errors.registrationDate && "error-control"}
                    onClick={(e) => {
                      const targetElement = e.target as HTMLInputElement;
                      targetElement.showPicker();
                    }}
                    value={registrationDate && registrationDate}
                    onChange={({ target: { value } }) =>
                      setRegistrationDate(value)
                    }
                  />
                </Form.Group>
                {errors.registrationDate && (
                  <span className="error-message">
                    {errors.registrationDate}
                  </span>
                )}
              </Col> */}
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-2" id="judet">
                  <Form.Label>
                    {t("contract_details.total_contract_value")}
                  </Form.Label>
                  <Form.Control
                    type="number"
                    id="value"
                    required
                    className={errors.value && "error-control"}
                    value={value && value}
                    onChange={({ target: { value } }) => setValue(value)}
                  />
                  {errors.value && (
                    <span className="error-message">{errors.value}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-2" id="judet">
                  <Form.Label>{t("contract_details.duration")}</Form.Label>
                  <Form.Control
                    type="text"
                    id="duration"
                    required
                    className={errors.duration && "error-control"}
                    value={duration && duration}
                    onChange={({ target: { value } }) => setDuration(value)}
                  />
                  {errors.duration && (
                    <span className="error-message">{errors.duration}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="signedPdf">
                  <Form.Label>
                    {t("contract_details.upload_contract")}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="signedPdf"
                    accept=".pdf"
                    className={errors.signedPdf && "error-control"}
                    required
                    onChange={({ target: { files } }: any) => {
                      setSignedPdfName(files[0].name);
                      setSignedPdf(files[0]);
                    }}
                  />
                  {errors.signedPdf && (
                    <span className="error-message">{errors.signedPdf}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    {t("contract_details.signature_date")}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="signDate"
                    name="signDate"
                    className={errors.signDate && "error-control"}
                    onClick={(e) => {
                      const targetElement = e.target as HTMLInputElement;
                      targetElement.showPicker();
                    }}
                    value={signDate && signDate}
                    onChange={({ target: { value } }) => setSignDate(value)}
                  />
                </Form.Group>
                {errors.signDate && (
                  <span className="error-message">{errors.signDate}</span>
                )}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenUploadContract(false);
              setSignDate("");
              setSignedPdf("");
              setOpenUploadContract(false);
              setValue("");
              setDuration("");
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleUploadContract}
            disabled={isSubmitting}
          >
            {t("contract_details.submit")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal pentru upload acte aditionale */}
      <Modal
        show={openUploadAnnex}
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={() => {
          setOpenUploadAnnex(false);
          // setSelectedTemplate("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("contract_details.upload_additional_document")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <h5 className="mt-3">{t("contract_details.signature_type")}</h5>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col md={6}>
                <input
                  type="radio"
                  className="form-check-input"
                  value="digital"
                  id="digitalSign"
                  checked={annexSignatureType === "digital"}
                  onChange={handleAnnexSignatureChange}
                />
                <label
                  htmlFor="digitalSign"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("contract_details.digital_signature")}
                </label>
              </Col>
              <Col md={6}>
                <input
                  type="radio"
                  value="olograf"
                  id="olografSign"
                  className="form-check-input"
                  checked={annexSignatureType === "olograf"}
                  onChange={handleAnnexSignatureChange}
                />
                <label
                  htmlFor="olografSign"
                  style={{
                    marginLeft: "5px",
                    marginTop: "3px",
                    cursor: "pointer",
                  }}
                >
                  {t("contract_details.olograph_signature")}
                </label>
              </Col>
            </Row>
            <Row>
              <Form.Group className="mb-2" id="judet">
                <Form.Label>
                  {t("contract_details.additioanl_document_name")}
                </Form.Label>
                <Form.Control
                  type="text"
                  id="annexName"
                  value={annexName && annexName}
                  onChange={({ target: { value } }) => setAnnexName(value)}
                />
              </Form.Group>
            </Row>
            <Row>
              <Col md={6} className="mb-3">
                <Form.Group className="mb-2" id="judet">
                  <Form.Label>{t("contract_details.number")}</Form.Label>
                  <Form.Control
                    type="text"
                    id="annexNumber"
                    required
                    className={errors.annexNumber && "error-control"}
                    value={annexNumber && annexNumber}
                    onChange={({ target: { value } }) => setAnnexNumber(value)}
                  />
                  {errors.annexNumber && (
                    <span className="error-message">{errors.annexNumber}</span>
                  )}
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>
                    {t("contract_details.registration_date")}
                  </Form.Label>
                  <Form.Control
                    type="date"
                    id="annex"
                    name="registrationDate"
                    className={errors.annexDate && "error-control"}
                    onClick={(e) => {
                      const targetElement = e.target as HTMLInputElement;
                      targetElement.showPicker();
                    }}
                    value={annexDate && annexDate}
                    onChange={({ target: { value } }) => setAnnexDate(value)}
                  />
                </Form.Group>
                {errors.annexDate && (
                  <span className="error-message">{errors.annexDate}</span>
                )}
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group>
                  <Form.Label>{t("contract_details.sign_date")}</Form.Label>
                  <Form.Control
                    type="date"
                    id="annexSignDate"
                    name="signDate"
                    className={errors.annexSignDate && "error-control"}
                    onClick={(e) => {
                      const targetElement = e.target as HTMLInputElement;
                      targetElement.showPicker();
                    }}
                    value={annexSignDate && annexSignDate}
                    onChange={({ target: { value } }) =>
                      setAnnexSignDate(value)
                    }
                  />
                </Form.Group>
                {errors.annexSignDate && (
                  <span className="error-message">{errors.annexSignDate}</span>
                )}
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="annexPdf">
                  <Form.Label>
                    {t("contract_details.upload_additional_document")}
                  </Form.Label>
                  <Form.Control
                    type="file"
                    id="annexPdf"
                    accept=".pdf"
                    className={errors.annexPDF && "error-control"}
                    required
                    onChange={({ target: { files } }: any) => {
                      setAnnexPdfName(files[0].name);
                      setAnnexPdf(files[0]);
                    }}
                  />
                  {errors.annexPdf && (
                    <span className="error-message">{errors.annexPdf}</span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenUploadAnnex(false);
              // setSelectedTemplate("");
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleUploadAnnex}
            disabled={isSubmitting}
          >
            {t("contract_details.submit")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal confirm delete annex */}
      <Modal
        show={openConfirmDelete}
        size="lg"
        onHide={() => {
          setOpenConfirmDelete(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("contract_details.delete_additional_document")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <p>
                {t("contract_details.verifiy_additional_document_deletion")}
              </p>
            </Row>
            <Row></Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenConfirmDelete(false);
              // setSelectedTemplate("");
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="error"
            className="m-1"
            onClick={async () => {
              if (annexToDelete !== null)
                await client.deleteAnnex(annexToDelete.id);
              setAnnexToDelete(null);
              getAllAnnexess();
              setOpenConfirmDelete(false);
            }}
            target="_blank"
          >
            <FontAwesomeIcon icon={faTrashCan} className="me-2" />
            {t("contract_details.delete")}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal upload user contract */}
      <Modal
        show={openUploadUserContract}
        size="lg"
        onHide={() => {
          setOpenUploadUserContract(false);
          // setSelectedTemplate("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("contract_details.upload_contract_signed_by_yourself")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group id="signedPdf">
              <Form.Label>{t("contract_details.upload_contract")}</Form.Label>
              <Form.Control
                type="file"
                id="userSignedPdf"
                accept=".pdf"
                className={errors.userSignedPdf && "error-control"}
                required
                onChange={({ target: { files } }: any) => {
                  setUserSignedPdfName(files[0].name);
                  setUserSignedPdf(files[0]);
                }}
              />
              {errors.userSignedPdf && (
                <span className="error-message">{errors.userSignedPdf}</span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenUploadUserContract(false);
              setSignDate("");
              setSignedPdf("");
              setOpenUploadContract(false);
              setValue("");
              setDuration("");
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handelUploadUserContract}
            disabled={isSubmitting}
          >
            {t("contract_details.submit")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal upload act aditional de beneficiar */}
      <Modal
        show={openUploadUserAnnex}
        size="lg"
        onHide={() => {
          setOpenUploadUserAnnex(false);
          // setSelectedTemplate("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {t("contract_details.upload_signed_additional_document")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group id="userAnnexPdf">
              <Form.Label>
                {t("contract_details.upload_additional_document")}
              </Form.Label>
              <Form.Control
                type="file"
                id="userAnnexPdf"
                accept=".pdf"
                className={errors.userAnnexPdf && "error-control"}
                required
                onChange={({ target: { files } }: any) => {
                  setUserAnnexPdf(files[0]);
                  setUserAnnexPdfName(files[0].name);
                }}
              />
              {errors.userSignedPdf && (
                <span className="error-message">{errors.userSignedPdf}</span>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setUserAnnexPdf("");
              setOpenUploadUserAnnex(false);
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleUploadUserAnnex}
            disabled={isSubmitting}
          >
            {t("contract_details.submit")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal confirm mark contract final */}
      <Modal
        show={openMarkContractFinal}
        size="lg"
        onHide={() => {
          setOpenMarkContractFinal(false);
          // setSelectedTemplate("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("contract_details.mark_contract_final")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("contract_details.mark_contract_final_question")}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenMarkContractFinal(false);
              // setSelectedTemplate("");
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={markContractFinal}
            disabled={isSubmitting}
          >
            {t("contract_details.finalize")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal confirm anulare contract */}
      <Modal
        show={openConfirmCancelContract}
        size="lg"
        onHide={() => {
          setOpenConfirmCancelContract(false);
          setCancelReason("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("contract_details.cancel_contract")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t("contract_details.cancel_contract_question")}</p>
          <Row>
            <Col md={12} className="mb-3">
              <Form.Group className="mb-2" id="judet">
                <Form.Label>{t("contract_details.cancel_reason")}</Form.Label>
                <Form.Control
                  type="text"
                  id="cancelReason"
                  required
                  className={errors.cancelReason && "error-control"}
                  value={cancelReason && cancelReason}
                  onChange={({ target: { value } }) => setCancelReason(value)}
                />
                {errors.cancelReason && (
                  <span className="error-message">{errors.cancelReason}</span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setOpenConfirmCancelContract(false);
              setCancelReason("");
            }}
          >
            {t("contract_details.cancel")}
          </Button>
          <Button
            variant="primary"
            type="submit"
            onClick={handleCancelContract}
            disabled={isSubmitting}
          >
            {t("contract_details.submit")}
            {isSubmitting && (
              <Spinner animation="border" variant="secondary" size="sm" />
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";

function ConfigurareProiecte() {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();

  const [loading, setLoading] = useState(false);

  return (
    <>
      <Container>
        
      </Container>
    </>
  );
}

export default ConfigurareProiecte;

import { useEffect, useState,useRef } from "react";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPIClient } from "../../../helpers/api";
import LoadingView from "../../../layout/LoadingView";
import { ICalendar, IProiect, IVote } from "../../../cjt";
import dayjs from "dayjs";

function VizualizareProiecte() {
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [calendare, setCalendare] = useState<ICalendar[]>(); 
  const [allSubmittedProiecte, setAllSubmittedProiecte] = useState<IProiect[] | null>();
  const [isLoading, setLoading] = useState(false);
  const [selectedCalendarId, setSelectedCalendarId] = useState<string>();

  const [allVotes, setAllVotes] = useState<IVote[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const currentPage = useRef<number>(0);
  const resetVotes = useRef<boolean>(false);

  const fetchAllVotesByCalendarId = async (id: string) => {
    setLoading(true);
    try {
      const request = await client.getVotesByCalendarId(id, currentPage.current, 50);
      if (request && request.data.length > 0) {
        if(resetVotes.current){
          currentPage.current = currentPage.current + 1;
          setAllVotes(request.data);
          resetVotes.current = false;
        }else{
          currentPage.current = currentPage.current + 1;
          setAllVotes((prevState) => [...prevState, ...request.data])
        }
      }

    } catch (error: any) {
      toast.error(error.message as string);
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  }
  
  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight ||
      isFetching || !selectedCalendarId
    )
      return;

    setIsFetching(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  useEffect(() => {
    if (!isFetching) return;

    fetchAllVotesByCalendarId(selectedCalendarId!);
  }, [isFetching]);

  const getAllCalendar = async () => {
    setLoading(true)
    try {
      const requests = await client.getAllCalendar();
      setCalendare(requests);
    } catch (error: any) {
      toast.error(error as string);
    }finally{
      setLoading(false)
    }
  }

  const fetchAllSubmittedProiecte = async (id: string) => {
    setLoading(true)
    try {
      if (id) {
        const submissionProiecte = await client.getAllSubmittedProiecteByCalendarId(id);
        setAllSubmittedProiecte(submissionProiecte);
      }
    } catch (err: any) {
      toast.error(err as string);
    }finally{
      setLoading(false)
    }
  };

  useEffect(() => {
    getAllCalendar();
  }, []);

  const handleChangeCalendar = async (value: string) => {
    resetVotes.current = true;
    //setResetVotes(true);
    currentPage.current = 0;

    fetchAllSubmittedProiecte(value);
    fetchAllVotesByCalendarId(value);
    setSelectedCalendarId(value);
  };

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <Card>
              <Card.Body>
                <h2 className="fw-bold mb-4 text-uppercase text-center">
                {t("sidebar_guvernare_deschisa.vezualizare_proiecte")}
                </h2>
                <Tab.Container defaultActiveKey="vizualizare-proiecte">
                  <Row>
                    <Col lg={12}>
                      <Nav className="nav-tabs">
                        <Nav.Item>
                          <Nav.Link
                            eventKey="vizualizare-proiecte"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("sidebar_guvernare_deschisa.vezualizare_proiecte")}
                          </Nav.Link>
                        </Nav.Item>
                        {allSubmittedProiecte &&
                        <Nav.Item>
                          <Nav.Link
                            eventKey="situatie-voturi"
                            className="mb-sm-3 mb-md-0"
                          >
                            {t("calendar.voting_situation")}
                            
                          </Nav.Link>
                        </Nav.Item>
                        }
                      </Nav>
                    </Col>
                    <Col lg={12}>
                      <Tab.Content>
                        <Tab.Pane eventKey="vizualizare-proiecte" className="py-4">
                          <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                              {isLoading ? (
                                <LoadingView />
                              ) : (
                                <Form>
                                  <Row>
                                    <Col md={12} className="mb-3 mt-2">
                                      <Form.Group className="mb-3">
                                        <Form.Select
                                          id="status"
                                          onChange={({
                                            currentTarget: { value },
                                          }) => handleChangeCalendar(value)}
                                        >
                                          <option value="">{t("calendar.choose_calendar")}...</option>
                                          {calendare?.map(element => (
                                            <option value={element.id} key={element.id}>{element.denumire}</option>
                                          ))}
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  {allSubmittedProiecte && allSubmittedProiecte?.length != 0 && (
                                  <Table
                                    responsive
                                    className="table-centered table-nowrap rounded mb-0"
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th className="border-0">#</th>
                                        <th className="border-0">{t("calendar.submission_date")}</th>
                                        <th className="border-0">{t("user")}</th>
                                        <th className="border-0">{t("state")}</th>
                                        <th className="border-0 text-center">{t("calendar.thead_options")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                    {allSubmittedProiecte?.map((element, index) => (
                                        <tr key="0">
                                          <td>
                                            {index+1}
                                          </td>
                                          <td>
                                          {dayjs(element.formSubmission.createdAt).format("DD-MM-YYYY HH:mm")}
                                          </td>
                                          <td
                                            style={{ whiteSpace: "pre-wrap" }}
                                          >
                                            {element.user.profile.nume} {element.user.profile.prenume}
                                          </td>
                                          <td>{element.status}</td>
                                          <td className="text-center">
                                            <Button
                                              variant="primary"
                                              size="sm"
                                              href={
                                                "/admin/vizualizare-detalii-proiecte/" + element.formSubmission.id
                                              }
                                            >
                                              {t("calendar.detalii")}
                                            </Button>
                                          </td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>)}
                                </Form>
                              )}
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                      {allSubmittedProiecte && 
                      <Tab.Content>
                        <Tab.Pane eventKey="situatie-voturi" className="py-4">
                          <Card border="light" className="shadow-sm mb-4">
                            <Card.Body className="pb-0">
                              <Table responsive className="table-centered table-nowrap rounded mb-0">
                                <thead className="thead-light">
                                  <tr>
                                    <th className="border-0">#</th>
                                    <th className="border-0">{t("calendar.voting_date")}</th>
                                    <th className="border-0">{t("user")}</th>
                                    <th className="border-0">{t("project")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {allVotes.length > 0 ? (
                                  allVotes.map((vote, index) => (
                                  <tr key={index+1}>
                                    <td>{index+1}</td>
                                    <td>{dayjs(vote.created).format("DD-MM-YYYY HH:mm")}</td>
                                    <td>{vote.user.profile.nume} {vote.user.profile.prenume}</td>
                                    <td style={{ whiteSpace: "pre-wrap" }}>{vote.proiect.title}</td>
                                  </tr>
                                  ))
                                ) : (
                                <tr key="0">
                                  <td>{t("calendar.no_votes_exists_on_calendar")}</td>
                                </tr>
                                )}

                                {/* {allSubmittedProiecte.length > 0 ? (
                                  allSubmittedProiecte.map((element) => (
                                    element.vote.length > 0 ? (
                                      element.vote.map((vot, index) => (
                                        <tr key={index}>
                                          <td>{dayjs(vot.created).format("DD-MM-YYYY HH:mm")}</td>
                                          <td>{vot.user.profile.nume} {vot.user.profile.prenume}</td>
                                          <td style={{ whiteSpace: "pre-wrap" }}>{element.title}</td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr key="0">
                                        <td>{t("calendar.no_votes_exists_on_calendar")}</td>
                                      </tr>
                                    )
                                  ))
                                ) : (
                                  <tr key="0">
                                    <td>{t("calendar.no_projects_exists_on_calendar")}</td>
                                  </tr>
                                )} */}
                                </tbody>
                              </Table> 
                            </Card.Body>
                          </Card>
                        </Tab.Pane>
                      </Tab.Content>
                      }
                    </Col>
                  </Row>
                </Tab.Container>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default VizualizareProiecte;

import { Badge } from "@themesberg/react-bootstrap";
import { TFunction } from "i18next";

export default function GrantSubmissionStatus({
  status,
  t,
}: {
  status: string;
  t: TFunction;
}) {
  if (status === "pending") {
    return <Badge bg="primary"> {t("grant_apply.submitted")}</Badge>;
  }

  if (status === "evaluated") {
    return <Badge bg="primary"> {t("grant_apply.evaluated")}</Badge>;
  }

  if (status === "approved") {
    return <Badge bg="success"> {t("grant_apply.approved")}</Badge>;
  }

  if (status === "rejected") {
    return <Badge bg="danger"> {t("grant_apply.rejected")}</Badge>;
  }
}

import { Button, ButtonProps } from "@themesberg/react-bootstrap";

export interface CloakButtonProps extends ButtonProps {
  isVisible: boolean;
}

export default function CloakButton({
  isVisible,
  children,
  ...props
}: CloakButtonProps) {
  return (
    <Button {...props} style={{ display: isVisible ? "block" : "none" }}>
      {children}
    </Button>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Container, Row, Col, Card, Form, Button, Nav, Tab, Table, InputGroup, Modal } from "react-bootstrap";
import { FieldValues, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../config/env";
import { useAPIClient } from "../../../helpers/api";
import { IProiectCategory } from "../../../cjt";

function ProiectCategoryScreen() {
  const { register, handleSubmit, getValues, reset } = useForm();
  const client = useAPIClient();
  const navigate = useNavigate();
  const { i18n, t }: { i18n: any; t: any } = useTranslation<any>();
  const [loading, setLoading] = useState(false);
  const [proiectCategories, setProiectCategories] = useState<IProiectCategory[] | null>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<IProiectCategory>();

  const [newLabel, setNewLabel] = useState<string>();

  //const [formData, setFormData] = useState<IProiectCategory | null>();


  
  const handleRegisterProiectCategory = async () => {
    const formData = getValues() as IProiectCategory;
    try {
      setLoading(true);

      if (!formData.denumire || formData.denumire.trim().length === 0) {
        toast.error("Denumirea categoriei trebuie să conțină minim 1 caracter.");
        return;
      }

      const data = await client.createProiectCategory(formData);
      toast.success("Categoria a fost adaugată cu success.");
      fetchProiectCategories();
      reset();
      return data;
    } catch (error: any) {
      toast.error("A apărut o eroare în adăugarea categoriei: " + error.message as string);
    } finally {
      setLoading(false);
    }
  };

  const fetchProiectCategories = async () => {
    try {
      setLoading(true);
      const categories = await client.getProiectCategories();
      setProiectCategories(categories)
    } catch (error: any) {
      toast.error("A apărut o eroare" + error.message as string);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    fetchProiectCategories();
  }, []);

  const handleClose = (event: any) => {
    event.preventDefault();
    setShowEditModal(false);
    setShowDeleteModal(false);
  };

  const handleUpdateCategory = async (event: any) => {
    try {
      setLoading(true);

      if (!newLabel || newLabel.trim().length === 0) {
        toast.error("Denumirea categoriei trebuie să conțină minim 1 caracter.");
        return;
      }
      
      const data = await client.updateProiectCategory(selectedCategory!.id, {denumire: newLabel!});
      toast.success("Categoria a fost modificată cu success.");
      fetchProiectCategories();
      reset();
      return data;
    } catch (error: any) {
      toast.error("A apărut o eroare în modificarea categoriei: " + error.message as string);
    } finally {
      setLoading(false);
      setShowEditModal(false);
    }
  };

  const handleDeleteCategory = async (event: any) => {
      try {
        setLoading(true);
        const categories = await client.deleteProiectCategory(selectedCategory!.id);
        toast.success("Categoria a fost șteasă cu success.");
        fetchProiectCategories();
        reset();
      } catch (error: any) {
        toast.error("A apărut o eroare" + error.message as string);
      } finally {
        setLoading(false);
        setShowDeleteModal(false);
      }
  }

  return (
    <>
      <Container>
      <Row>
        <Col xs={12} className="mt-5">
          <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0">
                <Form>
                  <Row>
                    <h5>{t("calendar.insert_new_category")}</h5>
                    <Col md={12} className="mb-3 mt-2">
                    <Form.Group className="d-flex align-items-center" id="depunere-start">
                      <Form.Label style={{marginRight:"1em"}}>{t("calendar.category_name")}:</Form.Label>
                      <Form.Control type="text" {...register("denumire", { required: true })} placeholder="Introducere categorie..."/>
                    </Form.Group>
                      <Button
                        variant="primary"
                        onClick={() => handleRegisterProiectCategory()}
                      >
                        {t("calendar.save")}
                      </Button>
                    </Col>
                  </Row>
                </Form>
                
              </Card.Body>
            </Card>
            <Card border="light" className="shadow-sm mb-4">
              <Card.Body className="pb-0">
                <Row>
                  <h5>{t("calendar.defined_categories")}</h5>
                  <Col md={12} className="mb-3 mt-2">
                  <Table
                      responsive
                      className="table-centered table-nowrap rounded mb-0"
                    >
                        <thead className="thead-light">
                          <tr>
                            <th className="border-0">{t("calendar.thead_name")}</th>
                            <th className="border-0">{t("calendar.thead_options")}</th>
                          </tr>
                        </thead>

                        <tbody>
                          {proiectCategories && proiectCategories.length > 0 ? proiectCategories?.map(element => (
                            <tr>
                              <td>
                                {element.denumire}
                              </td>
                              <td colSpan={1} className="text-center">
                                  <Button
                                    variant="primary me-2"
                                    onClick={() => {setShowEditModal(true); setSelectedCategory(element)}}
                                  >
                                    {t("account_modify_button")}
                                  </Button>

                                  <Button
                                    variant="danger"
                                    onClick={() => {setShowDeleteModal(true); setSelectedCategory(element)}}
                                  >
                                    {t("calendar.delete")}
                                  </Button>
                                </td>
                            </tr>
                            )) : (
                            <>
                              <tr>
                                <td colSpan={1} className="text-center">
                                {t("calendar.no_categories")}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                    </Table>
                  </Col>
                </Row>                              
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal backdrop="static" show={showEditModal}>
          <Modal.Header>
            <Modal.Title>{"Ștergere!"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Modificare categorie</p>
            <Form.Control type="text" placeholder="Introducere categorie..." defaultValue={selectedCategory?.denumire} onChange={(event) => {setNewLabel(event.target.value)}}/>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(event) => handleClose(event)}>
              Anuleaza
            </Button>
            <Button variant="primary" onClick={(event) => handleUpdateCategory(event)}>
              Modifică
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal backdrop="static" show={showDeleteModal}>
          <Modal.Header>
            <Modal.Title>{"Ștergere!"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Șterge categorie</p>
           <p>Confirmați ștergerea categoriei <b>{selectedCategory?.denumire}</b></p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={(event) => handleClose(event)}>
              Anuleaza
            </Button>
            <Button variant="primary" onClick={(event) => handleDeleteCategory(event)}>
              Șterge
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
}

export default ProiectCategoryScreen;

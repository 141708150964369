import React, { useState, useEffect } from "react";
import { Form, Button, Modal, ModalProps, Badge, Stack } from "react-bootstrap";
import { useAPIClient } from "../../../helpers/api";
import { toast } from "react-hot-toast";
import { ICategory, ISurveyAnswer } from "../../../cjt";
import { Trash3 } from "react-bootstrap-icons";
import { MdClose } from "react-icons/md";
import handleException from "../../../helpers/exceptions";
import { useTranslation } from "react-i18next";

interface IModalEditQuestionProps extends ModalProps {}

function ModalEditQuestion({
  showModalEditQuestion,
  setShowModalEditQuestion,
  handleClose,
  question,
  fetchSurveys,
}: IModalEditQuestionProps) {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [questionContent, setQuestionContent] = useState("");

  const [initialKeywords, setInitialKeywords] = useState<string[]>([]);
  const [keywords, setKeywords] = useState<string[]>([]);
  const [newKeyword, setNewKeyword] = useState<string>("");

  const addKeyword = () => {
    if (newKeyword.trim() !== "") {
      if (keywords.includes(newKeyword)) {
        toast.error(t("survey.error_keyword_exists"));
        return;
      }

      setKeywords([...keywords, newKeyword]);
      setNewKeyword("");
    } else {
      toast.error(t("survey.insert_keyword"));
    }
  };

  const removeKeyword = (index: number) => {
    const updatedKeywords = keywords.filter((_, i) => i !== index);
    setKeywords(updatedKeywords);
  };

  const fetchQuestionData = async () => {
    if (!question) return;
    try {
      setKeywords([]);
      setInitialKeywords([]);
      const res = await client.getSurveyQuestionByid(question.id);
      const fetchedKeywords = res.answers.map((answer: ISurveyAnswer) => answer.answer);
      setKeywords(fetchedKeywords);
      setInitialKeywords(fetchedKeywords);
      setQuestionContent(res.question);
    } catch (err) {
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(t("survey.error_cannot_load_questions"));
      });
    }
  };

  useEffect(() => {
    fetchQuestionData();
  }, [question]);

  const editQuestion = async () => {
    if (questionContent === "") {
      toast.error(t("survey.error_insert_question"));
      return;
    }
    if (
      questionContent == question.content &&
      keywords.every((q) => question.keywords.includes(q))
    ) {
      toast.error(t("survey.error_no_changes"));
      return;
    }
    if (keywords.length === 0) {
      toast.error(t("survey.insert_at_least_keyword"));
      return;
    }

    try {

        const edit_question = await client.editSurveyQuestion(
            question.id,
            questionContent
        );

        for(let i = 0; i < initialKeywords.length; i++){
            await client.deleteSurveyAnswer(question.answers[i].id);
        }

        for (let i = 0; i < keywords.length; i++) {
            const answer = await client.addSurveyAnswer(
                question.id,
                keywords[i]
            )  
        }

      //await client.addAnswer(question.id, answerContent);

      setShowModalEditQuestion(false);
      toast.success(t("survey.success_question_edited"));
      fetchQuestionData();
      fetchSurveys();
      setQuestionContent("");
      setKeywords([]);
      setInitialKeywords([]);
    } catch (err) {
      // console.log(err);
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };
  return (
    <Modal show={showModalEditQuestion} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("survey.edit_question")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="question" className="mt-3">
          <Form.Label>{t("survey.question")}:</Form.Label>
          <Form.Control
            type="text"
            // placeholder="Intrebare"
            value={questionContent}
            onChange={(e) => setQuestionContent(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="keyword" className="mt-3">
          <Form.Label>{t("survey.add_asn_to_question")}:</Form.Label>
          <div className="d-flex ">
            <Form.Control
              type="text"
              // placeholder="Enter a new keyword"
              value={newKeyword}
              style={{ width: "80%", marginRight: "10px" }}
              onChange={(e) => setNewKeyword(e.target.value)}
            />
            <Button variant="primary" onClick={addKeyword}>
            {t("calendar.add")}
            </Button>
          </div>
        </Form.Group>

        <Stack direction="horizontal" className="mt-3">
          {keywords.map((keyword, index) => (
            <div className="single-tag" key={index} style={{color:"black",border:"0"}}>
                <Form.Check
                    type="radio"
                    label={keyword}
                    disabled
                />
                <i style={{color:"black"}} onClick={() => removeKeyword(index)}>
                <MdClose />
              </i>
            </div>
          ))}
        </Stack>
        <p><i>{t("survey.edit_question_warning")} </i></p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        {t("grant_apply.close")} 
        </Button>
        <Button variant="primary" onClick={editQuestion}>
        {t("edit")} 
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalEditQuestion;

import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useAPIClient } from "../../helpers/api";
import FormListView from "../../components/Forms/FormListView";
import LoadingView from "../../layout/LoadingView";
import { ITemplateBody } from "../../cjt";
import { TagForm } from "../AdminSettings/TagForms";
import { Container } from "react-bootstrap";
import handleException from "../../helpers/exceptions";
import { useTranslation } from "react-i18next";

function SmartForms() {
  const [forms, setForms] = useState<ITemplateBody[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const client = useAPIClient();
  const { t } = useTranslation();

  const fetchForms = async () => {
    setIsLoading(true);
    try {
      const res = await client.getTemplates();
      const templates = await Promise.all(
        res.map((template) => {
          return client.getTemplateById(template.id);
        })
      );
      setForms(templates);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.forEach((message) => {
        toast.error(message);
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchForms();
  }, []);

  const handleRefresh = () => {
    fetchForms();
  };

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <>
      <Container>
        <TagForm></TagForm>
        <FormListView items={forms} refreshCallback={handleRefresh} />
      </Container>
    </>
  );
}

export default SmartForms;

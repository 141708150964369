import { useTranslation } from "react-i18next";

import { Routes } from "../routes";

function Footer() {
  const { t } = useTranslation();
  return (
    <div className="content">
      <footer className="py-3 my-4">
        <p className="text-center text-muted">
          {t("poca.text1")} <br></br>
          <a
            target="_blank"
            className="text-primary fw-bold text-decoration-none"
            href="http://www.poca.ro"
          >
            www.poca.ro
          </a>
          <br></br>
          {t("poca.text2")}{" "}
          <a
            target="_blank"
            className="text-primary fw-bold text-decoration-none"
            href="https://www.fonduri-ue.ro"
          >
            www.fonduri-ue.ro
          </a>
          <br></br>
          {t("poca.text3")}
          <br></br>
          <br></br>
          <a href={t("footer.terms.link")} className="mx-2" target="_blank">
            {t("footer.terms")}
          </a>
          <a href={t("footer.privacy.link")} target="_blank">
            {t("footer.privacy")}
          </a>
          <br></br>
          {t("credits")}
          <a
            className="text-primary fw-bold text-decoration-none"
            href="https://ici.ro"
          >
            {t("ici")}
          </a>
          .
        </p>
      </footer>
    </div>
  );
}

export default Footer;

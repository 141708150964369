import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Table } from "@themesberg/react-bootstrap";
import dayjs from "dayjs";
import orderBy from "lodash/orderBy";
import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusiness, IContractFolder, IProjectFolder } from "../../../cjt";
import { useAuthContext } from "../../../context/AuthContext";
import { AccountAttribute } from "../../../enums";
import { useAPIClient } from "../../../helpers/api";
import handleException from "../../../helpers/exceptions";
import LoadingView from "../../../layout/LoadingView";

export default function ListUserProjects() {
  const client = useAPIClient();
  const { t } = useTranslation();
  const [openAddProjectModal, setOpenAddProject] = useState(false);
  const [projects, setProjects] = useState<IProjectFolder[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bussinesses, setBusinesses] = useState<IBusiness[]>([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedBusinesses, setSelectedBusinesses] = useState<
    IBusiness[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: any }>({});
  const [newProjectName, setNewProjectName] = useState("");

  const { requiredAttribute } = useAuthContext();

  const isProjectAdmin = requiredAttribute(
    AccountAttribute.ProjectsAdministrator,
  );

  const fetchProjectList = async () => {
    try {
      const res: IProjectFolder[] = await client.getAllUserProjects();

      setProjects(res);
      setIsLoading(false);
    } catch (err: any) {
      const errorMessages = handleException(err, t);
      errorMessages.map((message) => {
        toast.error(message);
      });
    }
  };

  const getPartenersLabel = (project: IProjectFolder /* Add interface */) => {
    var label: string = "";
    const businesses: any[] = project.participants;
    businesses.every((business, i) => {
      // use every in order to break by returning false when the condition is met
      if (label.length < 30) {
        if (30 - label.length < business.denumire.length) {
          label +=
            String(business.denumire).substring(0, 30 - label.length) + "...";
          return false;
        } else label += business.denumire;

        label += i == businesses.length - 1 ? " " : ", ";

        return true;
      } else {
        label += "...";
        return false;
      }
    });

    console.log(label);
    return label;
  };

  const getFullPartenersString = (
    project: IProjectFolder /* Add interface */,
  ) => {
    var fullName: string = "";
    const businesses: Partial<IBusiness>[] = project.participants;
    businesses.forEach((business, i) => {
      fullName += business.denumire;
      fullName += i == businesses.length - 1 ? " " : ", ";
    });

    return fullName;
  };

  useEffect(() => {
    fetchProjectList();
  }, []);

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5 text-uppercase text-center">
              {t("PROJECTS")}
            </h2>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={12}>
                    {projects && projects.length === 0 ? (
                      <div className="d-flex justify-content-center align-items-center mb-3">
                        <h5 className="mb-0">{t("NO_PROJECTS_ADDED")}</h5>
                      </div>
                    ) : (
                      <Card border="light" className="shadow-sm mb-4">
                        <Card.Body className="pb-0">
                          {isLoading ? (
                            <LoadingView />
                          ) : (
                            <Table
                              responsive
                              className="table-centered table-nowrap rounded mb-0"
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="border-0">#</th>
                                  <th className="border-0">
                                    {t("PROJECTS_TABLEHEAD_DATE")}
                                  </th>
                                  <th className="border-0">
                                    {t("PROJECTS_TABLEHEAD_NAME")}
                                  </th>
                                  <th className="border-0">
                                    {t("PROJECTS_TABLEHEAD_PARTNERS")}
                                  </th>
                                  <th className="border-0 text-center"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {orderBy(projects, ["createdAt"], ["desc"]).map(
                                  (project, i) => (
                                    <tr key={i}>
                                      <td>
                                        <Card.Link
                                          href="#"
                                          className="text-primary fw-bold"
                                        >
                                          {i + 1}
                                        </Card.Link>
                                      </td>
                                      <td>
                                        {dayjs(project.createdAt).format(
                                          "DD-MM-YYYY",
                                        )}
                                      </td>
                                      <td style={{ whiteSpace: "pre-wrap" }}>
                                        {project.name}
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip className="m-0">
                                              {getFullPartenersString(project)}
                                            </Tooltip>
                                          }
                                        >
                                          <span>
                                            {getPartenersLabel(project)}
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td className="text-center">
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                          href={
                                            isProjectAdmin
                                              ? "/admin/project-details/" +
                                                project.id
                                              : "/account/project-details/" +
                                                project.id
                                          }
                                        >
                                          {t("PROJECT_TABLE_SEEDETAILS")}
                                        </Button>
                                      </td>
                                    </tr>
                                  ),
                                )}
                              </tbody>
                            </Table>
                          )}
                        </Card.Body>
                      </Card>
                    )}
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

import { Button } from "@themesberg/react-bootstrap";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { IBusiness, IReceivedInvoiceWithFileName, IUser } from "../../cjt";
import { useAPIClient } from "../../helpers/api";
import handleException from "../../helpers/exceptions";
import SelectUser, { OptionType } from "./SelectUser";

interface UserOptionType extends OptionType {
  instance: IUser | IBusiness;
}

export default function ListUserPayments() {
  const { t } = useTranslation();
  const [user, setUser] = useState<UserOptionType | null>(null);
  const [invoiceList, setInvoiceList] = useState<
    IReceivedInvoiceWithFileName[]
  >([]);
  const client = useAPIClient();

  const getAllInvoices = async (identifier: string) => {
    const res = await client.getAllReceivedPayments(identifier);
    console.log(res);
    setInvoiceList(await client.getAllReceivedPayments(identifier));
  };

  useEffect(() => {
    if (user) {
      if (user.tip === "pj") {
        getAllInvoices((user.instance as IBusiness).cui);
      } else {
        getAllInvoices((user.instance as IUser).profile.cnp);
      }
    }
  }, [user]);

  return (
    <>
      <Container className="px-0">
        <Row>
          <Col xs={12} className="p-3">
            <h2 className="fw-bold mb-5  text-center">{t("invoice.my_invoice")}</h2>
            <Card>
              <Card.Body>
                <Row>
                  <SelectUser setpUser={setUser} />
                </Row>
                <Row className="mt-2">
                  <Col lg={12}>
                    <Card border="light" className="shadow-sm mb-4">
                      <Card.Body className="pb-0">
                        {invoiceList.length === 0 ? (
                          <h6>{t("invoice.no_invoice")}</h6>
                        ) : (
                          <Table
                            responsive
                            className="table-centered table-nowrap rounded mb-0"
                          >
                            <thead className="thead-light">
                              <tr>
                                <th className="border-0">#</th>
                                <th className="border-0">{t("invoice.number")}</th>
                                <th className="border-0"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoiceList.map((invoice, i) => (
                                <tr key={i}>
                                  <td>
                                    <Card.Link
                                      href="#"
                                      className="text-primary fw-bold"
                                    >
                                      {i + 1}
                                    </Card.Link>
                                  </td>
                                  <td>{invoice.fileName}</td>
                                  <td>
                                    <Button
                                      size="sm"
                                      onClick={async () => {
                                        try {
                                          await client.getReceivedPaymentDocument(
                                            invoice.id,
                                          );
                                        } catch (err: any) {
                                          const errorMessages = handleException(
                                            err,
                                            t,
                                          );
                                          errorMessages.map((message) => {
                                            toast.error(message);
                                          });
                                        }
                                      }}
                                    >
                                      {t("invoice.download")}
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
